import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import DynamicPages from '@/views/DynamicPages'
import p404 from '@/views/404'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE,
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // Default to 404
    {
      path: '/',
      name: 'home',
      component: p404,
      meta: {
        lang: 'fr',
      },
    },

    // Dynamic pages
    {
      path: '/en',
      name: 'homeEn',
      component: DynamicPages,
      meta: {
        lang: 'en',
      },
    },
    {
      path: '/:saison/:campagne/:type/:page',
      name: 'page',
      component: DynamicPages,
      meta: {
        lang: 'fr',
      },
    },
    {
      path: '/en/:saison/:campagne/:type/:page',
      name: 'pageEn',
      component: DynamicPages,
      meta: {
        lang: 'en',
      },
    },

    // Legacy pages and 404
    {
      path: '/brindo-aquaclub',
      name: 'brindo',
      component: () => import(/* webpackChunkName: "brindo" */ '@/views/legacy/Brindo.vue'),
      meta: {
        lang: 'fr',
      },
    },
    {
      path: '/en/brindo-aquaclub',
      name: 'brindoEn',
      component: () => import(/* webpackChunkName: "brindoEn" */ '@/views/legacy/BrindoEn.vue'),
      meta: {
        lang: 'en',
      },
    },
    {
      path: '/brindo-aquaclub/termes',
      name: 'brindo',
      component: () => import(/* webpackChunkName: "brindo" */ '@/views/legacy/BrindoTerms.vue'),
      meta: {
        lang: 'fr',
      },
    },
    {
      path: '/en/brindo-aquaclub/terms',
      name: 'brindoEn',
      component: () => import(/* webpackChunkName: "brindoEn" */ '@/views/legacy/BrindoEnTerms.vue'),
      meta: {
        lang: 'en',
      },
    },
    {
      path: '/404',
      name: '404',
      component: p404,
      meta: {
        lang: 'fr',
      },
    },
    { path: '*', redirect: '/404' },
  ],
})

export default router
