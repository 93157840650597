<template>
  <div id="site-container" class="site-container">
    <div class="header-container">
      <section class="promo-header"></section>

      <header class="header error">
        <div class="header-nav">
          <div class="header-left">
            <img class="logo" src="https://tremblant-promo-demo.jnv.dev/assets/images/logo.png" alt="Logo Tremblant" title="Logo Tremblant" />
            <img
              class="tagline"
              src="https://tremblant-promo-demo.jnv.dev/assets/images/tagline-fr.png"
              alt="Emmène moi au sommet"
              title="Emmène moi au sommet"
            />
          </div>

          <div class="header-middle">
            <img class="logo" src="https://tremblant-promo-demo.jnv.dev/assets/images/logo.png" alt="Logo Tremblant" title="Logo Tremblant" />
            <img
              class="tagline"
              src="https://tremblant-promo-demo.jnv.dev/assets/images/tagline-fr.png"
              alt="Emmène moi au sommet"
              title="Emmène moi au sommet"
            />
          </div>

          <div class="header-right">
            <p class="reading-text light">
              <a href="tel:+" class="link light phone"> </a>
              <a href="tel:+" class="link light phone mobile"></a>
            </p>
          </div>
        </div>
      </header>
    </div>

    <main class="main">
      <section class="page404">
        <div
          class="bg-image"
          style="background-image: url('https://tremblant-promo-demo.jnv.dev/files/campaign/161211AVTSMT_daily_SummitSunrise-7370.jpg')"
        >
          <h1 class="title light">
            Page non trouvée <br />
            Page not found
          </h1>
          <p class="title2 light">
            La page que vous avez tenté de rejoindre n'existe pas. <br />
            Sorry, the page you were looking for does not exist.
          </p>
          <a class="cta" href="https://www.tremblant.ca/plan" target="_blank">Tremblant.ca</a>
        </div>
      </section>
    </main>

    <footer class="footer">
      <div class="covid-note">
        <p class="reading-text">
          Veuillez noter que certains contenus présentés sur
          <a
            href="https://www.tremblant.ca/?sc_lang=fr&amp;webSyncID=d78c5eaa-51e4-8183-e5c5-d560608d673d&amp;sessionGUID=edd91d24-672b-7dd8-90e7-209b49dd3ad0"
            target="_blank"
            >tremblant.ca</a
          >
          ne représentent pas l’expérience avec les mesures sanitaires en place. Merci de
          <a
            href="https://www.tremblant.ca/montagne-village/mesures-sanitaires?sc_lang=fr&amp;webSyncID=d78c5eaa-51e4-8183-e5c5-d560608d673d&amp;sessionGUID=edd91d24-672b-7dd8-90e7-209b49dd3ad0"
            target="_blank"
            >les consulter</a
          >
          avant votre venue.
        </p>
      </div>
      <div class="right-footer">
        <p class="left-footer reading-text">Tremblant © 2021</p>
        <span class="reading-text separator">|</span>
        <a
          class="reading-text link"
          href="https://www.alterramtnco.com/politique-de-vie-privee?webSyncID=d78c5eaa-51e4-8183-e5c5-d560608d673d&amp;sessionGUID=edd91d24-672b-7dd8-90e7-209b49dd3ad0"
        >
          Politique de vie privée</a
        >
        <span class="reading-text separator">|</span>
        <a
          class="reading-text link"
          href="https://www.alterramtnco.com/conditions-dutilisation?webSyncID=d78c5eaa-51e4-8183-e5c5-d560608d673d&amp;sessionGUID=edd91d24-672b-7dd8-90e7-209b49dd3ad0"
        >
          Conditions d'utilisation</a
        >
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'P404',

  head() {
    return {
      title: {
        inner: 'Tremblant',
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: 'Tremblant' },
        {
          name: 'description',
          content: 'Page non trouvé',
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: 'Tremblant' },
        { name: 'twitter:card', content: 'Tremblant' },
        {
          n: 'twitter:description',
          c: 'Page non trouvé',
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Tremblant' },
        { property: 'og:site_name', content: 'Tremblant' },
        {
          property: 'og:description',
          content: 'Page non trouvé',
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://promo.tremblant.ca/files/campaign/161211AVTSMT_daily_SummitSunrise-7370.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://promo.tremblant.ca/files/campaign/161211AVTSMT_daily_SummitSunrise-7370.jpg',
        },
      ],
      link: [
        { rel: 'stylesheet', href: 'https://tremblant-promo-demo.jnv.dev/legacy-assets/css/site.combined.min.css' },
        { rel: 'stylesheet', href: 'https://tremblant-promo-demo.jnv.dev/legacy-assets/css/support-old-campaing.css' },
      ],
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main {
  text-align: center;
  line-height: 1.4;
}

.cta {
  background-color: #bf3038;
  text-align: center;
  padding: 14px 25px;
  text-decoration: none;
  border-radius: 3px;
  transition: all 0.2s ease;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  font-family: stag_bold, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1;
}
</style>
