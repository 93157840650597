<template>
  <div id="app">
    <div class="main-wrapper">
      <router-view />
    </div>
  </div>
</template>
<script>
  import { isNil } from 'lodash'
  
  export default {
    watch: {
      $route: {
        handler($route) {
          if (!isNil($route)) {
            if ($route.meta.lang === 'en') {
              this.$i18n.locale = 'en'
            } else {
              this.$i18n.locale = 'fr'
            }
          }
        },
        immediate: true
      }
    },
    methods: {
      toogleNavBarStatus() {
        const st = document.documentElement.scrollTop

        if (!document.querySelector('.main-header')) {
          return
        }

        if( st >= 40 ) {
          document.querySelector('.main-header').classList.add('sticky')
        }else{
          document.querySelector('.main-header').classList.remove('sticky')
        }
      }
    },
    created() {
      // NavBar Follow
      this.toogleNavBarStatus()
      window.addEventListener('scroll', () => {
        this.toogleNavBarStatus()
      })
    },
  }
</script>

<style lang="scss">
@import '@/theme/variables.scss';
@import '@/theme/typos.scss';
@import '@/theme/animations-layer.scss';
@import '@/theme/button.scss';
@import '@/theme/header.scss';
@import '@/theme/hero.scss';
@import '@/theme/privilege.scss';
@import '@/theme/offre.scss';
@import '@/theme/cross-sell.scss';
@import '@/theme/overlays.scss';
@import '@/theme/glightbox.scss';
@import '@/theme/newsletter.scss';
@import '@/theme/terms.scss';
@import '@/theme/flickity.scss';
@import '@/theme/events-activity.scss';
@import '@/theme/video.scss';
@import '@/theme/quoi-faire.scss';
@import '@/theme/calendar-events.scss';
@import '@/theme/activities.scss';
@import '@/theme/bloc.scss';
@import '@/theme/golf.scss';
@import '@/theme/price-grid.scss';
@import '@/theme/vols.scss';
@import '@/theme/brindo.scss';

* {
  box-sizing: border-box;
}

.logo-partenaire {
  width: 100%;
  text-align: center;
  padding: 15px 0 25px 0;

  img {
    width: 150px;
    display: inline-block;
  }
}
</style>
