export const hiver2022En = {
  site: {
      'achetez-long': 'Buy your tickets',
      'achetez-pass': 'Buy your pass',
      'achetez-forfait': 'Buy your bundle',
      'achetez-short': 'Buy',
      lodging: 'Lodging',
      'reservez-long': 'Book your stay',
      'reservez-short': 'Book',
      slogan: 'Buzzing<br>from peak to village',
      'show-details': 'Show details',
      'promo-code': '* Promo code required',
      'enjoy-offer': 'Enjoy the offer',
      modalites: 'Terms & Conditions',
      'modalites-price': 'Terms & Conditions | Full pricing chart',
      arrivee: 'Arrival',
      depart: 'Departure',
      adulte: 'Adults',
      enfant: 'Kids',
      'adulte-2': 'Adult',
      'enfant-2': 'Child',
      'news-alert': 'Stay tuned!',
      more: 'Learn more',
      'mesures-note': `A proof of vaccination with a valid photo ID is required to access the ski lifts. Restaurants are only open for takeout. <a href="https://www.tremblant.ca/mountain-village/health-measures?sc_lang=en&webSyncID=3c97c009-b6bc-d746-b80d-8e8abeeaed6a&sessionGUID=e05c117a-ac5a-9199-362b-9eb3b36c8a95" target="_blank">Find out more</a>`,
      'mesures-note-smt': `<a href="/en/hiver/2122/launch/terms-latitude/">Proof of vaccination</a> and photo ID are required to access the ski lifts, restaurants, bars and certain activities.`,

      'countdown-title': 'Offer ends in',
      'countdown-jour': 'D',
      'countdown-heure': 'H',
      'countdown-minute': 'Min.',
      'countdown-seconde': 'Sec.',

      'activity-title': 'Things to do at Tremblant',

      'vols-title': 'Direct Flights to<br>Mont-Tremblant',
      'vols-desc':
      'Only 65 minutes from Toronto when you fly Porter!<br><br>For all your business or leisure needs, Porter offers direct flights from Toronto’s Billy Bishop Airport (YTZ) to Mont-Tremblant International Airport (YTM), plus connections to Ottawa, Sudbury, Windsor, Thunder Bay, Sault Ste. Marie and many more destinations.',
      'vols-cta': 'Learn more',
      'vols-link': 'https://www.flyporter.com/en-ca/',

      'newsletter-title': 'Stay tuned for our offers<br>and updates',
      'newsletter-cta': 'Subscribe now',
      'newsletter-link':
        'https://www.tremblant.ca/email/sign-up?sessionGUID=0123ccb6-7d57-c57a-be28-dbd0b50c7629&webSyncID=8e55a66e-00dc-9a6d-56be-22b1b35359d8&_gl=1*10n8f2g*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTE0NDg2Ny4xMi4xLjE2MjkxNDU5NjYuMA..&_ga=2.10862004.1090954148.1629141557-1179124804.1583853558&sc_lang=en',
      'newsletter-txt':
        'To manage your email preferences, <a href="https://www.tremblant.ca/email/preferences?sessionGUID=0123ccb6-7d57-c57a-be28-dbd0b50c7629&webSyncID=8e55a66e-00dc-9a6d-56be-22b1b35359d8&_gl=1*uuc5z1*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTM5NTkyMS4xOS4xLjE2MjkzOTY2MjUuMA..&_ga=2.212644116.1090954148.1629141557-1179124804.1583853558&sc_lang=en" target="_blank">click here</a>. To unsubscribe, <a href="https://www.tremblant.ca/email/unsubscribe?sessionGUID=0123ccb6-7d57-c57a-be28-dbd0b50c7629&webSyncID=8e55a66e-00dc-9a6d-56be-22b1b35359d8&_gl=1*xn8k4p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTM5NTkyMS4xOS4xLjE2MjkzOTY3NTYuMA..&_ga=2.212644116.1090954148.1629141557-1179124804.1583853558&sc_lang=en" target="_blank">click here</a>.',

      'footer-data':
        'Tremblant © 2022-2023 | <a href="https://www.alterramtnco.com/privacy-policy/?webSyncID=8e55a66e-00dc-9a6d-56be-22b1b35359d8&sessionGUID=0123ccb6-7d57-c57a-be28-dbd0b50c7629" target="_blank">Privacy Policy</a> | <a href="https://www.alterramtnco.com/terms-of-use/?webSyncID=8e55a66e-00dc-9a6d-56be-22b1b35359d8&sessionGUID=0123ccb6-7d57-c57a-be28-dbd0b50c7629" target="_blank">Terms of Use</a>',

      'dispo-title': 'Enjoy best unit availabilities AND THE<br>&nbsp;BEST CURRENT LODGING OFFERS',

      'eco-title': 'Tremblant, a leader in ecoresponsibility',
      'eco-data':
        'In 2021, Mont Tremblant Resort and Company will become the first Quebec company to obtain a level 3 certification in the Ecoresponsible program controlled by Ecocert Canada.<br><br>You can contribute too: Ski at Tremblant this winter and reduce your environmental footprint by offsetting your travels.',
      'eco-cta': 'Contribute now',
      'eco-link': 'https://www.tremblant.ca/about-us/eco-responsibility?sc_lang=en',

      'ecole-title': 'Tremblant<br>Snow School',
      'ecole-data':
        'Enjoy lessons and programs with certified specialists, whether you are a beginner or advanced. Our programs are for skiers and snowboarders as well.<br><br>Take advantage of your stay to perfect your technique!',
      'ecole-cta': 'Learn more',
      'ecole-link': 'https://www.tremblant.ca/plan/tremblant-snow-school?sc_lang=en',

      'rando-title': 'Alpine touring<br>pass included with your Tonik Pass',
      'rando-data': 'Enjoy our designated areas for climbing<br><br>Come climb the highest peak in the Laurentians with your skis on in one of our designated areas and trails, and ski down one of our 102 marked ski runs!',

      'heb-title': 'Save up to<br>30% on lodging',
      'heb-data':
        'Offer valid until October 19, 2021<br><br>You have up to October 19, 2021, to access the best units while taking advantage of the greatest discounts of the season! Book your vacation right away, even for Christmas or Easter.',

      'la-heb-title': 'Save up to<br>25% on lodging',
      'la-heb-data':
        'Offer valid until November 30, 2021<br><br>You have up to October 19, 2021, to access the best units while taking advantage of the current discounts! Book your vacation right away, even for Christmas or Easter.',

      'entrepot-heb-title': 'Save up to<br>20% on lodging',
      'entrepot-heb-data':
        'Access the best units while taking advantage of the current discounts! Book your vacation right away, even for Christmas or Easter.',

      'privilege-title': 'Lodging Privileges<br><span class="small-title">Skate Loan, First Tracks and Jardins de glace</span>',
      'privilege-price': 'VALUE OF $45 per adult, per day',
      'privilege-more': 'Learn more on lodging privileges',
      'privilege-data': `<h3 class="small-title">Skate Loan, First Tracks and Jardins de glace</h3>
                              <p class="regular-text bold">Skate Loan</p>
                              <p class="regular-text">The ice rink by the St-Bernard Chapel is welcoming you with music and a crackling fire. Enjoy skating throughout the evening with lighting! Free 2-hour skate loan from 1 p.m. to 9 p.m. from December 18, 2021 to March 20, 2022. Advanced booking required.</p>
                              <ul>
                                <li class="regular-text">Wearing a protective helmet recommended but not compulsory</li>
                                <li class="regular-text">Wearing a face cover recommended but not mandatory</li>
                              </ul>
                              <p class="regular-text bold">First Tracks</p>
                              <p class="regular-text">Be the first to carve the fresh snow! From December 18, 2021, to March 20, 2022, get on the lift 45 minutes before the official opening! Offer available at participating hotels.</p>
                              <ul>
                                <li class="regular-text">Access to designated lifts as early as 7:45&nbsp;a.m.</li>
                                <li class="regular-text">Ski on designated North and South Side slopes at 7:45&nbsp;a.m.</li>
                              </ul>
                              <p class="regular-text bold">Jardins de glace</p>
                              <p class="regular-text">This winter, meet up with friends, family and you loved ones at the Jardins de glace by ther by the Saint-Bernard Chapel to try out the two 2.5-m high frozen slides and admire the ice sculptures. Reserved exclusively for Lodging Privileges ticket holders, the activity is open every day from 1 p.m. to 9 p.m, from December 25, 2021 to March 20, 2022. An excellent reason to play outside!</p>
                              <ul>
                                <li class="regular-text">Wearing a face cover recommended but not mandatory</li>
                                <li class="regular-text">Subject to change without notice according to weather conditions</li>
                              </ul>
            `,
      'privilege-video': 'https://www.youtube.com/embed/z2Bnpq-J3mk',

      'garantie-title': 'Adventure Assurance program:<br><span class="small-title">More confidence to ride in 2022-23!</span>',
      'garantie-data': `<h3 class="small-title">Adventure Assurance program</h3>
                              <p class="regular-text">Adventure Assurance provides options that offer 22/23 Tonik pass holders peace of mind to ski and ride throughout winter 22/23. Should a 22/23 Tonik pass holders not use their pass, for any reason, they may elect by December 8, 2022 to receive a full credit for the purchase price of their unused 22/23 Tonik pass to be used toward the purchase of a 23/24 Tonik pass, subject to applicable terms and conditions. Alternatively, 22/23 Tonik pass holders who elect to use their pass will be eligible to receive a proportional credit toward the purchase of a 23/24 Tonik pass, in the event that Tremblant meets minimum closure thresholds due to COVID-19 between December 17, 2022, and March 5, 2023. Read terms and conditions for full details</p>
                              <a class="regular-text link" href="https://www.tremblant.ca/plan/tickets-and-passes/winter-season-passes/adventure-assurance?sc_lang=en" target="_blank">Show Terms & Conditions</a>
      `,
      'politique-title': `new<br>flexible<br>policies`,
      'politique-data': `<h3 class="small-title">New flexible reservation and cancellation policies</h3>
                              <p class="regular-text">During these unprecedented times, we understand that it can be difficult to plan a stay in advance. We therefore invite you to consult the new <a href="https://www.tremblant.ca/about-us/policies?sc_lang=en" target="_blank">flexible reservation and cancellation policies of our various hotel partners</a>. Policies vary from partner to partner. Subject to change without notice. Please feel free to contact us may you have any questions.</p>
            `,

      'privilege-card-title': 'Enhance your experience<br>with the privilege bundle<br><span>Exclusive to 2022/23 season passholders.</span>',
      'privilege-price-1': '<p>$139<br><span>Adults (18 and over)</span></p>',
      'privilege-price-2': '<p>$99<br><span>Children (17 and less)</span></p>',
      'privilege-1-title': 'On the slopes before the official opening!',
      'privilege-2-title': '20 coffees or hot chocolates',
      'privilege-3-title': '15% off in Tremblant cafeteria',
      'privilege-4-title': '15% off in Tremblant boutiques',
      'privilege-5-title': '$200 in Tremblant boutique',
      'privilege-6-title': '1 free equipement tune-up at le ski shop',
      'privilege-cta-title': 'Buy your Privilege Bundle',
      'privilege-cta-link':
        'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=43&ProductCategoryCode=279&ClientId=801&CultureName=en-CA',

      'bh-lodging-hero': 'Save up to<br><span>30%</span><br>on lodging',
      'bh-lodging-cut': 'Offers valid until October 19, 2021',
      'bh-lodging-data': `
                                <h2 class="medium-title">Save up to<br>30% on lodging</h2>
                                <p class="regular-text">Offer valid until October 19, 2021</p>
                                <p class="regular-text soft">You have up to October 19, 2021, to access the best units while taking advantage of the greatest discounts of the season! Book your vacation right away, even for Christmas or Easter.</p>
                                <ul>
                                  <li class="regular-text soft">Best offer of winter lodging.</li>
                                  <li class="regular-text soft">Best unit availabilities.</li>
                                  <li class="regular-text soft">Plenty of time to plan all aspects of your vacation.</li>
                                </ul>
                                <div class="price-grid">
                                  <div class="price">
                                    <p>30%<br><span>4-night and +</span></p>
                                  </div>
                                  <div class="price">
                                    <p>25%<br><span>3-night</span></p>
                                  </div>
                                  <div class="price">
                                    <p>15 to 20%<br><span>2-night</span></p>
                                  </div>
                                </div>
            `,
      'meta-title-lodging': 'Save up to 30% on lodging',
      'meta-desc-lodging': 'You have up to October 19, 2021, to access the best units while taking advantage of the greatest discounts of the season!',

      'al-lodging-hero': 'Save up to<br><span>25%</span><br>on lodging',
      'al-lodging-cut': 'Offers valid until November 30, 2021',
      'al-lodging-data': `
                                <h2 class="medium-title">Save up to<br>25% on lodging</h2>
                                <p class="regular-text">Offer valid until November 30, 2021</p>
                                <p class="regular-text soft">Plan today and save on your Tremblant winter vacation. Book before November 30, 2021, and save up to 25 % on lodging even for Christmas and Easter:</p>
                                <ul>
                                  <li class="regular-text soft">Best offer of the winter.</li>
                                  <li class="regular-text soft">Best lodging selection.</li>
                                  <li class="regular-text soft">More time to plan all aspects of your vacation.</li>
                                </ul>
                                <div class="price-grid">
                                  <div class="price">
                                    <p>25%<br><span>4-night and +</span></p>
                                  </div>
                                  <div class="price">
                                    <p>20%<br><span>3-night</span></p>
                                  </div>
                                  <div class="price">
                                    <p>15 to 20%<br><span>2-night</span></p>
                                  </div>
                                </div>
            `,
      'al-meta-title-lodging': 'Save up to 25% on lodging',
      'al-meta-desc-lodging': 'Plan today and save on your Tremblant winter vacation. Book before November 30, 2021, and save up to 25 % on lodging.',

      'in-lodging-hero': 'Save up to<br><span>20%</span><br>on lodging',
      'in-lodging-cut': 'On sale until April 29, 2022',
      'in-lodging-data': `
                                <h2 class="medium-title">Save up to<br>20% on lodging</h2>
                                <p class="regular-text">On sale until April 29, 2022</p>
                                <p class="regular-text soft">Take advantage of this offer all season long, for Christmas, Easter and even during spring season! From December 1, 2021 to April 29, 2022, book a two-night stay or more and save:</p>
                                <p class="regular-text soft">For stays between December 2, 2021 and March 19, 2022:</p>
                                <ul>
                                  <li class="regular-text soft">20% on 3-night stays or more</li>
                                  <li class="regular-text soft">15% on 2-night stays</li>
                                  <li class="regular-text soft">Except on Saturday where you save 15%</li>
                                </ul>
                                <p class="regular-text soft">For stays from March 20 to April 30, 2022:</p>
                                <ul>
                                  <li class="regular-text soft">20% on 2-night stays or more</li>
                                  <li class="regular-text soft">Except on Saturday where you save 15%</li>
                                </ul>
            `,
      'in-meta-title-lodging': 'Save up to 20% on lodging',
      'in-meta-desc-lodging': 'Take advantage of this offer all season long, for Christmas, Easter and even during spring season!',

      'bh-ski-hero': 'Ski 2 to 7 days from<br><span>$79</span><br>per day',
      'bh-ski-link': 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/en-CA/?productcategoryid=117&startMonth=11&_gl=1*1qi8pjj*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIxNzM4NC4xMy4wLjE2MjkyMTczODQuMA..&_ga=2.3495344.1090954148.1629141557-1179124804.1583853558',
      'bh-ski-data': `
                            <h2 class="medium-title">Ski 2 to 7 days<br>from 79$/day</h2>
                            <p class="regular-text">Offers valid until October 19, 2021.</p>
                            <p class="regular-text soft">Book in advance and secure the best rates. Purchase your lift tickets and get the best rates of the 2021/22 season. This year, lift tickets for Tremblant are only on sale through our platforms.</p>
                            <p class="regular-text">Monday to Friday:</p>
                            <div class="price-grid">
                              <div class="price">
                                <p>45$ <span>/day</span><br><span>Child (5-12)</span></p>
                              </div>
                              <div class="price">
                                <p>53$ <span>/day</span><br><span>Youth (13-17)</span></p>
                              </div>
                              <div class="price">
                                <p>68$ <span>/day</span><br><span>Senior (70+)</span></p>
                              </div>
                            </div>
            `,

      'al-ski-hero': 'Ski 2 to 7 days from<br><span>$83</span><br>per day',
      'al-ski-link':
        'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/en-CA/?productcategoryid=117&startMonth=11&_gl=1*1qi8pjj*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIxNzM4NC4xMy4wLjE2MjkyMTczODQuMA..&_ga=2.3495344.1090954148.1629141557-1179124804.1583853558',
      'al-ski-data': `
                            <h2 class="medium-title">Ski 2 to 7 days<br>from $83/day</h2>
                            <p class="regular-text">Offers valid until November 30, 2021.</p>
                            <p class="regular-text soft">Book in advance and secure the best rates. Purchase your lift tickets and get the best rates of the 2021/22 season. This year, lift tickets for Tremblant are only on sale through our platforms.</p>
                            <p class="regular-text">Monday to Friday:</p>
                            <div class="price-grid">
                              <div class="price">
                                <p>48$ <span>/day</span><br><span>Child (5-12)</span></p>
                              </div>
                              <div class="price">
                                <p>56$ <span>/day</span><br><span>Youth (13-17)</span></p>
                              </div>
                              <div class="price">
                                <p>72$ <span>/day</span><br><span>Senior (70+)</span></p>
                              </div>
                            </div>
            `,

      'bh-tonik-dink-hero': 'Tonik Pass at<br><span>$649</span><br>Adult (18-69)',
      'bh-tonik-fam-hero': 'Tonik Pass<br><span>$135</span><br>Child (5-12)',
      'bh-tonik-cut': 'Price goes up on October 15, 2021',
      'bh-tonik-link':
        'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=30&ProductCategoryCode=235&ClientId=801&CultureName=en-CA&_gl=1*t4fu9u*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTM5OTY4OC4xMy4wLjE2MjkzOTk2ODguMA..&_ga=2.208059794.1090954148.1629141557-1179124804.1583853558',
      'bh-tonik-argument-1': 'Up to 112 fixed days on the snow',
      'bh-tonik-argument-2': 'Up to 27 weekend days',
      'bh-tonik-argument-3': '3 floating days to be used anytime',
      'bh-tonik-dink-data': `
                              <h2 class="medium-title">Tonik Pass at $649<br>Adult (18-69)</h2>
                              <p class="regular-text">Price goes up on October 15, 2021</p>
                              <p class="regular-text soft">112 fixed days on the snow and 3 floating days to be used anytime during the season, even during the Holidays! (except on February 19 and 20, 2022)! <a href="https://medias.tremblant.ca/pdf/ski/tonik-pass-calendar-2021-22.pdf" target="_blank">See the calendar</a></p>
                              <ul>
                                <li class="regular-text soft">Up to 27 weekend days.</li>
                                <li class="regular-text soft">Includes days during school breaks.</li>
                                <li class="regular-text soft">15% discount on all non-included days.</li>
                                <li class="regular-text soft">Privileges Bundle for passholders for only $99 giving access to First Tracks and other feature products!</li>
                                <li class="regular-text soft">Alpine touring pass included.</li>
                                <li class="regular-text soft">Exclusive to Canadian residents.</li>
                              </ul>
                              <div class="price-grid">
                                <div class="price">
                                  <p>$415<br><span>Student (18-25)</span></p>
                                </div>
                                <div class="price">
                                  <p>$519<br><span>Senior (70+)</span></p>
                                </div>
                                <div class="price">
                                  <p>$239<br><span>Master (80+)</span></p>
                                </div>
                              </div> 
            `,
      'meta-title-tonik-dink': 'Tonik Pass at $649 Adult (18-69)',
      'meta-desc-tonik-dink': '112 fixed days on the snow and 3 floating days to be used anytime during the season, even during the Holidays!',
      'bh-tonik-fam-data': `
                              <h2 class="medium-title">Tonik Pass at $135<br>Child (5-12)</h2>
                              <p class="regular-text">Price goes up on October 15, 2021</p>
                              <p class="regular-text soft">112 fixed days on the snow and 3 floating days to be used anytime during the season, even during the Holidays! (except on February 19 and 20, 2022)! <a href="https://medias.tremblant.ca/pdf/ski/tonik-pass-calendar-2021-22.pdf?_gl=1*1u3uue9*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTcyNzEwOS4xMy4xLjE2Mjk3MjgzNDUuMA..&_ga=2.179147044.1090954148.1629141557-1179124804.1583853558" target="_blank">See the calendar</a></p>
                              <ul>
                                <li class="regular-text soft">Up to 27 weekend days.</li>
                                <li class="regular-text soft">Includes days during school breaks.</li>
                                <li class="regular-text soft">15% discount on all non-included days.</li>
                                <li class="regular-text soft">Privileges Bundle for passholders for only $79 giving access to First Tracks and other feature products!</li>
                                <li class="regular-text soft">Alpine touring pass included.</li>
                                <li class="regular-text soft">Exclusive to Canadian residents.</li>
                              </ul>
                              <div class="price-grid">
                                <div class="price">
                                  <p>$45<br><span>Peewee (0-4)</span></p>
                                </div>
                                <div class="price">
                                  <p>$415<br><span>Youth (13-17)</span></p>
                                </div>
                                <div class="price">
                                  <p>$415<br><span>Student (18-25)</span></p>
                                </div>
                              </div> 
            `,
      'meta-title-tonik-fam': 'Tonik Pass at $135 Child (5-12)',
      'meta-desc-tonik-fam': '112 fixed days on the snow and 3 floating days to be used anytime during the season, even during the Holidays!!',

      'bh-latitude-hero': 'Ski 2 to 4 days for<br><span>$76</span><br>per day',
      'bh-latitude-cut': 'Offer valid until October 7, 2021',
      'bh-latitude-link':
        'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=3&ProductCategoryCode=64&ClientId=801&CultureName=en-CA&_gl=1*13hdkim*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTczMDcyNi4xMy4xLjE2Mjk3MzE2NDAuMA..&_ga=2.179654948.1090954148.1629141557-1179124804.1583853558',
      'bh-latitude-argument-1': '2 to 4 days on the snow',
      'bh-latitude-argument-2': 'Savings off up to 36%',
      'bh-latitude-argument-3': '15% discount on all additional days',
      'bh-latitude-data': `
                              <h2 class="medium-title">Ski 2 to 4 days<br>for $76/day<br>Adult (18-69)</h2>
                              <p class="regular-text soft">Savings off up to 36% until October 7, 2021</p>
                              <ul>
                                <li class="regular-text soft">2 to 4 days of skiing or snowboarding to be used consecutively or not, from $76 per day, per adult.</li>
                                <li class="regular-text soft">The days can be used as you wish during the 2021/22 season – with the exception of December 27, 2021 to January 2, 2022 inclusively and the following 8 Saturdays: January 15, 22 and 29, February 12 and 19, March 5, 19 and 26, 2022.</li>
                                <li class="regular-text soft">15% discount on all additional days.</li>
                                <li class="regular-text soft">Exclusive to Canadian residents.</li>
                              </ul>
                              <div class="price-grid">
                                <div class="price">
                                  <p>$43 <span>/day</span><br><span>Child (5-12)</span></p>
                                </div>
                                <div class="price">
                                  <p>$51 <span>/day</span><br><span>Youth (13-17)</span></p>
                                </div>
                                <div class="price">
                                  <p>$66 <span>/day</span><br><span>Senior (70+)</span></p>
                                </div>
                              </div> 
            `,
      'bh-latitude-title':
        'Take advantage of the offer now!<br><br><span class="small-title">Ski tickets for Tremblant will be<br>on sale only through our platforms.</span>',
      'bh-latitude-date': 'Oct. 7',
      'meta-title-latitude': 'Ski 2 to 4 days for $76/day Adult (18-69)',
      'meta-desc-latitude': 'Savings off up to 36% until October 7, 2021',

      'la-latitude-hero': 'Ski 2 to 4 days for<br><span>$80</span><br>per day',
      'la-latitude-cut': 'On sale until November 23',
      'la-latitude-link':
        'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=3&ProductCategoryCode=64&ClientId=801&CultureName=en-CA&_gl=1*13hdkim*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTczMDcyNi4xMy4xLjE2Mjk3MzE2NDAuMA..&_ga=2.179654948.1090954148.1629141557-1179124804.1583853558',
      'la-latitude-argument-1': '2 to 4 days on the snow',
      'la-latitude-argument-2': 'Savings off up to 33%',
      'la-latitude-argument-3': '15% discount on all additional days',
      'la-latitude-data': `
                              <h2 class="medium-title">Ski 2 to 4 days<br>for $80/day<br>Adult (18-69)</h2>
                              <p class="regular-text soft">Savings off up to 33% until November 23, 2021</p>
                              <ul>
                                <li class="regular-text soft">2 to 4 days of skiing or snowboarding to be used consecutively or not, $80 per day, per adult.</li>
                                <li class="regular-text soft">The days can be used as you wish during the 2021/22 season – with the exception of December 27, 2021 to January 2, 2022 inclusively and the following 8 Saturdays: January 15, 22 and 29, February 12 and 19, March 5, 19 and 26, 2022.</li>
                                <li class="regular-text soft">15% discount on all additional days.</li>
                                <li class="regular-text soft">Exclusive to Canadian residents.</li>
                              </ul>
                              <div class="price-grid">
                                <div class="price">
                                  <p>$46 <span>/day</span><br><span>Child (5-12)</span></p>
                                </div>
                                <div class="price">
                                  <p>$54 <span>/day</span><br><span>Youth (13-17)</span></p>
                                </div>
                                <div class="price">
                                  <p>$69 <span>/day</span><br><span>Senior (70+)</span></p>
                                </div>
                              </div> 
            `,
      'la-latitude-title':
        'Take advantage of the offer now!<br><br><span class="small-title">Ski tickets for Tremblant will be<br>on sale only through our platforms.</span>',
      'la-latitude-date': 'Nov. 23',
      'la-meta-title': 'Ski 2 to 4 days for $80/day Adult (18-69)',
      'la-meta-desc': 'Savings off up to 33% until November 23, 2021',

      'smt-entrepot-hero': 'Ticket Outlet<br><span>Up to 25% off</span><br>on ski tickets',
      'smt-entrepot-hero-2': 'Save up to<br><span>25%</span><br>on lift tickets',
      'smt-entrepot-best-1': 'Enjoy the best prices on ski tickets',
      'smt-entrepot-best-2': '<span class="small-title">Ski tickets for Tremblant will be<br>on sale only through our platforms.</span>',
      'smt-entrepot-data': `
                              <h2 class="medium-title"><span>Tremblant Ticket Outlet</span><br>Up to 25% off lift tickets</h2>
                              <p class="regular-text soft">Welcome to the Tremblant Ticket Outlet, where you’ll find variable rates on lift tickets to ski at Tremblant on specific days while saving up to 25%! Daily quantities are limited.</p>
                              <ul>
                                <li class="regular-text soft">Save up to 25% on 1-day to 5-day lift tickets</li>
                                <li class="regular-text soft">Prices may vary based on dates</li>
                                <li class="regular-text soft">Daily quantities are limited</li>
                              </ul> 
            `,
      'smt-entrepot-meta-title': 'Ticket Outlet up to 25% off on ski tickets',
      'smt-entrepot-meta-desc': 'Enjoy the best prices on ski tickets',
      'smt-entrepot-link':
        'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/en-US/?productcategoryid=117&_ga=2.26410811.705942550.1638269394-858024303.1585064247&_gl=1*1ipyc2r*_ga*ODU4MDI0MzAzLjE1ODUwNjQyNDc.*_ga_B1YVPN56M6*MTYzODM3MTE2NC40My4xLjE2MzgzNzEzOTQuMA..',

      'flash-dec-hero': 'FLASH SALE<br><span>Transferable<br>ski tickets from $85</span><br>Purchase in pairs',
      'flash-dec-cut': 'On sale until December 10',
      'flash-dec-best-1': 'Enjoy the best prices on ski tickets',
      'flash-dec-best-2': '<span class="small-title">Ski tickets for Tremblant will be<br>on sale only through our platforms.</span>',
      'flash-dec-data': `
                              <h2 class="medium-title"><span>FLASH SALE</span><br>Transferable ski tickets from $85<br><span>Purchase in pairs</span></h2>
                              <p class="regular-text soft">Get transferable ski tickets at a great rate for a great gift! Get pairs of transferable lift tickets at an excellent price and take advantage of the mountain during three popular periods during the season! Keep them for yourself, invite friends or slip them under the Christmas tree! These tickets, are valid for adults (18+) during the following periods:</p>
                              <div class="table">
                                <div class="row nbb">
                                  <div class="cell-35">
                                    <p class="regular-text"><span>Beginning of the season</span><br>Dec. 9 to 17, 2021</p>
                                  </div>
                                  <div class="cell-65">
                                    <p class="regular-text"><span>$89 per ticket</span><br>Purchase in pairs<br>Excluding Saturdays</p>
                                  </div>
                                </div>
                                <div class="row nbb">
                                  <div class="cell-35">
                                    <p class="regular-text"><span>Heart of the season</span><br>Jan. 10 to Feb. 11, 2022</p>
                                  </div>
                                  <div class="cell-65">
                                    <p class="regular-text"><span>$85 per ticket</span><br>Purchase in pairs<br>Excluding Saturdays and Sundays</p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="cell-35">
                                    <p class="regular-text"><span>Spring skiing</span><br>Mar. 21 to Apr. 18, 2022</p>
                                  </div>
                                  <div class="cell-65">
                                    <p class="regular-text"><span>$89 per ticket</span><br>Purchase in pairs<br>Excluding Saturdays</p>
                                  </div>
                                </div>
                              </div>
            `,
      'flash-dec-meta-title': 'FLASH SALE Transferable ski tickets from $85',
      'flash-dec-meta-desc': 'Get transferable ski tickets at a great rate for a great gift!',
      'flash-dec-link': 'https://reservations.tremblant.ca/ecomm/shop/merchandise/2624850/en-US/?productsupercategoryid=23',

      'flash-jan-hero': 'FLASH SALE<br><span>Ski tickets from $89</span><br>Limited quantity',
      'flash-jan-cut': 'On sale until January 13, 1 p.m.',
      'flash-jan-best-1': 'Enjoy the best prices on ski tickets',
      'flash-jan-best-2': '<span class="small-title">Ski tickets for Tremblant will be on sale only through our platforms.</span>',
      'flash-jan-data': `
                              <h2 class="medium-title"><span>FLASH SALE</span><br>Ski tickets from $89<br><span>Limited quantity</span></h2>
                              <p class="regular-text soft">Get ski tickets at a great rate! Get lift tickets at an excellent price and hit the slopes this winter! For 48h only, we’ve added lift tickets in our ticket outlet starting from $89.</p>
                              <p class="regular-text bold">Pricing adult (18 years old and over)</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-35">
                                    <p class="regular-text"><span>Date specific.</span><br>From January 13, 2022, to April 18, 2022.</p>
                                  </div>
                                  <div class="cell-65">
                                    <p class="regular-text"><span>Starting from $89 per ticket.</span><br>Limited quantities.</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text soft">Royalty and taxes extra.<br>Rates, dates, royalty and taxes are subject to change without notice.</p>
            `,
      'flash-jan-meta-title': 'FLASH SALE Ski tickets from $89',
      'flash-jan-meta-desc': 'Get lift tickets at an excellent price and hit the slopes this winter!',
      'flash-jan-link': 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/en-CA/?productcategoryid=117&StartYear=2022&StartMonth=1&agecategory=8&days=1',

      'flash-fev-heb-hero': "Save up to<br><span>20%</span><br>on lodging",
      'flash-fev-ski-hero': "Ski tickets from<br><span>$89</span><br>Limited quantity",
      'flash-fev-cut': "Until Feburary 3rd, 1 PM",
      'flash-fev-best-1': 'Winter at its best!',
      'flash-fev-best-2': '<span class="small-title">Ski tickets for Tremblant will be on sale only through our platforms.</span>',
      'flash-fev-heb-data': `
                              <h2 class="medium-title"><span>WINTER GETAWAY IN THE SNOW OFFER</span><br>Save up to 20% on lodging</h2>
                              <p class="regular-text soft">Save all winter long when you book 2 nights and more! Up to 20% on lodging. Take advantage of this offer all season long, for Easter or during spring season! Until April 29, 2022, book a two-night stay or more and save :</p>
                              <p class="regular-text bold">For stays until March 19, 2022 :</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-50">
                                    <p class="regular-text"><span>20%</span><br>on 3-night stays or more</p>
                                  </div>
                                  <div class="cell-50">
                                    <p class="regular-text"><span>15%</span><br>on 2-night stays</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text bold">For stays from March 20 to April 30, 2022 :</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-100">
                                    <p class="regular-text"><span>20%</span><br>on 2-night stays or more</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text soft">Except on Saturday where you save 15%</p> 
      `,
      'flash-fev-ski-data': `
                              <h2 class="medium-title"><span>FLASH SALE</span><br>Ski tickets from $89<br><span>Limited quantity</span></h2>
                              <p class="regular-text soft">Get ski tickets at a great rate and enjoy the heart of winter! For 48h only, we’ve added lift tickets in our ticket outlet starting from $89. Ski tickets for Tremblant will be on sale only through our platforms.</p>
                              <p class="regular-text bold">Pricing adult (18 years old and over)</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-35">
                                    <p class="regular-text"><span>Date specific.</span><br>From Feburary 3, 2022, to April 18, 2022.</p>
                                  </div>
                                  <div class="cell-65">
                                    <p class="regular-text"><span>Starting from $89 per ticket.</span><br>Limited quantities.</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text soft">Royalty and taxes extra.<br>Rates, dates, royalty and taxes are subject to change without notice.</p> 
       `,
      'flash-fev-meta-title': 'FLASH SALE Save on lodging and ski tickets',
      'flash-fev-meta-desc': 'Take advantage of this offer all season long, for Easter or during spring season!',
      'flash-fev-link': 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/en-CA/?productcategoryid=117&StartYear=2022&StartMonth=2&agecategory=8&days=1&_gl=1*1yardv4*_ga*OTA3NjY0MzY4LjE2MzI5NDIyNzU.*_ga_B1YVPN56M6*MTY0MzI5MjM3NC42OC4xLjE2NDMyOTI1OTkuMA..&_ga=2.190863402.1039408263.1643292374-907664368.1632942275',

      'flash-mars-heb-hero': "Save up to<br><span>20%</span><br>on lodging",
      'flash-mars-ski-hero': "Ski tickets at<br><span>$95</span><br>Limited quantity",
      'flash-mars-ski-hero-usa': "Ski tickets at<br><span>$95<sup>CAD</sup></span><br>Limited quantity",
      'flash-mars-cut': "On sale until<br>March 3rd, 1 PM",
      'flash-mars-best-1': 'A 100% burst spring!',
      'flash-mars-best-2': '<span class="small-title">Ski tickets for Tremblant will be on sale only through our platforms.</span>',
      'flash-mars-heb-data': `
                              <h2 class="medium-title"><span>WINTER GETAWAY IN THE SNOW OFFER</span><br>Save up to 20% on lodging</h2>
                              <p class="regular-text soft">Save all winter long when you book 2 nights and more! Up to 20% on lodging. Take advantage of this offer all season long, for Easter or during spring season! Until April 29, 2022, book a two-night stay or more and save :</p>
                              <p class="regular-text bold">For stays until March 19, 2022 :</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-50">
                                    <p class="regular-text"><span>20%</span><br>on 3-night stays or more</p>
                                  </div>
                                  <div class="cell-50">
                                    <p class="regular-text"><span>15%</span><br>on 2-night stays</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text bold">For stays from March 20 to April 30, 2022 :</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-100">
                                    <p class="regular-text"><span>20%</span><br>on 2-night stays or more</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text soft">Except on Saturday where you save 15%</p> 
      `,
      'flash-mars-ski-data': `
                              <h2 class="medium-title"><span>FLASH SALE</span><br>Ski tickets at $95<br><span>Limited quantity</span></h2>
                              <p class="regular-text soft">Get ski tickets at a great rate and enjoy the slopes before the end of the season. These tickets are valid during the following periods:</p>
                              <ul>
                                <li class="regular-text soft">From March 7 to 11, 2022 (excluding Saturdays)</li>
                                <li class="regular-text soft">From March 21, 2022, to April 18, 2022 (excluding Saturdays)</li>
                              </ul>
                              <p class="regular-text bold">Pricing adult (18 years old and over)</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-35">
                                    <p class="regular-text"><span>Date specific.</span><br>March 7 to 11 and from March 21 to April 18</p>
                                  </div>
                                  <div class="cell-65">
                                    <p class="regular-text"><span>$95 per ticket.</span><br>6/7. Non valid on Saturdays</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text soft">Royalty and taxes extra.<br>Rates, dates, royalty and taxes are subject to change without notice.</p> 
       `,
       'flash-mars-ski-data-usa': `
                              <h2 class="medium-title"><span>FLASH SALE</span><br>Ski tickets at $95<sup>CAD</sup><br><span>Limited quantity</span></h2>
                              <p class="regular-text soft">Get ski tickets at a great rate and enjoy the slopes before the end of the season. These tickets are valid during the following periods:</p>
                              <ul>
                                <li class="regular-text soft">From March 7 to 11, 2022 (excluding Saturdays)</li>
                                <li class="regular-text soft">From March 21, 2022, to April 18, 2022 (excluding Saturdays)</li>
                              </ul>
                              <p class="regular-text bold">Pricing adult (18 years old and over)</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-35">
                                    <p class="regular-text"><span>Date specific.</span><br>March 7 to 11 and from March 21 to April 18</p>
                                  </div>
                                  <div class="cell-65">
                                    <p class="regular-text"><span>$95 CAD per ticket.</span><br>6/7. Non valid on Saturdays</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text soft">Royalty and taxes extra.<br>Rates, dates, royalty and taxes are subject to change without notice.</p> 
       `,
      'flash-mars-meta-title': 'FLASH SALE Save on lodging and ski tickets',
      'flash-mars-meta-desc': 'Take advantage of this offer all season long, for Easter or during spring season!',
      'flash-mars-link': 'https://reservations.tremblant.ca/ecomm/shop/merchandise/2624850/en-US/?productsupercategoryid=23',
      'flash-mars-video-link': 'https://www.youtube.com/watch?v=dwh-06CV9IU',

      'printemps-heb-hero': "Save up to<br><span>20%</span><br>on lodging",
      'printemps-ski-hero': "Save up to <br><span>25%</span><br>off on ski tickets",
      'printemps-cut': "Offer valid until April 29",
      'printemps-best-1': 'A 100% burst spring!',
      'printemps-best-2': '<span class="small-title">Ski tickets for Tremblant will be on sale only through our platforms.</span>',
      'printemps-heb-data': `
                              <h2 class="medium-title"><span>SPRING’S IN THE AIR OFFER</span><br>Save up to 20% on lodging</h2>
                              <p class="regular-text soft">Save all spring long when you book 2 nights and more! Take advantage of this offer all season long, event for Easter! Until April 29, 2022, book a two-night stay or more and save :</p>
                              <p class="regular-text bold">For stays until March 19, 2022 :</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-50">
                                    <p class="regular-text"><span>20%</span><br>on 3-night stays or more</p>
                                  </div>
                                  <div class="cell-50">
                                    <p class="regular-text"><span>15%</span><br>on 2-night stays</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text bold">For stays from March 20 to April 30, 2022 :</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-100">
                                    <p class="regular-text"><span>20%</span><br>on 2-night stays or more</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text soft">Except on Saturday where you save 15%</p> 
      `,
      'printemps-ski-data': `
                              <h2 class="medium-title"><span>Tremblant Ticket Outlet</span><br>Up to 25% off lift tickets!</h2>
                              <p class="regular-text bold">Welcome to the Tremblant Ticket Outlet!</p>
                              <p class="regular-text soft">You’ll find variable rates on lift tickets to ski at Tremblant this spring on specific days.This is your chance to save up to 25% on your tickets, so you can fully enjoy the last weeks of skiing!</p>
                              <ul>
                                <li class="regular-text soft">Save up to 25% on 1-day to 5-day lift tickets</li>
                                <li class="regular-text soft">Prices may vary based on dates</li>
                                <li class="regular-text soft">Daily quantities are limited</li>
                              </ul>
       `,
      'printemps-meta-title': 'Save up to 20% on lodging and up to 25% off lift tickets!',
      'printemps-meta-desc': 'Save all spring long when you book 2 nights and more!',
      'printemps-link': 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/en-US/?productcategoryid=117&_ga=2.26410811.705942550.1638269394-858024303.1585064247&_gl=1*1ipyc2r*_ga*ODU4MDI0MzAzLjE1ODUwNjQyNDc.*_ga_B1YVPN56M6*MTYzODM3MTE2NC40My4xLjE2MzgzNzEzOTQuMA..',
      'printemps-video-link': 'https://www.youtube.com/watch?v=hg9NxGPpQiA',

      'renouvellement-heb-hero': "Renewal<br><span>$559</span>",
      'renouvellement-ski-hero': "New<br><span>$599</span>",
      'renouvellement-hero-title': "Tonik Pass 2022-2023<br><span>Adult (18-69)</span>",
      'renouvellement-heb-hero-fam': "Renewal<br><span>$105</span>",
      'renouvellement-ski-hero-fam': "New<br><span>$115</span>",
      'renouvellement-hero-title-fam': "Tonik Pass 2022-2023<br><span>Child (5-12)</span>",
      'renouvellement-cut': "Price goes up on April 22",
      'renouvellement-best-1': 'Now available at the best rate of the year!',
      'renouvellement-best-2': '<span class="small-title">Ski tickets for Tremblant will be on sale only through our platforms.</span>',
      'renouvellement-dink-data': `
                              <h2 class="medium-title">Tonik Pass 2022-2023<br> Renewal $559<br>New $599</h2>
                              <p class="regular-text">Price goes up on April 22</p>
                              <p class="regular-text soft">Get your Tonik pass 2022-2023 now and benefit from the best prices of the year. Tone up your winter with a calendar that allows you to enjoy the slopes nearly all season <a href="https://medias.tremblant.ca/pdf/ski/tonik-pass-calendar-2022-23.pdf" target="_blank">(Calendar)</a>. The Tonik Pass is:</p>
                              <ul>
                                <li class="regular-text soft">111 fixed days on the snow and 3 floating days to be used anytime during the season, even during the Holidays! (except on December 29-30, 2022, and February 18-19, 2023)</li>
                                <li class="regular-text soft">Up to 25 weekend days</li>
                                <li class="regular-text soft">Includes days during school breaks</li>
                                <li class="regular-text soft">15% discount on all non-included days (discount applicable on a 1-day ticket at ticket office rate)</li>
                                <li class="regular-text soft">Privileges Bundle for passholders for only $139 giving access to First Tracks and other feature products!</li>
                                <li class="regular-text soft">Alpine touring pass included.</li>
                                <li class="regular-text soft">The Tonik Pass is exclusive to Canadian residents.</li>
                                <li class="regular-text soft">Exclusive renewal rate offered to 2021-22 Tonik passholders.</li>
                              </ul>
                              <p class="regular-text">Renewal</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>$559<br><span>Adult (18-69)</span></p>
                                </div>
                                <div class="price">
                                  <p>$355<br><span>Student (18-25)</span></p>
                                </div>
                                <div class="price">
                                  <p>$439<br><span>Senior (70+)</span></p>
                                </div>
                              </div>
                              <p class="regular-text">New</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>$599<br><span>Adult (18-69)</span></p>
                                </div>
                                <div class="price">
                                  <p>$385<br><span>Student (18-25)</span></p>
                                </div>
                                <div class="price">
                                  <p>$479<br><span>Senior (70+)</span></p>
                                </div>
                              </div> 
      `,
      'renouvellement-fam-data': `
                              <h2 class="medium-title">Tonik Pass 2022-2023<br> Renewal $105<br>New $115</h2>
                              <p class="regular-text">Price goes up on April 22</p>
                              <p class="regular-text soft">Get your Tonik pass 2022-2023 now and benefit from the best prices of the year. Tone up your winter with a calendar that allows you to enjoy the slopes nearly all season <a href="https://medias.tremblant.ca/pdf/ski/tonik-pass-calendar-2022-23.pdf" target="_blank">(Calendar)</a>. The Tonik Pass is:</p>
                              <ul>
                                <li class="regular-text soft">111 fixed days on the snow and 3 floating days to be used anytime during the season, even during the Holidays! (except on December 29-30, 2022, and February 18-19, 2023)</li>
                                <li class="regular-text soft">Up to 25 weekend days</li>
                                <li class="regular-text soft">Includes days during school breaks</li>
                                <li class="regular-text soft">15% discount on all non-included days (discount applicable on a 1-day ticket at ticket office rate)</li>
                                <li class="regular-text soft">Privileges Bundle for passholders for only $139 giving access to First Tracks and other feature products!</li>
                                <li class="regular-text soft">Alpine touring pass included.</li>
                                <li class="regular-text soft">The Tonik Pass is exclusive to Canadian residents.</li>
                                <li class="regular-text soft">Exclusive renewal rate offered to 2021-22 Tonik passholders.</li>
                              </ul>
                              <p class="regular-text">Renewal</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>$105<br><span>Child (5-12)</span></p>
                                </div>
                                <div class="price">
                                  <p>$355<br><span>Youth (13-17)</span></p>
                                </div>
                                <div class="price">
                                  <p>$45<br><span>Peewee (0-4)</span></p>
                                </div>
                              </div>
                              <p class="regular-text">New</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>$115<br><span>Child (5-12)</span></p>
                                </div>
                                <div class="price">
                                  <p>$385<br><span>Youth (13-17)</span></p>
                                </div>
                                <div class="price">
                                  <p>$45<br><span>Peewee (0-4)</span></p>
                                </div>
                              </div> 
      `,
      'renouvellement-meta-title': 'Tonik Pass 2022-2023 - Renewal $559 - New $599',
      'renouvellement-meta-title-fam': 'Tonik Pass 2022-2023 - Renewal $105 - New $115',
      'renouvellement-meta-desc': 'Get your Tonik pass 2022-2023 now and benefit from the best prices of the year.',
      'privileges-meta-title': 'Privilege Bundle',
      'privileges-meta-desc': 'Enhance your Tremblant experience with an exclusive set of feature products. Exclusive to 2022/23 season passholders.',
      'renouvellement-link': 'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=42&ProductCategoryCode=276&ClientId=801&CultureName=en-CA',
      'renouvellement-link-2': 'https://ecom.tremblant.ca/eStore/Login.aspx?CultureName=en-CA&ReturnUrl=%2feStore%2fContent%2fAuthenticated%2fProductReload%2fDisplayReloadProducts.aspx%3fmode%3dpass&mode=pass',
      'renouvellement-video-link-dink': 'https://www.youtube.com/watch?v=i7NWyHF0MkA',
      'renouvellement-video-link-famille': 'https://www.youtube.com/watch?v=8ZMxnXJ6yfE',
      
      'al-video-title': 'Enjoy the ski season at Tremblant',
      'al-video-link': 'https://www.youtube.com/watch?v=JC-AtnaJ5So',
    },
    privileges: [
      {
        title: 'First Tracks',
        image: '/vue-app-static-img/privilege-premiere-trace-new.jpg',
        details: `<h3 class="small-title">First Tracks</h3>
                      <p class="regular-text">Ski before everybody on mornings included in your pass starting at 7:45 a.m.</p>
                      <p class="regular-text">Valid from December 17, 2022, to March 19, 2023, on days when the pass is valid.</p>
                     `,
      },
      {
        title: '20 coffees/hot chocolates',
        image: '/vue-app-static-img/privilege-boissons-chaudes.jpg',
        details: `<h3 class="small-title">20 coffees/hot chocolates</h3>
                      <p class="regular-text">20 coffees or hot chocolates added to your season pass. Valid at the following Tremblant cafeterias: Le Grand Manitou, Chalet des Voyageurs, La Fourchette du Diable and Café Johannsen.</p>
                      <p class="regular-text">Valid from November 24, 2022, to April 16, 2023.</p>
                      <p class="regular-text">No limit per visit.</p>
                     `,
      },
      {
        title: '15% off in Tremblant cafeteria',
        image: '/vue-app-static-img/privilege-rabais-cafeteria-new.jpg',
        details: `<h3 class="small-title">15% off in Tremblant cafeteria</h3>
                      <p class="regular-text">On regular-priced at Le Grand Manitou, Chalet des voyageurs, La Fourchette du Diable and Café Johannsen.</p>
                      <p class="regular-text">Valid from November 24, 2022, to April 16, 2023.</p>
                      <p class="regular-text">Not transferable.</p>
                     `,
      },
      {
        title: '15% off in Tremblant Boutiques',
        image: '/vue-app-static-img/privilege-rabais-boutiques-new.jpg',
        details: `<h3 class="small-title">15% off in Tremblant Boutiques</h3>
                      <p class="regular-text">Applicable on regular-priced merchandise at participating Tremblant boutiques (except Ugg, Canada Goose, Arc’téryx, Blundstone articles and Tremblant's 24h hats): NOR Tremblant, Alpine, Le Skieur, Boutique Sommet des Neiges, Burton Tremblant, Chamonix, Columbia Tremblant, Explore!, Magasin de la Place, Le Manitou, Salomon Tremblant, Tremblant & CO.</p>
                      <p class="regular-text">Valid from September 1, 2022, until April 16, 2023. </p>
                      <p class="regular-text">Not transferable.</p>
                     `,
      },
      {
        title: '$200 in Tremblant Boutique',
        image: '/vue-app-static-img/privilege-carte-cadeau-new.jpg',
        details: `<h3 class="small-title">$200 in Tremblant Boutique</h3>
                      <p class="regular-text">4 gift certificates of $50 each. One gift certificate for every $300 spent after discounts and before taxes, calculated after the 15% member discount. Certificates are valid on all in-store products, even items on sale, except Club de Ski Mont-Tremblant and Club Élite Tremblant uniforms and products. Certificates must be used by January 31, 2021, at participating boutiques: NOR Tremblant, Alpine, Le Skieur, Boutique Sommet des Neiges, Burton Tremblant, Chamonix, Columbia Tremblant, Explore!, Magasin de la Place, Le Manitou, Salomon Tremblant, Tremblant & CO.</p>
                      <p class="regular-text">Valid from September 1, 2022, to January 31, 2023.</p>
                      <p class="regular-text">Non-transferable.</p>
                     `,
      },
      {
        title: '1 equipment tune-up at Le Ski Shop',
        image: '/vue-app-static-img/privilege-mise-au-point.jpg',
        details: `<h3 class="small-title">1 equipment tune-up at Le Ski Shop</h3>
                      <p class="regular-text">1 overnight equipment tune-up at Le Ski Shop located in Chalet des Voyageurs.</p>
                      <p class="regular-text">Valid from November 24, 2022, to April 16, 2023.</p>
                      <p class="regular-text">Non-transferable.</p>
                     `,
      },
    ],
    datepicker: {
      formatLocale: {
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        firstDayOfWeek: 0,
        firstWeekContainsDate: 1,
      },
      yearFormat: 'YYYY',
      monthFormat: 'MMM',
      monthBeforeYear: true,
    },
    activityDink: [
      // {
      //   title: '24h Tremblant',
      //   image: '/vue-app-static-img/events-activity/dink/24h.jpg',
      //   details: `<h3 class="small-title">24h Tremblant</h3>
      //                 <p class="regular-text">Tremblant’s 24h is Quebec’s #1 winter sport challenge! Teams of 6 to 12 participants relay each other for 24 consecutive hours either skiing, walking or running. In the weeks leading up to the event, participants undertake a donation drive from which all profits go to three important foundations dedicated to children’s causes. In 2020, they succeeded in raising more than $2.4 million!</p>`,
      //   date: 'DEC. 10 to 12',
      // },
      // {
      //   title: 'Holiday Season Celebrations',
      //   image: '/vue-app-static-img/events-activity/dink/fetes.jpg',
      //   details: `<h3 class="small-title">Create Unforgettable Memories With the Whole Family</h3>
      //                 <p class="regular-text bold">From December 22, 2021 to January 4, 2022<br>1 p.m. to 4 :30 p.m.</p>
      //                 <p class="regular-text">Experience Tremblant as it transforms for the holidays. Be transported into the dreamy world of our singers and storytellers before settling in for an outdoor movie experience. Listen for Santa’s sleigh as it passes through the village and find your inner child with ball games and activities for both young and old.</p>
      //                 <p class="regular-text">Wearing a mask is recommended.</p>
      //                 <p class="regular-text">Limited capacity. First come, first served.</p>
      //                 <p class="regular-text">Subject to change without notice.</p>`,
      //   date: 'DEC. 22 to JAN. 4',
      // },
      // {
      //   title: "New Year's Celebrations",
      //   image: '/vue-app-static-img/events-activity/famille/jour-an.jpg',
      //   details: `<h3 class="small-title">New Year's Celebrations</h3>
      //             <p class="regular-text bold">December 31</p>
      //             <p class="regular-text">The tradition continues! Put on your best clothing and accessories and ring in 2022 with a bang! Join the party at the base of the mountain all evening long with DJ Shortcut in an open-air disco. Dance and admire the magnificent fireworks display at the stroke of midnight! Join in the afterparty celebrations taking place throughout the pedestrian village!<br><br>
      //             A proof of vaccination with a valid photo ID is required to access the site of the event. The space of the event has a limited capacity, make sure to get there early!</p>`,
      //   date: 'DEC. 31',
      // },
      // {
      //   title: 'Mackenzie World Cup Moguls',
      //   image: '/vue-app-static-img/events-activity/mackenzie.jpg',
      //   details: `<h3 class="small-title">Mackenzie world cup moguls presented by Toyota</h3>
      //             <p class="regular-text bold">January 5 to January 8</p>
      //             <p class="regular-text">The world’s best mogul athletes will be at Mont Tremblant to vie for the honours of this prestigious World Cup. See them in action right on the course (on foot or on skis) or on a giant screen in the heart of the village.
      //             </p>`,
      //   date: 'JAN. 5 to JAN. 8',
      // },
      // {
      //   title: 'Festival Rando Alpine Tremblant',
      //   image: '/vue-app-static-img/events-activity/rando.jpg',
      //   details: `<h3 class="small-title">Festival rando alpine tremblant, presented by smartwool</h3>
      //             <p class="regular-text bold">February 18 to February 20</p>
      //             <p class="regular-text">Outdoor enthusiasts will gather to experience a wide range of activities during the Festival Rando Alpine Tremblant, presented by Smartwool. Two days of discoveries, challenges and festivities. On the program, various guided ascents on the mountain for all levels and interests!</p>`,
      //   date: 'FEB. 18 to FEB. 20',
      // },
      // {
      //   title: 'The Legends Classic',
      //   image: '/vue-app-static-img/events-activity/classique-legendes.jpg',
      //   details: `<h3 class="small-title">The legends classic</h3>
      //             <p class="regular-text bold">March 26</p>
      //             <p class="regular-text">Racers, are you ready? The annual Legends Classic will be held at Tremblant on Saturday, March 26, 2022. It’s a giant sslalom open to skiers of all levels from juniors (7-17) to adults (18- 59) to legends (60–85+). Join in the fun. Everyone is welcome!</p>`,
      //   date: 'MAR. 26',
      // },
      // {
      //   title: 'Ice Climbing',
      //   image: '/vue-app-static-img/events-activity/escalade-glace.jpg',
      //   details: `<h3 class="small-title">Ice Climbing</h3>
      //                 <p class="regular-text">Challenge yourself! An ice climbing initiation course on a frozen waterfall right on Tremblant resort. During the activity, you will get to climb the cliff several times, learn safety protocol, and belay fellow climbers. This activity is designed for beginners and no climbing experience is necessary.</p>`,
      // },
      // {
      //   title: 'Tubing',
      //   image: '/vue-app-static-img/events-activity/glissade-tube.jpg',
      //   details: `<h3 class="small-title">Tubing</h3>
      //                 <p class="regular-text">An ideal family activity! Hit the slope on an inflatable tube, then take the ski lift to the top. Eight descents from beginner to expert level. Access of 2 hours or 4 hours. Cafeteria on site for food ($) and to warm up.</p>`,
      // },
      // {
      //   title: 'Jardins de Glace',
      //   image: '/vue-app-static-img/events-activity/jardin-glace.jpg',
      //   details: `<h3 class="small-title">Jardins de Glace</h3>
      //                 <p class="regular-text">This winter, meet up with friends, family and you loved ones at the Jardins de glace by the Saint-Bernard Chapel to try out the two 2.5-m high frozen slides and admire the ice sculptures. An excellent reason to play outside!</p>`,
      // },
      // {
      //   title: 'Ice Skating',
      //   image: '/vue-app-static-img/events-activity/patin.jpg',
      //   details: `<h3 class="small-title">Ice Skating</h3>
      //                 <p class="regular-text">The ice rink by the Saint-Bernard Chapel welcomes you from day to night for a memorable skating experience. Enjoy music, a crackling fire and an outstanding atmosphere as you while away a few hours of your day.</p>`,
      // },
      {
        title: 'Alpine Touring',
        image: '/vue-app-static-img/events-activity/dink/rando-alpine.jpg',
        details: `<h3 class="small-title">Alpine Touring</h3>
                      <p class="regular-text">Come climb the highest peak in the Laurentians with your skis on in one of our designated areas and trails, and ski down one of our 102 marked ski runs!</p>`,
      },
      {
        title: 'Snowshoeing',
        image: '/vue-app-static-img/events-activity/raquette.jpg',
        details: `<h3 class="small-title">Snowshoeing</h3>
                      <p class="regular-text">Crisscrossing Mont Tremblant amidst waterfalls and streams, our touring trails offer open and shaded areas with some spectacular views. Discover the highest peak in the Laurentians via 7 trails ranging from 1 km to 5 km for people of all levels! Note that a daily access or the alpine touring season pass is mandatory to enjoy our designated areas.</p>`,
      },
      {
        title: 'Restaurants',
        image: '/vue-app-static-img/events-activity/restaurants-new.jpg',
        details: `<h3 class="small-title">Restaurants</h3>
                      <p class="regular-text">You will have as much fun discovering authentic specialties as you will enjoying a gourmet menu.</p>`,
      },
      {
        title: 'Cross-Country Skiing',
        image: '/vue-app-static-img/events-activity/ski-fond.jpg',
        details: `<h3 class="small-title">Cross-Country Skiing</h3>
                      <p class="regular-text">Enjoy up to 120km of cross-country trails for beginners and experts alike to discover nature at your own pace around Tremblant!</p>`,
      },
      {
        title: 'Spas and Massages',
        image: '/vue-app-static-img/events-activity/dink/spa.jpg',
        details: `<h3 class="small-title">Spas and Massages</h3>
                      <p class="regular-text">A timeless retreat to relax your body and mind! Treat yourself  to a massage or beauty treatment in one of the both spas located at the heart of the pedestrian village.</p>`,
      },
      {
        title: 'Snowshoeing and Fondue Tour',
        image: '/vue-app-static-img/events-activity/souper-refuge.jpg',
        details: `<h3 class="small-title">Snowshoeing and Fondue Tour</h3>
                      <p class="regular-text">From the summit, join our splendid guided snowshoeing tour to reach Versant Soleil’s shelter where you will be treated to a hearty meal. Enjoy a traditional fondue by the wood fireplace in a typical log cabin nestled at the heart of the forest. End your evening beautifully as you make your way back down the mountain led by the moonlight (or your headlamp) and good company!</p>`,
      },
      {
        title: 'Indoor activities',
        image: '/vue-app-static-img/events-activity/famille/studio-creatif-2.jpg',
        imageSmt: '/vue-app-static-img/events-activity/casino.jpg',
        details: `<h3 class="small-title">Indoor activities</h3>
                      <p class="regular-text">A unique experience awaits you in Tremblant with activities for everyone!</p>`,
      },
      {
        title: 'Dogsledding',
        image: '/vue-app-static-img/events-activity/chiens.jpg',
        details: `<h3 class="small-title">Dogsledding</h3>
                      <p class="regular-text">Bring the whole family and choose one of our exciting dogsledding excursions through the forest, on the mountain or on valley trails.</p>`,
      },
      {
        title: 'Biking',
        image: '/vue-app-static-img/events-activity/dink/fat-bike.jpg',
        details: `<h3 class="small-title">Biking</h3>
                      <p class="regular-text">In winter or summer, Mont-Tremblant area offers breathtaking scenery and many options for a bike ride. Come discover the region's bike paths and mountain biking trails accessible for riders of all abilities!</p>`,
      },
      {
        title: 'Ontario Spring Break',
        image: '/vue-app-static-img/events-activity/relache-ont.jpg',
        details: `<h3 class="small-title">Ontario Spring Break</h3>
                  <p class="regular-text bold">March 12 to 20</p>
                  <p class="regular-text">Come enjoy the school break at Tremblant! There is certainly something for everyone! Interactive games, daily entertainment and a musical atmosphere on Saturday evenings.</p>`,
        date: 'March 12 to 20',
      },
      {
        title: 'Musical atmosphere',
        image: '/vue-app-static-img/events-activity/musique.jpg',
        details: `<h3 class="small-title">Spring Musical atmosphere</h3>
                  <p class="regular-text bold">From March 19 to april 16 2022 </p>
                  <p class="regular-text">It's now time to join us and enjoy the long awaited beautiful afternoons at Place St-Bernard. We Have music to accompany all your Saturdays and Sundays, from March 19th to April 16.</p>
                  <p class="regular-text">Saturday and Sunday from 1:00 pm to 7:00 pm</p>
                  <p class="regular-text bold">DJ on site at Place St-Bernard</p>
                  <p class="regular-text">*Schedule and content subject to change without notice, face covering recommended*</p>`,
        date: 'March 19 to April 16',
      },
      {
        title: 'Jamigos',
        image: '/vue-app-static-img/events-activity/jamigos.jpg',
        details: `<h3 class="small-title">Jamigos</h3>
                  <p class="regular-text bold">April 2 and 3, 2022</p>
                  <p class="regular-text">Two days of fun with jam sessions, DJ, entertainment and several prizes.</p>
                  <p class="regular-text">What better occasion to celebrate spring skiing with your friends than the JAMIGOS Weekend? Come try our snow park specially redesigned for the occasion! Take in the fun and animation and cheer on your friends trying new tricks or take your own chance to win one of the many prizes! Two days of fun with jam sessions, DJ, entertainment and several prizes!</p>`,
        date: 'April 2 and 3',
      },
      {
        title: 'Coupe Caribou',
        image: '/vue-app-static-img/events-activity/caribou.jpg',
        details: `<h3 class="small-title">Coupe Caribou</h3>
                  <p class="regular-text bold">April 9, 2022</p>
                  <p class="regular-text">On April 9,The Coupe Caribou is back , take on the snow-covered trail with your skis or snowboard and try crossing the water basin at the bottom. To win door prizes, participants must give their best performance, wearing their craziest outfit (it’s mandatory!), to entertain spectators. At half-time, you can try running across the pool on floating mats and enjoy a live music concert. You can then continue to party with the musicians at the P’tit Caribou, once the race is over! Don’t miss this fun, surprising and laughter-filled event!</p>
                  <div class="table">
                    <div class="row">
                      <div class="cell">
                        <p class="regular-text"><span>9am-10h30am</span><br>Base montagne</p>
                      </div>
                      <div class="cell">
                        <p class="regular-text">Incription (125 pers. Max) 75$</p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="cell">
                        <p class="regular-text"><span>1pm</span><br>Base montagne</p>
                      </div>
                      <div class="cell">
                        <p class="regular-text">Start of the cup</p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="cell">
                        <p class="regular-text"><span>1pm-1:45pm</span><br>Base montagne</p>
                      </div>
                      <div class="cell">
                        <p class="regular-text">Registration for the crossing of the swimming pool running on floating mattress - Free</p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="cell">
                        <p class="regular-text"><span>2pm-2:30pm </span><br>Base montagne</p>
                      </div>
                      <div class="cell">
                        <p class="regular-text">Half-time show: live Band and Crossing the pool to running on floating mattresses</p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="cell">
                        <p class="regular-text"><span>2:30pm-3:30pm</span><br>Base montagne</p>
                      </div>
                      <div class="cell">
                        <p class="regular-text">Continuation of the Caribou Cup</p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="cell">
                        <p class="regular-text"><span>4:30pm</span><br>P’tit Caribou</p>
                      </div>
                      <div class="cell">
                        <p class="regular-text">Prize ceremony, music group and mechoui</p>
                      </div>
                    </div>

                  </div>`,
        date: 'April 9, 2022',
      },
      {
        title: 'Burton El Nino / El Rigleto',
        image: '/vue-app-static-img/events-activity/burton.jpg',
        details: `<h3 class="small-title">Burton el nino / el rigleto</h3>
                  <p class="regular-text bold">April 10</p>
                  <p class="regular-text">Kids aged 12 and under wanting to improve their freestyle snowboarding skills are invited to meet Burton Quebec and Tremblant team coaches and get some precious advice. Young kids can also take part in a fun and stimulating event with their parents on a day of activities. El Rigleto, presented by Burton Tremblant, aims at transmitting the passion of snowboarding to children while showing parents how to accompany their kids in this new sport.</p>`,
        date: 'APR. 10',
      },
      {
        title: 'Easter Celebrations',
        image: '/vue-app-static-img/events-activity/paques.jpg',
        details: `<h3 class="small-title">Easter Celebrations</h3>
                  <p class="regular-text bold">April 17</p>
                  <p class="regular-text">Easter is cause for celebration in Tremblant! Turn your family weekend into a magical one by taking part in Easter-themed activities: egg collecting, face painting and enjoying music and entertainment.<br><br>From 10 a.m. to 2 p.m.</p><br>
                  <p class="regular-text bold">Makeup artists</p>
                  <p class="regular-text">Place des Voyageurs - Children will love getting their hands painted by our professional makeup artists. The makeup will be exclusively on the hands in order to respect the health measures currently in place.<br><br>From 10 a.m. to 3 p.m.</p><br>
                  <p class="regular-text bold">Easter Egg delivery</p>
                  <p class="regular-text">Pedestrian village - Easter bunnies will walk around the pedestrian village and distribute chocolate eggs. (Available while supplies last)<br><br>From 10 a.m. to 4 p.m.</p><br>
                  <p class="regular-text bold">Interactive Games</p>
                  <p class="regular-text">Place Saint-Bernard - Come play outside! Many plays wait for you on Place Saint-Bernard. Boot hockey, snow soccer, football basketball, football/bowling and flag.</p><br>
                  <p class="regular-text bold">Music and Entertainment</p>
                  <p class="regular-text">Place Saint-Bernard - Take a break with the family and enjoy some great music. See you there!<br><br>From 12 a.m. to 3 p.m.</p><br>
                  <p class="regular-text bold">Meet Toufou</p>
                  <p class="regular-text">Pedestrian Village - Come meet Toufou, our mascot.<br><br>From 12 p.m. to 3 p.m.</p><br>
                  <p class="regular-text bold">Balloon sculptures by Marc-Ambulles</p>
                  <p class="regular-text">Pedestrian village - Marc-Ambulles, a multi-talented street performer, will stroll the Pedestrian village, giving away incredible Easter balloon sculptures. *Schedules and content are subject to change without notice. Wearing a face covering is recommended.<br><br>From 10 a.m. to 3 p.m.</p>`,
        date: 'April 17',
      },
      // {
      //   title: 'Washington Week',
      //   image: '/vue-app-static-img/events-activity/relache-usa.jpg',
      //   details: `<h3 class="small-title">Washington Week</h3>
      //             <p class="regular-text bold">Feb. 19 to 27</p>
      //             <p class="regular-text">Come enjoy the school break at Tremblant! Interactive games, daily entertainment and a musical atmosphere on Saturday evenings to make your holiday week in Canada unforgettable.</p>`,
      //   date: 'Feb. 19 to 27',
      // },
      // {
      //   title: 'Shopping',
      //   image: '/vue-app-static-img/events-activity/dink/boutique-2.jpg',
      //   details: `<h3 class="small-title">Shopping</h3>
      //                 <p class="regular-text">Saunter through European-style streets and discover a multitude of exclusive boutiques.</p>`,
      // },
      // {
      //   title: 'Super Demo Day',
      //   image: '/vue-app-static-img/events-activity/super-demo.jpg',
      //   details: `<h3 class="small-title">Super Demo Day</h3>
      //             <p class="regular-text bold">Feb. 12</p>
      //             <p class="regular-text">Super Demo Days are the perfect occasion to meet some of the biggest names in the ski and snowboard industry. Try next year’s ski and snowboard gear before anyone else and compare models before purchasing. You can take advantage of promotional deals in the boutiques of Tremblant’s pedestrian village.</p>`,
      //   date: 'Feb. 12',
      // },
      
      // {
      //   title: 'Quebec School Break',
      //   image: '/vue-app-static-img/events-activity/relache-qc.jpg',
      //   details: `<h3 class="small-title">Quebec School Break</h3>
      //             <p class="regular-text bold">Feb. 26 to March 6</p>
      //             <p class="regular-text">Come enjoy the school break at Tremblant! Special activities and entertainment are happening throughout the village: Interactive games, daily entertainment and a musical atmosphere on Saturday evenings.</p>`,
      //   date: 'Feb. 26 to March 6',
      // },
      // {
      //   title: "Brind'O Aquaclub",
      //   image: '/vue-app-static-img/events-activity/dink/brindo.jpg',
      //   details: `<h3 class="small-title">Brind'O Aquaclub</h3>
      //                 <p class="regular-text">Come and experience the reality of Brind’O Aquaclub’s world at the heart of the resort. With its 4 slides, 35 interactive water games, revamped fitness room and hot tub, the new family waterplay centre is becoming a must, rain or shine.<br><br>Brind’O Aquaclub welcomes you in a colourful universe where everything seems calm and everything is tremendously big: a blade of grass becomes a tree, a drop of water becomes a pond and every step brings a wonderful discovery. This bigger than life setting is filled with movement as huge dragonflies and ants captivate children to create a unique universe where joys of children and smiles of adults are intertwined. Brind’O Aquaclub, a realistic dream and a place to have fun at all times!</p>`,
      // },
    ],
    activityFamille: [
      // {
      //   title: '24h Tremblant',
      //   image: '/vue-app-static-img/events-activity/famille/24h.jpg',
      //   details: `<h3 class="small-title">24h Tremblant</h3>
      //                 <p class="regular-text">Tremblant’s 24h is Quebec’s #1 winter sport challenge! Teams of 6 to 12 participants relay each other for 24 consecutive hours either skiing, walking or running. In the weeks leading up to the event, participants undertake a donation drive from which all profits go to three important foundations dedicated to children’s causes. In 2020, they succeeded in raising more than $2.4 million!</p>`,
      //   date: '10 AU 12 DÉC.',
      // },
      // {
      //   title: 'Holiday Season Celebrations',
      //   image: '/vue-app-static-img/events-activity/famille/fetes.jpg',
      //   details: `<h3 class="small-title">Create Unforgettable Memories With the Whole Family</h3>
      //                 <p class="regular-text bold">From December 22, 2021 to January 4, 2022<br>1 p.m. to 4 :30 p.m.</p>
      //                 <p class="regular-text">Experience Tremblant as it transforms for the holidays. Be transported into the dreamy world of our singers and storytellers before settling in for an outdoor movie experience. Listen for Santa’s sleigh as it passes through the village and find your inner child with ball games and activities for both young and old.</p>
      //                 <p class="regular-text">Wearing a mask is recommended.</p>
      //                 <p class="regular-text">Limited capacity. First come, first served.</p>
      //                 <p class="regular-text">Subject to change without notice.</p>`,
      //   date: 'DEC. 22 to JAN. 4',
      // },
      // {
      //   title: "New Year's Celebrations",
      //   image: '/vue-app-static-img/events-activity/famille/jour-an.jpg',
      //   details: `<h3 class="small-title">New Year's Celebrations</h3>
      //             <p class="regular-text bold">December 31</p>
      //             <p class="regular-text">The tradition continues! Put on your best clothing and accessories and ring in 2022 with a bang! Join the party at the base of the mountain all evening long with DJ Shortcut in an open-air disco. Dance and admire the magnificent fireworks display at the stroke of midnight! Join in the afterparty celebrations taking place throughout the pedestrian village!<br><br>
      //             A proof of vaccination with a valid photo ID is required to access the site of the event. The space of the event has a limited capacity, make sure to get there early!</p>`,
      //   date: 'DEC. 31',
      // },
      {
        title: 'Mackenzie World Cup Moguls',
        image: '/vue-app-static-img/events-activity/mackenzie.jpg',
        details: `<h3 class="small-title">Mackenzie World Cup Moguls presented by Toyota</h3>
                  <p class="regular-text bold">January 5 to January 8</p>
                  <p class="regular-text">The world’s best mogul athletes will be at Mont Tremblant to vie for the honours of this prestigious World Cup. See them in action right on the course (on foot or on skis) or on a giant screen in the heart of the village.
                  </p>`,
        date: 'JAN. 5 to JAN. 8',
      },
      {
        title: 'Festival Rando Alpine Tremblant',
        image: '/vue-app-static-img/events-activity/rando.jpg',
        details: `<h3 class="small-title">Festival rando alpine tremblant, presented by smartwool</h3>
                  <p class="regular-text bold">February 18 to February 20</p>
                  <p class="regular-text">Outdoor enthusiasts will gather to experience a wide range of activities during the Festival Rando Alpine Tremblant, presented by Smartwool. Two days of discoveries, challenges and festivities. On the program, various guided ascents on the mountain for all levels and interests!</p>`,
        date: 'FEB. 18 to FEB. 20',
      },
      {
        title: 'The Legends Classic',
        image: '/vue-app-static-img/events-activity/classique-legendes.jpg',
        details: `<h3 class="small-title">The legends classic</h3>
                  <p class="regular-text bold">March 26</p>
                  <p class="regular-text">Racers, are you ready? The annual Legends Classic will be held at Tremblant on Saturday, March 26, 2022. It’s a giant sslalom open to skiers of all levels from juniors (7-17) to adults (18- 59) to legends (60–85+). Join in the fun. Everyone is welcome!</p>`,
        date: 'MAR. 26',
      },
      // {
      //   title: 'Burton El Nino / El Rigleto',
      //   image: '/vue-app-static-img/events-activity/burton.jpg',
      //   details: `<h3 class="small-title">Burton el nino / el rigleto</h3>
      //             <p class="regular-text bold">April 10</p>
      //             <p class="regular-text">Kids aged 12 and under wanting to improve their freestyle snowboarding skills are invited to meet Burton Quebec and Tremblant team coaches and get some precious advice. Young kids can also take part in a fun and stimulating event with their parents on a day of activities. El Rigleto, presented by Burton Tremblant, aims at transmitting the passion of snowboarding to children while showing parents how to accompany their kids in this new sport.</p>`,
      //   date: 'APR. 10',
      // },
      // {
      //   title: "Brind'O Aquaclub",
      //   image: '/vue-app-static-img/events-activity/famille/brindo.jpg',
      //   details: `<h3 class="small-title">Brind'O Aquaclub</h3>
      //                 <p class="regular-text">Come and experience the reality of Brind’O Aquaclub’s world at the heart of the resort. With its 4 slides, 35 interactive water games, revamped fitness room and hot tub, the new family waterplay centre is becoming a must, rain or shine.<br><br>Brind’O Aquaclub welcomes you in a colourful universe where everything seems calm and everything is tremendously big: a blade of grass becomes a tree, a drop of water becomes a pond and every step brings a wonderful discovery. This bigger than life setting is filled with movement as huge dragonflies and ants captivate children to create a unique universe where joys of children and smiles of adults are intertwined. Brind’O Aquaclub, a realistic dream and a place to have fun at all times!</p>`,
      // },
      {
        title: 'Biking',
        image: '/vue-app-static-img/events-activity/famille/fat-bike.jpg',
        details: `<h3 class="small-title">Biking</h3>
                      <p class="regular-text">In winter or summer, Mont-Tremblant area offers breathtaking scenery and many options for a bike ride. Come discover the region's bike paths and mountain biking trails accessible for riders of all abilities!</p>`,
      },
      {
        title: 'Shopping',
        image: '/vue-app-static-img/events-activity/famille/boutique.jpg',
        details: `<h3 class="small-title">Shopping</h3>
                      <p class="regular-text">Saunter through European-style streets and discover a multitude of exclusive boutiques.</p>`,
      },
      {
        title: 'Snowshoeing',
        image: '/vue-app-static-img/events-activity/famille/rando-alpine.jpg',
        details: `<h3 class="small-title">Explore nature at your own pace</h3>
                      <p class="regular-text">Crisscrossing Mont Tremblant amidst waterfalls and streams, our touring trails offer open and shaded areas with some spectacular views. Discover the highest peak in the Laurentians via 7 trails ranging from 1 km to 5 km for people of all levels! Note that a daily access or the alpine touring season pass is mandatory to enjoy our designated areas.</p>`,
      },
      {
        title: 'Ice Skating',
        image: '/vue-app-static-img/events-activity/famille/skating.jpg',
        details: `<h3 class="small-title">Ice Skating</h3>
                      <p class="regular-text">The ice rink by the Saint-Bernard Chapel welcomes you from day to night for a memorable skating experience. Enjoy music, a crackling fire and an outstanding atmosphere as you while away a few hours of your day.</p>`,
      },
      {
        title: 'Restaurants',
        image: '/vue-app-static-img/events-activity/famille/restaurants.jpg',
        details: `<h3 class="small-title">Restaurants</h3>
                      <p class="regular-text">You will have as much fun discovering authentic specialties as you will enjoying a gourmet menu.</p>`,
      },
      {
        title: 'Indoor activities',
        image: '/vue-app-static-img/events-activity/famille/studio-creatif-2.jpg',
        imageSmt: '/vue-app-static-img/events-activity/casino.jpg',
        details: `<h3 class="small-title">Indoor activities</h3>
                      <p class="regular-text">A unique experience awaits you in Tremblant with activities for the whole family!</p>`,
      },
    ],
    events: [
      {
        title: 'Ontario Spring Break',
        image: '/vue-app-static-img/events-activity/relache-ont.jpg',
        details: `<h3 class="small-title">Ontario Spring Break</h3>
                  <p class="regular-text bold">March 12 to 20</p>
                  <p class="regular-text">Come enjoy the school break at Tremblant! There is certainly something for everyone! Interactive games, daily entertainment and a musical atmosphere on Saturday evenings.</p>`,
        date: 'March 12 to 20',
      },
      {
        title: 'Musical atmosphere',
        image: '/vue-app-static-img/events-activity/musique.jpg',
        details: `<h3 class="small-title">Spring Musical atmosphere</h3>
                  <p class="regular-text bold">From March 19 to april 16 2022 </p>
                  <p class="regular-text">It's now time to join us and enjoy the long awaited beautiful afternoons at Place St-Bernard. We Have music to accompany all your Saturdays and Sundays, from March 19th to April 16.</p>
                  <p class="regular-text">Saturday and Sunday from 1:00 pm to 7:00 pm</p>
                  <p class="regular-text bold">DJ on site at Place St-Bernard</p>
                  <p class="regular-text">*Schedule and content subject to change without notice, face covering recommended*</p>`,
        date: 'March 19 to April 16',
      },
      {
        title: 'Jamigos',
        image: '/vue-app-static-img/events-activity/jamigos.jpg',
        details: `<h3 class="small-title">Jamigos</h3>
                  <p class="regular-text bold">April 2 and 3, 2022</p>
                  <p class="regular-text">Two days of fun with jam sessions, DJ, entertainment and several prizes.</p>
                  <p class="regular-text">What better occasion to celebrate spring skiing with your friends than the JAMIGOS Weekend? Come try our snow park specially redesigned for the occasion! Take in the fun and animation and cheer on your friends trying new tricks or take your own chance to win one of the many prizes! Two days of fun with jam sessions, DJ, entertainment and several prizes!</p>`,
        date: 'April 2 and 3',
      },
      {
        title: 'Coupe Caribou',
        image: '/vue-app-static-img/events-activity/caribou.jpg',
        details: `<h3 class="small-title">Coupe Caribou</h3>
                  <p class="regular-text bold">April 9, 2022</p>
                  <p class="regular-text">An extraordinary event that inaugurates the arrival of spring in Tremblant for several years.</p>
                  <p class="regular-text">Take up the challenge of crossing the pool of water at the foot of the snowy slope with your skis or snowboard. Dressed in your best costume (it's mandatory!), offer spectators one of the funniest performances of the day to win door prizes. An event filled with laughter, surprises and fun for all!</p>
                  <p class="regular-text">More details to come</p>`,
        date: 'April 9, 2022',
      },
      {
        title: 'Burton El Nino / El Rigleto',
        image: '/vue-app-static-img/events-activity/burton.jpg',
        details: `<h3 class="small-title">Burton el nino / el rigleto</h3>
                  <p class="regular-text bold">April 10</p>
                  <p class="regular-text">Kids aged 12 and under wanting to improve their freestyle snowboarding skills are invited to meet Burton Quebec and Tremblant team coaches and get some precious advice. Young kids can also take part in a fun and stimulating event with their parents on a day of activities. El Rigleto, presented by Burton Tremblant, aims at transmitting the passion of snowboarding to children while showing parents how to accompany their kids in this new sport.</p>`,
        date: 'APR. 10',
      },
      {
        title: 'Easter Celebrations',
        image: '/vue-app-static-img/events-activity/paques.jpg',
        details: `<h3 class="small-title">Easter Celebrations</h3>
                  <p class="regular-text bold">April 17</p>
                  <p class="regular-text">Easter is cause for celebration in Tremblant! Turn your family weekend into a magical one by taking part in Easter-themed activities: egg collecting, face painting and enjoying music and entertainment.<br><br>From 10 a.m. to 2 p.m.</p><br>
                  <p class="regular-text bold">Makeup artists</p>
                  <p class="regular-text">Place des Voyageurs - Children will love getting their hands painted by our professional makeup artists. The makeup will be exclusively on the hands in order to respect the health measures currently in place.<br><br>From 10 a.m. to 3 p.m.</p><br>
                  <p class="regular-text bold">Easter Egg delivery</p>
                  <p class="regular-text">Pedestrian village - Easter bunnies will walk around the pedestrian village and distribute chocolate eggs. (Available while supplies last)<br><br>From 10 a.m. to 4 p.m.</p><br>
                  <p class="regular-text bold">Interactive Games</p>
                  <p class="regular-text">Place Saint-Bernard - Come play outside! Many plays wait for you on Place Saint-Bernard. Boot hockey, snow soccer, football basketball, football/bowling and flag.</p><br>
                  <p class="regular-text bold">Music and Entertainment</p>
                  <p class="regular-text">Place Saint-Bernard - Take a break with the family and enjoy some great music. See you there!<br><br>From 12 a.m. to 3 p.m.</p><br>
                  <p class="regular-text bold">Meet Toufou</p>
                  <p class="regular-text">Pedestrian Village - Come meet Toufou, our mascot.<br><br>From 12 p.m. to 3 p.m.</p><br>
                  <p class="regular-text bold">Balloon sculptures by Marc-Ambulles</p>
                  <p class="regular-text">Pedestrian village - Marc-Ambulles, a multi-talented street performer, will stroll the Pedestrian village, giving away incredible Easter balloon sculptures. *Schedules and content are subject to change without notice. Wearing a face covering is recommended.<br><br>From 10 a.m. to 3 p.m.</p>`,
        date: 'April 17',
      },
    ],
}
export const hiver2022Fr = {
  site: {
      'achetez-long': 'Achetez vos billets',
      'achetez-pass': 'Achetez votre passe',
      'achetez-forfait': 'Achetez votre forfait',
      'achetez-short': 'Achetez',
      lodging: 'Hébergement',
      'reservez-long': 'Réservez votre séjour',
      'reservez-short': 'Réservez',
      slogan: 'Éclaté<br>de haut en bas',
      'show-details': 'Afficher les détails',
      'promo-code': '* Code promotionnel requis',
      'enjoy-offer': "Profitez de l'offre",
      modalites: 'Modalités',
      'modalites-price': 'Modalités | Grille de prix complète',
      arrivee: 'Arrivée',
      depart: 'Départ',
      adulte: 'Adultes',
      enfant: 'Enfants',
      'adulte-2': 'Adulte',
      'enfant-2': 'Enfant',
      'news-alert': 'Restez informé !',
      more: 'En savoir plus',
      'mesures-note': `Une preuve de vaccination et pièce d'identité avec photo sont requises pour accéder aux remontées mécaniques. Les salles à manger des restaurants sont fermées, mais des mets pour emporter sont disponibles. <a href="https://www.tremblant.ca/montagne-village/mesures-sanitaires" target="_blank">En savoir plus</a>.`,
      'mesures-note-smt': `Une <a href="/hiver/2122/launch/terms-latitude/">preuve vaccinale</a> et pièce d'identié avec photo sont requises pour accéder aux remontées mécaniques et certaines activités.`,

      'countdown-title': 'L’offre se termine dans',
      'countdown-jour': 'J',
      'countdown-heure': 'H',
      'countdown-minute': 'Min.',
      'countdown-seconde': 'Sec.',

      'activity-title': 'Quoi faire à Tremblant',

      'vols-title': 'Vols directs vers<br>Mont-Tremblant',
      'vols-desc':
        'En partance de Toronto en seulement 65 minutes<br>sur les ailes de Porter!<br><br>Pour vos besoins d’affaires ou de loisirs, Porter offre un service de vols directs vers l’aéroport international de Mont-Tremblant (YTM) en partance de l’aéroport Billy Bishop de Toronto (YTZ), en plus de correspondances vers Ottawa, Sudbury, Windsor, Thunder Bay, Sault-Sainte-Marie et plusieurs autres.',
      'vols-cta': 'En savoir plus',
      'vols-link': 'https://www.flyporter.com/fr-ca/',

      'newsletter-title': 'RECEVEZ LES MEILLEURES OFFRES<br>&nbsp;ET LES DERNIÈRES NOUVEAUTÉS',
      'newsletter-cta': "S'ABONNER MAINTENANT",
      'newsletter-link':
        'https://www.tremblant.ca/courriel/abonnement?sessionGUID=39a832be-6450-61da-042a-b6bda1e3b168&webSyncID=8e55a66e-00dc-9a6d-56be-22b1b35359d8&sessionGUID=0123ccb6-7d57-c57a-be28-dbd0b50c7629&_gl=1*10n8f2g*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTE0NDg2Ny4xMi4xLjE2MjkxNDU5NjYuMA..&_ga=2.10862004.1090954148.1629141557-1179124804.1583853558',
      'newsletter-txt':
        'Pour gérer vos préférences courriel, <a href="https://www.tremblant.ca/courriel/preferences?sessionGUID=39a832be-6450-61da-042a-b6bda1e3b168&webSyncID=8e55a66e-00dc-9a6d-56be-22b1b35359d8&sessionGUID=ddb2dad6-2e69-1ce1-3dda-dece4482c210&_gl=1*1y7o6s0*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyODk1NTUxMS45LjEuMTYyODk1NTUxNC4w&_ga=2.6518577.2057345983.1628795686-1179124804.1583853558" target="_blank">cliquez ici</a>. Pour vous désabonner, <a href="https://www.tremblant.ca/courriel/desabonnement?sessionGUID=39a832be-6450-61da-042a-b6bda1e3b168&webSyncID=8e55a66e-00dc-9a6d-56be-22b1b35359d8&sessionGUID=ddb2dad6-2e69-1ce1-3dda-dece4482c210&_gl=1*k3fjhg*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyODk1NTUxMS45LjEuMTYyODk1NTUyMC4w&_ga=2.110800902.2057345983.1628795686-1179124804.1583853558" target="_blank">cliquez ici</a>.',

      'footer-data':
        'Tremblant © 2022-2023 | <a href="https://www.alterramtnco.com/politique-de-vie-privee/?webSyncID=8e55a66e-00dc-9a6d-56be-22b1b35359d8&sessionGUID=ddb2dad6-2e69-1ce1-3dda-dece4482c210" target="_blank">Politique de vie privée</a> | <a href="https://www.alterramtnco.com/conditions-dutilisation/?webSyncID=8e55a66e-00dc-9a6d-56be-22b1b35359d8&sessionGUID=ddb2dad6-2e69-1ce1-3dda-dece4482c210" target="_blank">Conditions d\'utilisation</a>',

      'dispo-title': 'profitez des meilleures disponibilités et<br>&nbsp;des meilleures offres en cours',

      'eco-title': 'Tremblant, leader en écoresponsabilité',
      'eco-data':
        'En 2021, Station Mont-Tremblant devient la première entreprise québécoise à obtenir la certification de niveau 3 du programme Écoresponsablemc contrôlé par Ecocert Canada.<br><br>Séjournez à Tremblant cet hiver tout en compensant votre impact environnemental.',
      'eco-cta': 'En savoir plus',
      'eco-link': 'https://www.tremblant.ca/a-propos/ecoresponsabilite',

      'ecole-title': 'École sur neige<br>Tremblant',
      'ecole-data':
        'Profitez de leçons et de programmes avec des spécialistes certifiés, que vous soyez débutant ou avancé. Nos programmes s\'adressent autant aux skieurs qu\'aux planchistes.<br><br>Profitez de votre séjour pour perfectionner votre technique!',
      'ecole-cta': 'En savoir plus',
      'ecole-link': 'https://www.tremblant.ca/planifiez/ecole-sur-neige-tremblant?sc_lang=fr',

      'rando-title': 'Passe de randonnée<br>alpine incluse avec votre passe tonik',
      'rando-data':
        'Profitez de nos zones d’ascension désignées<br><br>Venez faire l’ascension de la plus haute montagne des Laurentides, skis aux pieds, dans l’une de nos zones désignées ou pistes boisées et redescendez dans l’une de nos 102 pistes de ski balisées!',

      'heb-title': "Économisez jusqu'à 30 %<br>Sur l’hébergement",
      'heb-data':
        "Offre valable jusqu'au 19 octobre 2021<br><br>Accédez aux meilleures unités tout en bénéficiant des plus importants rabais de la saison! Planifiez votre séjour dès maintenant, incluant les périodes des fêtes et de Pâques.",

      'la-heb-title': "Économisez jusqu'à 25 %<br>Sur l’hébergement",
      'la-heb-data':
        "Offre valable jusqu'au 30 novembre 2021<br><br>Accédez aux meilleures unités tout en bénéficiant de nos offres en cours ! Planifiez votre séjour dès maintenant, incluant les périodes des fêtes et de Pâques.",

      'entrepot-heb-title': "Économisez jusqu'à 20 %<br>Sur l’hébergement",
      'entrepot-heb-data':
        'Accédez aux meilleures unités tout en bénéficiant de nos offres en cours ! Planifiez votre séjour dès maintenant, incluant les périodes des fêtes et de Pâques.',

      'privilege-title': 'Privilèges hébergement<br><span class="small-title">Prêt de patins, premières traces et Jardins de glace</span>',
      'privilege-price': 'Valeur de 45$ par adulte par jour',
      'privilege-more': 'En savoir plus sur les Privilèges Hébergement',
      'privilege-data': `<h3 class="small-title">Prêt de patins, premières traces et Jardins de glace</h3>
                              <p class="regular-text bold">Prêt de patins</p>
                              <p class="regular-text">La patinoire sur le terrain de la chapelle Saint-Bernard vous accueille avec de la musique et un feu de joie. Patiner toute la soirée sur la glace illuminée ! Le prêt de patins pour une durée de 2 heures est gratuit de 13 h à 21 h, du 18 décembre au 20 mars pour les invités en hébergement, selon la disponibilité de l’équipement.</p>
                              <ul>
                                <li class="regular-text">Port du couvre-visage recommandé mais non obligatoire</li>
                                <li class="regular-text">Horaire sujet à changement sans préavis</li>
                              </ul>
                              <p class="regular-text bold">Premières traces</p>
                              <p class="regular-text">Soyez les premiers à laisser vos traces dans la neige fraîche du versant nord et sud ! Du 18 décembre 2021 au 20 mars 2022, prenez les remontées 45 minutes avant l’ouverture au grand public ! Chez les hôteliers participants.</p>
                              <ul>
                                <li class="regular-text">Accès aux remontées désignées dès 7&nbsp;h&nbsp;45.</li>
                                <li class="regular-text">Ski sur les pistes désignées du versant nord et sud dès 7&nbsp;h&nbsp;45.</li>
                              </ul>
                              <p class="regular-text bold">Jardins de glace</p>
                              <p class="regular-text">Cet hiver, on se donne rendez-vous aux Jardins de glace sur le terrain de la chapelle St-Bernard pour dévaler les deux glissades glacées d’une hauteur de plus de 2,5 mètres et admirer les sculptures de glace. Réservée exclusivement aux détenteurs de billets Privilèges hébergement, l’activité est ouverte tous les jours, de 13h à 21h, du 25 décembre au 20 mars. En famille, en couple ou entre amis, toutes les raisons sont bonnes pour jouer dehors!</p>
                              <ul>
                                <li class="regular-text">Port du casque de protection recommandé</li>
                                <li class="regular-text">Port du couvre-visage recommandé mais non obligatoire</li>
                                <li class="regular-text">Horaire sujet à changement sans préavis</li>
                              </ul>
            `,
      'privilege-video': 'https://www.youtube.com/embed/NUhIP9rCxds',

      'garantie-title':
        'Programme Assurance Aventure :<br><span class="small-title">dévalez les pistes en toute confiance en 2022-23</span>',
      'garantie-data': `<h3 class="small-title">Programme Assurance Aventure</h3>
                              <p class="regular-text">Grâce à l’assurance Aventure, les détenteurs d’une passe Tonik 2022/23 peuvent avoir l’esprit tranquille tout au long de la saison hivernale 2022/23. Si un détenteur d’une passe Tonik 2022/23 n’utilise pas son abonnement pour une quelconque raison, il peut choisir, d’ici le 8 décembre 2022, de se faire créditer complètement le prix d’achat de sa passe Tonik 2022/23 inutilisée et d’appliquer le crédit à l’achat d’une passe Tonik 2023/24, sous réserve des conditions générales applicables. Si Station Mont Tremblant atteint le nombre minimal de jours de fermeture en raison de la pandémie de COVID-19 entre le 17 décembre 2022 et 5 mars 2023, Station Mont Tremblant émettra automatiquement un crédit aux détenteurs d’une passe Tonik 2022/23, même s’ils ont déjà utilisé leur passe. Consulter les politiques et modalités</p>
                              <a class="regular-text link" href="https://www.tremblant.ca/planifiez/billets-passes/passes-de-saison-de-ski/assurance-aventure?sc_lang=fr" target="_blank">Consulter les politiques et modalités</a>
            `,
      'politique-title': `nouvelles<br>politiques<br>assouplies`,
      'politique-data': `<h3 class="small-title">Nouvelles politiques de réservation et d’annulation assouplies</h3>
                              <p class="regular-text">Puisque nous savons qu’il peut être difficile de planifier un séjour à l’avance en cette période sans précédent, nous vous invitons à consulter les nouvelles <a href="https://www.tremblant.ca/a-propos/politiques?sc_lang=fr#hebergement" target="_blank">politiques de réservation et d’annulation assouplies des différents partenaires hôteliers</a>. Les politiques varient d’un partenaire à l’autre. Sujet à changement sans préavis. N’hésitez pas à communiquer avec nous pour toutes questions.</p>
            `,

      'privilege-card-title': 'Bonifiez votre expérience<br>avec le forfait de privilèges<br><span>Exclusif aux détenteurs de passes de saison 2022-23.</span>',
      'privilege-price-1': '<p>139 $<br><span>Adultes (18 ans et +)</span></p>',
      'privilege-price-2': '<p>99 $<br><span>Enfants (17 ans et -)</span></p>',
      'privilege-1-title': 'Sur les pistes avant l’ouverture grand public!',
      'privilege-2-title': '20 cafés ou chocolats chauds',
      'privilege-3-title': '15 % de rabais dans les cafétérias Tremblant',
      'privilege-4-title': '15 % de rabais dans les boutiques Tremblant',
      'privilege-5-title': '200 $ dans les boutiques Tremblant',
      'privilege-6-title': 'Une mise au point gratuite au ski shop',
      'privilege-cta-title': 'ACHETEZ VOTRE FORFAIT PRIVILÈGES',
      'privilege-cta-link':
        'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=43&ProductCategoryCode=279&ClientId=802&CultureName=fr-CA',

      'bh-lodging-hero': "Économisez jusqu'à<br><span>30 %</span><br>Sur l’hébergement",
      'bh-lodging-cut': "Offres valables jusqu'au 19 octobre 2021",
      'bh-lodging-data': `
                                <h2 class="medium-title">Économisez jusqu'à<br>30 % sur l'hébergement</h2>
                                <p class="regular-text">Offre valable jusqu'au 19 octobre 2021.</p>
                                <p class="regular-text soft">Accédez aux meilleures unités tout en bénéficiant des plus importants rabais de la saison! Planifiez votre séjour dès maintenant, incluant les périodes des fêtes et de Pâques.</p>
                                <ul>
                                  <li class="regular-text soft">Meilleure offre pour des séjours d’hiver.</li>
                                  <li class="regular-text soft">Meilleures disponibilités d’unités d’hébergement.</li>
                                  <li class="regular-text soft">Du temps pour planifiez tous les détails de votre séjour.</li>
                                </ul>
                                <div class="price-grid">
                                  <div class="price">
                                    <p>30 %<br><span>4 nuits ou +</span></p>
                                  </div>
                                  <div class="price">
                                    <p>25 %<br><span>3 nuits</span></p>
                                  </div>
                                  <div class="price">
                                    <p>15 à 20 %<br><span>2 nuits</span></p>
                                  </div>
                                </div>
            `,
      'meta-title-lodging': "Économisez jusqu'à 30 % sur l'hébergement",
      'meta-desc-lodging': 'Accéder aux meilleures unités tout en bénéficiant des plus importants rabais de la saison!',

      'al-lodging-hero': "Économisez jusqu'à<br><span>25 %</span><br>Sur l’hébergement",
      'al-lodging-cut': "Offres valables jusqu'au 30 novembre 2021",
      'al-lodging-data': `
                                <h2 class="medium-title">Économisez jusqu'à<br>25 % sur l'hébergement</h2>
                                <p class="regular-text">Offre valable jusqu'au 30 novembre 2021.</p>
                                <p class="regular-text soft">Planifiez aujourd’hui et économisez pour la saison hivernale à Tremblant. Réservez d’ici au 30 novembre 2021 et économisez jusqu’à 25 %, même pour des séjours à Noël et à Pâques :</p>
                                <ul>
                                  <li class="regular-text soft">Meilleure offre pour des séjours d’hiver.</li>
                                  <li class="regular-text soft">Meilleure sélection d’hébergement.</li>
                                  <li class="regular-text soft">Plus de temps pour planifier tous les détails de votre séjour.</li>
                                </ul>
                                <div class="price-grid">
                                  <div class="price">
                                    <p>25 %<br><span>4 nuits ou +</span></p>
                                  </div>
                                  <div class="price">
                                    <p>20 %<br><span>3 nuits</span></p>
                                  </div>
                                  <div class="price">
                                    <p>15 à 20 %<br><span>2 nuits</span></p>
                                  </div>
                                </div>
            `,
      'al-meta-title-lodging': "Économisez jusqu'à 25 % sur l'hébergement",
      'al-meta-desc-lodging':
        'Planifiez aujourd’hui et économisez pour la saison hivernale à Tremblant. Réservez d’ici au 30 novembre 2021 et économisez jusqu’à 25 %.',

      'in-lodging-hero': "Économisez jusqu'à<br><span>20 %</span><br>Sur l’hébergement",
      'in-lodging-cut': "En vente jusqu'au 29 avril 2022",
      'in-lodging-data': `
                                <h2 class="medium-title"><span>OFFRE VACANCES DANS LA NEIGE</span><br>Économisez Jusqu'à 20 % sur l'hébergement</h2>
                                <p class="regular-text">En vente jusqu'au 29 avril 2022</p>
                                <p class="regular-text soft">Profitez de cette offre tout au long de la saison, que ce soit pour Noël, Pâques, ou au printemps! Du 1er décembre 2021 au 29 avril 2022, réservez un séjour de deux nuits et plus et obtenez :</p>
                                <p class="regular-text soft">Pour des séjours du 2 décembre 2021 au 19 mars 2022 :</p>
                                <ul>
                                  <li class="regular-text soft">20% de rabais sur un séjour de 3 nuits et plus</li>
                                  <li class="regular-text soft">15% de rabais sur un séjour de 2 nuits</li>
                                  <li class="regular-text soft">Sauf le samedi où le rabais est de 15%</li>
                                </ul>
                                <p class="regular-text soft">Pour des séjours du 20 mars au 30 avril 2022 :</p>
                                <ul>
                                  <li class="regular-text soft">20% de rabais sur un séjour de 2 nuits et plus</li>
                                  <li class="regular-text soft">Sauf le samedi où le rabais est de 15%</li>
                                </ul>
            `,
      'in-meta-title-lodging': "Économisez jusqu'à 20 % sur l'hébergement",
      'in-meta-desc-lodging': 'Profitez de cette offre tout au long de la saison, que ce soit pour Noël, Pâques, ou au printemps!',

      'bh-ski-hero': 'Skiez 2 à 7 jours à partir de<br><span>79 $</span><br>par jour',
      'bh-ski-link':
        'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/fr-CA/?productcategoryid=117&startMonth=11&_gl=1*1qi8pjj*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIxNzM4NC4xMy4wLjE2MjkyMTczODQuMA..&_ga=2.3495344.1090954148.1629141557-1179124804.1583853558',
      'bh-ski-data': `
                            <h2 class="medium-title">Skiez 2 à 7 jours<br>à partir de 79 $/jour</h2>
                            <p class="regular-text">Offre valable jusqu'au 19 octobre 2021.</p>
                            <p class="regular-text soft">Réservez à l'avance et sécurisez le meilleur tarif. Procurez-vous vos billets de ski et obtenez le meilleur tarif pour la saison 2021/22. N’hésitez pas à en profiter car cette année, les billets de ski pour Tremblant sont en vente seulement via nos plateformes.</p>
                            <p class="regular-text">Lundi au vendredi :</p>
                            <div class="price-grid">
                              <div class="price">
                                <p>45 $ <span>/jour</span><br><span>Enfant (5-12)</span></p>
                              </div>
                              <div class="price">
                                <p>53 $ <span>/jour</span><br><span>Jeune (13-17)</span></p>
                              </div>
                              <div class="price">
                                <p>68 $ <span>/jour</span><br><span>Aîné (70+)</span></p>
                              </div>
                            </div>
            `,

      'al-ski-hero': 'Skiez 2 à 7 jours à partir de<br><span>83 $</span><br>par jour',
      'al-ski-link':
        'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/fr-CA/?productcategoryid=117&startMonth=11&_gl=1*1qi8pjj*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIxNzM4NC4xMy4wLjE2MjkyMTczODQuMA..&_ga=2.3495344.1090954148.1629141557-1179124804.1583853558',
      'al-ski-data': `
                            <h2 class="medium-title">Skiez 2 à 7 jours<br>à partir de 83 $/jour</h2>
                            <p class="regular-text">Offre valable jusqu'au 30 novembre 2021.</p>
                            <p class="regular-text soft">Réservez à l'avance et sécurisez le meilleur tarif. Procurez-vous vos billets de ski et obtenez le meilleur tarif pour la saison 2021/22. N’hésitez pas à en profiter car cette année, les billets de ski pour Tremblant sont en vente seulement via nos plateformes.</p>
                            <p class="regular-text">Lundi au vendredi :</p>
                            <div class="price-grid">
                              <div class="price">
                                <p>48 $ <span>/jour</span><br><span>Enfant (5-12)</span></p>
                              </div>
                              <div class="price">
                                <p>56 $ <span>/jour</span><br><span>Jeune (13-17)</span></p>
                              </div>
                              <div class="price">
                                <p>72 $ <span>/jour</span><br><span>Aîné (70+)</span></p>
                              </div>
                            </div>
            `,

      'bh-tonik-dink-hero': 'Passe Tonik<br><span>649 $</span><br>Adulte (18-69)',
      'bh-tonik-fam-hero': 'Passe Tonik<br><span>135 $</span><br>Enfant (5-12)',
      'bh-tonik-cut': 'Les tarifs augmentent le 15 octobre 2021',
      'bh-tonik-link':
        'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=30&ProductCategoryCode=235&ClientId=802&CultureName=fr-CA&_gl=1*hvffdy*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTM5OTc0OC4xMy4xLjE2MjkzOTk3NjMuMA..&_ga=2.208059794.1090954148.1629141557-1179124804.1583853558',
      'bh-tonik-argument-1': "Jusqu'à 112 jours de glisse fixes",
      'bh-tonik-argument-2': "Jusqu'à 27 journées de fin de semaine",
      'bh-tonik-argument-3': '3 jours flottants pour utilisation en tout temps',
      'bh-tonik-dink-data': `
                              <h2 class="medium-title">Passe Tonik à 649 $<br>Adulte (18-69)</h2>
                              <p class="regular-text">Les tarifs augmentent le 15 octobre 2021</p>
                              <p class="regular-text soft">Jusqu'à 112 jours de glisse et 3 flottants pour utilisation en tout temps, même durant la période des fêtes (à l’exception des 19 et 20 février 2022)! <a href="https://medias.tremblant.ca/pdf/ski/calendrier-passe-tonik-2021-22.pdf" target="_blank">Voir le calendrier</a></p>
                              <ul>
                                <li class="regular-text soft">Jusqu’à 27 journées de fin de semaine.</li>
                                <li class="regular-text soft">Des journées durant les relâches scolaires.</li>
                                <li class="regular-text soft">15 % de rabais sur toutes les journées non incluses.</li>
                                <li class="regular-text soft">Forfait de privilèges pour détenteurs pour seulement 99 $ donnant accès aux Premières traces et à d’autres offres exclusives aux détenteurs!</li>
                                <li class="regular-text soft">Passe de randonnée alpine incluse. Valeur de 42$.</li>
                                <li class="regular-text soft">La passe Tonik est offerte en exclusivité aux résidents canadiens.</li>
                              </ul>
                              <div class="price-grid">
                                <div class="price">
                                  <p>415 $<br><span>Étudiant (18-25)</span></p>
                                </div>
                                <div class="price">
                                  <p>519 $<br><span>Aîné (70+)</span></p>
                                </div>
                                <div class="price">
                                  <p>239 $<br><span>Maître (80+)</span></p>
                                </div>
                              </div> 
            `,
      'meta-title-tonik-dink': 'Passe Tonik 649 $ Adulte (18-69)',
      'meta-desc-tonik-dink': "Jusqu'à 112 jours de glisse et 3 flottants pour utilisation en tout temps, même durant la période des fêtes",
      'bh-tonik-fam-data': `
                              <h2 class="medium-title">Passe Tonik à 135 $<br>Enfants (5-12)</h2>
                              <p class="regular-text">Les tarifs augmentent le 15 octobre 2021</p>
                              <p class="regular-text soft">Jusqu'à 112 jours de glisse et 3 flottants pour utilisation en tout temps, même durant la période des fêtes (à l’exception des 19 et 20 février 2022)! <a href="https://medias.tremblant.ca/pdf/ski/calendrier-passe-tonik-2021-22.pdf?_gl=1*1jrhxrl*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTcyNzEwOS4xMy4xLjE2Mjk3Mjc0MzUuMA..&_ga=2.209146386.1090954148.1629141557-1179124804.1583853558" target="_blank">Voir le calendrier</a></p>
                              <ul>
                                <li class="regular-text soft">Jusqu’à 27 journées de fin de semaine.</li>
                                <li class="regular-text soft">Des journées durant les relâches scolaires.</li>
                                <li class="regular-text soft">15 % de rabais sur toutes les journées non incluses.</li>
                                <li class="regular-text soft">Forfait de privilèges pour détenteurs pour seulement 79 $ donnant accès aux Premières traces et à d’autres offres exclusives aux détenteurs!</li>
                                <li class="regular-text soft">Passe de randonnée alpine incluse. Valeur de 42$.</li>
                                <li class="regular-text soft">La passe Tonik est offerte en exclusivité aux résidents canadiens.</li>
                              </ul>
                              <div class="price-grid">
                                <div class="price">
                                  <p>45 $<br><span>Peewee (0-4)</span></p>
                                </div>
                                <div class="price">
                                  <p>415 $<br><span>Jeune (13-17)</span></p>
                                </div>
                                <div class="price">
                                  <p>415 $<br><span>Étudiant (18-25)</span></p>
                                </div>
                              </div> 
            `,
      'meta-title-tonik-fam': 'Passe Tonik à 135 $ Enfants (5-12)',
      'meta-desc-tonik-fam': "Jusqu'à 112 jours de glisse et 3 flottants pour utilisation en tout temps, même durant la période des fêtes",

      'bh-latitude-hero': 'Skiez 2 à 4 jours pour<br><span>76 $</span><br>par jour',
      'bh-latitude-cut': "Offre valable jusqu'au 7 octobre 2021",
      'bh-latitude-link':
        'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=3&ProductCategoryCode=64&ClientId=802&CultureName=fr-CA&_gl=1*18ctl68*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTczMTY0Ni4xMy4xLjE2Mjk3MzIwODcuMA..&_ga=2.3101104.1090954148.1629141557-1179124804.1583853558',
      'bh-latitude-argument-1': '2 à 4 journées de glisse',
      'bh-latitude-argument-2': 'Jusqu’à 36 % d’économies',
      'bh-latitude-argument-3': '15 % de rabais sur toutes journées additionnelles',
      'bh-latitude-data': `
                              <h2 class="medium-title">Skiez 2 à 4 jours<br>pour 76 $/jour<br>Adulte (18-69)</h2>
                              <p class="regular-text soft">Jusqu'a 36 % d'économies en achetant d'ici le 7 octobre.</p>
                              <ul>
                                <li class="regular-text soft">2 à 4 journées de ski ou de planche, consécutives ou non, à partir de 76 $ par jour, par adulte.</li>
                                <li class="regular-text soft">Les journées peuvent être utilisées à votre guise durant la saison de ski 2021/22 – à l’exception du 27 décembre 2021 au 2 janvier 2022 inclusivement et des 8 samedis suivants: 15, 22 et 29 janvier, 12 et 19 février, 5, 19 et 26 mars 2022.</li>
                                <li class="regular-text soft">15 % de rabais sur toutes journées additionnelles.</li>
                                <li class="regular-text soft">Offert en exclusivité aux résidents canadiens.</li>
                              </ul>
                              <div class="price-grid">
                                <div class="price">
                                  <p>43 $<span> /jour</span><br><span>Enfant (5-12)</span></p>
                                </div>
                                <div class="price">
                                  <p>51 $<span> /jour</span><br><span>Jeune (13-17)</span></p>
                                </div>
                                <div class="price">
                                  <p>66 $<span> /jour</span><br><span>Aîné (70+)</span></p>
                                </div>
                              </div> 
            `,
      'bh-latitude-title':
        'Profitez de cette offre maintenant<br><br><span class="small-title">les billets de ski pour Tremblant seront<br>en vente seulement via nos plateformes.</span>',
      'bh-latitude-date': '7 oct.',
      'meta-title-latitude': 'Skiez 2 à 4 jours pour 76 $ par jour',
      'meta-desc-latitude': "Jusqu'a 36 % d'économies en achetant d'ici le 7 octobre.",

      'la-latitude-hero': 'Skiez 2 à 4 jours pour<br><span>80 $</span><br>par jour',
      'la-latitude-cut': 'En vente jusqu’au 23 novembre',
      'la-latitude-link':
        'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=3&ProductCategoryCode=64&ClientId=802&CultureName=fr-CA&_gl=1*18ctl68*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTczMTY0Ni4xMy4xLjE2Mjk3MzIwODcuMA..&_ga=2.3101104.1090954148.1629141557-1179124804.1583853558',
      'la-latitude-argument-1': '2 à 4 journées de glisse',
      'la-latitude-argument-2': 'Jusqu’à 33 % d’économies',
      'la-latitude-argument-3': '15 % de rabais sur toutes journées additionnelles',
      'la-latitude-data': `
                              <h2 class="medium-title">Skiez 2 à 4 jours<br>pour 80 $/jour<br>Adulte (18-69)</h2>
                              <p class="regular-text soft">Jusqu'a 33 % d'économies en achetant d'ici le 23 novembre.</p>
                              <ul>
                                <li class="regular-text soft">2 à 4 journées de ski ou de planche, consécutives ou non, pour 80 $ par jour, par adulte.</li>
                                <li class="regular-text soft">Les journées peuvent être utilisées à votre guise durant la saison de ski 2021/22 – à l’exception du 27 décembre 2021 au 2 janvier 2022 inclusivement et des 8 samedis suivants: 15, 22 et 29 janvier, 12 et 19 février, 5, 19 et 26 mars 2022.</li>
                                <li class="regular-text soft">15 % de rabais sur toutes journées additionnelles.</li>
                                <li class="regular-text soft">Offert en exclusivité aux résidents canadiens.</li>
                              </ul>
                              <div class="price-grid">
                                <div class="price">
                                  <p>46 $<span> /jour</span><br><span>Enfant (5-12)</span></p>
                                </div>
                                <div class="price">
                                  <p>54 $<span> /jour</span><br><span>Jeune (13-17)</span></p>
                                </div>
                                <div class="price">
                                  <p>69 $<span> /jour</span><br><span>Aîné (70+)</span></p>
                                </div>
                              </div> 
            `,
      'la-latitude-title':
        'Profitez de cette offre maintenant<br><br><span class="small-title">les billets de ski pour Tremblant seront<br>en vente seulement via nos plateformes.</span>',
      'la-latitude-date': '23 nov.',
      'la-meta-title': 'Skiez 2 à 4 jours pour 80 $ par jour',
      'la-meta-desc': "Jusqu'a 33 % d'économies en achetant d'ici le 23 novembre.",

      'smt-entrepot-hero': 'Entrepôt de billets<br><span>JUSQU’À 25% DE RABAIS</span><br>SUR LES BILLETS DE SKI',
      'smt-entrepot-hero-2': "Économisez jusqu'à<br><span>25 %</span><br>sur les billets de ski",
      'smt-entrepot-best-1': 'Profitez des meilleurs prix<br>sur les billets de ski',
      'smt-entrepot-best-2': '<span class="small-title">les billets de ski pour Tremblant seront<br>en vente seulement via nos plateformes.</span>',
      'smt-entrepot-data': `
                              <h2 class="medium-title"><span>Entrepôt de billets Tremblant</span><br>Jusqu’à 25% de rabais sur les billets de ski</h2>
                              <p class="regular-text soft">Bienvenue dans l’entrepôt de billets Tremblant! Vous y trouverez des tarifs variables pour skier Tremblant en fonction de la date et du nombre de jours que vous choisirez. Cette façon conviviale d’acheter vos billets de ski vous fera économiser jusqu’à 25%! Quantité journalières limitées.</p>
                              <ul>
                                <li class="regular-text soft">Économisez jusqu’à 25 % à l’achat de 1 à 5 journées de glisse.</li>
                                <li class="regular-text soft">Tarifs variables en fonction des dates que vous choisissez.</li>
                                <li class="regular-text soft">Quantité journalières limitées.</li>
                              </ul> 
            `,
      'smt-entrepot-meta-title': "Entrepôt de billets jusqu'à 25 % de rabais sur les billets de ski",
      'smt-entrepot-meta-desc': 'Profitez des meilleurs prix sur les billets de ski',
      'smt-entrepot-link': 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/fr-CA/?productcategoryid=117',

      'flash-dec-hero': 'VENTE ÉCLAIR<br><span>Billets transférables<br>à partir de 85 $</span><br>Achat en paire',
      'flash-dec-cut': "En vente jusqu'au 10 décembre",
      'flash-dec-best-1': 'Profitez des meilleurs prix<br>sur les billets de ski',
      'flash-dec-best-2': '<span class="small-title">les billets de ski pour Tremblant seront<br>en vente seulement via nos plateformes.</span>',
      'flash-dec-data': `
                              <h2 class="medium-title"><span>VENTE ÉCLAIR</span><br>Billets transférables à partir de 85 $<br><span>Achat en paire</span></h2>
                              <p class="regular-text soft">Obtenez des billets de ski transférables à un tarif avantageux, pour un cadeau joyeux! Obtenez des paires de billets de ski transférables à un excellent tarif pour profiter des pistes à trois moments forts de la saison! Gardez-les pour vous, invitez des amis ou glissez-les sous l’arbre de Noël! Les billets, sont valables pour les adultes (18+) pour les périodes suivantes :</p>
                              <div class="table">
                                <div class="row nbb">
                                  <div class="cell-35">
                                    <p class="regular-text"><span>Début de saison</span><br>9 au 17 déc. 2021</p>
                                  </div>
                                  <div class="cell-65">
                                    <p class="regular-text"><span>89 $ par billet</span><br>Achat en paire<br>Non valable les samedis</p>
                                  </div>
                                </div>
                                <div class="row nbb">
                                  <div class="cell-35">
                                    <p class="regular-text"><span>Cœur de l’hiver</span><br>10 jan. au 11 fév. 2022</p>
                                  </div>
                                  <div class="cell-65">
                                    <p class="regular-text"><span>85 $ par billet</span><br>Achat en paire<br>Non valable les samedis et dimanches</p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="cell-35">
                                    <p class="regular-text"><span>Ski de printemps</span><br>21 mar. au 18 avr. 2022</p>
                                  </div>
                                  <div class="cell-65">
                                    <p class="regular-text"><span>89 $ par billet</span><br>Achat en paire<br>Non valable les samedis</p>
                                  </div>
                                </div>
                              </div> 
       `,
      'flash-dec-meta-title': 'VENTE ÉCLAIR Billets transférables à partir de 85 $',
      'flash-dec-meta-desc': 'Obtenez des billets de ski transférables à un tarif avantageux, pour un cadeau joyeux!',
      'flash-dec-link': 'https://reservations.tremblant.ca/ecomm/shop/merchandise/2624850/fr-CA/?productsupercategoryid=23',

      'flash-jan-hero': 'VENTE ÉCLAIR<br><span>Billets de ski<br>à partir de 89 $</span><br>Quantité limitée',
      'flash-jan-cut': "En vente jusqu'au 13 janvier, 13h",
      'flash-jan-best-1': 'Profitez des meilleurs prix<br>sur les billets de ski',
      'flash-jan-best-2': '<span class="small-title">les billets de ski pour Tremblant seront en vente seulement via nos plateformes.</span>',
      'flash-jan-data': `
                              <h2 class="medium-title"><span>VENTE ÉCLAIR</span><br>Billets de ski<br>à partir de 89 $<br><span>Quantité limitée</span></h2>
                              <p class="regular-text soft">Obtenez des billets de ski à un tarif avantageux! Obtenez des billets de ski à un excellent tarif pour profiter des pistes cette saison! Pour 48h seulement, nous avons ajouté des billets de ski à partir de 89$ dans l’entrepôt de billets.</p>
                              <p class="regular-text bold">Tarification adultes (18 ans et plus)</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-35">
                                    <p class="regular-text"><span>Date spécifique.</span><br>Du 13 janvier 2022 au 18 avril 2022</p>
                                  </div>
                                  <div class="cell-65">
                                    <p class="regular-text"><span>À partir de 89 $ par billet.</span><br>Quantité limitée.</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text soft">Redevance et taxes en sus.<br>Ces tarifs, dates, redevance et taxes sont modifiables sans préavis.</p> 
       `,
      'flash-jan-meta-title': 'VENTE ÉCLAIR Billets de ski à partir de 89 $',
      'flash-jan-meta-desc': 'Obtenez des billets de ski à un excellent tarif pour profiter des pistes cette saison!',
      'flash-jan-link': 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/fr-CA/?productcategoryid=117&StartYear=2022&StartMonth=1&agecategory=8&days=1',

      'flash-fev-heb-hero': "Jusqu'à<br><span>20&hairsp;%</span><br>Sur l’hébergement",
      'flash-fev-ski-hero': "Billets de ski à partir de<br><span>89 $</span><br>Quantité limitée",
      'flash-fev-cut': "Jusqu'au 3 février, 13h",
      'flash-fev-best-1': 'L’hiver à son meilleur !',
      'flash-fev-best-2': '<span class="small-title">les billets de ski pour Tremblant seront en vente seulement via nos plateformes.</span>',
      'flash-fev-heb-data': `
                              <h2 class="medium-title"><span>OFFRE VACANCES DANS LA NEIGE</span><br>Jusqu'à 20 % sur l'hébergement</h2>
                              <p class="regular-text soft">Économisez tout l’hiver lorsque vous réservez un séjour de 2 nuits ou plus. Jusqu'à 20% sur l'hébergement. Profitez de cette offre tout au long de la saison, que ce soit pour Pâques, ou au printemps! Jusqu'au 19 mars, réservez un séjour de deux nuits et plus et obtenez :</p>
                              <p class="regular-text bold">Pour des séjours jusqu'au 19 mars :</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-50">
                                    <p class="regular-text"><span>20% de rabais</span> sur un séjour de 3 nuits et plus</p>
                                  </div>
                                  <div class="cell-50">
                                    <p class="regular-text"><span>15% de rabais</span> sur un séjour de 2 nuits</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text bold">Pour des séjours du 20 mars au 30 avril 2022: </p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-100">
                                    <p class="regular-text"><span>20% de rabais</span><br>sur un séjour de 2 nuits et plus </p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text soft">Sauf le samedi où le rabais est de 15%</p> 
      `,
      'flash-fev-ski-data': `
                              <h2 class="medium-title"><span>VENTE ÉCLAIR</span><br>Billets de ski<br>à partir de 89 $<br><span>Quantité limitée</span></h2>
                              <p class="regular-text soft">Obtenez des billets de ski à un tarif avantageux pour profiter du coeur de l'hiver! Pour 48h seulement, nous avons ajouté des billets de ski à partir de 89$ dans l’entrepôt de billets. Les billets de ski pour Tremblant seront en vente seulement via nos plateformes.</p>
                              <p class="regular-text bold">Tarification adultes (18 ans et plus)</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-35">
                                    <p class="regular-text"><span>Date spécifique.</span><br>Du 3 février 2022 au 18 avril 2022</p>
                                  </div>
                                  <div class="cell-65">
                                    <p class="regular-text"><span>À partir de 89 $ par billet.</span><br>Quantité limitée.</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text soft">Redevance et taxes en sus.<br>Ces tarifs, dates, redevance et taxes sont modifiables sans préavis.</p> 
       `,
      'flash-fev-meta-title': 'VENTE ÉCLAIR Économisez sur l’hébergement et sur les billets de ski',
      'flash-fev-meta-desc': 'Profitez de cette offre tout au long de la saison, que ce soit pour Pâques, ou au printemps!',
      'flash-fev-link': 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/fr-CA/?productcategoryid=117&StartYear=2022&StartMonth=2&agecategory=8&days=1&_gl=1*16alw9a*_ga*OTA3NjY0MzY4LjE2MzI5NDIyNzU.*_ga_B1YVPN56M6*MTY0MzI5MjM3NC42OC4xLjE2NDMyOTI0NzguMA..&_ga=2.162674620.1039408263.1643292374-907664368.1632942275',

      'flash-mars-heb-hero': "Jusqu'à<br><span>20&hairsp;%</span><br>Sur l’hébergement",
      'flash-mars-ski-hero': "Billets de ski à<br><span>95 $</span><br>Quantité limitée",
      'flash-mars-ski-hero-usa': "Billets de ski à<br><span>95 $ <sup>CAD</sup></span><br>Quantité limitée",
      'flash-mars-cut': "Jusqu'au 3 mars, 13h",
      'flash-mars-best-1': 'Un printemps 100% éclaté !',
      'flash-mars-best-2': '<span class="small-title">les billets de ski pour Tremblant seront en vente seulement via nos plateformes.</span>',
      'flash-mars-heb-data': `
                              <h2 class="medium-title"><span>OFFRE VACANCES DANS LA NEIGE</span><br>Jusqu'à 20 % sur l'hébergement</h2>
                              <p class="regular-text soft">Économisez tout l’hiver lorsque vous réservez un séjour de 2 nuits ou plus. Jusqu'à 20% sur l'hébergement. Profitez de cette offre tout au long de la saison, que ce soit pour Pâques, ou au printemps! Jusqu'au 19 mars, réservez un séjour de deux nuits et plus et obtenez :</p>
                              <p class="regular-text bold">Pour des séjours jusqu'au 19 mars :</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-50">
                                    <p class="regular-text"><span>20% de rabais</span> sur un séjour de 3 nuits et plus</p>
                                  </div>
                                  <div class="cell-50">
                                    <p class="regular-text"><span>15% de rabais</span> sur un séjour de 2 nuits</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text bold">Pour des séjours du 20 mars au 30 avril 2022: </p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-100">
                                    <p class="regular-text"><span>20% de rabais</span><br>sur un séjour de 2 nuits et plus </p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text soft">Sauf le samedi où le rabais est de 15%</p> 
      `,
      'flash-mars-ski-data': `
                              <h2 class="medium-title"><span>VENTE ÉCLAIR</span><br>Billets de ski à 95 $<br><span>Quantité limitée</span></h2>
                              <p class="regular-text soft">Obtenez des billets de ski à un tarif avantageux pour profiter des pistes d'ici la fin de la saison. Les billets sont valables pour les périodes suivantes :</p>
                              <ul>
                                <li class="regular-text soft">Du 7 au 11 mars 2022 – excluant les samedis</li>
                                <li class="regular-text soft">Du 21 mars au 18 avril 2022– excluant les samedis</li>
                              </ul>
                              <p class="regular-text bold">Tarification adultes (18 ans et plus)</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-35">
                                    <p class="regular-text"><span>Date spécifique.</span><br>7 au 11 mars 2022 et du 21 mars au 18 avril 2022</p>
                                  </div>
                                  <div class="cell-65">
                                    <p class="regular-text"><span>95 $ par billet.</span><br>6/7. Non valable les samedis</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text soft">Redevance et taxes en sus.<br>Ces tarifs, dates, redevance et taxes sont modifiables sans préavis.</p> 
       `,
       'flash-mars-ski-data-usa': `
                              <h2 class="medium-title"><span>VENTE ÉCLAIR</span><br>Billets de ski à 95 $<sup>CAD</sup><br><span>Quantité limitée</span></h2>
                              <p class="regular-text soft">Obtenez des billets de ski à un tarif avantageux pour profiter des pistes d'ici la fin de la saison. Les billets sont valables pour les périodes suivantes :</p>
                              <ul>
                                <li class="regular-text soft">Du 7 au 11 mars 2022 – excluant les samedis</li>
                                <li class="regular-text soft">Du 21 mars au 18 avril 2022– excluant les samedis</li>
                              </ul>
                              <p class="regular-text bold">Tarification adultes (18 ans et plus)</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-35">
                                    <p class="regular-text"><span>Date spécifique.</span><br>7 au 11 mars 2022 et du 21 mars au 18 avril 2022</p>
                                  </div>
                                  <div class="cell-65">
                                    <p class="regular-text"><span>95 $ CAD par billet.</span><br>6/7. Non valable les samedis</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text soft">Redevance et taxes en sus.<br>Ces tarifs, dates, redevance et taxes sont modifiables sans préavis.</p> 
       `,
      'flash-mars-meta-title': 'VENTE ÉCLAIR Économisez sur l’hébergement et sur les billets de ski',
      'flash-mars-meta-desc': 'Profitez de cette offre tout au long de la saison, que ce soit pour Pâques, ou au printemps!',
      'flash-mars-link': 'https://reservations.tremblant.ca/ecomm/shop/merchandise/2624850/fr-CA/?productsupercategoryid=23',
      'flash-mars-video-link': 'https://www.youtube.com/watch?v=hpqgrbxKb2Q',

      'printemps-heb-hero': "Jusqu'à<br><span>20&hairsp;%</span><br>Sur l’hébergement",
      'printemps-ski-hero': "Jusqu'à<br><span>25&hairsp;%</span><br>sur les billets de ski",
      'printemps-cut': "Offre valide jusqu'au 29 avril",
      'printemps-best-1': 'Un printemps 100% éclaté !',
      'printemps-best-2': '<span class="small-title">les billets de ski pour Tremblant seront en vente seulement via nos plateformes.</span>',
      'printemps-heb-data': `
                              <h2 class="medium-title"><span>OFFRE VIVEMENT LE PRINTEMPS</span><br>Jusqu'à 20 % sur l'hébergement</h2>
                              <p class="regular-text soft">Économisez ce printemps lorsque vous réservez un séjour de 2 nuits ou plus ! Profitez de cette offre tout au long de la saison, même pour Pâques ! Jusqu’au 29 avril 2022, réservez un séjour de deux nuits et plus et obtenez :</p>
                              <p class="regular-text bold">Pour des séjours jusqu’au 19 mars 2022:</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-50">
                                    <p class="regular-text"><span>20% de rabais</span> sur un séjour de 3 nuits et plus</p>
                                  </div>
                                  <div class="cell-50">
                                    <p class="regular-text"><span>15% de rabais</span> sur un séjour de 2 nuits</p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text bold">Pour des séjours du 20 mars au 30 avril 2022:</p>
                              <div class="table">
                                <div class="row">
                                  <div class="cell-100">
                                    <p class="regular-text"><span>20% de rabais</span><br>sur un séjour de 2 nuits et plus </p>
                                  </div>
                                </div>
                              </div>
                              <p class="regular-text soft">Sauf le samedi où le rabais est de 15%</p>
      `,
      'printemps-ski-data': `
                              <h2 class="medium-title"><span>Entrepôt de billets Tremblant</span><br>Jusqu’à 25% de rabais sur les billets de ski !</h2>
                              <p class="regular-text bold">Bienvenue dans l’entrepôt de billets Tremblant!</p>
                              <p class="regular-text soft">Vous y trouverez des tarifs variables pour skier à Tremblant ce printemps, en fonction de la date et du nombre de jours que vous choisirez. C’est votre chance d’économiser jusqu’à 25 % sur vos billets, afin de profiter pleinement des dernières semaines de ski!</p>
                              <ul>
                                <li class="regular-text soft">Économisez jusqu’à 25 % à l’achat de 1 à 5 journées de glisse.</li>
                                <li class="regular-text soft">Tarifs variables en fonction des dates que vous choisissez.</li>
                                <li class="regular-text soft">Quantité journalières limitées.</li>
                              </ul>
       `,
      'printemps-meta-title': 'Jusqu\'à 20 % sur l\'hébergement et 25% de rabais sur les billets de ski',
      'printemps-meta-desc': 'Économisez ce printemps lorsque vous réservez un séjour de 2 nuits ou plus !',
      'printemps-link': 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/fr-CA/?productcategoryid=117&_gl=1*15lg9y*_ga*ODU4MDI0MzAzLjE1ODUwNjQyNDc.*_ga_B1YVPN56M6*MTY0NjA2NjY4NS4xNi4xLjE2NDYwNjg1NTUuMA..&_ga=2.145750646.337673118.1646055410-858024303.1585064247',
      'printemps-video-link': 'https://www.youtube.com/watch?v=yBQza5ulYJA',

      'renouvellement-heb-hero': "Renouvellement<br><span>559 $</span>",
      'renouvellement-ski-hero': "Nouveau détendeur<br><span>599 $</span>",
      'renouvellement-hero-title': "Passe Tonik 2022-2023<br><span>Adulte (18-69)</span>",
      'renouvellement-heb-hero-fam': "Renouvellement<br><span>105 $</span>",
      'renouvellement-ski-hero-fam': "Nouveau détendeur<br><span>115 $</span>",
      'renouvellement-hero-title-fam': "Passe Tonik 2022-2023<br><span>Enfant (5-12)</span>",
      'renouvellement-cut': "Les tarifs augmentent le 22 avril",
      'renouvellement-best-1': 'En vente au meilleur tarif de l’année!',
      'renouvellement-best-2': '<span class="small-title">Ski tickets for Tremblant will be on sale only through our platforms.</span>',
      'renouvellement-dink-data': `
                              <h2 class="medium-title">Passe Tonik 2022-2023<br>Renouvellement 559 $<br>Nouveau détendeur 599 $</h2>
                              <p class="regular-text">Les tarifs augmentent le 22 avril</p>
                              <p class="regular-text soft">Procurez-vous la passe Tonik 2022-2023 dès maintenant au meilleur tarif de l’année et donnez du tonus à votre hiver en profitant des pentes presque toute la saison <a href="https://medias.tremblant.ca/pdf/ski/calendrier-passe-tonik-2022-23.pdf" target="_blank">(Calendrier)</a>. La passe Tonik, c’est :</p>
                              <ul>
                                <li class="regular-text soft">Jusqu'à 111 jours de glisse et 3 flottants pour utilisation en tout temps, même durant la période des fêtes! (à l’exception des 29 et 30 décembres 2022 18 et 19 février 2023)</li>
                                <li class="regular-text soft">Jusqu’à 25 journées de fin de semaine.</li>
                                <li class="regular-text soft">Des journées durant les relâches scolaires</li>
                                <li class="regular-text soft">15% de rabais sur toutes les journées non incluses (rabais applicable sur le billet journalier au tarif de la billetterie)</li>
                                <li class="regular-text soft">Forfait de privilèges pour détenteurs pour seulement 139 $ donnant accès aux Premières traces et à d’autres offres exclusives aux détenteurs !</li>
                                <li class="regular-text soft">Passe de randonnée alpine incluse.</li>
                                <li class="regular-text soft">La passe Tonik est offerte en exclusivité aux résidents canadiens</li>
                                <li class="regular-text soft">Tarif de renouvellement offert exclusivement aux détenteurs de passe Tonik 2021-22.</li>
                              </ul>
                              <p class="regular-text">Renouvellement</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>559 $<br><span>Adulte (18-69)</span></p>
                                </div>
                                <div class="price">
                                  <p>355 $<br><span>Étudiant (18-25)</span></p>
                                </div>
                                <div class="price">
                                  <p>439 $<br><span>Aîné (70+)</span></p>
                                </div>
                              </div>
                              <p class="regular-text">Nouveau détendeur</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>599 $<br><span>Adulte (18-69)</span></p>
                                </div>
                                <div class="price">
                                  <p>385 $<br><span>Étudiant (18-25)</span></p>
                                </div>
                                <div class="price">
                                  <p>479 $<br><span>Aîné (70+)</span></p>
                                </div>
                              </div> 
      `,
      'renouvellement-fam-data': `
                              <h2 class="medium-title">Passe Tonik 2022-2023<br>Renouvellement 105 $<br>Nouveau détendeur 115 $</h2>
                              <p class="regular-text">Les tarifs augmentent le 22 avril</p>
                              <p class="regular-text soft">Procurez-vous la passe Tonik 2022-2023 dès maintenant au meilleur tarif de l’année et donnez du tonus à votre hiver en profitant des pentes presque toute la saison <a href="https://medias.tremblant.ca/pdf/ski/calendrier-passe-tonik-2022-23.pdf" target="_blank">(Calendrier)</a>. La passe Tonik, c’est :</p>
                              <ul>
                                <li class="regular-text soft">Jusqu'à 111 jours de glisse et 3 flottants pour utilisation en tout temps, même durant la période des fêtes! (à l’exception des 29 et 30 décembres 2022 18 et 19 février 2023)</li>
                                <li class="regular-text soft">Jusqu’à 25 journées de fin de semaine.</li>
                                <li class="regular-text soft">Des journées durant les relâches scolaires</li>
                                <li class="regular-text soft">15% de rabais sur toutes les journées non incluses (rabais applicable sur le billet journalier au tarif de la billetterie)</li>
                                <li class="regular-text soft">Forfait de privilèges pour détenteurs pour seulement 139 $ donnant accès aux Premières traces et à d’autres offres exclusives aux détenteurs !</li>
                                <li class="regular-text soft">Passe de randonnée alpine incluse.</li>
                                <li class="regular-text soft">La passe Tonik est offerte en exclusivité aux résidents canadiens</li>
                                <li class="regular-text soft">Tarif de renouvellement offert exclusivement aux détenteurs de passe Tonik 2021-22.</li>
                              </ul>
                              <p class="regular-text">Renouvellement</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>105 $<br><span>Enfant (5-12)</span></p>
                                </div>
                                <div class="price">
                                  <p>355 $<br><span>Jeune (13-17)</span></p>
                                </div>
                                <div class="price">
                                  <p>45 $<br><span>Peewee (0-4)</span></p>
                                </div>
                              </div>
                              <p class="regular-text">Nouveau détendeur</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>115 $<br><span>Enfant (5-12)</span></p>
                                </div>
                                <div class="price">
                                  <p>385 $<br><span>Jeune (13-17)</span></p>
                                </div>
                                <div class="price">
                                  <p>45 $<br><span>Peewee (0-4)</span></p>
                                </div>
                              </div> 
      `,
      'renouvellement-meta-title': 'Passe Tonik 2022-2023 - Renouvellement 559 $ - Nouveau détendeur 599 $',
      'renouvellement-meta-title-fam': 'Passe Tonik 2022-2023 - Renouvellement 105 $ - Nouveau détendeur 115 $',
      'renouvellement-meta-desc': 'Procurez-vous la passe Tonik 2022-2023 dès maintenant au meilleur tarif de l’année.',
      'privileges-meta-title': 'Forfait de privilèges',
      'privileges-meta-desc': 'Bonifiez votre expérience Tremblant avec un ensemble de produits vedettes exclusif aux détenteurs de passes de saison 2022-23.',
      'renouvellement-link': 'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=42&ProductCategoryCode=276&ClientId=802&CultureName=fr-CA',
      'renouvellement-link-2': 'https://ecom.tremblant.ca/eStore/Login.aspx?CultureName=fr-CA&ReturnUrl=%2feStore%2fContent%2fAuthenticated%2fProductReload%2fDisplayReloadProducts.aspx%3fmode%3dpass&mode=pass',
      'renouvellement-video-link-dink': 'https://www.youtube.com/watch?v=i7NWyHF0MkA',
      'renouvellement-video-link-famille': 'https://www.youtube.com/watch?v=8ZMxnXJ6yfE',

      'al-video-title': 'Profitez de la saison de ski à Tremblant',
      'al-video-link': 'https://www.youtube.com/watch?v=-xLQ6MgtRG0',
    },
    privileges: [
      {
        title: 'Premières traces',
        image: '/vue-app-static-img/privilege-premiere-trace-new.jpg',
        details: `<h3 class="small-title">Premières traces</h3>
                      <p class="regular-text">Skiez dès 7 h 45 du 17 décembre 2022 au 19 mars 2023, lors des journées de validité de votre passe.</p>
                     `,
      },
      {
        title: '20 cafés ou chocolats chauds',
        image: '/vue-app-static-img/privilege-boissons-chaudes.jpg',
        details: `<h3 class="small-title">20 cafés ou chocolats chauds</h3>
                      <p class="regular-text">Ajout de 20 cafés ou chocolats chauds sur votre passe de saison. Valable au Grand Manitou, Chalet des voyageurs, Fourchette du Diable, au Café Johannsen et au Nomade. Aucune limite d’achat par visite</p>
                      <p class="regular-text">Valables du 24 novembre 2022 au 16 avril 2023.</p>
                     `,
      },
      {
        title: '15 % de rabais dans les cafétérias Tremblant',
        image: '/vue-app-static-img/privilege-rabais-cafeteria-new.jpg',
        details: `<h3 class="small-title">15 % de rabais dans les cafétérias Tremblant</h3>
                      <p class="regular-text">Applicable sur tous les produits à prix courant dans les cafétérias Tremblant suivantes : Le Grand Manitou, Chalet des voyageurs, La Fourchette du Diable, Café Johannsen et au Nomade. Non transférable.</p>
                      <p class="regular-text">Valables du 24 novembre 2022 au 16 avril 2023.</p>
                     `,
      },
      {
        title: '15 % de rabais dans les boutiques Tremblant',
        image: '/vue-app-static-img/privilege-rabais-boutiques-new.jpg',
        details: `<h3 class="small-title">15 % de rabais dans les boutiques Tremblant</h3>
                      <p class="regular-text">Applicable sur marchandise à prix courant dans les boutiques Tremblant participantes (à l’exception des produits Ugg, Canada Goose, Arc’téryx, Blundstone et tuques 24 h de Tremblant) : Nor Tremblant, Boutique Sommet des Neiges, Burton Tremblant, Chamonix, Columbia Tremblant, Explore!, Alpine, Le Skieur, Magasin de la Place, Le Manitou, Salomon Tremblant, Tremblant & CO. Non transférable.</p>
                      <p class="regular-text">Valable  du 1er septembre 2022  à la fin de la saison 2023.</p>
                     `,
      },
      {
        title: '200 $ dans les boutiques Tremblant',
        image: '/vue-app-static-img/privilege-carte-cadeau-new.jpg',
        details: `<h3 class="small-title">200 $ dans les boutiques Tremblant</h3>
                      <p class="regular-text">4 certificats de 50 $ chacun. Chaque certificat est applicable par tranche de 300 $, calculé après l’escompte de 15 % offert aux détenteurs et avant les taxes. Applicable sur tous les articles, même les articles en solde, sauf les uniformes et produits du Club de Ski Mont-Tremblant et du Club Élite Tremblant. Les certificats doivent être utilisés avant le 31 janvier 2023 dans les boutiques participantes : Nor Tremblant, Boutique Sommet des Neiges, Burton Tremblant, Chamonix, Columbia Tremblant, Explore!, Alpine, Le Skieur, Magasin de la Place, Le Manitou, Salomon Tremblant, Tremblant & CO. Non transférable.</p>
                      <p class="regular-text">Valable  du 1er septembre 2022  au 31 janvier 2023.</p>
                     `,
      },
      {
        title: 'Une mise au point gratuite au ski shop',
        image: '/vue-app-static-img/privilege-mise-au-point.jpg',
        details: `<h3 class="small-title">Une mise au point gratuite au ski shop</h3>
                      <p class="regular-text">Ajout d’une mise au point pour le lendemain sur votre passe de saison à la boutique Le Ski Shop du Chalet des voyageurs. Non transférable.</p>
                      <p class="regular-text">Valable de l’ouverture du Ski Shop au 16 avril 2023.</p>
                     `,
      },
    ],
    activityDink: [
      // {
      //   title: '24h Tremblant',
      //   image: '/vue-app-static-img/events-activity/dink/24h.jpg',
      //   details: `<h3 class="small-title">24h Tremblant</h3>
      //                 <p class="regular-text">Le 24h Tremblant, c’est LE défi sportif hivernal au Québec! Des équipes de 6 à 12 participants se relaient jour et nuit pendant 24 heures en ski, à la marche ou à la course, pendant le week-end du 10 au 12 décembre 2021. En amont de l'événement, les participants effectuent une levée de fonds d'envergure où tous les profits sont remis à trois grandes fondations vouées à la cause des enfants. En 2020, c'est plus de 2,4 millions de dollars qui ont été amassés!</p>`,
      //   date: '10 AU 12 DÉC.',
      // },
      // {
      //   title: 'Célébrations du temps des fêtes',
      //   image: '/vue-app-static-img/events-activity/dink/fetes.jpg',
      //   details: `<h3 class="small-title">Créez des souvenirs inoubliables avec toute la famille</h3>
      //                 <p class="regular-text bold">Du 22 décembre 2021 au 4 janvier 2022<br>13h à 16h30</p>
      //                 <p class="regular-text">Le village de Tremblant se métamorphose pour les fêtes. Laissez-vous transporter dans l’univers de nos chansonniers et conteurs avant de vous installer pour une expérience de cinéma en plein air. Arrêtez-vous au son du traîneau du père Noël qui défile au village et retrouvez votre cœur d’enfant avec des jeux de ballons et toutes sortes d’autres activités pour les petits et les grands.</p>
      //                 <p class="regular-text">Port du masque recommandé.</p>
      //                 <p class="regular-text">Nombre de places limitées. Premier arrivé, premier servi. </p>
      //                 <p class="regular-text">Sujet à changement sans préavis.</p>`,
      //   date: '22 Déc. au 4 JAN.',
      // },
      // {
      //   title: 'Célébrations du Nouvel An',
      //   image: '/vue-app-static-img/events-activity/dink/jour-an.jpg',
      //   details: `<h3 class="small-title">Célébrations du Nouvel An</h3>
      //             <p class="regular-text bold">31 décembre</p>
      //             <p class="regular-text">La tradition se poursuit! Revêtez vos plus beaux vêtements et accessoires et lancez l’année 2022 en grand! Rendez-vous à la base de la montagne, transformée pour l’occasion en discothèque à ciel ouvert! Venez danser en plein air avec DJ Shortcut jusqu’aux 12 coups de minuit et admirez les grandioses feux d’artifice qui marqueront l’arrivée de la nouvelle année! <br><br>
      //             Une preuve vaccinale accompagnée d'une pièce d'identité avec photo est obligatoire pour l'accès au site de l'événement. L'espace de l'évenement dispose d'une capacité limitée, assurez-vous d'arriver tôt!</p>`,
      //   date: '31 Déc.',
      // },
      // {
      //   title: 'Coupe du monde de bosses Mackenzie',
      //   image: '/vue-app-static-img/events-activity/mackenzie.jpg',
      //   details: `<h3 class="small-title">Coupe de monde de bosses Mackenzie présentée par Toyota</h3>
      //             <p class="regular-text bold">5 au 8 janvier</p>
      //             <p class="regular-text">Les meilleurs athlètes de bosses au monde seront à Tremblant et donneront tout pour remporter les honneurs de cette prestigieuse Coupe du monde. Voyez-les en personne sur le parcours (à pieds ou en ski) ou sur écran géant au cœur du village piétonnier.</p>`,
      //   date: '5 au 8 Jan.',
      // },
      // {
      //   title: 'Festival rando alpine tremblant',
      //   image: '/vue-app-static-img/events-activity/rando.jpg',
      //   details: `<h3 class="small-title">Festival rando alpine tremblant, présenté par smartwool</h3>
      //             <p class="regular-text bold">18 au 20 Février</p>
      //             <p class="regular-text">Les amateurs de plein air se donnent rendez-vous au Festival Rando Alpine Tremblant, présenté par Smartwool, pour vivre une foule d’activités. Deux jours de découvertes, de festivités et de défis. Au programme, différentes ascensions guidées sur la montagne pour tous les goûts et les niveaux!</p>`,
      //   date: '18 Fév. au 20 Fév.',
      // },
      // {
      //   title: 'La classique des légendes',
      //   image: '/vue-app-static-img/events-activity/classique-legendes.jpg',
      //   details: `<h3 class="small-title">La classique des légendes</h3>
      //             <p class="regular-text bold">26 Mars</p>
      //             <p class="regular-text">Prêt au départ? La Classique des Légendes annuelle aura lieu à Tremblant le samedi 26 mars 2022. La Classique est un slalom géant ouvert aux skieurs de tous niveaux et de tous âges, autant juniors (7 à 17 ans), qu’adultes (18 à 59 ans) ou légendes (60 à 85 ans et +)… Venez-vous amuser. Bienvenue à tous!</p>`,
      //   date: '26 Mar.',
      // },
      // {
      //   title: 'Escalade de glace',
      //   image: '/vue-app-static-img/events-activity/escalade-glace.jpg',
      //   details: `<h3 class="small-title">Escalade de glace</h3>
      //                 <p class="regular-text">Relevez le défi! Une initiation à l'escalade sur une cascade de glace situé sur le site même de Tremblant. L'instructeur vous enseigne l'assurage, la descente en rappel et les principes de base de l'escalade. Cette activité s'adresse aux débutants et ne nécessite aucune expérience d'escalade.</p>`,
      // },
      // {
      //   title: 'Glissades sur tubes',
      //   image: '/vue-app-static-img/events-activity/glissade-tube.jpg',
      //   details: `<h3 class="small-title">Glissades sur tubes</h3>
      //                 <p class="regular-text">Une activité familiale idéale! Dévalez la pente sur un tube gonflable, puis prenez la remontée mécanique jusqu'au sommet. Huit descentes de niveau débutant à expert. Accès de 2 heures ou 4 heures. Cafétéria sur place pour nourriture ($) et se réchauffer.</p>`,
      // },
      // {
      //   title: 'Jardins de glace',
      //   image: '/vue-app-static-img/events-activity/jardin-glace.jpg',
      //   details: `<h3 class="small-title">Jardins de glace</h3>
      //                 <p class="regular-text">Cet hiver, on se donne rendez-vous aux Jardins de glace sur le terrain de la chapelle St-Bernard pour dévaler les deux glissades glacées d’une hauteur de plus de 2,5 mètres et admirer les sculptures de glace. En famille, en couple ou entre amis, toutes les raisons sont bonnes pour jouer dehors!</p>`,
      // },
      // {
      //   title: 'Patinage',
      //   image: '/vue-app-static-img/events-activity/patin.jpg',
      //   details: `<h3 class="small-title">Patinage</h3>
      //                 <p class="regular-text">La patinoire située sur le terrain de la chapelle Saint-Bernard vous accueille pour une sortie de patin mémorable. Musique, feu de joie et ambiance féérique seront au rendez-vous. Apportez vos patins et profitez de la patinoire de jour comme de soir.</p>`,
      // },
      {
        title: 'Randonnée alpine',
        image: '/vue-app-static-img/events-activity/dink/rando-alpine.jpg',
        details: `<h3 class="small-title">Randonnée alpine</h3>
                      <p class="regular-text">Venez faire l’ascension de la plus haute montagne des Laurentides, skis aux pieds, dans l’une de nos zones désignées ou pistes boisées et redescendez dans l’une de nos 102 pistes de ski balisées!</p>`,
      },
      {
        title: 'Raquette',
        image: '/vue-app-static-img/events-activity/raquette.jpg',
        details: `<h3 class="small-title">Raquette</h3>
                      <p class="regular-text">Notre réseau de sentiers de raquette serpente le mont Tremblant au milieu de ruisseaux et chutes d’eau. Découvrez la plus haute cime des Laurentides sur 7 sentiers de randonnée de 1 km à 5 km, de tous les niveaux! Notez qu'un accès journalier ou la passe de randonnée alpine est obligatoire pour accéder à nos accès désignés.</p>`,
      },
      {
        title: 'Restaurants',
        image: '/vue-app-static-img/events-activity/restaurants-new.jpg',
        details: `<h3 class="small-title">Restaurants</h3>
                      <p class="regular-text">D’un bout à l’autre du village piétonnier de Tremblant, les saveurs n’attendent que vos papilles. Peu importe votre envie, ou celle de votre famille ou de vos amis, vous trouverez le restaurant qui convient.</p>`,
      },
      {
        title: 'Ski de fond',
        image: '/vue-app-static-img/events-activity/ski-fond.jpg',
        details: `<h3 class="small-title">Ski de fond</h3>
                      <p class="regular-text">Que vous soyez débutant en ski de fond ou plus expérimenté, découvrez la nature à votre rythme sur un réseau de sentiers de près de 120 km autour de Tremblant!</p>`,
      },
      {
        title: 'Spas et massages',
        image: '/vue-app-static-img/events-activity/dink/spa.jpg',
        position: 'bottom',
        details: `<h3 class="small-title">Spas et massages</h3>
                      <p class="regular-text">Refuge hors du temps, promesse de détente absolue! Gâtez-vous grâce un massage ou un soin esthétique dans l'un des deux centres de spa situés au cœur du village piétonnier.</p>`,
      },
      {
        title: 'Souper au refuge',
        image: '/vue-app-static-img/events-activity/souper-refuge.jpg',
        details: `<h3 class="small-title">Souper au refuge</h3>
                      <p class="regular-text">Au départ du sommet, profitez d’une randonnée guidée en raquettes pour atteindre le refuge du Versant Soleil où vous attend un copieux repas. Après vous être régalé d’une fondue près de l’âtre du chalet de bois rond situé au cœur de la forêt, terminez l’aventure en descendant la montagne en bonne compagnie et éclairé de votre lampe frontale ou de la pleine lune!</p>`,
      },
      {
        title: 'Activités intérieures',
        image: '/vue-app-static-img/events-activity/famille/studio-creatif-2.jpg',
        imageSmt: '/vue-app-static-img/events-activity/casino.jpg',
        details: `<h3 class="small-title">Activités intérieures</h3>
                      <p class="regular-text">Sur un terrain de jeu grandeur nature, Tremblant offre une panoplie d'activités pour tous les goûts.</p>`,
      },
      {
        title: 'Traîneau à chiens',
        image: '/vue-app-static-img/events-activity/chiens.jpg',
        details: `<h3 class="small-title">Traîneau à chiens</h3>
                      <p class="regular-text">Gâtez toute la famille! Choisissez parmi nos différentes excursions sur des sentiers en forêt, en montagne ou dans la vallée.</p>`,
      },
      {
        title: 'Vélo',
        image: '/vue-app-static-img/events-activity/dink/fat-bike.jpg',
        details: `<h3 class="small-title">Vélo</h3>
                      <p class="regular-text">Été comme hiver, la région de Mont-Tremblant regorge de paysages à couper le souffle et offre de nombreuses possibilités pour une sortie à vélo. Que ce soit sur les pistes cyclables ou dans les sentiers, il y en a pour tous les goûts... et tous les niveaux!</p>`,
      },
      {
        title: 'Relâche scolaire de l’Ontario',
        image: '/vue-app-static-img/events-activity/relache-ont.jpg',
        details: `<h3 class="small-title">Relâche scolaire de l’Ontario</h3>
                  <p class="regular-text bold">12 au 20 mars</p>
                  <p class="regular-text">Vive les vacances à Tremblant! Venez profiter de la relâche scolaire à Tremblant avec une panoplie d’activités pour tous! Jeux interactifs, animation quotidienne par nos amuseurs et ambiance musicale les samedis soirs.</p>`,
        date: '12 au 20 mars',
      },
      {
        title: 'Ambiance musicale',
        image: '/vue-app-static-img/events-activity/musique.jpg',
        details: `<h3 class="small-title">Ambiance musicale du printemps 2022</h3>
                  <p class="regular-text bold">Du 19 mars au 16 avril</p>
                  <p class="regular-text">C'est le moment de venir profiter des beaux après-midis printaniers à la place St-Bernard. Une ambiance musicale vous y attend tous les samedis et dimanche du 19 mars au 16 avril. Samedi et dimanche de 13h00 à 19h00</p>
                  <p class="regular-text bold">DJ sur place à la Place St-Bernard</p>
                  <p class="regular-text">*Horaire et contenu sujets à changement sans préavis, port du couvre visage recommandé*</p>`,
        date: '19 mars au 16 avril',
      },
      {
        title: 'Jamigos',
        image: '/vue-app-static-img/events-activity/jamigos.jpg',
        details: `<h3 class="small-title">Jamigos</h3>
                  <p class="regular-text bold">2 et 3 Avril</p>
                  <p class="regular-text">Jam, DJ, animation et plusieurs prix seront de la partie</p>
                  <p class="regular-text">Venez célébrer le ski de printemps avec vos amis lors du week-end JAMIGOS ! Cet événement unique vous permettra d'essayer notre parc à neige remodelé spécialement pour l’occasion. Surpassez-vous avec des tricks inédits et courez la chance de remporter l’un des nombreux prix! Il y aura une compétition jugée en ski et en snowboard! Jam, DJ, animation et plusieurs prix seront de la partie pour deux jours de plaisir.</p>`,
        date: '2 et 3 Avril',
      },
      {
        title: 'Coupe Caribou',
        image: '/vue-app-static-img/events-activity/caribou.jpg',
        details: `<h3 class="small-title">Coupe Caribou</h3>
                  <p class="regular-text bold">9 avril 2022</p>
                  <p class="regular-text">Le 9 avril prochain, c'est le grand retour de la Coupe Caribou. Relevez le défi de traverser le bassin d'eau au pied de la piste enneigée avec vos skis ou votre planche à neige. Vêtu de votre plus beau costume (c’est obligatoire!), offrez aux spectateurs l’une des prestations les plus cocasses de la journée pour gagner des prix de présence. Tentez la traversée du bassin en course à pied sur des matelas flottants et profitez d’un spectacle musical à la mi-temps. Les festivités se poursuivront au P’tit Caribou, une fois la course terminée. Un événement rempli de rires, de surprises et de plaisir pour tous!</p>
                  <div class="table">
                    <div class="row">
                      <div class="cell">
                        <p class="regular-text"><span>9h-10h30</span><br>Base montagne</p>
                      </div>
                      <div class="cell">
                        <p class="regular-text">Incription (125 pers. Max) 75$</p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="cell">
                        <p class="regular-text"><span>13h</span><br>Base montagne</p>
                      </div>
                      <div class="cell">
                        <p class="regular-text">Début de la coupe</p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="cell">
                        <p class="regular-text"><span>13h-13h45</span><br>Base montagne</p>
                      </div>
                      <div class="cell">
                        <p class="regular-text">Inscription pour la traversée de la piscine à course sur matelas flottant - Gratuit</p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="cell">
                        <p class="regular-text"><span>14-14h30</span><br>Base montagne</p>
                      </div>
                      <div class="cell">
                        <p class="regular-text">Spectacle de la mi-temps : live Band et traversée de la piscine à la course sur des matelas flottants</p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="cell">
                        <p class="regular-text"><span>14h30-15h30</span><br>Base montagne</p>
                      </div>
                      <div class="cell">
                        <p class="regular-text">Suite de la Coupe Caribou</p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="cell">
                        <p class="regular-text"><span>16h30</span><br>P’tit Caribou</p>
                      </div>
                      <div class="cell">
                        <p class="regular-text">Remise des prix , groupe de musique et méchoui</p>
                      </div>
                    </div>

                  </div>`,
        date: '9 avril 2022',
      },
      {
        title: 'Burton el nino / el rigleto',
        image: '/vue-app-static-img/events-activity/burton.jpg',
        details: `<h3 class="small-title">Burton el nino / el rigleto</h3>
                  <p class="regular-text bold">10 Avril</p>
                  <p class="regular-text">Les jeunes de 12 ans et moins souhaitant améliorer leurs habiletés de freestyle en planche à neige sont invités à rencontrer des entraîneurs de l’équipe Burton Québec et Tremblant pour tirer profit de leurs précieux conseils. Les tout-petits peuvent également prendre part à un événement formateur et stimulant avec leurs parents. El Rigleto, présenté par Burton Tremblant, vise à transmettre la passion de la planche à neige aux enfants tout en accompagnant les parents dans l’apprentissage de ce nouveau sport à leurs tout-petits.</p>`,
        date: '10 Avr.',
      },
      {
        title: 'Célébrations de Pâques',
        image: '/vue-app-static-img/events-activity/paques.jpg',
        details: `<h3 class="small-title">Célébrations de Pâques</h3>
                  <p class="regular-text bold">17 Avril</p>
                  <p class="regular-text">À Tremblant, on célèbre Pâques en grand! Prenez part à l’une des nombreuses activités offertes dans le village piétonnier pour l’occasion : jeux interactifs, maquillage pour enfants et distribution de chocolat, en plus d’une visite des lapins de Pâques!<br><br>De 10 h à 14 h</p><br>
                  <p class="regular-text bold">Maquillage</p>
                  <p class="regular-text">Place des Voyageurs - Deux maquilleuses professionnelles seront sur place pour le plus grand plaisir des enfants. Le maquillage sera exclusivement sur les mains afin de respecter les mesures sanitaires.<br><br>De 10 h à 15 h</p><br>
                  <p class="regular-text bold">Distribution de cocos en chocolat</p>
                  <p class="regular-text">Village piétonnier - Des lapins de Pâques se promèneront et distribueront des cocos en chocolat dans le village piétonnier. (Jusqu’à épuisement des cocos)<br><br>De 10 h à 16 h</p><br>
                  <p class="regular-text bold">Jeux interactifs</p>
                  <p class="regular-text">Place Saint-Bernard - Venez jouer dehors! Plusieurs jeux vous attendent sur la place Saint-Bernard. Hockey-bottines, soccer sur neige, foot-basketball, football/quilles et drapeau.</p><br>
                  <p class="regular-text bold">Ambiance musicale</p>
                  <p class="regular-text">Passez un agréable moment au son d’une musique rythmée d’ambiance pour toute la famille. C’est un rendez-vous!<br><br>De 10 h à 15 h</p><br>
                  <p class="regular-text bold">Rencontrez Toufou</p>
                  <p class="regular-text">Venez rencontrer notre mascotte Toufou dans le village piétonnier.<br><br>De 10 h à 15 h</p><br>
                  <p class="regular-text bold">Sculpture de ballons avec Marc-Ambulles</p>
                  <p class="regular-text">Village piétonnier - Marc-Ambulles, un amuseur public aux talents multiples, déambulera dans le village piétonnier en offrant des sculptures de ballons aux formes originales pour Pâques.<br><br>*Horaires et contenu de la programmation sujets à changement sans préavis. Le port du couvre-visage est recommandé.<br><br>De 10 h à 15 h</p>`,
        date: '17 Avril',
      },
      // {
      //   title: 'Relâche scolaire américaine',
      //   image: '/vue-app-static-img/events-activity/relache-usa.jpg',
      //   details: `<h3 class="small-title">Relâche scolaire américaine</h3>
      //             <p class="regular-text bold">19 au 27 février</p>
      //             <p class="regular-text">Une multitude d’activités et d’animation organisées pour la relâche scolaire américaine! Jeux interactifs, animation quotidienne par nos amuseurs et ambiance musicale les samedis soirs.</p>`,
      //   date: '19 au 27 Fév.',
      // },
      // {
      //   title: 'Boutiques',
      //   image: '/vue-app-static-img/events-activity/dink/boutique-2.jpg',
      //   details: `<h3 class="small-title">Boutiques</h3>
      //                 <p class="regular-text">Sillonnez le pittoresque village au pied du mont Tremblant pour faire de belles découvertes. C’est simple, visitez chacune des boutiques et tous les commerces exclusifs pour de belles trouvailles! Que ce soit pour une sortie en plein-air ou une sortie branchée, vous trouverez.</p>`,
      // },
      // {
      //   title: 'Journée Super Démo',
      //   image: '/vue-app-static-img/events-activity/super-demo.jpg',
      //   details: `<h3 class="small-title">Journée Super Démo</h3>
      //             <p class="regular-text bold">12 Février</p>
      //             <p class="regular-text">Les journées Super Démo vous donnent l’occasion de rencontrer les plus grands noms de l’industrie du ski et de la planche à neige. Testez en primeur les modèles de l’année à venir et comparez avant d’acheter votre nouvel équipement. Les journées Super Démo sont jumelées à des promotions dans les boutiques de Tremblant.</p>`,
      //   date: '12 Fév.',
      // },
      // {
      //   title: 'Relâche scolaire du Québec',
      //   image: '/vue-app-static-img/events-activity/relache-qc.jpg',
      //   details: `<h3 class="small-title">Relâche scolaire du Québec</h3>
      //             <p class="regular-text bold">26 fév. au 6 mars</p>
      //             <p class="regular-text">Venez profiter de la relâche scolaire à Tremblant! Jeux interactifs, animation quotidienne par nos amuseurs et ambiance musicale les samedis soirs organisés pour le congé en famille.</p>`,
      //   date: '26 fév. au 6 mars',
      // },
      // {
      //   title: "Brind'O Aquaclub",
      //   image: '/vue-app-static-img/events-activity/dink/brindo.jpg',
      //   details: `<h3 class="small-title">Brind'O Aquaclub</h3>
      //                 <p class="regular-text">Venez vivre la réalité du monde de Brind’O Aquaclub au cœur du Centre de villégiature. Avec ses 4 glissades, ses 35 jeux d’eau interactifs, sa salle d’entraînement modernisée et son bain à remous, le nouveau centre familial de jeux aquatiques devient un incontournable, beau temps, mauvais temps.<br><br>Dans son univers coloré, tout peut paraître calme, mais tout y est formidablement grand : un brin d’herbe devient un arbre, un brin d’eau est transformé en mare et chaque pas se transforme en une découverte fascinante où joies d’enfants et sourires d’adultes s’entremêlent. Ce décor démesuré foisonne de mouvements avec d’énormes libellules et des fourmis qui captiveront les enfants pour créer un univers où tout est plus grand que nature. Brind’O Aquaclub, un rêve bien réel et un lieu où il fait bon s’amuser en tout temps!</p>`,
      // },
    ],
    activityFamille: [
      // {
      //   title: '24h Tremblant',
      //   image: '/vue-app-static-img/events-activity/famille/24h.jpg',
      //   details: `<h3 class="small-title">24h Tremblant</h3>
      //                 <p class="regular-text">Le 24h Tremblant, c’est LE défi sportif hivernal au Québec! Des équipes de 6 à 12 participants se relaient jour et nuit pendant 24 heures en ski, à la marche ou à la course, pendant le week-end du 10 au 12 décembre 2021. En amont de l'événement, les participants effectuent une levée de fonds d'envergure où tous les profits sont remis à trois grandes fondations vouées à la cause des enfants. En 2020, c'est plus de 2,4 millions de dollars qui ont été amassés!</p>`,
      //   date: '10 AU 12 DÉC.',
      // },
      // {
      //   title: 'Célébrations du temps des fêtes',
      //   image: '/vue-app-static-img/events-activity/dink/fetes.jpg',
      //   details: `<h3 class="small-title">Créez des souvenirs inoubliables avec toute la famille</h3>
      //                 <p class="regular-text bold">Du 22 décembre 2021 au 4 janvier 2022<br>13h à 16h30</p>
      //                 <p class="regular-text">Le village de Tremblant se métamorphose pour les fêtes. Laissez-vous transporter dans l’univers de nos chansonniers et conteurs avant de vous installer pour une expérience de cinéma en plein air. Arrêtez-vous au son du traîneau du père Noël qui défile au village et retrouvez votre cœur d’enfant avec des jeux de ballons et toutes sortes d’autres activités pour les petits et les grands.</p>
      //                 <p class="regular-text">Port du masque recommandé.</p>
      //                 <p class="regular-text">Nombre de places limitées. Premier arrivé, premier servi. </p>
      //                 <p class="regular-text">Sujet à changement sans préavis.</p>`,
      //   date: '22 Déc. au 4 JAN.',
      // },
      // {
      //   title: 'Célébrations du Nouvel An',
      //   image: '/vue-app-static-img/events-activity/famille/jour-an.jpg',
      //   details: `<h3 class="small-title">Célébrations du Nouvel An</h3>
      //             <p class="regular-text bold">31 décembre</p>
      //             <p class="regular-text">La tradition se poursuit! Revêtez vos plus beaux vêtements et accessoires et lancez l’année 2022 en grand! Rendez-vous à la base de la montagne, transformée pour l’occasion en discothèque à ciel ouvert! Venez danser en plein air avec DJ Shortcut jusqu’aux 12 coups de minuit et admirez les grandioses feux d’artifice qui marqueront l’arrivée de la nouvelle année! <br><br>
      //             Une preuve vaccinale accompagnée d'une pièce d'identité avec photo est obligatoire pour l'accès au site de l'événement. L'espace de l'évenement dispose d'une capacité limitée, assurez-vous d'arriver tôt!</p>`,
      //   date: '31 Déc.',
      // },
      {
        title: 'Coupe du monde de bosses Mackenzie',
        image: '/vue-app-static-img/events-activity/mackenzie.jpg',
        details: `<h3 class="small-title">Coupe de monde de bosses Mackenzie présentée par Toyota</h3>
                  <p class="regular-text bold">5 au 8 janvier</p>
                  <p class="regular-text">Les meilleurs athlètes de bosses au monde seront à Tremblant et donneront tout pour remporter les honneurs de cette prestigieuse Coupe du monde. Voyez-les en personne sur le parcours (à pieds ou en ski) ou sur écran géant au cœur du village piétonnier.</p>`,
        date: '5 au 8 Jan.',
      },
      {
        title: 'Festival rando alpine tremblant',
        image: '/vue-app-static-img/events-activity/rando.jpg',
        details: `<h3 class="small-title">Festival rando alpine tremblant, présenté par smartwool</h3>
                  <p class="regular-text bold">18 au 20 Février</p>
                  <p class="regular-text">Les amateurs de plein air se donnent rendez-vous au Festival Rando Alpine Tremblant, présenté par Smartwool, pour vivre une foule d’activités. Deux jours de découvertes, de festivités et de défis. Au programme, différentes ascensions guidées sur la montagne pour tous les goûts et les niveaux!</p>`,
        date: '18 Fév. au 20 Fév.',
      },
      {
        title: 'La classique des légendes',
        image: '/vue-app-static-img/events-activity/classique-legendes.jpg',
        details: `<h3 class="small-title">La classique des légendes</h3>
                  <p class="regular-text bold">26 Mars</p>
                  <p class="regular-text">Prêt au départ? La Classique des Légendes annuelle aura lieu à Tremblant le samedi 26 mars 2022. La Classique est un slalom géant ouvert aux skieurs de tous niveaux et de tous âges, autant juniors (7 à 17 ans), qu’adultes (18 à 59 ans) ou légendes (60 à 85 ans et +)… Venez-vous amuser. Bienvenue à tous!</p>`,
        date: '26 Mar.',
      },
      // {
      //   title: 'Burton el nino / el rigleto',
      //   image: '/vue-app-static-img/events-activity/burton.jpg',
      //   details: `<h3 class="small-title">Burton el nino / el rigleto</h3>
      //             <p class="regular-text bold">10 Avril</p>
      //             <p class="regular-text">Les jeunes de 12 ans et moins souhaitant améliorer leurs habiletés de freestyle en planche à neige sont invités à rencontrer des entraîneurs de l’équipe Burton Québec et Tremblant pour tirer profit de leurs précieux conseils. Les tout-petits peuvent également prendre part à un événement formateur et stimulant avec leurs parents. El Rigleto, présenté par Burton Tremblant, vise à transmettre la passion de la planche à neige aux enfants tout en accompagnant les parents dans l’apprentissage de ce nouveau sport à leurs tout-petits.</p>`,
      //   date: '10 Avr.',
      // },
      // {
      //   title: "Brind'O Aquaclub",
      //   image: '/vue-app-static-img/events-activity/famille/brindo.jpg',
      //   details: `<h3 class="small-title">Brind'O Aquaclub</h3>
      //                 <p class="regular-text">Venez vivre la réalité du monde de Brind’O Aquaclub au cœur du Centre de villégiature. Avec ses 4 glissades, ses 35 jeux d’eau interactifs, sa salle d’entraînement modernisée et son bain à remous, le nouveau centre familial de jeux aquatiques devient un incontournable, beau temps, mauvais temps.<br><br>Dans son univers coloré, tout peut paraître calme, mais tout y est formidablement grand : un brin d’herbe devient un arbre, un brin d’eau est transformé en mare et chaque pas se transforme en une découverte fascinante où joies d’enfants et sourires d’adultes s’entremêlent. Ce décor démesuré foisonne de mouvements avec d’énormes libellules et des fourmis qui captiveront les enfants pour créer un univers où tout est plus grand que nature. Brind’O Aquaclub, un rêve bien réel et un lieu où il fait bon s’amuser en tout temps!</p>`,
      // },
      {
        title: 'Vélo',
        image: '/vue-app-static-img/events-activity/famille/fat-bike.jpg',
        details: `<h3 class="small-title">Vélo</h3>
                      <p class="regular-text">Été comme hiver, la région de Mont-Tremblant regorge de paysages à couper le souffle et offre de nombreuses possibilités pour une sortie à vélo. Que ce soit sur les pistes cyclables ou dans les sentiers, il y en a pour tous les goûts... et tous les niveaux!</p>`,
      },
      {
        title: 'Boutiques',
        image: '/vue-app-static-img/events-activity/famille/boutique.jpg',
        details: `<h3 class="small-title">Boutiques</h3>
                      <p class="regular-text">Sillonnez le pittoresque village au pied du mont Tremblant pour faire de belles découvertes. C’est simple, visitez chacune des boutiques et tous les commerces exclusifs pour de belles trouvailles! Que ce soit pour une sortie en plein-air ou une sortie branchée, vous trouverez.</p>`,
      },
      {
        title: 'Raquette',
        image: '/vue-app-static-img/events-activity/famille/rando-alpine.jpg',
        details: `<h3 class="small-title">Explorez la nature à votre rythme</h3>
                      <p class="regular-text">Notre réseau de sentiers de raquette serpente le mont Tremblant au milieu de ruisseaux et chutes d’eau. Découvrez la plus haute cime des Laurentides sur 7 sentiers de randonnée de 1 km à 5 km, de tous les niveaux! Notez qu'un accès journalier ou la passe de randonnée alpine est obligatoire pour accéder à nos accès désignés.</p>`,
      },
      {
        title: 'Patinage',
        image: '/vue-app-static-img/events-activity/famille/skating.jpg',
        details: `<h3 class="small-title">Patinage</h3>
                      <p class="regular-text">La patinoire située sur le terrain de la chapelle Saint-Bernard vous accueille pour une sortie de patin mémorable. Musique, feu de joie et ambiance féérique seront au rendez-vous. Apportez vos patins et profitez de la patinoire de jour comme de soir.</p>`,
      },
      {
        title: 'Restaurants',
        image: '/vue-app-static-img/events-activity/famille/restaurants.jpg',
        details: `<h3 class="small-title">Restaurants</h3>
                      <p class="regular-text">D’un bout à l’autre du village piétonnier de Tremblant, les saveurs n’attendent que vos papilles. Peu importe votre envie, ou celle de votre famille ou de vos amis, vous trouverez le restaurant qui convient.</p>`,
      },
      {
        title: 'Activités intérieures',
        image: '/vue-app-static-img/events-activity/famille/studio-creatif-2.jpg',
        imageSmt: '/vue-app-static-img/events-activity/casino.jpg',
        details: `<h3 class="small-title">Activités intérieures</h3>
                      <p class="regular-text">Sur un terrain de jeu grandeur nature, Tremblant offre une panoplie d'activités pour tous les goûts et toute la famille</p>`,
      },
    ],
    events: [
      {
        title: 'Relâche scolaire de l’Ontario',
        image: '/vue-app-static-img/events-activity/relache-ont.jpg',
        details: `<h3 class="small-title">Relâche scolaire de l’Ontario</h3>
                  <p class="regular-text bold">12 au 20 mars</p>
                  <p class="regular-text">Vive les vacances à Tremblant! Venez profiter de la relâche scolaire à Tremblant avec une panoplie d’activités pour tous! Jeux interactifs, animation quotidienne par nos amuseurs et ambiance musicale les samedis soirs.</p>`,
        date: '12 au 20 mars',
      },
      {
        title: 'Ambiance musicale',
        image: '/vue-app-static-img/events-activity/musique.jpg',
        details: `<h3 class="small-title">Ambiance musicale du printemps 2022</h3>
                  <p class="regular-text bold">Du 19 mars au 16 avril</p>
                  <p class="regular-text">C'est le moment de venir profiter des beaux après-midis printaniers à la place St-Bernard. Une ambiance musicale vous y attend tous les samedis et dimanche du 19 mars au 16 avril. Samedi et dimanche de 13h00 à 19h00</p>
                  <p class="regular-text bold">DJ sur place à la Place St-Bernard</p>
                  <p class="regular-text">*Horaire et contenu sujets à changement sans préavis, port du couvre visage recommandé*</p>`,
        date: '19 mars au 16 avril',
      },
      {
        title: 'Jamigos',
        image: '/vue-app-static-img/events-activity/jamigos.jpg',
        details: `<h3 class="small-title">Jamigos</h3>
                  <p class="regular-text bold">2 et 3 Avril</p>
                  <p class="regular-text">Jam, DJ, animation et plusieurs prix seront de la partie</p>
                  <p class="regular-text">Venez célébrer le ski de printemps avec vos amis lors du week-end JAMIGOS ! Cet événement unique vous permettra d'essayer notre parc à neige remodelé spécialement pour l’occasion. Surpassez-vous avec des tricks inédits et courez la chance de remporter l’un des nombreux prix! Il y aura une compétition jugée en ski et en snowboard! Jam, DJ, animation et plusieurs prix seront de la partie pour deux jours de plaisir.</p>`,
        date: '2 et 3 Avril',
      },
      {
        title: 'Coupe Caribou',
        image: '/vue-app-static-img/events-activity/caribou.jpg',
        details: `<h3 class="small-title">Coupe Caribou</h3>
                  <p class="regular-text bold">9 avril 2022</p>
                  <p class="regular-text">Un événement hors du commun qui inaugure bien l’arrivée du printemps à Tremblant depuis plusieurs années.</p>
                  <p class="regular-text">Relevez le défi de traverser le bassin d'eau au pied de la piste enneigée avec vos skis ou votre planche à neige. Vêtu de votre plus beau costume (c’est obligatoire!), offrez aux spectateurs l’une des prestations les plus cocasses de la journée pour gagner des prix de présence. Un événement rempli de rires, de surprises et de plaisir pour tous!</p>
                  <p class="regular-text">Plus de détails à venir</p>`,
        date: '9 avril 2022',
      },
      {
        title: 'Burton el nino / el rigleto',
        image: '/vue-app-static-img/events-activity/burton.jpg',
        details: `<h3 class="small-title">Burton el nino / el rigleto</h3>
                  <p class="regular-text bold">10 Avril</p>
                  <p class="regular-text">Les jeunes de 12 ans et moins souhaitant améliorer leurs habiletés de freestyle en planche à neige sont invités à rencontrer des entraîneurs de l’équipe Burton Québec et Tremblant pour tirer profit de leurs précieux conseils. Les tout-petits peuvent également prendre part à un événement formateur et stimulant avec leurs parents. El Rigleto, présenté par Burton Tremblant, vise à transmettre la passion de la planche à neige aux enfants tout en accompagnant les parents dans l’apprentissage de ce nouveau sport à leurs tout-petits.</p>`,
        date: '10 Avr.',
      },
      {
        title: 'Célébrations de Pâques',
        image: '/vue-app-static-img/events-activity/paques.jpg',
        details: `<h3 class="small-title">Célébrations de Pâques</h3>
                  <p class="regular-text bold">17 Avril</p>
                  <p class="regular-text">À Tremblant, on célèbre Pâques en grand! Prenez part à l’une des nombreuses activités offertes dans le village piétonnier pour l’occasion : jeux interactifs, maquillage pour enfants et distribution de chocolat, en plus d’une visite des lapins de Pâques!<br><br>De 10 h à 14 h</p><br>
                  <p class="regular-text bold">Maquillage</p>
                  <p class="regular-text">Place des Voyageurs - Deux maquilleuses professionnelles seront sur place pour le plus grand plaisir des enfants. Le maquillage sera exclusivement sur les mains afin de respecter les mesures sanitaires.<br><br>De 10 h à 15 h</p><br>
                  <p class="regular-text bold">Distribution de cocos en chocolat</p>
                  <p class="regular-text">Village piétonnier - Des lapins de Pâques se promèneront et distribueront des cocos en chocolat dans le village piétonnier. (Jusqu’à épuisement des cocos)<br><br>De 10 h à 16 h</p><br>
                  <p class="regular-text bold">Jeux interactifs</p>
                  <p class="regular-text">Place Saint-Bernard - Venez jouer dehors! Plusieurs jeux vous attendent sur la place Saint-Bernard. Hockey-bottines, soccer sur neige, foot-basketball, football/quilles et drapeau.</p><br>
                  <p class="regular-text bold">Ambiance musicale</p>
                  <p class="regular-text">Passez un agréable moment au son d’une musique rythmée d’ambiance pour toute la famille. C’est un rendez-vous!<br><br>De 10 h à 15 h</p><br>
                  <p class="regular-text bold">Rencontrez Toufou</p>
                  <p class="regular-text">Venez rencontrer notre mascotte Toufou dans le village piétonnier.<br><br>De 10 h à 15 h</p><br>
                  <p class="regular-text bold">Sculpture de ballons avec Marc-Ambulles</p>
                  <p class="regular-text">Village piétonnier - Marc-Ambulles, un amuseur public aux talents multiples, déambulera dans le village piétonnier en offrant des sculptures de ballons aux formes originales pour Pâques.<br><br>*Horaires et contenu de la programmation sujets à changement sans préavis. Le port du couvre-visage est recommandé.<br><br>De 10 h à 15 h</p>`,
        date: '17 Avril',
      },
    ],
    datepicker: {
      formatLocale: {
        months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
        monthsShort: ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'],
        weekdays: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
        weekdaysShort: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
        weekdaysMin: ['di', 'lu', 'ma', 'me', 'je', 've', 'sa'],
        firstDayOfWeek: 1,
        firstWeekContainsDate: 1,
      },
      yearFormat: 'YYYY',
      monthFormat: 'MMM',
      monthBeforeYear: true,
    },
}