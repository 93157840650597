<template>
  <component v-if="pageIsLoaded" :is="pageComponent" :pageData="pageData"></component>
</template>

<script>
import { isNil } from 'lodash'

import { initAnimationsLayer } from '@/misc/animations-layer'

export default {
  watch: {
    $route: {
      handler($route) {
        if (!isNil($route)) {
          this.pageData = $route.params

          this.pageIsLoaded = true

          setTimeout(() => {
            initAnimationsLayer()
          }, 755)
        }
      },
      immediate: true
    }
  },
  computed: {
    pageComponent() {
      if (!this.pageData) {
        return null
      }

      return () => import(`./pages/${this.pageData.campagne}/${this.pageData.saison}/${this.pageData.type}/${this.pageData.page}`)
    }
  },
  data() {
    return {
      pageData: null,
      pageIsLoaded: false
    }
  },
}
</script>
