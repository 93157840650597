export const ete2022En = {
  site_ete_2022: {
    more: 'Learn more',
    golf_cta: 'Buy your tickets',
    show_offer: 'See offer',

    lodging_form_link: 'https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/en-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA',

    avantage_1: 'Enjoy 100% of the Tremblant experience',
    avantage_2: 'Enjoy the best discounts and availabilities of the season',
    avantage_3: 'Enjoy flexible policies',

    sejour_title: 'Enjoy your stay',
    sejour_desc: 'On a life-size playground, Tremblant offers a wide range of activities for all tastes. Throughout the summer, come enjoy what the pedestrian village, the mountain and the lake have to offer: beach, Ziptrek, hiking, events and much more! You will find everything to move and have fun, whether alone, as a couple or with friends!',
    sejour_desc_famille: 'On a life-size playground, Tremblant offers a wide range of activities for all tastes. Throughout the summer, come enjoy what the pedestrian village, the mountain and the lake have to offer: beach, Ziptrek, hiking, events and much more! You will find everything to move and have fun with family!',
    secteur_title: 'CONSULT THE ACTIVITIES BY AREA',

    experience_title: 'The Tremblant experience is',
    event_title: 'More than 62 days of<br>events and entertainment!',

    eco_small_title: 'Tremblant leader in<br>eco-responsibility',
    eco_intro_txt: 'In 2021, Mont Tremblant Resort and Company will become the first Quebec company to obtain a level 3 certification in the Ecoresponsible program controlled by Ecocert Canada. You can contribute too: Ski at Tremblant this winter and reduce your environmental footprint by offsetting your travels.',
    eco_saviez_vous: 'Here are some of Tremblant’s innovative practices:',
    eco_point_1: '<span>Training</span> for employees: hazardous materials spills, composting and recycling, trail creation and maintenance.',
    eco_point_2: 'Involvement with <span>20+ economic development and environmental committees</span>. ',
    eco_point_3: 'Irrigation of landscapes with <spam>non-potable water</span>. ',
    eco_point_4: 'Diversion of <span>120 tons of compostable waste</span> from landfill sites annually. ',
    eco_point_5: 'Diversion of <span>600 tons of recyclable materials</span> from landfill sites annually. ',
    eco_point_6: 'Free introductory ski lessons for <span>1,200 youths</span> from local municipalities yearly. ',
    eco_point_7: 'Offering <span>24 electric vehicle charging stations</span> in underground and outdoor parking lots.',
    eco_point_8: 'Installation of low-flow toilets and automatic sinks in public bathrooms for <span>water consumption reduction</span>. ',
    eco_point_9: 'Recipient of <span>Vivats 2020 Award</span> thanks to our standards as environmentally responsible event venue.',

    politique_title: 'Flexible booking and cancellation policies',
    politique_data: `<p class="regular-text">We understand that it can be difficult to plan a stay in advance. We therefore invite you to <a href="https://www.tremblant.ca/about-us/policies?sc_lang=en" style="color:#E31B23;" target="_blank">consult the flexible reservation and cancellation policies</a> of our various hotel partners. Please note that the policies vary from partner to partner.</p>`,

    bh_lodging_hero: 'Save up to<br><span>25%</span><br>on lodging',
    bh_lodging_pop: 'Save up to<br>25% on lodging',
    bh_lodging_pop_cta: 'Show details',
    bh_lodging_cut: 'Offer valid until June 14, 2022',
    bh_lodging_data: `
                      <h2 class="small-title">Save up to<br>25% on lodging</h2>
                      <p class="regular-text">Offer valid until June 14, 2022</p>
                      <p class="regular-text soft">Plan ahead your dream vacation at Tremblant for your 2022 summer getaway and save big on lodging! Get the best picks for units when you book early! Meet up with friends or family in a condo at the heart of the action of the pedestrian village or escape in a romantic suite on the mountainside or next to one of our fabulous golf courses.</p>
                      <p class="regular-text soft">From April 1 to June 14, 2022, book a 2 night-stay or more and get:</p>
                      <div class="price-grid">
                        <div class="price half">
                          <p>25%<br><span>3-nights and +</span></p>
                        </div>
                        <div class="price half">
                          <p>15%<br><span>2-nights</span></p>
                        </div>
                      </div>
    `,
    bh_lodging_meta_title: 'Save up to 25% on lodging',
    bh_lodging_meta_desc: 'Plan ahead your dream vacation at Tremblant for your 2022 summer getaway and save big on lodging!',

    al_lodging_hero: 'Save up to<br><span>20%</span><br>on lodging',
    al_lodging_pop: 'Save up to<br>20% on lodging',
    al_lodging_pop_cta: 'Show details',
    al_lodging_cut: 'Offer valid between June 15 to Oct. 28, 2022',
    al_lodging_data: `
                      <h2 class="small-title">Save up to<br>20% on lodging</h2>
                      <p class="regular-text">Offer valid between June 15 to October 28, 2022</p>
                      <p class="regular-text soft">Plan ahead your stay at Tremblant for this summer and save on lodging with flexible policies! Get the best picks for units when you book early and enjoy flexible policies! Meet up with friends or family in a condo at the heart of the action of the pedestrian village or escape in a romantic suite on the mountainside or next to one of our fabulous golf courses.</p>
                      <p class="regular-text soft">From June 15 to October 28, 2022, book a 2 night-stay or more and get:</p>
                      <div class="price-grid">
                        <div class="price half">
                          <p>20%<br><span>3-nights and +</span></p>
                        </div>
                        <div class="price half">
                          <p>10%<br><span>2-nights</span></p>
                        </div>
                      </div>
    `,
    al_lodging_meta_title: 'Save up to 20% on lodging',
    al_lodging_meta_desc: 'Plan ahead your dream vacation at Tremblant for your 2022 summer getaway and save big on lodging!',

    flash_aout_hero_1: "SUMMER ESCAPE<br>FLASH SALE",
    flash_aout_hero_2: "Save 20% on a 2 night stay or more in Tremblant this summer",
    flash_aout_pop: "Save 20%<br>on lodging",
    flash_aout_pop_cta: 'Afficher les détails',
    flash_aout_cut: "Offer valid until<br>August 3, 2022",
    flash_aout_data: `
                      <h2 class="small-title">SUMMER ESCAPE<br>FLASH SALE</h2>
                      <p class="regular-text">Save on a two-night stay or more in Tremblant this summer</p>
                      <p class="regular-text soft">Fully enjoy summer events, entertainment and activities around the pedestrian village, on the mountain and at the lake when you book lodging in Tremblant!</p>
                      <p class="regular-text soft">From August 1 to 3, 2022, book a two-night stay or more and save 20% on lodging. Offer is valid every day from August 2 to October 31, 2022, except from August 18 to 21, 2022, when participating hotels may offer lower discounts.</p>
    `,
    flash_aout_meta_title: 'Summer escape flash sale',
    flash_aout_meta_desc: 'Save 20% on a 2 night stay or more in Tremblant this summer',

    flash_aout_2_hero_1: "SURPRISE ESCAPE<br>FLASH SALE",
    flash_aout_2_hero_2: "Save up to 25% on a two-night stay or more<br>in Tremblant this summer or fall",
    flash_aout_2_pop: "Save up to 25%<br>on lodging",
    flash_aout_2_pop_cta: 'Show details',
    flash_aout_2_cut: "On sale until<br>August 24, 2022, 1 p.m.",
    flash_aout_2_data: `
                      <h2 class="small-title">SURPRISE ESCAPE<br>FLASH SALE</h2>
                      <p class="regular-text">Save up to 25% on a two-night stay or more in Tremblant this summer or fall</p>
                      <p class="regular-text soft">Fully enjoy events and entertainment, as well as beautiful fall colours and activities around the pedestrian village and on the mountain when you book lodging in Tremblant!</p>
                      <p class="regular-text soft">From August 22 to 24, 2022, book a two-night stay or more and save up to 25% on lodging. Offer is valid every day from August 23 to October 31, 2022.</p>
    `,
    flash_aout_2_meta_title: 'Surprise escape flash sale',
    flash_aout_2_meta_desc: 'Save up to 25% on a two-night stay or more in Tremblant this summer or fall',

    launch_lodging_hero: 'Save up to<br><span>20%</span><br>on lodging',
    launch_lodging_pop: 'Save up to<br>20% on lodging',
    launch_lodging_pop_cta: 'Show details',
    launch_lodging_cut: 'Offer valid until Oct. 28, 2022',
    launch_lodging_data: `
                      <h2 class="small-title">Save up to<br>20% on lodging</h2>
                      <p class="regular-text">Offer valid until Oct. 28, 2022</p>
                      <p class="regular-text soft">Plan ahead your stay at Tremblant and save on lodging with flexible policies! Get the best picks for units when you book early and enjoy flexible policies! Meet up with friends or family in a condo at the heart of the action of the pedestrian village or escape in a romantic suite on the mountainside or next to one of our fabulous golf courses.</p>
                      <p class="regular-text soft">Until October 28, 2022, book a 2 night-stay or more and get:</p>
                      <div class="price-grid">
                        <div class="price half">
                          <p>20%<br><span>3-nights and +</span></p>
                        </div>
                        <div class="price half">
                          <p>10%<br><span>2-nights</span></p>
                        </div>
                      </div>
    `,
    launch_lodging_meta_title: 'Save up to 20% on lodging',
    launch_lodging_meta_desc: 'Plan ahead your stay at Tremblant and save on lodging with flexible policies!',

    golf_event_title: '62 days of events and entertainment',
    golf_event_desc: `<p class="regular-text">Stay on top of all the upcoming music, wellness and sports events to enhance your stay. A host of free concerts are presented throughout the season.</p>
                      <a href="/en/ete/2022/bighit/dink/#events" target="_blank" class="cta vert">Learn more</a>
    `,
    golf_activity_title: '36 restaurants, bars and cafes',
    golf_activity_desc: `<p class="regular-text">Whatever you wish to eat, you will find the right restaurant for your family and friends. The hardest part will be to pick between French, Italian, Asian and more!</p>
                         <a href="/en/ete/2022/bighit/dink/#quoi-faire" target="_blank" class="cta vert">Learn more</a>
    `,

    golf_slogan: 'Duel with Le Diable,<br>battle with Le Géant',
    golf_argument_1: 'Golf cart included',
    golf_argument_2: 'Access to driving range',
    golf_argument_3: 'Valet service',
    golf_intro_title: 'Two championship courses,<br>one magnificient experience',
    golf_stat_1: 'Championship holes',
    golf_stat_2: 'Par',
    golf_stat_3: 'Yards',
    golf_check_1: 'Cart included',
    golf_check_2: 'Access to driving range',
    golf_check_3: 'Equipment rental',
    golf_check_4: 'Boutique',
    golf_check_5: 'Valet service',
    golf_check_6: 'Restaurant',
    golf_check_7: 'Take-out',
    golf_holes_title: 'Discover each holes<br>like a bird',
    golf_stay_title: 'Extend your stay',
    golf_stay_title_1: 'days of events<br>and entertainment',
    golf_stay_title_2: 'RESTAURANTS,<br>BARS AND CAFES',
    golf_stay_title_3: 'on lodging',
    golf_stay_up_to: 'save up to',
    golf_igore_title: 'Book your tee time<br>with the new Igore app',
    golf_link: 'https://reservations.tremblant.ca/Ecomm/Shop/Merchandise/2624850/en-CA/?productsupercategoryid=20&_gl=1*6s2nng*_ga*MTc0NzMxMDkyNi4xNjMyMTQ3ODg3*_ga_B1YVPN56M6*MTY1MjEyMzU1MS4zNzUuMS4xNjUyMTI0MzIwLjA.&_ga=2.220528538.323630238.1652111474-1747310926.1632147887&_gac=1.148983236.1650380375.CjwKCAjwu_mSBhAYEiwA5BBmf4AuKDB36deCsDZ0CbYttjaka2HarBq9uabTo4YGSxjnVsk28ihpVhoCMJoQAvD_BwE',
    golf_link_reservation: 'https://www.tremblant.ca/things-to-do/golfing/tee-times?sc_lang=en',

    golf_diable_dates: 'From May 6 to Oct. 16',
    golf_diable_desc: 'Live an incomparable golfing experience on Le Diable, an astonishing course with devilish surprises at every turn, imposing red-sand bunkers and long narrow fairways. This unique 7,056-yard marvel designed by Master Architects Michael Hurdzan and Dana Fry will put all your drive and precision to the supreme test.',

    golf_geant_dates: 'From June 10 to Oct. 10',
    golf_geant_desc: 'Plateaus sculpted right into the mountainside, heart-stopping views of the vast Laurentian landscape – this master\'s golf course is an unforgettable experience for golfers who like a game in glorious surroundings that are… gigantic. Your biggest challenge is keeping your eye on the ball.',

    bh_golf_cut: 'Offer valid until June 10, 2022',
    bh_golf_title: 'T-GOLF LE DIABLE',
    bh_golf_hero: `  <div class="price">
                        <p class="expo">$</p>
                        <p class="number">95</p>
                        <div class="right">
                          <p class="big"></p>
                          <p>/round</p>
                          <p class="stroke">Reg. 139 $</p>
                        </div>
                      </div>
    `,
    bh_golf_deal: ` <h2 class="medium-title">T-Golf Le Diable Tickets<br>$95/round <span class="reg">Reg. $139</span></h2>
                    <p class="regular-text">Offer valid until June 10, 2022</p>
                    <p class="regular-text soft">Fill your summer up with rounds and take advantage of discounts to play on Le Diable golf course.</p>
                    <ul>
                      <li class="regular-text soft">Rounds at $95 each. that’s 36% off</li>
                      <li class="regular-text soft">Minimum buy of 2 rounds.</li>
                      <li class="regular-text soft">Rounds valid at all times, 7 days a week.</li>
                      <li class="regular-text soft">Tickets can be used by different players, by the same player for different rounds, or offered as a gift.</li>
                      <li class="regular-text soft">Must be purchased at least 48 hours prior to tee-time.</li>
                      <li class="regular-text soft">T-Golf Le Diable tickets are valid on Le Diable golf course and Le Géant golf course.</li>
                    </ul>
                    <div class="price-grid">
                      <div class="price">
                        <p>$139<br><span>Regular rate</span></p>
                      </div>
                      <div class="price">
                        <p>$95<br><span>Sale</span></p>
                      </div>
                      <div class="price">
                        <p>$44<br><span>Savings</span></p>
                      </div>
                    </div>
    `,
    bh_golf_meta_title: 'T-Golf Le Diable Tickets $95/round',
    bh_golf_meta_desc: 'Fill your summer up with rounds and take advantage of discounts to play on Le Diable golf course.',

    bh_diable_price: `
                      <div class="left">
                        <p class="small-title sup">$</p>
                        <p class="small-title">95</p>
                      </div>
                      <div class="right">
                        <p class="small-title big"></p>
                        <p class="small-title">/round</p>
                        <p class="small-title stroke">Reg. 139 $</p>
                      </div>
    `,
    bh_geant_price: `
                      <div class="left">
                        <p class="small-title sup">$</p>
                        <p class="small-title">89</p>
                      </div>
                      <div class="right">
                        <p class="small-title big"></p>
                        <p class="small-title">/round</p>
                      </div>
    `,
  },
  dink_ete_2022: {
    calendar_filters: ['All', 'September', 'October'],
    calendar_events: [
      {
        filter: 'June,July,August,September,October',
        img: '/vue-app-static-img/events-ete-2022/amuseurs_dink_v2.jpg',
        titre: 'Entertainment and Play Areas',
        date: 'Summer 2022',
        data: `
              <h3 class="small-title">Entertainment and Play Areas</h3>
              <p class="regular-text bold">Discover the street entertainers and fun play areas in the pedestrian village</p>
              <p class="regular-text">Children and adults' entertainment is everywhere in Tremblant's pedestrian village. Enjoy the street entertainers, discover fun play areas for climbing, sliding, playing hide, and more!</p>
              <ul>
                <li class="regular-text"><span class="bold">Meet Toufou</span><br>May 1 to October 31, 2022</li>
                <li class="regular-text"><span class="bold">Marc-Ambulles</span><br>May 1 to October 31, 2022</li>
              </ul>
              <p class="regular-text bold">Play areas</p>
              <ul>
                <li class="regular-text"><span class="bold">Water Games</span><br>June 11 to Sept. 11, 2022, 10 a.m to 6 p.m</li>
                <li class="regular-text"><span class="bold">Giant Game of Snakes and Ladders</span><br>May 21 to October 9, 2022</li>
                <li class="regular-text"><span class="bold">Pirate's Park</span><br>Open all year long</li>
                <li class="regular-text"><span class="bold">Vieux-Tremblant Park</span><br>Open all year long</li>
              </ul>
              `,
      },
      {
        filter: 'June',
        img: '/vue-app-static-img/events-ete-2022/championnat-nbhpa.jpg',
        titre: 'NBHPA Championship presented by Burrows',
        date: 'From June 10 to 12',
        data: `
              <h3 class="small-title">NBHPA Championship presented by Burrows</h3>
              <p class="regular-text bold">From June 10 to 12</p>
              <p class="regular-text">The most beautiful deck hockey event in Canada for all NBHPA’s association players. Come experience an unforgettable stay with a major tournament while being in a dream environment in the heart of Tremblant pedestrian village. The competition will be fierce to determine the winners of our 17 categories in 3 v.s 3 and 4 vs. 4 formats.</p>`,
      },
      {
        filter: 'June',
        img: '/vue-app-static-img/events-ete-2022/spartan-race.jpg',
        titre: 'Spartan Trifecta Weekend',
        date: 'From June 17 to 19',
        data: `
              <h3 class="small-title">Spartan Trifecta Weekend</h3>
              <p class="regular-text bold">From June 17 to 19</p>
              <p class="regular-text">On June 17, 18 and 19, test your resilience, strength, stamina, quick decision making skills, and ability to laugh in the face of adversity on unique obstacle course trail races. For this edition which will be held on the North side, choose between a course of 5km, 10km, 21km and 50km. There will even be a kids race. Obstacle racing at its toughest! You will know at the finish line...</p>`,
      },
      {
        filter: 'June',
        img: '/vue-app-static-img/events-ete-2022/sabaru-ironman-1.jpg',
        titre: 'Subaru Ironman 70.3 et 5I50 Mont-Tremblant',
        date: 'From June 25 to 26',
        data: `
              <h3 class="small-title">SUBARU IRONMAN 70.3 ET 5I50 MONT-TREMBLANT</h3>
              <p class="regular-text bold">From June 25 to 26</p>
              <p class="regular-text">On June 25, come and cheer on the athletes taking part in the opening race of the Subaru IRONMAN Triathlon (Olympic event). The weekend will draw to a close on Sunday, June 26 with the Subaru IRONMAN 70.3 Mont-Tremblant, also known as the Half-IRONMAN. The athletes will jump in Lac Tremblant from the Beach and Tennis Club to swim 1.9 km, bike 90 km, and run 21.1 km before crossing the finish line in the pedestrian village.</p>`,
      },
      {
        filter: 'July',
        img: '/vue-app-static-img/events-ete-2022/canada-day.jpg',
        titre: 'Canada day',
        date: 'July 1st',
        data: `
              <h3 class="small-title">Canada day</h3>
              <p class="regular-text bold">July 1st</p>
              <p class="regular-text">Enjoy the fresh air for Canada day. Animation and special activities will be organized in honor of our national holiday: public entertainers, face painting, raising of the Canadian flag, cake tasting, fireworks and concert will delight the whole family.</p>`,
      },
      {
        filter: 'July',
        img: '/vue-app-static-img/events-ete-2022/rythmes-tremblant.jpg',
        titre: 'Les Rythmes Tremblant',
        date: 'From July 8 to 10',
        data: `
              <h3 class="small-title">LES RYTHMES TREMBLANT</h3>
              <p class="regular-text bold">From July 8 to 10</p>
              <p class="regular-text">Les Rythmes Tremblant offers visitors 3 days of free outdoor musical performances by some of Quebec’s and Canada’s hottest artists in a festive yet wonderfully relaxed atmosphere. Every afternoon you can enjoy concerts for the whole family. In the evening, artists will entertain the crowd with a mix of timeless classics and fresh new sounds.</p>`,
      },
      {
        filter: 'July',
        img: '/vue-app-static-img/events-ete-2022/festival-blues.jpg',
        titre: 'Tremblant international blues festival',
        date: 'From July 14 to 16',
        data: `
              <h3 class="small-title">Tremblant international blues festival</h3>
              <p class="regular-text bold">From July 14 to 16</p>
              <p class="regular-text">The Tremblant International Blues Festival is back! Day or night, from cobbled streets to wilderness stages, your heart will beat to a whole new tempo. Over the course of three days, you can enjoy numerous free shows, set against the enchanting pedestrian village. Let the rhythm of the Tremblant’s destination take you over during this iconic event, where street music and family activities come together.</p>`,
      },
      {
        filter: 'July,August',
        img: '/vue-app-static-img/events-ete-2022/banzai.jpg',
        titre: 'BANZAÏ',
        date: 'From July 25 to August 10',
        data: `
              <h3 class="small-title">BANZAÏ EVENING ENTERTAINMENT</h3>
              <p class="regular-text bold">From July 25 to August 10</p>
              <p class="regular-text">Dancers, comedians, and entertainers present interactive entertainment for kids and adults alike. To the sound of music, follow the festive parade to Place Saint-Bernard where entertainment, dancing and games are awaiting you.</p>
              <p class="regular-text">July 25-26-28-29 / August 1-3-4-9-10 :<br>7 p.m - Animation<br>7:15 p.m - Show</p>`,
      },
      {
        filter: 'July,August',
        img: '/vue-app-static-img/events-ete-2022/soiree-corona.jpg',
        titre: 'Corona Summer Nights',
        date: 'From July 28 to August 25',
        data: `
              <h3 class="small-title">Corona Summer Nights</h3>
              <p class="regular-text bold">From July 28 to August 25</p>
              <p class="regular-text">Tremblant and Corona invite you to come and enjoy the summer during a 5 to 7 at the beach and tennis specially equipped for the event. Start your happy hour at the beach with ambient music. Afterwards, we will be entitled to an artist’s performance in the late afternoon. We will finish it all around a fire with acoustic music. You are expected there, rain or shine, on July 28, August 11 and 25!</p>`,
      },
      {
        filter: 'August',
        img: '/vue-app-static-img/events-ete-2022/traversee-lac.jpg',     titre: 'Traversée du lac Tremblant',
        date: 'From August 3 to 7',
        data: `
              <h3 class="small-title">Traversée du lac Tremblant</h3>
              <p class="regular-text bold">From August 3 to 7</p>
              <p class="regular-text">Five distances, one swim camp, 1000 swimmers, 170 kayakers, and one magnificent lake. Whatever your level, there is a distance for you: 1km Desjardins, 3km, 5km, 12km team and the ultimate 12Km individual crossing of Lac Tremblant. Traversée du Lac Tremblant’s 5 km and 12 km individual distances have been selected as North American Championship for the Global Swim Series.</p>
              <p class="regular-text bold">NOVELTIES!</p>
              <p class="regular-text">A 2-day swim camp (August 3-4) and the crossing of the lake (12km) as a team.</p>`,
      },
      {
        filter: 'August',
        img: '/vue-app-static-img/events-ete-2022/demi-marathon.jpg',
        titre: 'Mont-Tremblant Half Marathon',
        date: 'August 14',
        data: `
              <h3 class="small-title">Demi-marathon Mont-Tremblant</h3>
              <p class="regular-text bold">August 14</p>
              <p class="regular-text">Running in Mont-Tremblant means running the way you love it! The Mont-Tremblant Half-Marathon is perfect for seasoned runners as well as for less experienced participants taking on a personal challenge. There are various routes with splendid views (5 km, 10 km and 21.1 km), as well as a 1 km route for the kids!</p>`,
      },
      {
        filter: 'August',
        img: '/vue-app-static-img/events-ete-2022/sabaru-ironman-2.jpg',        
        titre: 'Subaru Ironman Mont-Tremblant',
        date: 'August 21',
        data: `
              <h3 class="small-title">Subaru Ironman Mont-Tremblant</h3>
              <p class="regular-text bold">August 21</p>
              <p class="regular-text">Tremblant, the best four-season resort in Eastern North America, is a world leader when it comes to providing activities and experiences that surpass the expected. Tremblant is a 10-time IRONMAN Athlete Choice Award winner. The course consists of a 2.4 mile swim in the picturesque Lac Tremblant, a 112 mile ride on an award winning bike course and a 26.2 mile run through the enchanting community of the region of Mont- Tremblant. Tremblant’s European pedestrian village is a vibrant setting for lodging, dining, shopping, and spas to meet everyone’s needs.</p>`,
      },
      {
        filter: 'September',
        img: '/vue-app-static-img/events-ete-2022/fete-de-la-musique.jpg',
        titre: 'La fête de la musique de Tremblant with Angèle Dubeau',
        date: 'From September 2 to 5',
        data: `
              <h3 class="small-title">LA FÊTE DE LA MUSIQUE DE TREMBLANT with ANGÈLE DUBEAU</h3>
              <p class="regular-text bold">From September 2 to 5</p>
              <p class="regular-text">Under the artistic direction of Angèle Dubeau, this unique event will take you on a festive musical getaway. The diverse program ranges from classical to jazz, together with world music and much more, including a roster of renowned artists and the virtuosos of tomorrow. More than 30 free concerts will be held in the Tremblant pedestrian village (at the base of the mountain) as well as in the Village and downtown areas of Mont-Tremblant.</p>`,
      },
      {
        filter: 'September,October',
        img: '/vue-app-static-img/events-ete-2022/autour-du-feu.jpg',
        titre: 'Gather Around the Fire',
        date: 'From Sept. 10 to Oct. 10',
        data: `
              <h3 class="small-title">Gather Around the Fire</h3>
              <p class="regular-text bold">From Sept. 10 to Oct. 10</p>
              <p class="regular-text">Every weekend from September 10 to October 10, time to make the most of fall’s warm atmosphere at Tremblant. After spending the day hiking or strolling in the pedestrian village, take a seat at one of the restaurants’ terraces or around Place Saint-Bernard’s fireplace and let the music take you away.</p>`,
      },
      {
        filter: 'October',
        img: '/vue-app-static-img/events-ete-2022/halloween.jpg',
        titre: 'La Classique Salomon Tremblant',
        date: 'October 22',
        data: `
              <h3 class="small-title">La Classique Salomon Tremblant</h3>
              <p class="regular-text bold">October 22</p>
              <p class="regular-text">We are thrilled to invite you to join us for the 8th annual Salomon Tremblant Classic trail running event. You will be able to discover Tremblant in our forest trails of 1, 3, 5, 10, 20 or 30 kilometers.</p>`,
      },
      {
        filter: 'October',
        img: '/vue-app-static-img/events-ete-2022/classique-salomon.jpg',
        titre: 'Halloween',
        date: 'October 30',
        data: `
              <h3 class="small-title">Halloween</h3>
              <p class="regular-text bold">October 30</p>
              <p class="regular-text">This year, for Halloween, Tremblant is transformed into an extraordinary universe with a very special atmosphere. Animations as terrifying as they are fun coexist during the time of a candy harvest. Put on your costume and visit each of the stations where animations and exciting activities unfold. End your getaway with lively dancing that will ring out lively music for the whole family.</p>`,
      },
    ],
  },
  famille_ete_2022: {
    calendar_filters: ['All', 'August', 'September', 'October'],
    calendar_events: [
      {
        filter: 'June,July,August,September,October',
        img: '/vue-app-static-img/events-ete-2022/amuseurs.jpg',
        titre: 'Entertainment and Play Areas',
        date: 'Summer 2022',
        data: `
              <h3 class="small-title">Entertainment and Play Areas</h3>
              <p class="regular-text bold">Discover the street entertainers and fun play areas in the pedestrian village</p>
              <p class="regular-text">Children and adults' entertainment is everywhere in Tremblant's pedestrian village. Enjoy the street entertainers, discover fun play areas for climbing, sliding, playing hide, and more!</p>
              <ul>
                <li class="regular-text"><span class="bold">Meet Toufou</span><br>May 1 to October 31, 2022</li>
                <li class="regular-text"><span class="bold">Marc-Ambulles</span><br>May 1 to October 31, 2022</li>
                <li class="regular-text"><span class="bold">Les Baronnes Bleues</span><br>July 2 and 31, and August 7</li>
                <li class="regular-text"><span class="bold">Giant Flowers</span><br>June 25, July 3, and August 28</li>
                <li class="regular-text"><span class="bold">The Flower Women</span><br>July 23, and August 5 and 25</li>
                <li class="regular-text"><span class="bold">Giant bubbles</span><br>July 24 and 30, and August 24</li>
                <li class="regular-text"><span class="bold">Hula Hoops</span><br>July 22 and 27, and August 6 and 27</li>
                <li class="regular-text"><span class="bold">Dishevelled</span><br>August 2</li>
                <li class="regular-text"><span class="bold">Face Painting</span><br>August 11</li>
                <li class="regular-text"><span class="bold">Bleu Pastel</span><br>August 15, 2022</li>
              </ul>
              <p class="regular-text bold">Play areas</p>
              <ul>
                <li class="regular-text"><span class="bold">Water Games</span><br>June 11 to Sept. 11, 2022, 10 a.m to 6 p.m</li>
                <li class="regular-text"><span class="bold">Giant Game of Snakes and Ladders</span><br>May 21 to October 9, 2022</li>
                <li class="regular-text"><span class="bold">Pirate's Park</span><br>Open all year long</li>
                <li class="regular-text"><span class="bold">Vieux-Tremblant Park</span><br>Open all year long</li>
              </ul>
              `,
      },
      {
        filter: 'June',
        img: '/vue-app-static-img/events-ete-2022/championnat-nbhpa.jpg',
        titre: 'NBHPA Championship presented by Burrows',
        date: 'From June 10 to 12',
        data: `
              <h3 class="small-title">NBHPA Championship presented by Burrows</h3>
              <p class="regular-text bold">From June 10 to 12</p>
              <p class="regular-text">The most beautiful deck hockey event in Canada for all NBHPA’s association players. Come experience an unforgettable stay with a major tournament while being in a dream environment in the heart of Tremblant pedestrian village. The competition will be fierce to determine the winners of our 17 categories in 3 v.s 3 and 4 vs. 4 formats.</p>`,
      },
      {
        filter: 'June',
        img: '/vue-app-static-img/events-ete-2022/spartan-race.jpg',
        titre: 'Spartan Trifecta Weekend',
        date: 'From June 17 to 19',
        data: `
              <h3 class="small-title">Spartan Trifecta Weekend</h3>
              <p class="regular-text bold">From June 17 to 19</p>
              <p class="regular-text">On June 17, 18 and 19, test your resilience, strength, stamina, quick decision making skills, and ability to laugh in the face of adversity on unique obstacle course trail races. For this edition which will be held on the North side, choose between a course of 5km, 10km, 21km and 50km. There will even be a kids race. Obstacle racing at its toughest! You will know at the finish line...</p>`,
      },
      {
        filter: 'June',
        img: '/vue-app-static-img/events-ete-2022/sabaru-ironman-1.jpg',
        titre: 'Subaru Ironman 70.3 et 5I50 Mont-Tremblant',
        date: 'From June 25 to 26',
        data: `
              <h3 class="small-title">SUBARU IRONMAN 70.3 ET 5I50 MONT-TREMBLANT</h3>
              <p class="regular-text bold">From June 25 to 26</p>
              <p class="regular-text">On June 25, come and cheer on the athletes taking part in the opening race of the Subaru IRONMAN Triathlon (Olympic event). The weekend will draw to a close on Sunday, June 26 with the Subaru IRONMAN 70.3 Mont-Tremblant, also known as the Half-IRONMAN. The athletes will jump in Lac Tremblant from the Beach and Tennis Club to swim 1.9 km, bike 90 km, and run 21.1 km before crossing the finish line in the pedestrian village.</p>`,
      },
      {
        filter: 'July',
        img: '/vue-app-static-img/events-ete-2022/canada-day.jpg',
        titre: 'Canada day',
        date: 'July 1st',
        data: `
              <h3 class="small-title">Canada day</h3>
              <p class="regular-text bold">July 1st</p>
              <p class="regular-text">Enjoy the fresh air for Canada day. Animation and special activities will be organized in honor of our national holiday: public entertainers, face painting, raising of the Canadian flag, cake tasting, fireworks and concert will delight the whole family.</p>`,
      },
      {
        filter: 'July',
        img: '/vue-app-static-img/events-ete-2022/rythmes-tremblant.jpg',
        titre: 'Les Rythmes Tremblant',
        date: 'From July 8 to 10',
        data: `
              <h3 class="small-title">LES RYTHMES TREMBLANT</h3>
              <p class="regular-text bold">From July 8 to 10</p>
              <p class="regular-text">Les Rythmes Tremblant offers visitors 3 days of free outdoor musical performances by some of Quebec’s and Canada’s hottest artists in a festive yet wonderfully relaxed atmosphere. Every afternoon you can enjoy concerts for the whole family. In the evening, artists will entertain the crowd with a mix of timeless classics and fresh new sounds.</p>`,
      },
      {
        filter: 'July',
        img: '/vue-app-static-img/events-ete-2022/festival-blues.jpg',
        titre: 'Tremblant international blues festival',
        date: 'From July 14 to 16',
        data: `
              <h3 class="small-title">Tremblant international blues festival</h3>
              <p class="regular-text bold">From July 14 to 16</p>
              <p class="regular-text">The Tremblant International Blues Festival is back! Day or night, from cobbled streets to wilderness stages, your heart will beat to a whole new tempo. Over the course of three days, you can enjoy numerous free shows, set against the enchanting pedestrian village. Let the rhythm of the Tremblant’s destination take you over during this iconic event, where street music and family activities come together.</p>`,
      },
      {
        filter: 'July,August',
        img: '/vue-app-static-img/events-ete-2022/banzai.jpg',
        titre: 'BANZAÏ',
        date: 'From July 25 to August 10',
        data: `
              <h3 class="small-title">BANZAÏ EVENING ENTERTAINMENT</h3>
              <p class="regular-text bold">From July 25 to August 10</p>
              <p class="regular-text">Dancers, comedians, and entertainers present interactive entertainment for kids and adults alike. To the sound of music, follow the festive parade to Place Saint-Bernard where entertainment, dancing and games are awaiting you.</p>
              <p class="regular-text">July 25-26-28-29 / August 1-3-4-9-10 :<br>7 p.m - Animation<br>7:15 p.m - Show</p>`,
      },
      {
        filter: 'July,August',
        img: '/vue-app-static-img/events-ete-2022/soiree-corona.jpg',
        titre: 'Corona Summer Nights',
        date: 'From July 28 to August 25',
        data: `
              <h3 class="small-title">Corona Summer Nights</h3>
              <p class="regular-text bold">From July 28 to August 25</p>
              <p class="regular-text">Tremblant and Corona invite you to come and enjoy the summer during a 5 to 7 at the beach and tennis specially equipped for the event. Start your happy hour at the beach with ambient music. Afterwards, we will be entitled to an artist’s performance in the late afternoon. We will finish it all around a fire with acoustic music. You are expected there, rain or shine, on July 28, August 11 and 25!</p>`,
      },
      {
        filter: 'August',
        img: '/vue-app-static-img/events-ete-2022/traversee-lac.jpg',
        titre: 'Traversée du lac Tremblant',
        date: 'From August 3 to 7',
        data: `
              <h3 class="small-title">Traversée du lac Tremblant</h3>
              <p class="regular-text bold">From August 3 to 7</p>
              <p class="regular-text">Five distances, one swim camp, 1000 swimmers, 170 kayakers, and one magnificent lake. Whatever your level, there is a distance for you: 1km Desjardins, 3km, 5km, 12km team and the ultimate 12Km individual crossing of Lac Tremblant. Traversée du Lac Tremblant’s 5 km and 12 km individual distances have been selected as North American Championship for the Global Swim Series.</p>
              <p class="regular-text bold">NOVELTIES!</p>
              <p class="regular-text">A 2-day swim camp (August 3-4) and the crossing of the lake (12km) as a team.</p>`,
      },
      {
        filter: 'Août',
        img: '/vue-app-static-img/events-ete-2022/demi-marathon.jpg',
        titre: 'Mont-Tremblant Half Marathon',
        date: 'August 14',
        data: `
              <h3 class="small-title">Demi-marathon Mont-Tremblant</h3>
              <p class="regular-text bold">August 14</p>
              <p class="regular-text">Running in Mont-Tremblant means running the way you love it! The Mont-Tremblant Half-Marathon is perfect for seasoned runners as well as for less experienced participants taking on a personal challenge. There are various routes with splendid views (5 km, 10 km and 21.1 km), as well as a 1 km route for the kids!</p>`,
      },
      {
        filter: 'August',
        img: '/vue-app-static-img/events-ete-2022/sabaru-ironman-2.jpg',
        titre: 'Subaru Ironman Mont-Tremblant',
        date: 'August 21',
        data: `
              <h3 class="small-title">Subaru Ironman Mont-Tremblant</h3>
              <p class="regular-text bold">August 21</p>
              <p class="regular-text">Tremblant, the best four-season resort in Eastern North America, is a world leader when it comes to providing activities and experiences that surpass the expected. Tremblant is a 10-time IRONMAN Athlete Choice Award winner. The course consists of a 2.4 mile swim in the picturesque Lac Tremblant, a 112 mile ride on an award winning bike course and a 26.2 mile run through the enchanting community of the region of Mont- Tremblant. Tremblant’s European pedestrian village is a vibrant setting for lodging, dining, shopping, and spas to meet everyone’s needs.</p>`,
      },
      {
        filter: 'September',
        img: '/vue-app-static-img/events-ete-2022/fete-de-la-musique.jpg',
        titre: 'La fête de la musique de Tremblant with Angèle Dubeau',
        date: 'From September 2 to 5',
        data: `
              <h3 class="small-title">LA FÊTE DE LA MUSIQUE DE TREMBLANT with ANGÈLE DUBEAU</h3>
              <p class="regular-text bold">From September 2 to 5</p>
              <p class="regular-text">Under the artistic direction of Angèle Dubeau, this unique event will take you on a festive musical getaway. The diverse program ranges from classical to jazz, together with world music and much more, including a roster of renowned artists and the virtuosos of tomorrow. More than 30 free concerts will be held in the Tremblant pedestrian village (at the base of the mountain) as well as in the Village and downtown areas of Mont-Tremblant.</p>`,
      },
      {
        filter: 'September,October',
        img: '/vue-app-static-img/events-ete-2022/autour-du-feu.jpg',
        titre: 'Gather Around the Fire',
        date: 'From Sept. 10 to Oct. 10',
        data: `
              <h3 class="small-title">Gather Around the Fire</h3>
              <p class="regular-text bold">From Sept. 10 to Oct. 10</p>
              <p class="regular-text">Every weekend from September 10 to October 10, time to make the most of fall’s warm atmosphere at Tremblant. After spending the day hiking or strolling in the pedestrian village, take a seat at one of the restaurants’ terraces or around Place Saint-Bernard’s fireplace and let the music take you away.</p>`,
      },
      {
        filter: 'October',
        img: '/vue-app-static-img/events-ete-2022/halloween.jpg',
        titre: 'Halloween',
        date: 'October 30',
        data: `
              <h3 class="small-title">Halloween</h3>
              <p class="regular-text bold">October 30</p>
              <p class="regular-text">This year, for Halloween, Tremblant is transformed into an extraordinary universe with a very special atmosphere. Animations as terrifying as they are fun coexist during the time of a candy harvest. Put on your costume and visit each of the stations where animations and exciting activities unfold. End your getaway with lively dancing that will ring out lively music for the whole family.</p>`,
      },
    ],
  },
  dink_ete_2022_experiences: [
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-events.jpg',
      number: '62',
      titre: 'days of events<br>and entertainment',
      data: `
            <h3 class="small-title">62 days of events and entertainment: throughout the season</h3>
            <p class="regular-text">Stay on top of all the upcoming music, wellness and sports events to enhance your stay. A host of free concerts are presented throughout the season.</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-bars.jpg',
      number: '36',
      titre: 'Restaurants,<br />bars and cafes',
      data: `
            <h3 class="small-title">36 restaurants, bars and cafes</h3>
            <p class="regular-text">Whatever you wish to eat, you will find the right restaurant for your family and friends. The hardest part will be to pick between French, Italian, Asian and more!</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-activities.jpg',
      number: '44',
      titre: 'ways<br>to have fun',
      data: `
            <h3 class="small-title">44 ways to have fun:<br>exhilarating activities for everyone!</h3>
            <p class="regular-text">A unique experience awaits you in Tremblant! Discover exhilarating summer activities for the whole family!</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-lodging.jpg',
      number: '1900',
      titre: 'lodging<br>units',
      data: `
            <h3 class="small-title">1900 lodging units</h3>
            <p class="regular-text">Rejoice in one of our luxurious hotels at the foot of the hills or in one of our condos on the mountainside or by the golf course.</p>
      `,
    },
  ],
  general_ete_2022_experiences: [
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-events-3.jpg',
      number: '62',
      titre: 'days of events<br>and entertainment<br>during the season',
      data: `
            <h3 class="small-title">62 days of events and entertainment: throughout the season</h3>
            <p class="regular-text">Stay on top of all the upcoming music, wellness and sports events to enhance your stay. A host of free concerts are presented throughout the season.</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-bars-famille-3.jpg',
      number: '36',
      titre: 'Restaurants,<br />bars and cafes',
      data: `
            <h3 class="small-title">36 restaurants, bars and cafes</h3>
            <p class="regular-text">Whatever you wish to eat, you will find the right restaurant for your family and friends. The hardest part will be to pick between French, Italian, Asian and more!</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-activities-3.jpg',
      number: '44',
      titre: 'ways<br>to have fun',
      data: `
            <h3 class="small-title">44 ways to have fun:<br>exhilarating activities for everyone!</h3>
            <p class="regular-text">A unique experience awaits you in Tremblant! Discover exhilarating summer activities for the whole family!</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-lodging-3.jpg',
      number: '1900',
      titre: 'lodging<br>units',
      data: `
            <h3 class="small-title">1900 lodging units</h3>
            <p class="regular-text">Rejoice in one of our luxurious hotels at the foot of the hills or in one of our condos on the mountainside or by the golf course.</p>
      `,
    },
  ],
  famille_ete_2022_experiences: [
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-events-famille.jpg',
      number: '62',
      titre: 'days of events<br>and entertainment',
      data: `
            <h3 class="small-title">62 days of events and entertainment: throughout the season</h3>
            <p class="regular-text">Stay on top of all the upcoming music, wellness and sports events to enhance your stay. A host of free concerts are presented throughout the season.</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-bars-famille.jpg',
      number: '36',
      titre: 'Restaurants,<br />bars and cafes',
      data: `
            <h3 class="small-title">36 restaurants, bars and cafes</h3>
            <p class="regular-text">Whatever you wish to eat, you will find the right restaurant for your family and friends. The hardest part will be to pick between French, Italian, Asian and more!</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-activities-famille.jpg',
      number: '44',
      titre: 'ways<br>to have fun',
      data: `
            <h3 class="small-title">44 ways to have fun:<br>exhilarating activities for everyone!</h3>
            <p class="regular-text">A unique experience awaits you in Tremblant! Discover exhilarating summer activities for the whole family!</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-lodging.jpg',
      number: '1900',
      titre: 'lodging<br>units',
      data: `
            <h3 class="small-title">1900 lodging units</h3>
            <p class="regular-text">Rejoice in one of our luxurious hotels at the foot of the hills or in one of our condos on the mountainside or by the golf course.</p>
      `,
    },
  ],
  dink_ete_2022_activity: [
    {
      video: '',
      img: '/vue-app-static-img/activity-ete-2022/tonga-dink.jpg',
      titre: `
            <p class="big">Tonga</p>
            <p class="small-title">Lumina</p>
      `,
      data: `<h3 class="small-title">Tonga Lumina - A NIGHT WALK IN SEARCH OF THE GIANT</h3>
              <p class="regular-text">Deep in the heart of Mont Tremblant, the last of the giants has awoken. Follow your sense of adventure to discover the secret he wants to share. Tonga Lumina is a sensory experience in the forest produced by Moment Factory. This 1.5-km night walk takes place on a stone dust and illuminated pathway at the heart of nature.</p>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=93k68YNqI58',
      img: '/vue-app-static-img/activity-ete-2022/golf.jpg',
      titre: `
            <p class="big">Golf</p>
            <p class="small-title">Tremblant</p>
      `,
      data: `<h3 class="small-title">Tremblant Golf</h3>
              <p class="regular-text">Come play on Le Diable and Le Géant golf courses and enjoy spectacular plateaus, beautiful greens and heart-stopping views. Make your golf vacation a dream come true!</p>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=ZMaiaKKdse0',
      img: '/vue-app-static-img/activity-ete-2022/ziptrek.jpg',
      titre: `
            <p class="big">Ziptrek</p>
            <p class="small-title">Ecotours</p>
      `,
      data: `<h3 class="small-title">Ziptrek Ecotours - FUN FOR THRILL-SEEKERS OF ALL AGES</h3>
              <p class="regular-text">Take a big leap with this awe-inspiring and exhilarating zipline tour that will take you to new heights! An awe inspiring journey that allows you to soar high above the trees and experience one of the most spectacular zipline courses in the world! The ultimate zipline tour experience - providing the opportunity to zip from the summit of the mountain back to the village. Featuring five distinct and spectacular ziplines that total almost 4km in length - prepare to be amazed!</p>
      `,
    },
  ],
  general_ete_2022_activity: [
    {
      video: '',
      img: '/vue-app-static-img/activity-ete-2022/tonga-famille.jpg',
      titre: `
            <p class="big">Tonga</p>
            <p class="small-title">Lumina</p>
      `,
      data: `<h3 class="small-title">Tonga Lumina - A NIGHT WALK IN SEARCH OF THE GIANT</h3>
              <p class="regular-text">Deep in the heart of Mont Tremblant, the last of the giants has awoken. Follow your sense of adventure to discover the secret he wants to share. Tonga Lumina is a sensory experience in the forest produced by Moment Factory. This 1.5-km night walk takes place on a stone dust and illuminated pathway at the heart of nature.</p>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=93k68YNqI58',
      img: '/vue-app-static-img/activity-ete-2022/golf-automne.jpg',
      titre: `
            <p class="big">Golf</p>
            <p class="small-title">Tremblant</p>
      `,
      data: `<h3 class="small-title">Tremblant Golf</h3>
              <p class="regular-text">Come play on Le Diable and Le Géant golf courses and enjoy spectacular plateaus, beautiful greens and heart-stopping views. Make your golf vacation a dream come true!</p>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=ZMaiaKKdse0',
      img: '/vue-app-static-img/activity-ete-2022/ziptrek-automne.jpg',
      titre: `
            <p class="big">Ziptrek</p>
            <p class="small-title">Ecotours</p>
      `,
      data: `<h3 class="small-title">Ziptrek Ecotours - FUN FOR THRILL-SEEKERS OF ALL AGES</h3>
              <p class="regular-text">Take a big leap with this awe-inspiring and exhilarating zipline tour that will take you to new heights! An awe inspiring journey that allows you to soar high above the trees and experience one of the most spectacular zipline courses in the world! The ultimate zipline tour experience - providing the opportunity to zip from the summit of the mountain back to the village. Featuring five distinct and spectacular ziplines that total almost 4km in length - prepare to be amazed!</p>
      `,
    },
  ],
  famille_ete_2022_activity: [
    {
      video: '',
      img: '/vue-app-static-img/activity-ete-2022/tonga-famille.jpg',
      titre: `
            <p class="big">Tonga</p>
            <p class="small-title">Lumina</p>
      `,
      data: `<h3 class="small-title">Tonga Lumina - A NIGHT WALK IN SEARCH OF THE GIANT</h3>
              <p class="regular-text">Deep in the heart of Mont Tremblant, the last of the giants has awoken. Follow your sense of adventure to discover the secret he wants to share. Tonga Lumina is a sensory experience in the forest produced by Moment Factory. This 1.5-km night walk takes place on a stone dust and illuminated pathway at the heart of nature.</p>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=tfMGQojD6uY',
      img: '/vue-app-static-img/activity-ete-2022/brindo.jpg',
      titre: `
            <p class="big">Brind'O</p>
            <p class="small-title">Aquaclub</p>
      `,
      data: `<h3 class="small-title">Brind'O Aquaclub</h3>
             <p class="regular-text">Come and experience the reality of Brind’O Aquaclub’s world at the heart of the resort. With its 4 slides, 35 interactive water games, revamped fitness room and hot tub, the new family waterplay centre is becoming a must, rain or shine.</p>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=ZMaiaKKdse0',
      img: '/vue-app-static-img/activity-ete-2022/ziptrek-famille.jpg',
      titre: `
            <p class="big">Ziptrek</p>
            <p class="small-title">Ecotours</p>
      `,
      data: `<h3 class="small-title">Ziptrek Ecotours - FUN FOR THRILL-SEEKERS OF ALL AGES</h3>
              <p class="regular-text">Take a big leap with this awe-inspiring and exhilarating zipline tour that will take you to new heights! An awe inspiring journey that allows you to soar high above the trees and experience one of the most spectacular zipline courses in the world! The ultimate zipline tour experience - providing the opportunity to zip from the summit of the mountain back to the village. Featuring five distinct and spectacular ziplines that total almost 4km in length - prepare to be amazed!</p>
      `,
    },
  ],
  dink_ete_2022_secteurs: [
    {
      video: 'https://www.youtube.com/watch?v=AvXvPGsg7VE',
      img: '/vue-app-static-img/secteurs-ete-2022/montagne-dink.jpg',
      titre: 'Mountain',
      data: `<h3 class="small-title">Mountain</h3>
              <p class="regular-text">Appreciate the destination from another point of view with our activities at altitude! From thrills to relaxing hikes, discover a landscape that will take your breath away.</p>
              <ul>
                <li class="regular-text">Apprentice Falconer</li>
                <li class="regular-text">Birds of Prey Show</li>
                <li class="regular-text">Hiking</li>
                <li class="regular-text">Lunch Trio</li>
                <li class="regular-text">Multi-Activity</li>
                <li class="regular-text">Panoramic Gondola</li>
                <li class="regular-text">Tonga Lumina</li>
                <li class="regular-text">Tremblant Activity Centre</li>
                <li class="regular-text">Ziptrek Ecotours</li>
              </ul>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=8fAUeDGcSss',
      img: '/vue-app-static-img/secteurs-ete-2022/lac-dink.jpg',
      titre: 'Lake',
      data: `<h3 class="small-title">Lake</h3>
              <p class="regular-text">Enjoy summer fun in Tremblant! The stunning views on mount Tremblant and a multitude of activities await vacationers at the beach and on the lake all summer long.</p>
              <ul>
                <li class="regular-text">Beach and Tennis Club</li>
                <li class="regular-text">Boat Rentals</li>
                <li class="regular-text">Canoeing, Kayaking and SUP Paddle Boarding</li>
                <li class="regular-text">Centre Nautique Pierre Plouffe</li>
                <li class="regular-text">Cruises on Lac Tremblant</li>
                <li class="regular-text">Fishing</li>
                <li class="regular-text">Iceberg</li>
                <li class="regular-text">Lunch Trio</li>
                <li class="regular-text">Multi-Activity</li>
                <li class="regular-text">Parc Plage</li>
                <li class="regular-text">Tremblant Activity Centre</li>
                <li class="regular-text">Water Sports</li>
                <li class="regular-text">Watersports Day Camp</li>
              </ul>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=E8vqQ77xVAE',
      img: '/vue-app-static-img/secteurs-ete-2022/village-dink.jpg',
      titre: 'Pedestrian',
      sub_title: 'Village',
      data: `<h3 class="small-title">Pedestrian Village</h3>
              <p class="regular-text">Make beautiful discoveries as you stroll around the picturesque pedestrian village at the foot of Mont Tremblant. Find everything you dreamed of for an outdoor or trendy outing.</p>
              <ul>
                <li class="regular-text">Arcade Virtual Reality Tremblant</li>
                <li class="regular-text">Biking</li>
                <li class="regular-text">Brind'O Aquaclub</li>
                <li class="regular-text">Brind'O Fitness Room</li>
                <li class="regular-text">Captain's Harbour</li>
                <li class="regular-text">Casino de Mont-Tremblant</li>
                <li class="regular-text">Climbing Tower</li>
                <li class="regular-text">Entertainment and Play Areas</li>
                <li class="regular-text">Eurobungy</li>
                <li class="regular-text">Le Petit Géant Mini-golf</li>
                <li class="regular-text">Luge</li>
                <li class="regular-text">Mission Laser</li>
                <li class="regular-text">Mission Liberté</li>
                <li class="regular-text">Multi-Activity</li>
                <li class="regular-text">Plush Factory</li>
                <li class="regular-text">Rock Climbing</li>
                <li class="regular-text">Spas and Massages</li>
                <li class="regular-text">Studio Créatif</li>
                <li class="regular-text">Tremblant Activity Centre</li>
              </ul>
      `,
    },
  ],
  general_ete_2022_secteurs: [
    {
      video: 'https://www.youtube.com/watch?v=AvXvPGsg7VE',
      img: '/vue-app-static-img/activity-ete-2022/montagne-automne.jpg',
      titre: 'Mountain',
      data: `<h3 class="small-title">Mountain</h3>
              <p class="regular-text">Appreciate the destination from another point of view with our activities at altitude! From thrills to relaxing hikes, discover a landscape that will take your breath away.</p>
              <ul>
                <li class="regular-text">Apprentice Falconer</li>
                <li class="regular-text">Birds of Prey Show</li>
                <li class="regular-text">Hiking</li>
                <li class="regular-text">Lunch Trio</li>
                <li class="regular-text">Multi-Activity</li>
                <li class="regular-text">Panoramic Gondola</li>
                <li class="regular-text">Tonga Lumina</li>
                <li class="regular-text">Tremblant Activity Centre</li>
                <li class="regular-text">Ziptrek Ecotours</li>
              </ul>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=8fAUeDGcSss',
      img: '/vue-app-static-img/activity-ete-2022/lac-automne.jpg',
      titre: 'Lake',
      data: `<h3 class="small-title">Lake</h3>
              <p class="regular-text">Enjoy summer fun in Tremblant! The stunning views on mount Tremblant and a multitude of activities await vacationers at the beach and on the lake all summer long.</p>
              <ul>
                <li class="regular-text">Beach and Tennis Club</li>
                <li class="regular-text">Boat Rentals</li>
                <li class="regular-text">Canoeing, Kayaking and SUP Paddle Boarding</li>
                <li class="regular-text">Centre Nautique Pierre Plouffe</li>
                <li class="regular-text">Cruises on Lac Tremblant</li>
                <li class="regular-text">Fishing</li>
                <li class="regular-text">Iceberg</li>
                <li class="regular-text">Lunch Trio</li>
                <li class="regular-text">Multi-Activity</li>
                <li class="regular-text">Parc Plage</li>
                <li class="regular-text">Tremblant Activity Centre</li>
                <li class="regular-text">Water Sports</li>
                <li class="regular-text">Watersports Day Camp</li>
              </ul>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=E8vqQ77xVAE',
      img: '/vue-app-static-img/activity-ete-2022/village-automne.jpg',
      titre: 'Pedestrian',
      sub_title: 'Village',
      data: `<h3 class="small-title">Pedestrian Village</h3>
              <p class="regular-text">Make beautiful discoveries as you stroll around the picturesque pedestrian village at the foot of Mont Tremblant. Find everything you dreamed of for an outdoor or trendy outing.</p>
              <ul>
                <li class="regular-text">Arcade Virtual Reality Tremblant</li>
                <li class="regular-text">Biking</li>
                <li class="regular-text">Brind'O Aquaclub</li>
                <li class="regular-text">Brind'O Fitness Room</li>
                <li class="regular-text">Captain's Harbour</li>
                <li class="regular-text">Casino de Mont-Tremblant</li>
                <li class="regular-text">Climbing Tower</li>
                <li class="regular-text">Entertainment and Play Areas</li>
                <li class="regular-text">Eurobungy</li>
                <li class="regular-text">Le Petit Géant Mini-golf</li>
                <li class="regular-text">Luge</li>
                <li class="regular-text">Mission Laser</li>
                <li class="regular-text">Mission Liberté</li>
                <li class="regular-text">Multi-Activity</li>
                <li class="regular-text">Plush Factory</li>
                <li class="regular-text">Rock Climbing</li>
                <li class="regular-text">Spas and Massages</li>
                <li class="regular-text">Studio Créatif</li>
                <li class="regular-text">Tremblant Activity Centre</li>
              </ul>
      `,
    },
  ],
  famille_ete_2022_secteurs: [
    {
      video: 'https://www.youtube.com/watch?v=ierb2bG_Dy8',
      img: '/vue-app-static-img/secteurs-ete-2022/montagne-famille.jpg',
      titre: 'Mountain',
      data: `<h3 class="small-title">Mountain</h3>
              <p class="regular-text">Appreciate the destination from another point of view with our activities at altitude! From thrills to relaxing hikes, discover a landscape that will take your breath away.</p>
              <ul>
                <li class="regular-text">Apprentice Falconer</li>
                <li class="regular-text">Birds of Prey Show</li>
                <li class="regular-text">Hiking</li>
                <li class="regular-text">Lunch Trio</li>
                <li class="regular-text">Multi-Activity</li>
                <li class="regular-text">Panoramic Gondola</li>
                <li class="regular-text">Tonga Lumina</li>
                <li class="regular-text">Tremblant Activity Centre</li>
                <li class="regular-text">Ziptrek Ecotours</li>
              </ul>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=DEiK8VtSWFI',
      img: '/vue-app-static-img/secteurs-ete-2022/lac-famille.jpg',
      titre: 'Lake',
      data: `<h3 class="small-title">Lake</h3>
              <p class="regular-text">Enjoy summer fun in Tremblant! The stunning views on mount Tremblant and a multitude of activities await vacationers at the beach and on the lake all summer long.</p>
              <ul>
                <li class="regular-text">Beach and Tennis Club</li>
                <li class="regular-text">Boat Rentals</li>
                <li class="regular-text">Canoeing, Kayaking and SUP Paddle Boarding</li>
                <li class="regular-text">Centre Nautique Pierre Plouffe</li>
                <li class="regular-text">Cruises on Lac Tremblant</li>
                <li class="regular-text">Fishing</li>
                <li class="regular-text">Iceberg</li>
                <li class="regular-text">Lunch Trio</li>
                <li class="regular-text">Multi-Activity</li>
                <li class="regular-text">Parc Plage</li>
                <li class="regular-text">Tremblant Activity Centre</li>
                <li class="regular-text">Water Sports</li>
                <li class="regular-text">Watersports Day Camp</li>
              </ul>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=mUCXyKo9NmQ',
      img: '/vue-app-static-img/secteurs-ete-2022/village-famille.jpg',
      titre: 'Pedestrian',
      sub_title: 'Village',
      data: `<h3 class="small-title">Pedestrian Village</h3>
              <p class="regular-text">Make beautiful discoveries as you stroll around the picturesque pedestrian village at the foot of Mont Tremblant. Find everything you dreamed of for an outdoor or trendy outing.</p>
              <ul>
                <li class="regular-text">Arcade Virtual Reality Tremblant</li>
                <li class="regular-text">Biking</li>
                <li class="regular-text">Brind'O Aquaclub</li>
                <li class="regular-text">Brind'O Fitness Room</li>
                <li class="regular-text">Captain's Harbour</li>
                <li class="regular-text">Casino de Mont-Tremblant</li>
                <li class="regular-text">Climbing Tower</li>
                <li class="regular-text">Entertainment and Play Areas</li>
                <li class="regular-text">Eurobungy</li>
                <li class="regular-text">Le Petit Géant Mini-golf</li>
                <li class="regular-text">Luge</li>
                <li class="regular-text">Mission Laser</li>
                <li class="regular-text">Mission Liberté</li>
                <li class="regular-text">Multi-Activity</li>
                <li class="regular-text">Plush Factory</li>
                <li class="regular-text">Rock Climbing</li>
                <li class="regular-text">Spas and Massages</li>
                <li class="regular-text">Studio Créatif</li>
                <li class="regular-text">Tremblant Activity Centre</li>
              </ul>
      `,
    },
  ],
  golf_cross_sell: [
    {
      img: '/vue-app-static-img/golf-academie.jpg',
      titre: 'TREMBLANT<br>GOLF ACADEMY',
      data: `<h3 class="small-title">Tremblant golf academy</h3>
              <p class="regular-text">Located on Le Diable golf course and operated by the Dan Bilodeau Golf Académie, Tremblant Golf Academy ranks among the best in North America with a staff of certified CPGA teaching professionals and top-notch facilities, unique in Canada. The Golf Academy offers programs adapted to all levels during the summer season.</p>
      `,
    },
    {
      img: '/vue-app-static-img/golf-equipement.jpg',
      titre: 'GOLF EQUIPMENT<br>RENTALS',
      data: `<h3 class="small-title">Golf equipment rental</h3>
              <p class="regular-text">Our golf equipment rental shops offer the latest upscale clubs that will allow you to truly enjoy your experience on Tremblant golf courses. Whether you are playing on Le Diable or Le Géant, make your way to our rental counter at the clubhouse and access premium equipment for your round.</p>
      `,
    },
    {
      img: '/vue-app-static-img/golf-groups.jpg',
      titre: 'GROUPS &<br>TOURNAMENTS',
      data: `<h3 class="small-title">Groups & tournaments</h3>
              <p class="regular-text">Bring your group of 16 or more and enjoy preferential rates on rounds and equipment rentals. Get 2 free rounds from 33 to 79 persons and 4 free rounds for groups of 80 pesons and more!</p>
      `,
    },
  ],
  golf_diable_holes: [
    {
      number: '1',
      par: '4',
      noir: '459',
      bleu: '424',
      blanc: '390',
      rouge: '358',
      video: 'https://www.youtube.com/watch?v=cRbdBibdTeE',
      img: '/vue-app-static-img/trous/diable-trou-1.jpg',
    },
    {
      number: '2',
      par: '5',
      noir: '550',
      bleu: '519',
      blanc: '480',
      rouge: '439',
      video: 'https://www.youtube.com/watch?v=QLkVyYb8U8w',
      img: '/vue-app-static-img/trous/diable-trou-2.jpg',
    },
    {
      number: '3',
      par: '3',
      noir: '199',
      bleu: '189',
      blanc: '154',
      rouge: '130',
      video: 'https://www.youtube.com/watch?v=FcNM5HXQxQ0',
      img: '/vue-app-static-img/trous/diable-trou-3.jpg',
    },
    {
      number: '4',
      par: '4',
      noir: '389',
      bleu: '380',
      blanc: '362',
      rouge: '315',
      video: 'https://www.youtube.com/watch?v=HtyyORcMBHw',
      img: '/vue-app-static-img/trous/diable-trou-4.jpg',
    },
    {
      number: '5',
      par: '5',
      noir: '597',
      bleu: '565',
      blanc: '535',
      rouge: '496',
      video: 'https://www.youtube.com/watch?v=X9Vt5-4o9Uk',
      img: '/vue-app-static-img/trous/diable-trou-5.jpg',
    },
    {
      number: '6',
      par: '3',
      noir: '221',
      bleu: '177',
      blanc: '164',
      rouge: '148',
      video: 'https://www.youtube.com/watch?v=FCdWfUEn2kE',
      img: '/vue-app-static-img/trous/diable-trou-6.jpg',
    },
    {
      number: '7',
      par: '4',
      noir: '480',
      bleu: '447',
      blanc: '422',
      rouge: '370',
      video: 'https://www.youtube.com/watch?v=fYSDEI9wQiE',
      img: '/vue-app-static-img/trous/diable-trou-7.jpg',
    },
    {
      number: '8',
      par: '3',
      noir: '204',
      bleu: '170',
      blanc: '160',
      rouge: '135',
      video: 'https://www.youtube.com/watch?v=hpwjHd5TAMc',
      img: '/vue-app-static-img/trous/diable-trou-8.jpg',
    },
    {
      number: '9',
      par: '4',
      noir: '411',
      bleu: '379',
      blanc: '340',
      rouge: '297',
      video: 'https://www.youtube.com/watch?v=IFEnaxgX42E',
      img: '/vue-app-static-img/trous/diable-trou-9.jpg',
    },
    {
      number: '10',
      par: '4',
      noir: '448',
      bleu: '414',
      blanc: '381',
      rouge: '347',
      video: 'https://www.youtube.com/watch?v=n_sz5PjEuSQ',
      img: '/vue-app-static-img/trous/diable-trou-10.jpg',
    },
    {
      number: '11',
      par: '4',
      noir: '396',
      bleu: '373',
      blanc: '330',
      rouge: '260',
      video: 'https://www.youtube.com/watch?v=WJRLzgMGvKQ',
      img: '/vue-app-static-img/trous/diable-trou-11.jpg',
    },
    {
      number: '12',
      par: '3',
      noir: '213',
      bleu: '204',
      blanc: '195',
      rouge: '174',
      video: 'https://www.youtube.com/watch?v=pcnQf4pIEEI',
      img: '/vue-app-static-img/trous/diable-trou-12.jpg',
    },
    {
      number: '13',
      par: '4',
      noir: '402',
      bleu: '393',
      blanc: '366',
      rouge: '329',
      video: 'https://www.youtube.com/watch?v=Wbn3LiuBbso',
      img: '/vue-app-static-img/trous/diable-trou-13.jpg',
    },
    {
      number: '14',
      par: '4/3',
      noir: '403',
      bleu: '389',
      blanc: '365',
      rouge: '172',
      video: 'https://www.youtube.com/watch?v=e87uLxvWxU4',
      img: '/vue-app-static-img/trous/diable-trou-14.jpg',
    },
    {
      number: '15',
      par: '5',
      noir: '535',
      bleu: '482',
      blanc: '451',
      rouge: '377',
      video: 'https://www.youtube.com/watch?v=J0_ZmM0IlTQ',
      img: '/vue-app-static-img/trous/diable-trou-15.jpg',
    },
    {
      number: '16',
      par: '3',
      noir: '156',
      bleu: '150',
      blanc: '147',
      rouge: '118',
      video: 'https://www.youtube.com/watch?v=l2ia-MkJA-U',
      img: '/vue-app-static-img/trous/diable-trou-16.jpg',
    },
    {
      number: '17',
      par: '4',
      noir: '435',
      bleu: '405',
      blanc: '370',
      rouge: '335',
      video: 'https://www.youtube.com/watch?v=3LGwkKy1674',
      img: '/vue-app-static-img/trous/diable-trou-17.jpg',
    },
    {
      number: '18',
      par: '5',
      noir: '561',
      bleu: '527',
      blanc: '501',
      rouge: '470',
      video: 'https://www.youtube.com/watch?v=6mUPxNh4x5Q',
      img: '/vue-app-static-img/trous/diable-trou-18.jpg',
    },
  ],
  golf_geant_holes: [
    {
      number: '1',
      par: '5',
      noir: '510',
      bleu: '500',
      blanc: '486',
      rouge: '434',
      video: 'https://www.youtube.com/watch?v=zC4V_FV7W44',
      img: '/vue-app-static-img/trous/geant-trou-1.jpg',
    },
    {
      number: '2',
      par: '4',
      noir: '429',
      bleu: '377',
      blanc: '367',
      rouge: '328',
      video: 'https://www.youtube.com/watch?v=PtH1QIevPl8',
      img: '/vue-app-static-img/trous/geant-trou-2.jpg',
    },
    {
      number: '3',
      par: '3',
      noir: '183',
      bleu: '157',
      blanc: '144',
      rouge: '105',
      video: 'https://www.youtube.com/watch?v=oyL_37417Ek',
      img: '/vue-app-static-img/trous/geant-trou-3.jpg',
    },
    {
      number: '4',
      par: '4',
      noir: '406',
      bleu: '368',
      blanc: '342',
      rouge: '235',
      video: 'https://www.youtube.com/watch?v=uq3Dqe_mgQw',
      img: '/vue-app-static-img/trous/geant-trou-4.jpg',
    },
    {
      number: '5',
      par: '4',
      noir: '385',
      bleu: '375',
      blanc: '348',
      rouge: '318',
      video: 'https://www.youtube.com/watch?v=4GXJpTGwOS0',
      img: '/vue-app-static-img/trous/geant-trou-5.jpg',
    },
    {
      number: '6',
      par: '3',
      noir: '174',
      bleu: '151',
      blanc: '127',
      rouge: '111',
      video: 'https://www.youtube.com/watch?v=BqshfmjsVbo',
      img: '/vue-app-static-img/trous/geant-trou-6.jpg',
    },
    {
      number: '7',
      par: '5',
      noir: '578',
      bleu: '528',
      blanc: '519',
      rouge: '471',
      video: 'https://www.youtube.com/watch?v=VKgNClpGFsI',
      img: '/vue-app-static-img/trous/geant-trou-7.jpg',
    },
    {
      number: '8',
      par: '4',
      noir: '492',
      bleu: '423',
      blanc: '413',
      rouge: '379',
      video: 'https://www.youtube.com/watch?v=xkKtEIc7RdI',
      img: '/vue-app-static-img/trous/geant-trou-8.jpg',
    },
    {
      number: '9',
      par: '4',
      noir: '406',
      bleu: '375',
      blanc: '363',
      rouge: '311',
      video: 'https://www.youtube.com/watch?v=s0IFyZw4hm0',
      img: '/vue-app-static-img/trous/geant-trou-9.jpg',
    },
    {
      number: '10',
      par: '3',
      noir: '159',
      bleu: '149',
      blanc: '143',
      rouge: '114',
      video: 'https://www.youtube.com/watch?v=0oXs093m-pQ',
      img: '/vue-app-static-img/trous/geant-trou-10.jpg',
    },
    {
      number: '11',
      par: '4',
      noir: '311',
      bleu: '304',
      blanc: '281',
      rouge: '268',
      video: 'https://www.youtube.com/watch?v=TLqO1_LcqBE',
      img: '/vue-app-static-img/trous/geant-trou-11.jpg',
    },
    {
      number: '12',
      par: '5',
      noir: '547',
      bleu: '501',
      blanc: '492',
      rouge: '415',
      video: 'https://www.youtube.com/watch?v=XrNHWgSTf28',
      img: '/vue-app-static-img/trous/geant-trou-12.jpg',
    },
    {
      number: '13',
      par: '4',
      noir: '329',
      bleu: '319',
      blanc: '309',
      rouge: '273',
      video: 'https://www.youtube.com/watch?v=kObpjXPZhfo',
      img: '/vue-app-static-img/trous/geant-trou-13.jpg',
    },
    {
      number: '14',
      par: '4',
      noir: '380',
      bleu: '342',
      blanc: '333',
      rouge: '238',
      video: 'https://www.youtube.com/watch?v=W5yr103eftg',
      img: '/vue-app-static-img/trous/geant-trou-14.jpg',
    },
    {
      number: '15',
      par: '4',
      noir: '393',
      bleu: '382',
      blanc: '348',
      rouge: '329',
      video: 'https://www.youtube.com/watch?v=alGwd2xKgwI',
      img: '/vue-app-static-img/trous/geant-trou-15.jpg',
    },
    {
      number: '16',
      par: '3',
      noir: '202',
      bleu: '183',
      blanc: '155',
      rouge: '120',
      video: 'https://www.youtube.com/watch?v=OgJUQcCnAc0',
      img: '/vue-app-static-img/trous/geant-trou-16.jpg',
    },
    {
      number: '17',
      par: '5',
      noir: '495',
      bleu: '458',
      blanc: '395',
      rouge: '363',
      video: 'https://www.youtube.com/watch?v=tM4efC7myv8',
      img: '/vue-app-static-img/trous/geant-trou-17.jpg',
    },
    {
      number: '18',
      par: '4',
      noir: '457',
      bleu: '438',
      blanc: '305',
      rouge: '303',
      video: 'https://www.youtube.com/watch?v=Qfp1L3xA1zw',
      img: '/vue-app-static-img/trous/geant-trou-18.jpg',
    },
  ],
}
export const ete2022Fr = {
  site_ete_2022: {
    more: 'En savoir plus',
    golf_cta: 'Achetez vos billets',
    show_offer: 'Afficher l’offre',

    lodging_form_link: 'https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA',

    avantage_1: 'Profitez de l\'expérience Tremblant à 100%',
    avantage_2: 'Profitez des meilleurs rabais et disponibilités de la saison',
    avantage_3: 'Profitez des politiques d\'annulation flexibles',

    sejour_title: 'Profitez de votre séjour',
    sejour_desc: 'Sur un terrain de jeu grandeur nature, Tremblant offre une panoplie d\'activités pour tous les goûts. Durant tout l\'été, venez profiter de ce que le village piétonnier, la montagne et le lac ont à offrir: plage, Ziptrek, randonnée, événements et bien plus encore! Vous trouverez de tout pour bouger et vous divertir, que ce soit seul, en couple ou entre amis!',
    sejour_desc_famille: 'Sur un terrain de jeu grandeur nature, Tremblant offre une panoplie d\'activités pour tous les goûts. Durant tout l\'été, venez profiter de ce que le village piétonnier, la montagne et le lac ont à offrir: plage, Ziptrek, randonnée, événements et bien plus encore! Vous trouverez de tout pour bouger et vous divertir en famille!',
    secteur_title: 'Consultez les activités par secteur',

    experience_title: 'L’expérience Tremblant c’est',
    event_title: 'Plus de 62 jours d\'événements<br />et d\'animations !',

    eco_small_title: 'Tremblant leader en écoresponsabilité',
    eco_intro_txt: 'En 2021, Station Mont-Tremblant devient la première entreprise québécoise à obtenir la certification de niveau 3 du programme Écoresponsablemc contrôlé par Ecocert Canada. Séjournez à Tremblant cet hiver tout en compensant votre impact environnemental.',
    eco_saviez_vous: 'Voici quelques pratiques novatrices mises en place par Tremblant :',
    eco_point_1: '<span>Formations</span> pour employés : déversement de matières dangereuses, compost et recyclage, entretien et création de nouveaux sentiers.',
    eco_point_2: 'Implication dans <span>+ de 20 comités</span> environnementaux et de développement économique. ',
    eco_point_3: '<span>Eau non potable</span> utilisée pour l’arrosage des aménagements paysagers.',
    eco_point_4: 'Détournement des sites d’enfouissement de <span>120 tonnes/an</span> de déchets compostables. ',
    eco_point_5: 'Détournement des sites d’enfouissements de <span>600 tonnes/an</span> de matières recyclables.',
    eco_point_6: 'Initiation gratuite au ski pour <span>1 200 jeunes</span> des municipalités locales annuellement.',
    eco_point_7: '24 places de stationnements souterrains et extérieurs pour <span>recharge de véhicules électriques</span>.',
    eco_point_8: 'Réduction de la consommation d’eau avec des <span>toilettes à faible débit et lavabos automatiques</span> dans les salles de bains publiques.',
    eco_point_9: '<span>Prix Vivats 2020</span> — lieu d’accueil d’événements écoresponsables — grâce aux normes imposées aux événements.',

    politique_title: 'Politiques de réservation et d\'annulation flexibles',
    politique_data: `<p class="regular-text">Nous savons qu’il peut être difficile de planifier un séjour à l’avance. Nous vous invitons à <a href="https://www.tremblant.ca/fr-CA/a-propos/politiques" style="color:#E31B23;" target="_blank">consulter les politiques de réservation et d’annulation assouplies</a> des différents partenaires hôteliers. Il est à noter que les politiques varient d’un partenaire à l’autre.</p>`,

    bh_lodging_hero: "Économisez jusqu'à<br><span>25 %</span><br>Sur l’hébergement",
    bh_lodging_pop: "Économisez jusqu'à<br>25 % Sur l’hébergement",
    bh_lodging_pop_cta: 'Afficher les détails',
    bh_lodging_cut: "Offre valable jusqu'au 14 juin 2022",
    bh_lodging_data: `
                      <h2 class="small-title">Économisez jusqu'à<br>25 % sur l'hébergement</h2>
                      <p class="regular-text">Offre valable jusqu'au 14 juin 2022</p>
                      <p class="regular-text soft">Planifiez dès maintenant vos vacances de rêve pour l’été 2022 à Tremblant et économisez! Accédez aux meilleurs choix d’unités en réservant d’avance ! Retrouvez-vous entre amis ou en famille dans un condo au cœur de l’action du village piétonnier ou en amoureux dans une suite à flanc de montagne ou au pied de l’un de nos fabuleux terrains de golfs.</p>
                      <p class="regular-text soft">Du 1er avril au 14 juin 2022, réservez un séjour de deux nuits et plus et obtenez :</p>
                      <div class="price-grid">
                        <div class="price half">
                          <p>25 %<br><span>3 nuits ou +</span></p>
                        </div>
                        <div class="price half">
                          <p>15 %<br><span>2 nuits</span></p>
                        </div>
                      </div>
    `,
    bh_lodging_meta_title: 'Économisez jusqu\'à 25 % sur l’hébergement',
    bh_lodging_meta_desc: 'Planifiez dès maintenant vos vacances de rêve pour l’été 2022 à Tremblant et économisez!',

    al_lodging_hero: "Économisez jusqu'à<br><span>20 %</span><br>Sur l’hébergement",
    al_lodging_pop: "Économisez jusqu'à<br>20 % Sur l’hébergement",
    al_lodging_pop_cta: 'Afficher les détails',
    al_lodging_cut: "Offre valable du 15 juin au 28 octobre 2022",
    al_lodging_data: `
                      <h2 class="small-title">Économisez jusqu'à<br>20 % sur l'hébergement</h2>
                      <p class="regular-text">Profitez des meilleurs prix sur l'hébergement</p>
                      <p class="regular-text soft">Planifiez dès maintenant votre séjour pour l’été 2022 à Tremblant et économisez en toute flexibilité! Accédez aux meilleurs choix d’unités en réservant d’avance, tout en profitant de politiques flexibles! Retrouvez-vous entre amis ou en famille dans un condo au cœur de l’action du village piétonnier ou en amoureux dans une suite à flanc de montagne ou au pied de l’un de nos fabuleux terrains de golfs.</p>
                      <p class="regular-text soft">Du 15 juin au 28 octobre 2022, réservez un séjour de deux nuits et plus et obtenez :</p>
                      <div class="price-grid">
                        <div class="price half">
                          <p>20 %<br><span>3 nuits ou +</span></p>
                        </div>
                        <div class="price half">
                          <p>10 %<br><span>2 nuits</span></p>
                        </div>
                      </div>
    `,
    al_lodging_meta_title: 'Économisez jusqu\'à 20 % sur l’hébergement',
    al_lodging_meta_desc: 'Planifiez dès maintenant vos vacances de rêve pour l’été 2022 à Tremblant et économisez!',

    flash_aout_hero_1: "VENTE ÉCLAIR<br>ESCAPADE ESTIVALE",
    flash_aout_hero_2: "Économisez 20% sur 2 nuits ou plus à Tremblant cet été",
    flash_aout_pop: "Économisez 20%<br>sur l'hébergement",
    flash_aout_pop_cta: 'Afficher les détails',
    flash_aout_cut: "Offre valable jusqu'au<br>3 août 2022",
    flash_aout_data: `
                      <h2 class="small-title">VENTE ÉCLAIR<br>ESCAPADE ESTIVALE</h2>
                      <p class="regular-text">Économisez sur 2 nuits ou plus à Tremblant cet été</p>
                      <p class="regular-text soft">Profitez pleinement des événements estivaux, de l’animation et des activités du village piétonnier, du lac et de la montagne en réservant 2 nuits ou plus à Tremblant! Du 1 er au 3 août 2022, réservez un séjour de deux nuitées ou plus et obtenez 20 % de rabais sur l’hébergement.</p>
                      <p class="regular-text soft">L’offre est valable tous les jours du 2 août au 31 octobre 2022, à l’exception du 18 au 21 août 2022 où les partenaires hôteliers participants pourraient ne pas offrir de rabais, ou offrir un rabais inférieur à ceux stipulés dans cette offre.</p>
    `,
    flash_aout_meta_title: 'Vente éclair escapade estivale',
    flash_aout_meta_desc: 'Économisez sur 2 nuits ou plus à Tremblant cet été',

    flash_aout_2_hero_1: "VENTE ÉCLAIR<br>ESCAPADE SURPRISE",
    flash_aout_2_hero_2: "Économisez jusqu'à 25% sur 2 nuits ou plus<br>à Tremblant cet été et cet automne",
    flash_aout_2_pop: "Économisez jusqu'à 25%<br>sur l'hébergement",
    flash_aout_2_pop_cta: 'Afficher les détails',
    flash_aout_2_cut: "En vente jusqu'au<br>24 août 2022, 13h",
    flash_aout_2_data: `
                      <h2 class="small-title">VENTE ÉCLAIR<br>ESCAPADE SURPRISE</h2>
                      <p class="regular-text">Économisez jusqu'à 25% sur 2 nuits ou plus à Tremblant cet été et cet automne</p>
                      <p class="regular-text soft">Profitez pleinement des événements, de l’animation et des activités du village piétonnier, de la saison des couleurs et de la montagne en réservant 2 nuits ou plus à Tremblant!</p>
                      <p class="regular-text soft">Du 22 au 24 août 2022, réservez un séjour de deux nuitées ou plus et obtenez jusqu’à 25 % de rabais sur l’hébergement. L’offre est valable tous les jours du 23 août au 31 octobre 2022.</p>
    `,
    flash_aout_2_meta_title: 'Vente éclair escapade surprise',
    flash_aout_2_meta_desc: 'Économisez jusqu\'à 25% sur 2 nuits ou plus à Tremblant cet été et cet automne',

    launch_lodging_hero: "Économisez jusqu'à<br><span>20 %</span><br>Sur l’hébergement",
    launch_lodging_pop: "Économisez jusqu'à<br>20 % Sur l’hébergement",
    launch_lodging_pop_cta: 'Afficher les détails',
    launch_lodging_cut: "Offre valable jusqu'au 28 octobre 2022",
    launch_lodging_data: `
                      <h2 class="small-title">Économisez jusqu'à<br>20 % sur l'hébergement</h2>
                      <p class="regular-text">Offre valable jusqu'au 28 octobre 2022</p>
                      <p class="regular-text soft">Planifiez dès maintenant votre séjour pour à Tremblant et économisez en toute flexibilité! Accédez aux meilleurs choix d’unités en réservant d’avance, tout en profitant de politiques flexibles! Retrouvez-vous entre amis ou en famille dans un condo au cœur de l’action du village piétonnier ou en amoureux dans une suite à flanc de montagne ou au pied de l’un de nos fabuleux terrains de golfs.</p>
                      <p class="regular-text soft">Jusqu'au 28 octobre 2022, réservez un séjour de deux nuits et plus et obtenez :</p>
                      <div class="price-grid">
                        <div class="price half">
                          <p>20 %<br><span>3 nuits ou +</span></p>
                        </div>
                        <div class="price half">
                          <p>10 %<br><span>2 nuits</span></p>
                        </div>
                      </div>
    `,
    launch_lodging_meta_title: 'Économisez jusqu\'à 20 % sur l’hébergement',
    launch_lodging_meta_desc: 'Planifiez dès maintenant votre séjour pour à Tremblant et économisez en toute flexibilité!',

    golf_event_title: '62 JOURS D\'ÉVÉNEMENTS ET DE DIVERTISSEMENT',
    golf_event_desc: `<p class="regular-text">Restez à l’affût des prochains événements musicaux, mieux-être et sportifs pour agrémenter votre séjour. Une foule de concerts gratuits sont présentés tout au long de la saison.</p>
                      <a href="/ete/2022/bighit/dink/#events" target="_blank" class="cta vert">En savoir plus</a>
    `,
    golf_activity_title: '36 restaurants, bars et cafés',
    golf_activity_desc: `<p class="regular-text">Peu importe votre envie, celle de votre famille ou de vos amis, vous trouverez le restaurant qui vous comblera. Il ne restera plus qu’à s'entendre sur le type de cuisine : française, italienne, asiatique, ou plus encore!</p>
                         <a href="/ete/2022/bighit/dink/#quoi-faire" target="_blank" class="cta vert">En savoir plus</a>
    `,

    golf_slogan: 'Tentez Le Diable,<br>combattez Le Géant',
    golf_argument_1: 'Voiturette toujours incluse',
    golf_argument_2: 'Terrain d\'exercice gratuit',
    golf_argument_3: 'Service de valet',
    golf_intro_title: 'Deux parcours de championnat,<br>une expérience unique',
    golf_stat_1: 'Trous championnat',
    golf_stat_2: 'Normale',
    golf_stat_3: 'Verges',
    golf_check_1: 'Voiturette incluse',
    golf_check_2: 'Accès au terrain d’exercice',
    golf_check_3: 'Location d’équipement',
    golf_check_4: 'Boutique',
    golf_check_5: 'Service de valet',
    golf_check_6: 'Restaurant',
    golf_check_7: 'Casse-Croûte',
    golf_holes_title: 'Découvrez chacun des trous<br>tel un oiselet',
    golf_stay_title: 'Prolongez votre séjour',
    golf_stay_title_1: 'JOURS D\'ÉVÉNEMENTS<br>ET DE DIVERTISSEMENT',
    golf_stay_title_2: 'RESTAURANTS,<br>BARS ET CAFÉS',
    golf_stay_title_3: 'DE RABAIS SUR<br>L’HÉBERGEMENT',
    golf_stay_up_to: 'jusqu\'à',
    golf_igore_title: 'Réservez votre départ<br>sur la plateforme Igore',
    golf_link: 'https://reservations.tremblant.ca/Ecomm/Shop/Merchandise/2624850/fr-CA/?productsupercategoryid=20&_gl=1*6s2nng*_ga*MTc0NzMxMDkyNi4xNjMyMTQ3ODg3*_ga_B1YVPN56M6*MTY1MjEyMzU1MS4zNzUuMS4xNjUyMTI0MzIwLjA.&_ga=2.220528538.323630238.1652111474-1747310926.1632147887&_gac=1.148983236.1650380375.CjwKCAjwu_mSBhAYEiwA5BBmf4AuKDB36deCsDZ0CbYttjaka2HarBq9uabTo4YGSxjnVsk28ihpVhoCMJoQAvD_BwE',
    golf_link_reservation: 'https://www.tremblant.ca/quoi-faire/golf/reservations?sc_lang=fr',

    golf_diable_dates: 'Du 6 mai au 16 oct.',
    golf_diable_desc: 'Constitué de vallons, de vastes fosses de sable rouge et de longues allées étroites, le parcours du golf Le Diable vous promet une expérience de golf plus grande que nature. Ce chef-d\'œuvre unique de 7 056 verges, conçu en 1998 par les maîtres architectes Michael Hurdzan et Dana Fry.',

    golf_geant_dates: 'Du 10 juin au 10 oct.',
    golf_geant_desc: 'Départs à flanc de montagne, allées vallonnées et vues époustouflantes sur les Laurentides... le parcours de championnat du golf Le Géant vous coupera le souffle par son décor grandiose. Peu importe votre calibre, son imprenable beauté vous fera tout de même bien paraître!',
    
    bh_golf_cut: 'Offre valable jusqu\'au 10 juin 2022',
    bh_golf_title: 'T-GOLF LE DIABLE',
    bh_golf_hero: `  <div class="price">
                        <p class="number">95</p>
                        <div class="right">
                          <p class="big">$</p>
                          <p>/partie</p>
                          <p class="stroke">Reg. 139 $</p>
                        </div>
                      </div>
    `,
    bh_golf_deal: ` <h2 class="medium-title">Billets T-Golf Le Diable<br>95 $ / partie <span class="reg">Reg. 139$</span></h2>
                    <p class="regular-text">Offre valable jusqu'au 10 juin 2022</p>
                    <p class="regular-text soft">Faites le plein de parties transférables et profitez de rabais pour jouer sur le golf Le Diable cet été.</p>
                    <ul>
                      <li class="regular-text soft">Partie de golf à 95 $ chacune.</li>
                      <li class="regular-text soft">Achat minimum de 2 parties.</li>
                      <li class="regular-text soft">Parties valables en tout temps, 7 jours sur 7</li>
                      <li class="regular-text soft">Les billets peuvent être utilisés par deux personnes différentes, par la même personne pour deux parties différentes ou être offerts en cadeau</li>
                      <li class="regular-text soft">Les billets doivent être achetés 48 heures avant utilisation.</li>
                      <li class="regular-text soft">Les billets T-Golf Le Diable sont valables sur Le Diable et<br>Le Géant.</li>
                    </ul>
                    <div class="price-grid">
                      <div class="price">
                        <p>139 $<br><span>Tarif courant</span></p>
                      </div>
                      <div class="price">
                        <p>95 $<br><span>Vente</span></p>
                      </div>
                      <div class="price">
                        <p>44 $<br><span>Économies</span></p>
                      </div>
                    </div>
    `,
    bh_golf_meta_title: 'Billets T-Golf Le Diable 95 $ / partie',
    bh_golf_meta_desc: 'Faites le plein de parties transférables et profitez de rabais pour jouer sur le golf Le Diable cet été.',

    bh_diable_price: `
                      <div class="left">
                        <p class="small-title">95</p>
                      </div>
                      <div class="right">
                        <p class="small-title big">$</p>
                        <p class="small-title">/partie</p>
                        <p class="small-title stroke">Reg. 139 $</p>
                      </div>
    `,
    bh_geant_price: `
                      <div class="left">
                        <p class="small-title">89</p>
                      </div>
                      <div class="right">
                        <p class="small-title big">$</p>
                        <p class="small-title">/partie</p>
                      </div>
    `,
  },
  dink_ete_2022: {
    calendar_filters: ['Tous', 'Septembre', 'Octobre'],
    calendar_events: [
      {
        filter: 'Juin,Juillet,Août,Septembre,Octobre',
        img: '/vue-app-static-img/events-ete-2022/amuseurs_dink_v2.jpg',
        titre: 'Animation et aires de jeux',
        date: 'Été 2022',
        data: `
              <h3 class="small-title">Animation et aires de jeux</h3>
              <p class="regular-text bold">Découvrez les amuseurs de rue et espaces amusants dans le village piétonnier</p>
              <p class="regular-text">L'animation pour les petits et les grands est omniprésente dans le village piétonnier de Tremblant. Laissez-vous séduire par les amuseurs de rue, découvrez les espaces amusants pour grimper, glisser, se cacher, s'arroser, et plus encore!</p>
              <ul>
                <li class="regular-text"><span class="bold">Rencontrez Toufou</span><br>1 mai au 31 octobre 2022</li>
                <li class="regular-text"><span class="bold">Marc-Ambulles</span><br>1 mai au 31 octobre 2022</li>
              </ul>
              <p class="regular-text bold">Aires de jeux</p>
              <ul>
                <li class="regular-text"><span class="bold">Jeux d'eau</span><br>11 juin au 11 sept. 2022, 10 h à 18 h</li>
                <li class="regular-text"><span class="bold">Jeu de serpents et échelles géant</span><br>21 mai au 9 octobre 2022</li>
                <li class="regular-text"><span class="bold">Parc-pirate</span><br>Ouvert toute l'année</li>
                <li class="regular-text"><span class="bold">Parc du Vieux-Tremblant</span><br>Ouvert toute l'année</li>
              </ul>
              `,
      },
      {
        filter: 'Juin',
        img: '/vue-app-static-img/events-ete-2022/championnat-nbhpa.jpg',
        titre: 'Championnat NBHPA présenté par Burrows',
        date: 'Du 10 au 12 juin',
        data: `
              <h3 class="small-title">CHAMPIONNAT NBHPA PRÉSENTÉ PAR BURROWS</h3>
              <p class="regular-text bold">10 au 12 juin</p>
              <p class="regular-text">Le plus bel événement de deck hockey au Canada pour tous les passionnés de l’association NBHPA. Venez vivre un séjour inoubliable avec un tournoi d’envergure tout en étant dans un environnement de rêve au cœur du village piétonnier de Tremblant. La compétition sera féroce pour déterminer les vainqueurs de nos 17 catégories en format 3 vs 3 et 4 vs 4.</p>`,
      },
      {
        filter: 'Juin',
        img: '/vue-app-static-img/events-ete-2022/spartan-race.jpg',
        titre: 'Spartan Trifecta Week-end',
        date: 'Du 17 au 19 juin',
        data: `
              <h3 class="small-title">Spartan Trifecta Week-end</h3>
              <p class="regular-text bold">Du 17 au 19 juin</p>
              <p class="regular-text">Les 17, 18 et 19 juin prochains, mettez à l'épreuve votre résistance, votre force, votre endurance, votre vitesse de prise de décisions ainsi que votre capacité à vous moquer de l'adversité sur les parcours hors du commun des courses Spartan Race. Pour cette édition qui se tiendra au versant Nord, choisissez entre un parcours de 5 km, 10 km, 21 km et 50 km. Il y aura même une course pour enfants. Le concept de course à obstacles prend tout son sens! Vous l'aurez compris à la ligne d'arrivée…</p>`,
      },
      {
        filter: 'Juin',
        img: '/vue-app-static-img/events-ete-2022/sabaru-ironman-1.jpg',
        titre: 'Subaru Ironman 70.3 et 5I50 Mont-Tremblant',
        date: 'Du 25 au 26 juin',
        data: `
              <h3 class="small-title">SUBARU IRONMAN 70.3 ET 5I50 MONT-TREMBLANT</h3>
              <p class="regular-text bold">Du 25 au 26 juin</p>
              <p class="regular-text">Le samedi 25 juin, venez encourager les athlètes qui prendront le départ pour la distance olympique. La fin de semaine se poursuivra le dimanche 26 juin avec le départ du Subaru IRONMAN 70.3 Mont-Tremblant, bien connu sous le nom de demi-IRONMAN. Les athlètes se jetteront à l'eau depuis le Club plage et tennis, pour parcourir 1,9 km à la nage, 90 km en vélo et 21,1 km en course à pied avant de croiser le fil d'arrivée dans le village piétonnier.</p>`,
      },
      {
        filter: 'Juillet',
        img: '/vue-app-static-img/events-ete-2022/canada-day.jpg',
        titre: 'Fête du Canada',
        date: '1er juillet',
        data: `
              <h3 class="small-title">FÊTE DU CANADA</h3>
              <p class="regular-text bold">1er juillet</p>
              <p class="regular-text">Passez la journée en plein air, pour la fête du Canada.  De l’animation et plusieurs activités sont spécialement organisées en l'honneur de notre fête nationale : amuseurs publics, maquillage, levée du drapeau canadien, dégustation de gâteau, feux d’artifice et concert feront le plaisir de toute la famille.</p>`,
      },
      {
        filter: 'Juillet',
        img: '/vue-app-static-img/events-ete-2022/rythmes-tremblant.jpg',
        titre: 'Les Rythmes Tremblant',
        date: 'Du 8 au 10 juillet',
        data: `
              <h3 class="small-title">LES RYTHMES TREMBLANT</h3>
              <p class="regular-text bold">Du 8 au 10 juillet</p>
              <p class="regular-text">Les Rythmes Tremblant proposent 3 jours de musique avec des spectacles gratuits présentés en plein air par des artistes du Québec et d’autres provinces, le tout dans une atmosphère des plus festives et décontractées. En après-midi auront lieu des spectacles qui plairont aux petits comme aux grands. En soirée, des artistes vous offriront des spectacles avec un répertoire de chansons connues et de nouvelles découvertes.</p>`,
      },
      {
        filter: 'Juillet',
        img: '/vue-app-static-img/events-ete-2022/festival-blues.jpg',
        titre: 'Festival international du blues de Tremblant',
        date: 'Du 14 au 16 juillet',
        data: `
              <h3 class="small-title">FESTIVAL INTERNATIONAL DU BLUES DE TREMBLANT</h3>
              <p class="regular-text bold">Du 14 au 16 juillet</p>
              <p class="regular-text">Le Festival international du blues est de retour! De jour comme de soir, des rues pavées aux scènes en pleine nature, votre cœur battra la mesure à un tout nouveau tempo. Pendant 3 jours, profitez de nombreux concerts gratuits dans l’esprit envoûtant du village piétonnier. Vibrez au rythme de la destination Tremblant lors de cet événement où se côtoient la musique de rue et des activités familiales.</p>`,
      },
      {
        filter: 'Juillet,Août',
        img: '/vue-app-static-img/events-ete-2022/banzai.jpg',
        titre: 'BANZAÏ',
        date: 'Du 25 juillet au 10 août',
        data: `
              <h3 class="small-title">BANZAÏ</h3>
              <p class="regular-text bold">Du 25 juillet au 10 août</p>
              <p class="regular-text">Danseurs, comédiens et animateurs proposent de l’animation interactive pour petits et grands. C’est un rendez-vous à la Place des Voyageurs pour le départ de la parade! Au son de la musique, laissez-vous guider jusqu’à la scène de la place Saint-Bernard où animation, danse et jeux vous attendent.<br><br>25-26-28-29 juillet / 1-3-4-9-10 août :<br>19h00 - Animation<br>19h15 - Spectacle</p>`,
      },
      {
        filter: 'Juillet,Août',
        img: '/vue-app-static-img/events-ete-2022/soiree-corona.jpg',
        titre: 'Soirées d\'été Corona',
        date: 'Du 28 juillet au 25 août',
        data: `
              <h3 class="small-title">Soirées d'été Corona</h3>
              <p class="regular-text bold">Du 28 juillet au 25 août</p>
              <p class="regular-text">Tremblant et Corona vous invitent à venir profiter de l’été lors d’un 5 à 7 à la plage et tennis spécialement aménagé pour l’événement. Commencez votre 5 à 7 à la plage avec music ambiante. Par la suite, nous aurons droit à une performance musicale d'un artiste invité en fin d’après-midi. Nous finirons le tout autour d'un feu avec la musique acoustique. Vous y êtes attendus, beau temps, mauvais temps, les 28 juillet, 11 et 25 août!</p>`,
      },
      {
        filter: 'Août',
        img: '/vue-app-static-img/events-ete-2022/traversee-lac.jpg',
        titre: 'Traversée du lac Tremblant',
        date: 'Du 3 au 7 août',
        data: `
              <h3 class="small-title">Traversée du lac Tremblant</h3>
              <p class="regular-text bold">Du 3 au 7 août</p>
              <p class="regular-text">Cinq épreuves, un camp de nage, 1000 nageurs, 170 kayakistes et un magnifique lac. Quel que soit votre niveau, il y a une distance pour vous : 1km Desjardins, 3km, 5km, 12km en équipe et l’ultime traversée de 12km individuel. Le 5 km et le 12 km individuel de la Traversée du Lac Tremblant sont les épreuves officielles du Championnat nord-américain du Global Swim Series.</p>
              <p class="regular-text bold">NOUVEAUTÉS !</p>
              <p class="regular-text">Un camp de nage de 2 jours (3-4 août) et la Traversée du lac (12km) en équipe.</p>`,
      },
      {
        filter: 'Août',
        img: '/vue-app-static-img/events-ete-2022/demi-marathon.jpg',
        titre: 'Demi-marathon Mont-Tremblant',
        date: '14 août',
        data: `
              <h3 class="small-title">Demi-marathon Mont-Tremblant</h3>
              <p class="regular-text bold">14 août</p>
              <p class="regular-text">Courir à Mont-Tremblant, c’est la course à pied comme on l’aime ! Le Demi-Marathon Mont-Tremblant saura combler tant le coureur expérimenté que le coureur occasionnel en quête de défis. Parcours avec vues splendides de 21.1 km, 10 km, 5 km ainsi qu’une course de 1 km pour les enfants!</p>`,
      },
      {
        filter: 'Août',
        img: '/vue-app-static-img/events-ete-2022/sabaru-ironman-2.jpg',
        titre: 'Subaru Ironman Mont-Tremblant',
        date: '21 août',
        data: `
              <h3 class="small-title">Subaru Ironman Mont-Tremblant</h3>
              <p class="regular-text bold">21 août</p>
              <p class="regular-text">Tremblant, centre de villégiature quatre saisons de l'Est de l'Amérique du Nord, est un chef de file mondial lorsqu'il s'agit d'offrir des activités et des expériences qui dépassent les attentes. Tremblant a remporté 10 fois le prix IRONMAN Athlete Choice Award. Le parcours consiste en une nage de 3,8 km dans le pittoresque lac Tremblant, 180,2 km en vélo sur un parcours cycliste primé et une course à pied de 42,2 km à travers l’environnement splendide de la région de Mont-Tremblant. Le village piétonnier au cachet européen de Tremblant est un lieu vibrant avec de l'hébergement, de la restauration, du shopping et des spas afin de répondre aux besoins de chacun.</p>`,
      },
      {
        filter: 'Septembre',
        img: '/vue-app-static-img/events-ete-2022/fete-de-la-musique.jpg',
        titre: 'La fête de la musique de Tremblant avec Angèle Dubeau',
        date: '2 au 5 septembre',
        data: `
              <h3 class="small-title">LA FÊTE DE LA MUSIQUE DE TREMBLANT AVEC ANGÈLE DUBEAU</h3>
              <p class="regular-text bold">2 au 5 septembre</p>
              <p class="regular-text">Sous la direction artistique d'Angèle Dubeau, ce rendez-vous unique vous fera vivre une escapade musicale des plus festives qui célèbre la diversité musicale allant du classique au jazz, en passant par la musique du monde et bien plus encore. Cette programmation riche et de haut niveau propose des artistes de renom et des virtuoses à découvrir. Plus de 30 concerts gratuits sur les scènes du village piétonnier au pied de la montagne et des secteurs Village et centre-ville de la Ville de Mont-Tremblant.</p>`,
      },
      {
        filter: 'Septembre,Octobre',
        img: '/vue-app-static-img/events-ete-2022/autour-du-feu.jpg',
        titre: 'Autour du feu',
        date: '10 sept. au 10 oct.',
        data: `
              <h3 class="small-title">Autour du feu</h3>
              <p class="regular-text bold">10 sept. au 10 oct.</p>
              <p class="regular-text">Toutes les fins de semaines du 10 septembre au 10 octobre, c'est le moment de profiter de l'ambiance chaleureuse de l'automne à Tremblant. Après une randonnée ou une journée passée dans le village, prenez place sur une terrasse de l’un des restaurants et autour du foyer de la place Saint-Bernard et laissez-vous emporter par la musique. prenez place sur une terrasse de l’un des restaurants ou autour du foyer de la place Saint-Bernard et profitez de l'ambiance chaleureuse de l'automne à Tremblant.</p>`,
      },
      {
        filter: 'Octobre',
        img: '/vue-app-static-img/events-ete-2022/classique-salomon.jpg',
        titre: 'La Classique Salomon Tremblant',
        date: '22 octobre',
        data: `
              <h3 class="small-title">La Classique Salomon Tremblant</h3>
              <p class="regular-text bold">22 octobre</p>
              <p class="regular-text">Nous sommes heureux de vous accueillir pour une 8e édition de la Classique Salomon Tremblant. Vous serez en mesure de découvrir Tremblant grâce à un de nos parcours en forêt de 1, 3, 5, 10, 20 ou 30 kilomètres.</p>`,
      },
      {
        filter: 'Octobre',
        img: '/vue-app-static-img/events-ete-2022/halloween.jpg',
        titre: 'Halloween',
        date: '30 octobre',
        data: `
              <h3 class="small-title">Halloween</h3>
              <p class="regular-text bold">30 octobre</p>
              <p class="regular-text">Cette année, pour l’Halloween, Tremblant se transforme en un univers extraordinaire avec une atmosphère bien spéciale. Animations aussi terrifiantes qu’amusantes se côtoient le temps d’une récolte de bonbons. Revêtez votre costume et visitez chacune des stations où se déploient de l’animation et des activités enlevantes. Terminez votre escapade avec danse animée qui fera résonner une musique endiablée pour toute la famille.</p>`,
      },
    ],
  },
  famille_ete_2022: {
    calendar_filters: ['Tous', 'Août', 'Septembre', 'Octobre'],
    calendar_events: [
      {
        filter: 'Juin,Juillet,Août,Septembre,Octobre',
        img: '/vue-app-static-img/events-ete-2022/amuseurs.jpg',
        titre: 'Animation et aires de jeux',
        date: 'Été 2022',
        data: `
              <h3 class="small-title">Animation et aires de jeux</h3>
              <p class="regular-text bold">Découvrez les amuseurs de rue et espaces amusants dans le village piétonnier</p>
              <p class="regular-text">L'animation pour les petits et les grands est omniprésente dans le village piétonnier de Tremblant. Laissez-vous séduire par les amuseurs de rue, découvrez les espaces amusants pour grimper, glisser, se cacher, s'arroser, et plus encore!</p>
              <ul>
                <li class="regular-text"><span class="bold">Rencontrez Toufou</span><br>1 mai au 31 octobre 2022</li>
                <li class="regular-text"><span class="bold">Marc-Ambulles</span><br>1 mai au 31 octobre 2022</li>
                <li class="regular-text"><span class="bold">Les Baronnes Bleues</span><br>2, 31 juillet et 7 août</li>
                <li class="regular-text"><span class="bold">Fleurs géantes</span><br>25 juin, 3 juillet et 28 août</li>
                <li class="regular-text"><span class="bold">Les Femmes Fleurs</span><br>23 juillet et 5, 25 août</li>
                <li class="regular-text"><span class="bold">Les bulles géantes</span><br>24, 30 juillet et 24 août</li>
                <li class="regular-text"><span class="bold">Hula Hoops</span><br>22, 27 juillet et 6, 27 août</li>
                <li class="regular-text"><span class="bold">Ébouriffées</span><br>2 août</li>
                <li class="regular-text"><span class="bold">Maquillage</span><br>11 août</li>
                <li class="regular-text"><span class="bold">Bleu Pastel</span><br>15 aout 2022</li>
              </ul>
              <p class="regular-text bold">Aires de jeux</p>
              <ul>
                <li class="regular-text"><span class="bold">Jeux d'eau</span><br>11 juin au 11 sept. 2022, 10 h à 18 h</li>
                <li class="regular-text"><span class="bold">Jeu de serpents et échelles géant</span><br>21 mai au 9 octobre 2022</li>
                <li class="regular-text"><span class="bold">Parc-pirate</span><br>Ouvert toute l'année</li>
                <li class="regular-text"><span class="bold">Parc du Vieux-Tremblant</span><br>Ouvert toute l'année</li>
              </ul>
              `,
      },
      {
        filter: 'Juin',
        img: '/vue-app-static-img/events-ete-2022/championnat-nbhpa.jpg',
        titre: 'Championnat NBHPA présenté par Burrows',
        date: 'Du 10 au 12 juin',
        data: `
              <h3 class="small-title">CHAMPIONNAT NBHPA PRÉSENTÉ PAR BURROWS</h3>
              <p class="regular-text bold">10 au 12 juin</p>
              <p class="regular-text">Le plus bel événement de deck hockey au Canada pour tous les passionnés de l’association NBHPA. Venez vivre un séjour inoubliable avec un tournoi d’envergure tout en étant dans un environnement de rêve au cœur du village piétonnier de Tremblant. La compétition sera féroce pour déterminer les vainqueurs de nos 17 catégories en format 3 vs 3 et 4 vs 4.</p>`,
      },
      {
        filter: 'Juin',
        img: '/vue-app-static-img/events-ete-2022/spartan-race.jpg',
        titre: 'Spartan Trifecta Week-end',
        date: 'Du 17 au 19 juin',
        data: `
              <h3 class="small-title">Spartan Trifecta Week-end</h3>
              <p class="regular-text bold">Du 17 au 19 juin</p>
              <p class="regular-text">Les 17, 18 et 19 juin prochains, mettez à l'épreuve votre résistance, votre force, votre endurance, votre vitesse de prise de décisions ainsi que votre capacité à vous moquer de l'adversité sur les parcours hors du commun des courses Spartan Race. Pour cette édition qui se tiendra au versant Nord, choisissez entre un parcours de 5 km, 10 km, 21 km et 50 km. Il y aura même une course pour enfants. Le concept de course à obstacles prend tout son sens! Vous l'aurez compris à la ligne d'arrivée…</p>`,
      },
      {
        filter: 'Juin',
        img: '/vue-app-static-img/events-ete-2022/sabaru-ironman-1.jpg',
        titre: 'Subaru Ironman 70.3 et 5I50 Mont-Tremblant',
        date: 'Du 25 au 26 juin',
        data: `
              <h3 class="small-title">SUBARU IRONMAN 70.3 ET 5I50 MONT-TREMBLANT</h3>
              <p class="regular-text bold">Du 25 au 26 juin</p>
              <p class="regular-text">Le samedi 25 juin, venez encourager les athlètes qui prendront le départ pour la distance olympique. La fin de semaine se poursuivra le dimanche 26 juin avec le départ du Subaru IRONMAN 70.3 Mont-Tremblant, bien connu sous le nom de demi-IRONMAN. Les athlètes se jetteront à l'eau depuis le Club plage et tennis, pour parcourir 1,9 km à la nage, 90 km en vélo et 21,1 km en course à pied avant de croiser le fil d'arrivée dans le village piétonnier.</p>`,
      },
      {
        filter: 'Juillet',
        img: '/vue-app-static-img/events-ete-2022/canada-day.jpg',
        titre: 'Fête du Canada',
        date: '1er juillet',
        data: `
              <h3 class="small-title">FÊTE DU CANADA</h3>
              <p class="regular-text bold">1er juillet</p>
              <p class="regular-text">Passez la journée en plein air, pour la fête du Canada.  De l’animation et plusieurs activités sont spécialement organisées en l'honneur de notre fête nationale : amuseurs publics, maquillage, levée du drapeau canadien, dégustation de gâteau, feux d’artifice et concert feront le plaisir de toute la famille.</p>`,
      },
      {
        filter: 'Juillet',
        img: '/vue-app-static-img/events-ete-2022/rythmes-tremblant.jpg',
        titre: 'Les Rythmes Tremblant',
        date: 'Du 8 au 10 juillet',
        data: `
              <h3 class="small-title">LES RYTHMES TREMBLANT</h3>
              <p class="regular-text bold">Du 8 au 10 juillet</p>
              <p class="regular-text">Les Rythmes Tremblant proposent 3 jours de musique avec des spectacles gratuits présentés en plein air par des artistes du Québec et d’autres provinces, le tout dans une atmosphère des plus festives et décontractées. En après-midi auront lieu des spectacles qui plairont aux petits comme aux grands. En soirée, des artistes vous offriront des spectacles avec un répertoire de chansons connues et de nouvelles découvertes.</p>`,
      },
      {
        filter: 'Juillet',
        img: '/vue-app-static-img/events-ete-2022/festival-blues.jpg',      
        titre: 'Festival international du blues de Tremblant',
        date: 'Du 14 au 16 juillet',
        data: `
              <h3 class="small-title">FESTIVAL INTERNATIONAL DU BLUES DE TREMBLANT</h3>
              <p class="regular-text bold">Du 14 au 16 juillet</p>
              <p class="regular-text">Le Festival international du blues est de retour! De jour comme de soir, des rues pavées aux scènes en pleine nature, votre cœur battra la mesure à un tout nouveau tempo. Pendant 3 jours, profitez de nombreux concerts gratuits dans l’esprit envoûtant du village piétonnier. Vibrez au rythme de la destination Tremblant lors de cet événement où se côtoient la musique de rue et des activités familiales.</p>`,
      },
      {
        filter: 'Juillet,Août',
        img: '/vue-app-static-img/events-ete-2022/banzai.jpg',
        titre: 'BANZAÏ',
        date: 'Du 25 juillet au 10 août',
        data: `
              <h3 class="small-title">BANZAÏ</h3>
              <p class="regular-text bold">Du 25 juillet au 10 août</p>
              <p class="regular-text">Danseurs, comédiens et animateurs proposent de l’animation interactive pour petits et grands. C’est un rendez-vous à la Place des Voyageurs pour le départ de la parade! Au son de la musique, laissez-vous guider jusqu’à la scène de la place Saint-Bernard où animation, danse et jeux vous attendent.<br><br>25-26-28-29 juillet / 1-3-4-9-10 août :<br>19h00 - Animation<br>19h15 - Spectacle</p>`,
      },
      {
        filter: 'Juillet,Août',
        img: '/vue-app-static-img/events-ete-2022/soiree-corona.jpg',
        titre: 'Soirées d\'été Corona',
        date: 'Du 28 juillet au 25 août',
        data: `
              <h3 class="small-title">Soirées d'été Corona</h3>
              <p class="regular-text bold">Du 28 juillet au 25 août</p>
              <p class="regular-text">Tremblant et Corona vous invitent à venir profiter de l’été lors d’un 5 à 7 à la plage et tennis spécialement aménagé pour l’événement. Commencez votre 5 à 7 à la plage avec music ambiante. Par la suite, nous aurons droit à une performance musicale d'un artiste invité en fin d’après-midi. Nous finirons le tout autour d'un feu avec la musique acoustique. Vous y êtes attendus, beau temps, mauvais temps, les 28 juillet, 11 et 25 août!</p>`,
      },
      {
        filter: 'Août',
        img: '/vue-app-static-img/events-ete-2022/traversee-lac.jpg',
        titre: 'Traversée du lac Tremblant',
        date: 'Du 3 au 7 août',
        data: `
              <h3 class="small-title">Traversée du lac Tremblant</h3>
              <p class="regular-text bold">Du 3 au 7 août</p>
              <p class="regular-text">Cinq épreuves, un camp de nage, 1000 nageurs, 170 kayakistes et un magnifique lac. Quel que soit votre niveau, il y a une distance pour vous : 1km Desjardins, 3km, 5km, 12km en équipe et l’ultime traversée de 12km individuel. Le 5 km et le 12 km individuel de la Traversée du Lac Tremblant sont les épreuves officielles du Championnat nord-américain du Global Swim Series.</p>
              <p class="regular-text bold">NOUVEAUTÉS !</p>
              <p class="regular-text">Un camp de nage de 2 jours (3-4 août) et la Traversée du lac (12km) en équipe.</p>`,
      },
      {
        filter: 'Août',
        img: '/vue-app-static-img/events-ete-2022/demi-marathon.jpg',     
        titre: 'Demi-marathon Mont-Tremblant',
        date: '14 août',
        data: `
              <h3 class="small-title">Demi-marathon Mont-Tremblant</h3>
              <p class="regular-text bold">14 août</p>
              <p class="regular-text">Courir à Mont-Tremblant, c’est la course à pied comme on l’aime ! Le Demi-Marathon Mont-Tremblant saura combler tant le coureur expérimenté que le coureur occasionnel en quête de défis. Parcours avec vues splendides de 21.1 km, 10 km, 5 km ainsi qu’une course de 1 km pour les enfants!</p>`,
      },
      {
        filter: 'Août',
        img: '/vue-app-static-img/events-ete-2022/sabaru-ironman-2.jpg',
        titre: 'Subaru Ironman Mont-Tremblant',
        date: '21 août',
        data: `
              <h3 class="small-title">Subaru Ironman Mont-Tremblant</h3>
              <p class="regular-text bold">21 août</p>
              <p class="regular-text">Tremblant, centre de villégiature quatre saisons de l'Est de l'Amérique du Nord, est un chef de file mondial lorsqu'il s'agit d'offrir des activités et des expériences qui dépassent les attentes. Tremblant a remporté 10 fois le prix IRONMAN Athlete Choice Award. Le parcours consiste en une nage de 3,8 km dans le pittoresque lac Tremblant, 180,2 km en vélo sur un parcours cycliste primé et une course à pied de 42,2 km à travers l’environnement splendide de la région de Mont-Tremblant. Le village piétonnier au cachet européen de Tremblant est un lieu vibrant avec de l'hébergement, de la restauration, du shopping et des spas afin de répondre aux besoins de chacun.</p>`,
      },
      {
        filter: 'Septembre',
        img: '/vue-app-static-img/events-ete-2022/fete-de-la-musique.jpg',
        titre: 'La fête de la musique de Tremblant avec Angèle Dubeau',
        date: '2 au 5 septembre',
        data: `
              <h3 class="small-title">LA FÊTE DE LA MUSIQUE DE TREMBLANT AVEC ANGÈLE DUBEAU</h3>
              <p class="regular-text bold">2 au 5 septembre</p>
              <p class="regular-text">Sous la direction artistique d'Angèle Dubeau, ce rendez-vous unique vous fera vivre une escapade musicale des plus festives qui célèbre la diversité musicale allant du classique au jazz, en passant par la musique du monde et bien plus encore. Cette programmation riche et de haut niveau propose des artistes de renom et des virtuoses à découvrir. Plus de 30 concerts gratuits sur les scènes du village piétonnier au pied de la montagne et des secteurs Village et centre-ville de la Ville de Mont-Tremblant.</p>`,
      },
      {
        filter: 'Septembre,Octobre',
        img: '/vue-app-static-img/events-ete-2022/autour-du-feu.jpg',
        titre: 'Autour du feu',
        date: '10 sept. au 10 oct.',
        data: `
              <h3 class="small-title">Autour du feu</h3>
              <p class="regular-text bold">10 sept. au 10 oct.</p>
              <p class="regular-text">Toutes les fins de semaines du 10 septembre au 10 octobre, c'est le moment de profiter de l'ambiance chaleureuse de l'automne à Tremblant. Après une randonnée ou une journée passée dans le village, prenez place sur une terrasse de l’un des restaurants et autour du foyer de la place Saint-Bernard et laissez-vous emporter par la musique. prenez place sur une terrasse de l’un des restaurants ou autour du foyer de la place Saint-Bernard et profitez de l'ambiance chaleureuse de l'automne à Tremblant.</p>`,
      },
      {
        filter: 'Octobre',
        img: '/vue-app-static-img/events-ete-2022/halloween.jpg',
        titre: 'Halloween',
        date: '30 octobre',
        data: `
              <h3 class="small-title">Halloween</h3>
              <p class="regular-text bold">30 octobre</p>
              <p class="regular-text">Cette année, pour l’Halloween, Tremblant se transforme en un univers extraordinaire avec une atmosphère bien spéciale. Animations aussi terrifiantes qu’amusantes se côtoient le temps d’une récolte de bonbons. Revêtez votre costume et visitez chacune des stations où se déploient de l’animation et des activités enlevantes. Terminez votre escapade avec danse animée qui fera résonner une musique endiablée pour toute la famille.</p>`,
      },
    ],
  },
  dink_ete_2022_experiences: [
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-events.jpg',
      number: '62',
      titre: 'JOURS D\'ÉVÉNEMENTS<br>ET DE DIVERTISSEMENT',
      data: `
            <h3 class="small-title">62 JOURS D'ÉVÉNEMENTS ET DE DIVERTISSEMENT TOUT AU LONG DE LA SAISON</h3>
            <p class="regular-text">Restez à l’affût des prochains événements musicaux, mieux-être et sportifs pour agrémenter votre séjour. Une foule de concerts gratuits sont présentés tout au long de la saison.</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-bars.jpg',
      number: '36',
      titre: 'Restaurants,<br />bars et cafés',
      data: `
            <h3 class="small-title">36 restaurants, bars et cafés</h3>
            <p class="regular-text">Peu importe votre envie, celle de votre famille ou de vos amis, vous trouverez le restaurant qui vous comblera. Il ne restera plus qu’à s'entendre sur le type de cuisine : française, italienne, asiatique, ou plus encore!</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-activities.jpg',
      number: '44',
      titre: 'FAÇONS<br>DE S\'AMUSER',
      data: `
            <h3 class="small-title">44 FAÇONS DE S'AMUSER : des activités sensationnelles pour tous !</h3>
            <p class="regular-text">Sur un terrain de jeu grandeur nature, Tremblant offre une panoplie d'activités pour tous les goûts et toute la famille.</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-lodging.jpg',
      number: '1900',
      titre: 'unités<br />d’hébergement',
      data: `
            <h3 class="small-title">1900 unités d'hébergement</h3>
            <p class="regular-text">Ressourcez-vous dans un de nos luxueux hôtels au pied des pentes ou un splendide condo à flanc de montagne ou sur les golfs.</p>
      `,
    },
  ],
  general_ete_2022_experiences: [
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-events-3.jpg',
      number: '62',
      titre: 'JOURS D\'ÉVÉNEMENTS<br>ET DE DIVERTISSEMENT<br>durant la saison',
      data: `
            <h3 class="small-title">62 JOURS D'ÉVÉNEMENTS ET DE DIVERTISSEMENT TOUT AU LONG DE LA SAISON</h3>
            <p class="regular-text">Restez à l’affût des prochains événements musicaux, mieux-être et sportifs pour agrémenter votre séjour. Une foule de concerts gratuits sont présentés tout au long de la saison.</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-bars-famille-3.jpg',
      number: '36',
      titre: 'Restaurants,<br />bars et cafés',
      data: `
            <h3 class="small-title">36 restaurants, bars et cafés</h3>
            <p class="regular-text">Peu importe votre envie, celle de votre famille ou de vos amis, vous trouverez le restaurant qui vous comblera. Il ne restera plus qu’à s'entendre sur le type de cuisine : française, italienne, asiatique, ou plus encore!</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-activities-3.jpg',
      number: '44',
      titre: 'FAÇONS<br>DE S\'AMUSER',
      data: `
            <h3 class="small-title">44 FAÇONS DE S'AMUSER : des activités sensationnelles pour tous !</h3>
            <p class="regular-text">Sur un terrain de jeu grandeur nature, Tremblant offre une panoplie d'activités pour tous les goûts et toute la famille.</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-lodging-3.jpg',
      number: '1900',
      titre: 'unités<br />d’hébergement',
      data: `
            <h3 class="small-title">1900 unités d'hébergement</h3>
            <p class="regular-text">Ressourcez-vous dans un de nos luxueux hôtels au pied des pentes ou un splendide condo à flanc de montagne ou sur les golfs.</p>
      `,
    },
  ],
  famille_ete_2022_experiences: [
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-events-famille.jpg',
      number: '62',
      titre: 'JOURS D\'ÉVÉNEMENTS<br>ET DE DIVERTISSEMENT',
      data: `
            <h3 class="small-title">62 jours d'événements et d'animations</h3>
            <p class="regular-text">Restez à l’affût des prochains événements musicaux, mieux-être et sportifs pour agrémenter votre séjour. Une foule de concerts gratuits sont présentés tout au long de la saison.</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-bars-famille.jpg',
      number: '36',
      titre: 'Restaurants,<br />bars et cafés',
      data: `
            <h3 class="small-title">36 restaurants, bars et cafés</h3>
            <p class="regular-text">Peu importe votre envie, celle de votre famille ou de vos amis, vous trouverez le restaurant qui vous comblera. Il ne restera plus qu’à s'entendre sur le type de cuisine : française, italienne, asiatique, ou plus encore!</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-activities-famille.jpg',
      number: '44',
      titre: 'FAÇONS<br>DE S\'AMUSER!',
      data: `
            <h3 class="small-title">44 FAÇONS DE S'AMUSER : des activités sensationnelles pour tous !</h3>
            <p class="regular-text">Sur un terrain de jeu grandeur nature, Tremblant offre une panoplie d'activités pour tous les goûts et toute la famille.</p>
      `,
    },
    {
      img: '/vue-app-static-img/quoi-faire-ete-2022/quoi-faire-lodging.jpg',
      number: '1900',
      titre: 'unités<br />d’hébergement',
      data: `
            <h3 class="small-title">1900 unités d'hébergement</h3>
            <p class="regular-text">Ressourcez-vous dans un de nos luxueux hôtels au pied des pentes ou un splendide condo à flanc de montagne ou sur les golfs.</p>
      `,
    },
  ],
  dink_ete_2022_activity: [
    {
      video: '',
      img: '/vue-app-static-img/activity-ete-2022/tonga-dink.jpg',
      titre: `
            <p class="big">Tonga</p>
            <p class="small-title">Lumina</p>
      `,
      data: `<h3 class="small-title">Tonga Lumina - UN PARCOURS NOCTURNE SUR LES TRACES DU GÉANT</h3>
              <p class="regular-text">Depuis le mont Tremblant s’élève un chuchotement ; le dernier des géants se réveille. Laissez-vous porter par votre âme d’aventurier et découvrez le secret qu’il veut vous confier. Tonga Lumina est une expérience sensorielle en forêt signée Moment Factory. Cette marche nocturne de 1,5 km se déroule entièrement en nature, sur un sentier illuminé et en poussière de roche.</p>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=A2GAU1SYFLQ',
      img: '/vue-app-static-img/activity-ete-2022/golf.jpg',
      titre: `
            <p class="big">Golf</p>
            <p class="small-title">Tremblant</p>
      `,
      data: `<h3 class="small-title">Golf Tremblant</h3>
              <p class="regular-text">Venez jouer sur les golfs Le Diable et Le Géant et profitez de leurs plateaux spectaculaires, magnifiques verts et vues à couper le souffle. Faites de vos vacances de golf de rêves une réalité!</p>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=CiiYvUGLoTs',
      img: '/vue-app-static-img/activity-ete-2022/ziptrek.jpg',
      titre: `
            <p class="big">Ziptrek</p>
            <p class="small-title">Ecotours</p>
      `,
      data: `<h3 class="small-title">Ziptrek Ecotours - DU PLAISIR ET DES SENSATIONS FORTES POUR TOUS</h3>
              <p class="regular-text">Lancez-vous en toute confiance dans une aventure aérienne originale qui combine vitesse et émerveillement, tout en offrant des vues spectaculaires sur les Laurentides! Planez au-dessus des arbres avec l’un des parcours de tyroliennes les plus spectaculaires au monde! Une aventure inoubliable qui vous offre la chance de dévaler le mont Tremblant du sommet jusqu’à son village piétonnier grâce aux cinq tyroliennes qui s’étendent sur près de 4 kilomètres.</p>
      `,
    },
  ],
  general_ete_2022_activity: [
    {
      video: '',
      img: '/vue-app-static-img/activity-ete-2022/tonga-famille.jpg',
      titre: `
            <p class="big">Tonga</p>
            <p class="small-title">Lumina</p>
      `,
      data: `<h3 class="small-title">Tonga Lumina - UN PARCOURS NOCTURNE SUR LES TRACES DU GÉANT</h3>
              <p class="regular-text">Depuis le mont Tremblant s’élève un chuchotement ; le dernier des géants se réveille. Laissez-vous porter par votre âme d’aventurier et découvrez le secret qu’il veut vous confier. Tonga Lumina est une expérience sensorielle en forêt signée Moment Factory. Cette marche nocturne de 1,5 km se déroule entièrement en nature, sur un sentier illuminé et en poussière de roche.</p>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=A2GAU1SYFLQ',
      img: '/vue-app-static-img/activity-ete-2022/golf-automne.jpg',
      titre: `
            <p class="big">Golf</p>
            <p class="small-title">Tremblant</p>
      `,
      data: `<h3 class="small-title">Golf Tremblant</h3>
              <p class="regular-text">Venez jouer sur les golfs Le Diable et Le Géant et profitez de leurs plateaux spectaculaires, magnifiques verts et vues à couper le souffle. Faites de vos vacances de golf de rêves une réalité!</p>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=CiiYvUGLoTs',
      img: '/vue-app-static-img/activity-ete-2022/ziptrek-automne.jpg',
      titre: `
            <p class="big">Ziptrek</p>
            <p class="small-title">Ecotours</p>
      `,
      data: `<h3 class="small-title">Ziptrek Ecotours - DU PLAISIR ET DES SENSATIONS FORTES POUR TOUS</h3>
              <p class="regular-text">Lancez-vous en toute confiance dans une aventure aérienne originale qui combine vitesse et émerveillement, tout en offrant des vues spectaculaires sur les Laurentides! Planez au-dessus des arbres avec l’un des parcours de tyroliennes les plus spectaculaires au monde! Une aventure inoubliable qui vous offre la chance de dévaler le mont Tremblant du sommet jusqu’à son village piétonnier grâce aux cinq tyroliennes qui s’étendent sur près de 4 kilomètres.</p>
      `,
    },
  ],
  famille_ete_2022_activity: [
    {
      video: '',
      img: '/vue-app-static-img/activity-ete-2022/tonga-famille.jpg',
      titre: `
            <p class="big">Tonga</p>
            <p class="small-title">Lumina</p>
      `,
      data: `<h3 class="small-title">Tonga Lumina - UN PARCOURS NOCTURNE SUR LES TRACES DU GÉANT</h3>
              <p class="regular-text">Depuis le mont Tremblant s’élève un chuchotement ; le dernier des géants se réveille. Laissez-vous porter par votre âme d’aventurier et découvrez le secret qu’il veut vous confier. Tonga Lumina est une expérience sensorielle en forêt signée Moment Factory. Cette marche nocturne de 1,5 km se déroule entièrement en nature, sur un sentier illuminé et en poussière de roche.</p>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=tfMGQojD6uY',
      img: '/vue-app-static-img/activity-ete-2022/brindo.jpg',
      titre: `
            <p class="big">Brind'O</p>
            <p class="small-title">Aquaclub</p>
      `,
      data: `<h3 class="small-title">Brind'O Aquaclub</h3>
              <p class="regular-text">Venez vivre la réalité du monde de Brind’O Aquaclub au cœur du Centre de villégiature. Avec ses 4 glissades, ses 35 jeux d’eau interactifs, sa salle d’entraînement modernisée et son bain à remous, le nouveau centre familial de jeux aquatiques devient un incontournable, beau temps, mauvais temps.</p>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=CiiYvUGLoTs',
      img: '/vue-app-static-img/activity-ete-2022/ziptrek-famille.jpg',
      titre: `
            <p class="big">Ziptrek</p>
            <p class="small-title">Ecotours</p>
      `,
      data: `<h3 class="small-title">Ziptrek Ecotours - DU PLAISIR ET DES SENSATIONS FORTES POUR TOUS</h3>
              <p class="regular-text">Lancez-vous en toute confiance dans une aventure aérienne originale qui combine vitesse et émerveillement, tout en offrant des vues spectaculaires sur les Laurentides! Planez au-dessus des arbres avec l’un des parcours de tyroliennes les plus spectaculaires au monde! Une aventure inoubliable qui vous offre la chance de dévaler le mont Tremblant du sommet jusqu’à son village piétonnier grâce aux cinq tyroliennes qui s’étendent sur près de 4 kilomètres.</p>
      `,
    },
  ],
  dink_ete_2022_secteurs: [
    {
      video: 'https://www.youtube.com/watch?v=AvXvPGsg7VE',
      img: '/vue-app-static-img/secteurs-ete-2022/montagne-dink.jpg',
      titre: 'Montagne',
      data: `<h3 class="small-title">Montagne</h3>
              <p class="regular-text">Appréciez la destination d'un autre point de vue avec nos activités en altitude! Allant des sensations fortes, aux randonnées relaxante, découvrez un paysage qui vous coupera le souffle.</p>
              <ul>
                <li class="regular-text">Apprenti fauconnier</li>
                <li class="regular-text">Centre d'Activités Tremblant</li>
                <li class="regular-text">Multi-activités</li>
                <li class="regular-text">Randonnée pédestre</li>
                <li class="regular-text">Spectacle d'oiseaux de proie</li>
                <li class="regular-text">Télécabine panoramique</li>
                <li class="regular-text">Tonga Lumina</li>
                <li class="regular-text">Trio Lunch</li>
                <li class="regular-text">Ziptrek Écotours</li>
              </ul>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=8fAUeDGcSss',
      img: '/vue-app-static-img/secteurs-ete-2022/lac-dink.jpg',
      titre: 'Lac',
      data: `<h3 class="small-title">Lac</h3>
              <p class="regular-text">Profitez des joies de l'été à Tremblant! Une vue imprenable sur le mont Tremblant attend petits et grands qui viendront profiter de multiples activités offertes aux vacanciers sur la plage et sur le lac.</p>
              <ul>
                <li class="regular-text">Camp de jour nautique</li>
                <li class="regular-text">Canot, kayak et planche à rame</li>
                <li class="regular-text">Centre d'Activités Tremblant</li>
                <li class="regular-text">Centre Nautique Pierre Plouffe</li>
                <li class="regular-text">Club plage et tennis</li>
                <li class="regular-text">Croisières sur le lac Tremblant</li>
                <li class="regular-text">Iceberg</li>
                <li class="regular-text">Location de bateaux</li>
                <li class="regular-text">Multi-activités</li>
                <li class="regular-text">Parc Plage</li>
                <li class="regular-text">Pêche</li>
                <li class="regular-text">Sports nautiques</li>
                <li class="regular-text">Trio Lunch</li>
              </ul>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=E8vqQ77xVAE',
      img: '/vue-app-static-img/secteurs-ete-2022/village-dink.jpg',
      titre: 'Village',
      sub_title: 'Piétonnier',
      data: `<h3 class="small-title">Village piétonnier</h3>
              <p class="regular-text">Sillonnez le pittoresque village au pied du mont Tremblant pour faire de belles découvertes. Vous y trouverez tout ce dont vous rêvez pour le plein-air ou une sortie branchée.</p>
              <ul>
                <li class="regular-text">Animation et aires de jeux</li>
                <li class="regular-text">Arcade Réalité Virtuelle Tremblant</li>
                <li class="regular-text">Brind'O Aquaclub</li>
                <li class="regular-text">Casino de Mont-Tremblant</li>
                <li class="regular-text">Centre d'Activités Tremblant</li>
                <li class="regular-text">Escalade</li>
                <li class="regular-text">Eurobungy</li>
                <li class="regular-text">Le Port du capitaine</li>
                <li class="regular-text">Luge</li>
                <li class="regular-text">Mini-golf Le Petit Géant</li>
                <li class="regular-text">Mission Laser</li>
                <li class="regular-text">Mission Liberté</li>
                <li class="regular-text">Multi-activités</li>
                <li class="regular-text">Salle d'entrainement Brind'O</li>
                <li class="regular-text">Spas et massages</li>
                <li class="regular-text">Studio Créatif</li>
                <li class="regular-text">Tour d'escalade</li>
                <li class="regular-text">Univers Toutou</li>
                <li class="regular-text">Vélo</li>
              </ul>
      `,
    },
  ],
  general_ete_2022_secteurs: [
    {
      video: 'https://www.youtube.com/watch?v=AvXvPGsg7VE',
      img: '/vue-app-static-img/activity-ete-2022/montagne-automne.jpg',
      titre: 'Montagne',
      data: `<h3 class="small-title">Montagne</h3>
              <p class="regular-text">Appréciez la destination d'un autre point de vue avec nos activités en altitude! Allant des sensations fortes, aux randonnées relaxante, découvrez un paysage qui vous coupera le souffle.</p>
              <ul>
                <li class="regular-text">Apprenti fauconnier</li>
                <li class="regular-text">Centre d'Activités Tremblant</li>
                <li class="regular-text">Multi-activités</li>
                <li class="regular-text">Randonnée pédestre</li>
                <li class="regular-text">Spectacle d'oiseaux de proie</li>
                <li class="regular-text">Télécabine panoramique</li>
                <li class="regular-text">Tonga Lumina</li>
                <li class="regular-text">Trio Lunch</li>
                <li class="regular-text">Ziptrek Écotours</li>
              </ul>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=8fAUeDGcSss',
      img: '/vue-app-static-img/activity-ete-2022/lac-automne.jpg',
      titre: 'Lac',
      data: `<h3 class="small-title">Lac</h3>
              <p class="regular-text">Profitez des joies de l'été à Tremblant! Une vue imprenable sur le mont Tremblant attend petits et grands qui viendront profiter de multiples activités offertes aux vacanciers sur la plage et sur le lac.</p>
              <ul>
                <li class="regular-text">Camp de jour nautique</li>
                <li class="regular-text">Canot, kayak et planche à rame</li>
                <li class="regular-text">Centre d'Activités Tremblant</li>
                <li class="regular-text">Centre Nautique Pierre Plouffe</li>
                <li class="regular-text">Club plage et tennis</li>
                <li class="regular-text">Croisières sur le lac Tremblant</li>
                <li class="regular-text">Iceberg</li>
                <li class="regular-text">Location de bateaux</li>
                <li class="regular-text">Multi-activités</li>
                <li class="regular-text">Parc Plage</li>
                <li class="regular-text">Pêche</li>
                <li class="regular-text">Sports nautiques</li>
                <li class="regular-text">Trio Lunch</li>
              </ul>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=E8vqQ77xVAE',
      img: '/vue-app-static-img/activity-ete-2022/village-automne.jpg',
      titre: 'Village',
      sub_title: 'Piétonnier',
      data: `<h3 class="small-title">Village piétonnier</h3>
              <p class="regular-text">Sillonnez le pittoresque village au pied du mont Tremblant pour faire de belles découvertes. Vous y trouverez tout ce dont vous rêvez pour le plein-air ou une sortie branchée.</p>
              <ul>
                <li class="regular-text">Animation et aires de jeux</li>
                <li class="regular-text">Arcade Réalité Virtuelle Tremblant</li>
                <li class="regular-text">Brind'O Aquaclub</li>
                <li class="regular-text">Casino de Mont-Tremblant</li>
                <li class="regular-text">Centre d'Activités Tremblant</li>
                <li class="regular-text">Escalade</li>
                <li class="regular-text">Eurobungy</li>
                <li class="regular-text">Le Port du capitaine</li>
                <li class="regular-text">Luge</li>
                <li class="regular-text">Mini-golf Le Petit Géant</li>
                <li class="regular-text">Mission Laser</li>
                <li class="regular-text">Mission Liberté</li>
                <li class="regular-text">Multi-activités</li>
                <li class="regular-text">Salle d'entrainement Brind'O</li>
                <li class="regular-text">Spas et massages</li>
                <li class="regular-text">Studio Créatif</li>
                <li class="regular-text">Tour d'escalade</li>
                <li class="regular-text">Univers Toutou</li>
                <li class="regular-text">Vélo</li>
              </ul>
      `,
    },
  ],
  famille_ete_2022_secteurs: [
    {
      video: 'https://www.youtube.com/watch?v=ierb2bG_Dy8',
      img: '/vue-app-static-img/secteurs-ete-2022/montagne-famille.jpg',
      titre: 'Montagne',
      data: `<h3 class="small-title">Montagne</h3>
              <p class="regular-text">Appréciez la destination d'un autre point de vue avec nos activités en altitude! Allant des sensations fortes, aux randonnées relaxante, découvrez un paysage qui vous coupera le souffle.</p>
              <ul>
                <li class="regular-text">Apprenti fauconnier</li>
                <li class="regular-text">Centre d'Activités Tremblant</li>
                <li class="regular-text">Multi-activités</li>
                <li class="regular-text">Randonnée pédestre</li>
                <li class="regular-text">Spectacle d'oiseaux de proie</li>
                <li class="regular-text">Télécabine panoramique</li>
                <li class="regular-text">Tonga Lumina</li>
                <li class="regular-text">Trio Lunch</li>
                <li class="regular-text">Ziptrek Écotours</li>
              </ul>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=DEiK8VtSWFI',
      img: '/vue-app-static-img/secteurs-ete-2022/lac-famille.jpg',
      titre: 'Lac',
      data: `<h3 class="small-title">Lac</h3>
              <p class="regular-text">Profitez des joies de l'été à Tremblant! Une vue imprenable sur le mont Tremblant attend petits et grands qui viendront profiter de multiples activités offertes aux vacanciers sur la plage et sur le lac.</p>
              <ul>
                <li class="regular-text">Camp de jour nautique</li>
                <li class="regular-text">Canot, kayak et planche à rame</li>
                <li class="regular-text">Centre d'Activités Tremblant</li>
                <li class="regular-text">Centre Nautique Pierre Plouffe</li>
                <li class="regular-text">Club plage et tennis</li>
                <li class="regular-text">Croisières sur le lac Tremblant</li>
                <li class="regular-text">Iceberg</li>
                <li class="regular-text">Location de bateaux</li>
                <li class="regular-text">Multi-activités</li>
                <li class="regular-text">Parc Plage</li>
                <li class="regular-text">Pêche</li>
                <li class="regular-text">Sports nautiques</li>
                <li class="regular-text">Trio Lunch</li>
              </ul>
      `,
    },
    {
      video: 'https://www.youtube.com/watch?v=mUCXyKo9NmQ',
      img: '/vue-app-static-img/secteurs-ete-2022/village-famille.jpg',
      titre: 'Village',
      sub_title: 'Piétonnier',
      data: `<h3 class="small-title">Village piétonnier</h3>
              <p class="regular-text">Sillonnez le pittoresque village au pied du mont Tremblant pour faire de belles découvertes. Vous y trouverez tout ce dont vous rêvez pour le plein-air ou une sortie branchée.</p>
              <ul>
                <li class="regular-text">Animation et aires de jeux</li>
                <li class="regular-text">Arcade Réalité Virtuelle Tremblant</li>
                <li class="regular-text">Brind'O Aquaclub</li>
                <li class="regular-text">Casino de Mont-Tremblant</li>
                <li class="regular-text">Centre d'Activités Tremblant</li>
                <li class="regular-text">Escalade</li>
                <li class="regular-text">Eurobungy</li>
                <li class="regular-text">Le Port du capitaine</li>
                <li class="regular-text">Luge</li>
                <li class="regular-text">Mini-golf Le Petit Géant</li>
                <li class="regular-text">Mission Laser</li>
                <li class="regular-text">Mission Liberté</li>
                <li class="regular-text">Multi-activités</li>
                <li class="regular-text">Salle d'entrainement Brind'O</li>
                <li class="regular-text">Spas et massages</li>
                <li class="regular-text">Studio Créatif</li>
                <li class="regular-text">Tour d'escalade</li>
                <li class="regular-text">Univers Toutou</li>
                <li class="regular-text">Vélo</li>
              </ul>
      `,
    },
  ],
  golf_cross_sell: [
    {
      img: '/vue-app-static-img/golf-academie.jpg',
      titre: 'ACADÉMIE DE<br>GOLF TREMBLANT',
      data: `<h3 class="small-title">Académie de golf Tremblant</h3>
              <p class="regular-text">L’académie de golf Tremblant située sur le golf Le Diable et opérée par Dan Bilodeau Golf Academie rivalise avec les meilleures académies de golf en Amérique du Nord grâce à une équipe de professionnels certifiés CPGA et ses installations haut de gamme. L’école de golf offre des programmes adaptés à tous les niveaux durant la saison estivale.</p>
      `,
    },
    {
      img: '/vue-app-static-img/golf-equipement.jpg',
      titre: 'Location<br>Équipement',
      data: `<h3 class="small-title">Location Équipement</h3>
              <p class="regular-text">Nos centres de location d'équipement de golf vous proposent des bâtons haut de gamme de l'année qui vous permettront de vraiment profiter de votre expérience sur les golfs Tremblant. Que vous soyez au golf Le Diable ou au golf Le Géant, rendez vous au comptoir du pavillon d'accueil pour louer l'équipement requis.</p>
      `,
    },
    {
      img: '/vue-app-static-img/golf-groups.jpg',
      titre: 'Groupes<br>et tournois',
      data: `<h3 class="small-title">Groupes et tournois</h3>
              <p class="regular-text">Venez jouer sur nos parcours de golf avec votre groupe de 16 personnes et plus et profitez de tarifs avantageux sur les droits de jeu et la location d’équipement! Golf en formule tout inclus.</p>
      `,
    },
  ],
  golf_diable_holes: [
    {
      number: '1',
      par: '4',
      noir: '459',
      bleu: '424',
      blanc: '390',
      rouge: '358',
      video: 'https://www.youtube.com/watch?v=cRbdBibdTeE',
      img: '/vue-app-static-img/trous/diable-trou-1.jpg',
    },
    {
      number: '2',
      par: '5',
      noir: '550',
      bleu: '519',
      blanc: '480',
      rouge: '439',
      video: 'https://www.youtube.com/watch?v=QLkVyYb8U8w',
      img: '/vue-app-static-img/trous/diable-trou-2.jpg',
    },
    {
      number: '3',
      par: '3',
      noir: '199',
      bleu: '189',
      blanc: '154',
      rouge: '130',
      video: 'https://www.youtube.com/watch?v=FcNM5HXQxQ0',
      img: '/vue-app-static-img/trous/diable-trou-3.jpg',
    },
    {
      number: '4',
      par: '4',
      noir: '389',
      bleu: '380',
      blanc: '362',
      rouge: '315',
      video: 'https://www.youtube.com/watch?v=HtyyORcMBHw',
      img: '/vue-app-static-img/trous/diable-trou-4.jpg',
    },
    {
      number: '5',
      par: '5',
      noir: '597',
      bleu: '565',
      blanc: '535',
      rouge: '496',
      video: 'https://www.youtube.com/watch?v=X9Vt5-4o9Uk',
      img: '/vue-app-static-img/trous/diable-trou-5.jpg',
    },
    {
      number: '6',
      par: '3',
      noir: '221',
      bleu: '177',
      blanc: '164',
      rouge: '148',
      video: 'https://www.youtube.com/watch?v=FCdWfUEn2kE',
      img: '/vue-app-static-img/trous/diable-trou-6.jpg',
    },
    {
      number: '7',
      par: '4',
      noir: '480',
      bleu: '447',
      blanc: '422',
      rouge: '370',
      video: 'https://www.youtube.com/watch?v=fYSDEI9wQiE',
      img: '/vue-app-static-img/trous/diable-trou-7.jpg',
    },
    {
      number: '8',
      par: '3',
      noir: '204',
      bleu: '170',
      blanc: '160',
      rouge: '135',
      video: 'https://www.youtube.com/watch?v=hpwjHd5TAMc',
      img: '/vue-app-static-img/trous/diable-trou-8.jpg',
    },
    {
      number: '9',
      par: '4',
      noir: '411',
      bleu: '379',
      blanc: '340',
      rouge: '297',
      video: 'https://www.youtube.com/watch?v=IFEnaxgX42E',
      img: '/vue-app-static-img/trous/diable-trou-9.jpg',
    },
    {
      number: '10',
      par: '4',
      noir: '448',
      bleu: '414',
      blanc: '381',
      rouge: '347',
      video: 'https://www.youtube.com/watch?v=n_sz5PjEuSQ',
      img: '/vue-app-static-img/trous/diable-trou-10.jpg',
    },
    {
      number: '11',
      par: '4',
      noir: '396',
      bleu: '373',
      blanc: '330',
      rouge: '260',
      video: 'https://www.youtube.com/watch?v=WJRLzgMGvKQ',
      img: '/vue-app-static-img/trous/diable-trou-11.jpg',
    },
    {
      number: '12',
      par: '3',
      noir: '213',
      bleu: '204',
      blanc: '195',
      rouge: '174',
      video: 'https://www.youtube.com/watch?v=pcnQf4pIEEI',
      img: '/vue-app-static-img/trous/diable-trou-12.jpg',
    },
    {
      number: '13',
      par: '4',
      noir: '402',
      bleu: '393',
      blanc: '366',
      rouge: '329',
      video: 'https://www.youtube.com/watch?v=Wbn3LiuBbso',
      img: '/vue-app-static-img/trous/diable-trou-13.jpg',
    },
    {
      number: '14',
      par: '4/3',
      noir: '403',
      bleu: '389',
      blanc: '365',
      rouge: '172',
      video: 'https://www.youtube.com/watch?v=e87uLxvWxU4',
      img: '/vue-app-static-img/trous/diable-trou-14.jpg',
    },
    {
      number: '15',
      par: '5',
      noir: '535',
      bleu: '482',
      blanc: '451',
      rouge: '377',
      video: 'https://www.youtube.com/watch?v=J0_ZmM0IlTQ',
      img: '/vue-app-static-img/trous/diable-trou-15.jpg',
    },
    {
      number: '16',
      par: '3',
      noir: '156',
      bleu: '150',
      blanc: '147',
      rouge: '118',
      video: 'https://www.youtube.com/watch?v=l2ia-MkJA-U',
      img: '/vue-app-static-img/trous/diable-trou-16.jpg',
    },
    {
      number: '17',
      par: '4',
      noir: '435',
      bleu: '405',
      blanc: '370',
      rouge: '335',
      video: 'https://www.youtube.com/watch?v=3LGwkKy1674',
      img: '/vue-app-static-img/trous/diable-trou-17.jpg',
    },
    {
      number: '18',
      par: '5',
      noir: '561',
      bleu: '527',
      blanc: '501',
      rouge: '470',
      video: 'https://www.youtube.com/watch?v=6mUPxNh4x5Q',
      img: '/vue-app-static-img/trous/diable-trou-18.jpg',
    },
  ],
  golf_geant_holes: [
    {
      number: '1',
      par: '5',
      noir: '510',
      bleu: '500',
      blanc: '486',
      rouge: '434',
      video: 'https://www.youtube.com/watch?v=zC4V_FV7W44',
      img: '/vue-app-static-img/trous/geant-trou-1.jpg',
    },
    {
      number: '2',
      par: '4',
      noir: '429',
      bleu: '377',
      blanc: '367',
      rouge: '328',
      video: 'https://www.youtube.com/watch?v=PtH1QIevPl8',
      img: '/vue-app-static-img/trous/geant-trou-2.jpg',
    },
    {
      number: '3',
      par: '3',
      noir: '183',
      bleu: '157',
      blanc: '144',
      rouge: '105',
      video: 'https://www.youtube.com/watch?v=oyL_37417Ek',
      img: '/vue-app-static-img/trous/geant-trou-3.jpg',
    },
    {
      number: '4',
      par: '4',
      noir: '406',
      bleu: '368',
      blanc: '342',
      rouge: '235',
      video: 'https://www.youtube.com/watch?v=uq3Dqe_mgQw',
      img: '/vue-app-static-img/trous/geant-trou-4.jpg',
    },
    {
      number: '5',
      par: '4',
      noir: '385',
      bleu: '375',
      blanc: '348',
      rouge: '318',
      video: 'https://www.youtube.com/watch?v=4GXJpTGwOS0',
      img: '/vue-app-static-img/trous/geant-trou-5.jpg',
    },
    {
      number: '6',
      par: '3',
      noir: '174',
      bleu: '151',
      blanc: '127',
      rouge: '111',
      video: 'https://www.youtube.com/watch?v=BqshfmjsVbo',
      img: '/vue-app-static-img/trous/geant-trou-6.jpg',
    },
    {
      number: '7',
      par: '5',
      noir: '578',
      bleu: '528',
      blanc: '519',
      rouge: '471',
      video: 'https://www.youtube.com/watch?v=VKgNClpGFsI',
      img: '/vue-app-static-img/trous/geant-trou-7.jpg',
    },
    {
      number: '8',
      par: '4',
      noir: '492',
      bleu: '423',
      blanc: '413',
      rouge: '379',
      video: 'https://www.youtube.com/watch?v=xkKtEIc7RdI',
      img: '/vue-app-static-img/trous/geant-trou-8.jpg',
    },
    {
      number: '9',
      par: '4',
      noir: '406',
      bleu: '375',
      blanc: '363',
      rouge: '311',
      video: 'https://www.youtube.com/watch?v=s0IFyZw4hm0',
      img: '/vue-app-static-img/trous/geant-trou-9.jpg',
    },
    {
      number: '10',
      par: '3',
      noir: '159',
      bleu: '149',
      blanc: '143',
      rouge: '114',
      video: 'https://www.youtube.com/watch?v=0oXs093m-pQ',
      img: '/vue-app-static-img/trous/geant-trou-10.jpg',
    },
    {
      number: '11',
      par: '4',
      noir: '311',
      bleu: '304',
      blanc: '281',
      rouge: '268',
      video: 'https://www.youtube.com/watch?v=TLqO1_LcqBE',
      img: '/vue-app-static-img/trous/geant-trou-11.jpg',
    },
    {
      number: '12',
      par: '5',
      noir: '547',
      bleu: '501',
      blanc: '492',
      rouge: '415',
      video: 'https://www.youtube.com/watch?v=XrNHWgSTf28',
      img: '/vue-app-static-img/trous/geant-trou-12.jpg',
    },
    {
      number: '13',
      par: '4',
      noir: '329',
      bleu: '319',
      blanc: '309',
      rouge: '273',
      video: 'https://www.youtube.com/watch?v=kObpjXPZhfo',
      img: '/vue-app-static-img/trous/geant-trou-13.jpg',
    },
    {
      number: '14',
      par: '4',
      noir: '380',
      bleu: '342',
      blanc: '333',
      rouge: '238',
      video: 'https://www.youtube.com/watch?v=W5yr103eftg',
      img: '/vue-app-static-img/trous/geant-trou-14.jpg',
    },
    {
      number: '15',
      par: '4',
      noir: '393',
      bleu: '382',
      blanc: '348',
      rouge: '329',
      video: 'https://www.youtube.com/watch?v=alGwd2xKgwI',
      img: '/vue-app-static-img/trous/geant-trou-15.jpg',
    },
    {
      number: '16',
      par: '3',
      noir: '202',
      bleu: '183',
      blanc: '155',
      rouge: '120',
      video: 'https://www.youtube.com/watch?v=OgJUQcCnAc0',
      img: '/vue-app-static-img/trous/geant-trou-16.jpg',
    },
    {
      number: '17',
      par: '5',
      noir: '495',
      bleu: '458',
      blanc: '395',
      rouge: '363',
      video: 'https://www.youtube.com/watch?v=tM4efC7myv8',
      img: '/vue-app-static-img/trous/geant-trou-17.jpg',
    },
    {
      number: '18',
      par: '4',
      noir: '457',
      bleu: '438',
      blanc: '305',
      rouge: '303',
      video: 'https://www.youtube.com/watch?v=Qfp1L3xA1zw',
      img: '/vue-app-static-img/trous/geant-trou-18.jpg',
    },
  ],
}
