export const hiver2023En = {
  site_hiver_2023: {
    buy_card: 'Buy your card',
    buy_tickets: 'Buy your tickets',
    buy_short: 'Buy',

    bh_lodging_hero: 'Save up to<br><span>25%</span><br>on lodging',
    bh_lodging_title: 'Save up to 25%<br>on lodging',
    bh_lodging_pop: 'Best discounts and<br>availabilities of the year',
    bh_lodging_pop_cta: 'Show details',
    bh_lodging_cut: 'Offers valid until October 18, 2022',
    bh_lodging_link: 'https://www.tremblant.ca/plan/hotels-condos?sc_lang=en',
    bh_lodging_data: `
                      <h2 class="small-title">Save up to<br>25% on lodging</h2>
                      <p class="regular-text">Offer valid until October 18, 2022</p>
                      <p class="regular-text soft">You have up to October 18, 2022, to access the best units while taking advantage of the greatest discounts of the season! Book your vacation right away, even for Christmas or Easter, and get:</p>
                      <div class="price-grid">
                        <div class="price">
                          <p>25%<br><span>4-night stays and +</span></p>
                        </div>
                        <div class="price">
                          <p>20%<br><span>3-night stays</span></p>
                        </div>
                        <div class="price">
                          <p>15%<br><span>2-night stays</span></p>
                        </div>
                      </div>
    `,
    bh_lodging_meta_title: 'Save up to 25% on lodging',
    bh_lodging_meta_desc: 'You have up to October 18, 2022, to access the best units while taking advantage of the greatest discounts of the season!',

    bh_ski_hero_dink: 'Ski 2 to 7 days from<br><span>$85</span><br>/day',
    bh_ski_hero_famille: 'Ski 2 to 7 days from<br><span>$49</span><br>/day child (5-12)',
    bh_ski_link: 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/en-US/?productcategoryid=117&startMonth=11',
    bh_ski_name: 'Multi-day tickets',
    bh_ski_data_dink: `
                      <h2 class="small-title">Ski 2 to 7 days<br>from $85/day</h2>
                      <p class="regular-text">Offer valid until October 18, 2022</p>
                      <p class="regular-text soft">Purchase your lift tickets before October 18, 2022, and get the best rates of the 2022/23 season. Llift tickets for Tremblant are only on sale through our plateforms. Monday to Friday :</p>
                      <div class="price-grid wrap">
                        <div class="price half">
                          <p>$85 <span>/day</span><br><span>Adult (18-69)</span></p>
                        </div>
                        <div class="price half">
                          <p>$73 <span>/day</span><br><span>Senior (70+)</span></p>
                        </div>
                        <div class="price half">
                          <p>$64 <span>/day</span><br><span>Youth (13-17)</span></p>
                        </div>
                        <div class="price half">
                          <p>$49 <span>/day</span><br><span>Child (5-12)</span></p>
                        </div>
                      </div>
    `,
    bh_ski_data_famille: `
                      <h2 class="small-title">Ski 2 to 7 days<br>from $49/day child (5-12)</h2>
                      <p class="regular-text">Offer valid until October 18, 2022</p>
                      <p class="regular-text soft">Purchase your lift tickets before October 18, 2022, and get the best rates of the 2022/23 season. Llift tickets for Tremblant are only on sale through our plateforms. Monday to Friday :</p>
                      <div class="price-grid wrap">
                        <div class="price half">
                          <p>$49 <span>/day</span><br><span>Child (5-12)</span></p>
                        </div>
                        <div class="price half">
                          <p>$64 <span>/day</span><br><span>Youth (13-17)</span></p>
                        </div>
                        <div class="price half">
                          <p>$73 <span>/day</span><br><span>Senior (70+)</span></p>
                        </div>
                        <div class="price half">
                          <p>$85 <span>/day</span><br><span>Adult (18-69)</span></p>
                        </div>
                      </div>
    `,

    sejour_title: 'Enjoy your stay',
    sejour_desc:
      'Tremblant’s beautiful natural playground has endless options for things to do and events to attend all winter long. Take advantage of everything the mountain and the pedestrian village have to offer, either as a couple, with friends or on your own!',

    tonik_dink_hero: 'Tonik Pass at<br><span>$699</span><br>Adult (18-69)',
    tonik_dink_pop: 'Tonik Pass at $699<br>Adult (18-69)',
    tonik_famille_hero: 'Tonik Pass at<br><span>$145</span><br>Child (5-12)',
    tonik_famille_pop: 'Tonik Pass at $145<br>Child (5-12)',
    tonik_cut_off: 'Price goes up on October 14, 2022',
    tonik_link:
      'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=42&ProductCategoryCode=276&ClientId=801&CultureName=en-CA&_ga=2.226458716.711625545.1659354497-858024303.1585064247&_gl=1*11sawzk*_ga*ODU4MDI0MzAzLjE1ODUwNjQyNDc.*_ga_B1YVPN56M6*MTY1OTU0NzIwMS42OC4xLjE2NTk1NDcyMzQuMjc.',
    tonik_argument_1: 'Up to 111 fixed days on the snow',
    tonik_argument_2: 'Up to 25 weekend days',
    tonik_argument_3: '3 floating days to be used anytime',
    tonik_dink_data: `
                      <h2 class="small-title">Tonik Pass at $739<br>Adult (18-69)</h2>
                      <p class="regular-text soft">A season that keeps up with you! Get your Tonik pass 2022-2023 now and benefit from the best prices of the year. Tone up your winter with a calendar that allows you to enjoy the slopes nearly all season. The Tonik Pass is:</p>
                      <ul>
                        <li class="regular-text soft">111 fixed days on the snow and 3 floating days to be used anytime during the season, even during the Holidays! (except on December 29-30, 2022, and February 18-19, 2023). <a href="https://medias.tremblant.ca/pdf/ski/tonik-pass-calendar-2022-23.pdf?_gl=1*11jnamm*_ga*ODU4MDI0MzAzLjE1ODUwNjQyNDc.*_ga_B1YVPN56M6*MTY2MDgzNzI5MS43Ni4xLjE2NjA4MzczMTguMzMuMC4w&_ga=2.23596734.520283421.1660570183-858024303.1585064247" target="_blank">2022/2023 Calendar</a></li>
                        <li class="regular-text soft">Up to 25 weekend days</li>
                        <li class="regular-text soft">Includes days during school breaks</li>
                        <li class="regular-text soft">15% discount on all non-included days (discount applicable on a 1-day regular ticket office ticket)</li>
                        <li class="regular-text soft">Privileges Bundle for passholders for only $159 giving access to First Tracks and other feature products!</li>
                        <li class="regular-text soft">Exclusive renewal rate offered to 2022-23 Tonik passholders.</li>
                        <li class="regular-text soft">Alpine touring pass included.</li>
                        <li class="regular-text soft">The Tonik Pass is exclusive to Canadian residents.</li>
                      </ul>
                      <div class="price-grid wrap">
                        <div class="price half">
                          <p>$469<br><span>Student (18-25)</span></p>
                        </div>
                        <div class="price half">
                          <p>$739<br><span>Adult (18-69)</span></p>
                        </div>
                        <div class="price half">
                          <p>$595<br><span>Senior (70+)</span></p>
                        </div>
                        <div class="price half">
                          <p>$259<br><span>Master (80+)</span></p>
                        </div>
                      </div> 
    `,
    tonik_famille_data: `
                      <h2 class="small-title">Tonik Pass at $165<br>Child (5-12)</h2>
                      <p class="regular-text soft">A season that keeps up with you! Get your Tonik pass 2022-2023 now and benefit from the best prices of the year. Tone up your winter with a calendar that allows you to enjoy the slopes nearly all season. The Tonik Pass is:</p>
                      <ul>
                        <li class="regular-text soft">111 fixed days on the snow and 3 floating days to be used anytime during the season, even during the Holidays! (except on December 29-30, 2022, and February 18-19, 2023). <a href="https://medias.tremblant.ca/pdf/ski/tonik-pass-calendar-2022-23.pdf?_gl=1*11jnamm*_ga*ODU4MDI0MzAzLjE1ODUwNjQyNDc.*_ga_B1YVPN56M6*MTY2MDgzNzI5MS43Ni4xLjE2NjA4MzczMTguMzMuMC4w&_ga=2.23596734.520283421.1660570183-858024303.1585064247" target="_blank">2022/2023 Calendar</a></li>
                        <li class="regular-text soft">Up to 25 weekend days</li>
                        <li class="regular-text soft">Includes days during school breaks</li>
                        <li class="regular-text soft">15% discount on all non-included days (discount applicable on a 1-day regular ticket office ticket)</li>
                        <li class="regular-text soft">Privileges Bundle for passholders for only $119 giving access to First Tracks and other feature products!</li>
                        <li class="regular-text soft">Exclusive renewal rate offered to 2022-23 Tonik passholders.</li>
                        <li class="regular-text soft">Alpine touring pass included.</li>
                        <li class="regular-text soft">The Tonik Pass is exclusive to Canadian residents.</li>
                      </ul>
                      <div class="price-grid wrap">
                        <div class="price half">
                          <p>$45<br><span>Peewee (0-4)</span></p>
                        </div>
                        <div class="price half">
                          <p>$165<br><span>Child (5-12)</span></p>
                        </div>
                        <div class="price half">
                          <p>$469<br><span>Youth (13-17)</span></p>
                        </div>
                        <div class="price half">
                          <p>$469<br><span>Student (18-25)</span></p>
                        </div>
                      </div>  
    `,
    tonik_dink_meta_title: 'Tonik Pass at $699 Adult (18-69)',
    tonik_dink_meta_desc: 'Get your Tonik pass 2022-2023 now and benefit from the best prices of the year.',

    tonik_famille_meta_title: 'Tonik Pass at $145 Child (5-12)',
    tonik_famille_meta_desc: 'Get your Tonik pass 2022-2023 now and benefit from the best prices of the year.',

    latitude_hero: 'Ski 3 to 4 days for<br><span>$82</span><br>per day',
    latitude_pop: 'Ski 3 to 4 days for<br>$82 per day',
    latitude_cut_off: 'Offer valid until October 6, 2022',
    latitude_link:
      'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=3&ProductCategoryCode=64&ClientId=801&CultureName=en-CA',
    latitude_argument_1: '3 to 4 days on the snow',
    latitude_argument_2: 'Savings off up to 36%',
    latitude_argument_3: '15% discount on all additional days',
    latitude_name: 'Latitude card',
    latitude_data: `
                      <h2 class="small-title">Ski 3 to 4 days for<br>$82 per day</h2>
                      <p class="regular-text">Offer valid until October 6, 2022</p>
                      <p class="regular-text soft">The best discounts to ski Tremblant with no compromise! Opt for the Latitude Card and get 3 to 4 days at the lowest price available:</p>
                      <ul>
                        <li class="regular-text soft">3 to 4 days of skiing or snowboarding to be used consecutively or not, from $82 per day, per adult</li>
                        <li class="regular-text soft">The days can be used as you wish during the 2022/23 season with the exception of: December 27, 2022, to January 5, 2023, inclusively</li>
                        <li class="regular-text soft">The following 8 Saturdays: January 14, 21 and 28, February 11 and 18, March 4, 18 and 25, 2023.</li>
                        <li class="regular-text soft">Savings of up to 36%</li>
                        <li class="regular-text soft">15% discount on all additional days (discount applicable on a 1-day regular ticket office ticket)</li>
                        <li class="regular-text soft">The Latitude Card is exclusive to Canadian residents.</li>
                      </ul>
                      <p class="regular-text soft">Take advantage of the Latitude Card! Ski tickets for Tremblant will be on sale only through our platforms.</p>
    `,
    latitude_meta_title: 'Ski 3 to 4 days for $82 per day',
    latitude_meta_desc:
      'The best discounts to ski Tremblant with no compromise! Opt for the Latitude Card and get 3 to 4 days at the lowest price available.',

    latitude_product_1: `
                      <div class="row">
                        <p class="small-title">Multi-day tickets from</p>
                        <p class="medium-title">$85</p>
                        <p class="small-title">Per day</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Ski 2 to 7 days</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Consecutive use</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Offer valid until<br>October 18, 2022</p>
                      </div>
    `,
    latitude_product_2: `
                      <div class="row">
                        <p class="small-title">Latitude card</p>
                        <p class="medium-title">$82</p>
                        <p class="small-title">Per day</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Ski 3 to 4 days</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Spontaneous use</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Offer valid until<br>October 6, 2022</p>
                      </div>
    `,
    latitude_product_3: `
                      <div class="row">
                        <p class="small-title">Tonik Pass</p>
                        <p class="medium-title">$699</p>
                        <p class="small-title">2022 - 2023 season</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Up to 111 fixed days on the snow</p>
                      </div>
                      <div class="row">
                        <p class="small-title">use at all times</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Price goes up on<br>October 14, 2022</p>
                      </div>
    `,

    privilege_card_title: 'Enhance your experience<br>with the privilege bundle',
    privilege_price_1: '<p>$175<br><span>Adults (18 and over)</span></p>',
    privilege_price_2: '<p>$125<br><span>Youth (0-17)</span></p>',
    privilege_cta_title: 'Buy your Privilege Bundle',
    privilege_cta_link:
      'https://www.ikonpass.com/en/local-passes/tremblant/privilege-bundle?currency=CAD&co-branded=tremblant',

    domaine_title: 'More freedom. More skiing. More fun.',
    domaine_title_latitude: 'Feel the buzz of the slopes',
    domaine_desc:
      "Tremblant's summit stands at 875 meters (2,871 feet) and offers a breathtaking view of the Laurentians. The resort's ski area covers 305 hectares (755 acres) and includes 102 trails on 4 sides.",

    rando_title: 'Alpine touring pass<br>included with your Tonik Pass',
    rando_data:
      '<p class="regular-text">Enjoy our designated areas for climbing<br><br>Come climb the highest peak in the Laurentians with your skis on in one of our designated areas and trails, and ski down one of our 102 marked ski runs!</p>',

    apres_ski_title: 'Enjoy your Après-Ski',

    price_grid_title: 'Faite le choix qui convient<gr>le mieux à vos besoins',

    al_lodging_hero: 'Save up to<br><span>15%</span><br>on lodging',
    al_lodging_title: 'Save up to 15%<br>on lodging',
    al_lodging_pop: 'Save up to 15%<br>on lodging',
    al_lodging_pop_cta: 'Show details',
    al_lodging_cut: 'On sale until April 29, 2023',
    al_lodging_link: 'https://www.tremblant.ca/plan/hotels-condos?sc_lang=en',
    al_lodging_data: `
                      <h2 class="small-title">Save up to<br>15% on lodging</h2>
                      <p class="regular-text soft">Take advantage of this offer all season long! From November, 30, 2022 to April 29, 2023, book a two-night stay between December 1, 2022 and April 30, 2023 and save :</p>
                      <div class="price-grid">
                        <div class="price half">
                          <p>15%<br><span>3-night stays and +</span></p>
                        </div>
                        <div class="price half">
                          <p>10%<br><span>2-night stays</span></p>
                        </div>
                      </div>
    `,
    al_lodging_meta_title: 'Save up to 15% on lodging',
    al_lodging_meta_desc: 'Plan today and save on your Tremblant winter stay. Book before April 29, 2023, and save up to 15 % on lodging.',

    al_ski_hero_dink: 'Ski 2 to 7 days from<br><span>$89</span><br>/day',
    al_ski_link: 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/en-US/?productcategoryid=117&startMonth=11',
    al_ski_name: 'Multi-day tickets',
    al_ski_data_dink: `
                      <h2 class="small-title">Ski 2 to 7 days<br>from $89/day</h2>
                      <p class="regular-text">Offer valid until November 29, 2022</p>
                      <p class="regular-text soft">Book in advance and secure the best prices. Purchase your lift tickets before October 18, 2022, and get the best rates of the 2022/23 season. Lift tickets for Tremblant are only on sale through our plateforms. Monday to Friday :</p>
                      <div class="price-grid wrap">
                        <div class="price half">
                          <p>$89 <span>/day</span><br><span>Adult (18-69)</span></p>
                        </div>
                        <div class="price half">
                          <p>$89 <span>/day</span><br><span>Senior (70+)</span></p>
                        </div>
                        <div class="price half">
                          <p>$67 <span>/day</span><br><span>Youth (13-17)</span></p>
                        </div>
                        <div class="price half">
                          <p>$51 <span>/day</span><br><span>Child (5-12)</span></p>
                        </div>
                      </div>
    `,

    al_entrepot_hero: '<span class="smaller">Ticket Outlet Up to<br><span>20%</span><br>off lift tickets</span>',
    al_entrepot_hero_small: 'Save up to<br><span>20%</span><br>off lift tickets',
    al_entrepot_title: 'Save up to 20% off lift tickets',
    al_entrepot_pop: 'Save up to 20% off lift tickets',
    al_entrepot_pop_cta: 'Show details',
    al_entrepot_link: 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/en-US/?productcategoryid=117',
    al_entrepot_data: `
                      <h2 class="small-title">Save up to 20% off lift tickets</h2>
                      <p class="regular-text soft">Save up to 20% on 1-day to 5-day lift tickets! Prices may vary based on dates. Daily quantities are limited. Afternoon (half-day) tickets also available to ski from noon.</p>
    `,
    al_entrepot_meta_title: 'Save up to 20% off lift tickets',
    al_entrepot_meta_desc:
      'Welcome to the Tremblant Ticket Outlet, where you’ll find variable rates on lift tickets to ski at Tremblant on specific days while saving up to 20%!',

    launch_lodging_hero: 'Save up to<br><span>20%</span><br>on lodging',
    launch_lodging_title: 'Save up to 20%<br>on lodging',
    launch_lodging_pop: 'Save up to 20%<br>on lodging',
    launch_lodging_pop_cta: 'Show details',
    launch_lodging_cut: 'On sale until November 29, 2022',
    launch_lodging_link: 'https://www.tremblant.ca/plan/hotels-condos?sc_lang=en',
    launch_lodging_data: `
                      <h2 class="small-title">Save up to<br>20% on lodging</h2>
                      <p class="regular-text">On sale until November 29, 2022</p>
                      <p class="regular-text soft">Dream winter getaway offer. Book early your winter vacation in Tremblant and save! Plan today and save on your Tremblant winter stay. Book before November 29, 2022, and save up to 20 % on lodging even for Christmas and Easter:</p>
                      <div class="price-grid">
                        <div class="price half">
                          <p>20%<br><span>3-night stays and +</span></p>
                        </div>
                        <div class="price half">
                          <p>10%<br><span>2-night stays</span></p>
                        </div>
                      </div>
                      <br>
                      <p class="regular-text soft">Why book early?</p>
                      <ul>
                        <li class="regular-text">Best offer of the winter</li>
                        <li class="regular-text">Best lodging selection</li>
                        <li class="regular-text">More time to plan all aspects of your vacation</li>
                      </ul> 
    `,
    launch_lodging_meta_title: 'Save up to 20% on lodging',
    launch_lodging_meta_desc:
      'Dream winter getaway offer. Book early your winter vacation in Tremblant and save! Plan today and save on your Tremblant winter stay.',

    launch_ski_hero_dink: 'Ski 2 to 7 days from<br><span>$89</span><br>/day',
    launch_ski_hero_famille: 'Ski 2 to 7 days from<br><span>$51</span><br>/day child (5-12)',
    launch_ski_link: 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/en-US/?productcategoryid=117&startMonth=11',
    launch_ski_name: 'Multi-day tickets',
    launch_ski_data_dink: `
                      <h2 class="small-title">Ski 2 to 7 days<br>from $89/day</h2>
                      <p class="regular-text">On sale until November 29, 2022</p>
                      <p class="regular-text soft">Book in advance and secure the best prices. Purchase your lift tickets before November 29, 2022, and get the best rates of the 2022/23 season. Lift tickets for Tremblant are only on sale through our plateforms. Monday to Friday :</p>
                      <div class="price-grid wrap">
                        <div class="price half">
                          <p>$89 <span>/day</span><br><span>Adult (18-69)</span></p>
                        </div>
                        <div class="price half">
                          <p>$77 <span>/day</span><br><span>Senior (70+)</span></p>
                        </div>
                        <div class="price half">
                          <p>$67 <span>/day</span><br><span>Youth (13-17)</span></p>
                        </div>
                        <div class="price half">
                          <p>$51 <span>/day</span><br><span>Child (5-12)</span></p>
                        </div>
                      </div>
    `,
    launch_ski_data_famille: `
                      <h2 class="small-title">Ski 2 to 7 days<br>from $51/day child (5-12)</h2>
                      <p class="regular-text">On sale until November 29, 2022</p>
                      <p class="regular-text soft">Book in advance and secure the best prices. Purchase your lift tickets before November 29, 2022, and get the best rates of the 2022/23 season. Lift tickets for Tremblant are only on sale through our plateforms. Monday to Friday :</p>
                      <div class="price-grid wrap">
                        <div class="price half">
                          <p>$51 <span>/day</span><br><span>Child (5-12)</span></p>
                        </div>
                        <div class="price half">
                          <p>$67 <span>/day</span><br><span>Youth (13-17)</span></p>
                        </div>
                        <div class="price half">
                          <p>$77 <span>/day</span><br><span>Senior (70+)</span></p>
                        </div>
                        <div class="price half">
                          <p>$89 <span>/day</span><br><span>Adult (18-69)</span></p>
                        </div>
                      </div>
    `,

    launch_latitude_hero: 'Ski 3 to 4 days for<br><span>$86</span><br>per day',
    launch_latitude_pop: 'Ski 3 to 4 days for<br>$86 per day',
    launch_latitude_cut_off: 'On sale until November 22',
    launch_latitude_link:
      'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=3&ProductCategoryCode=64&ClientId=801&CultureName=en-CA',
    launch_latitude_argument_1: '3 to 4 days on the snow',
    launch_latitude_argument_2: 'Savings off up to 33%',
    launch_latitude_argument_3: '15% discount on all additional days',
    launch_latitude_name: 'Latitude card',
    launch_latitude_data: `
                      <h2 class="small-title">Ski 3 to 4 days for<br>$86 per day</h2>
                      <p class="regular-text">On sale until November 22</p>
                      <p class="regular-text soft">The best discounts to ski Tremblant with no compromise! Opt for the Latitude Card and get 3 to 4 days at the lowest price available:</p>
                      <ul>
                        <li class="regular-text soft">3 to 4 days of skiing or snowboarding to be used consecutively or not, from $86 per day, per adult</li>
                        <li class="regular-text soft">The days can be used as you wish during the 2022/23 season with the exception of: December 27, 2022, to January 5, 2023, inclusively. The following 8 Saturdays: January 14, 21 and 28, February 11 and 18, March 4, 18 and 25, 2023.</li>
                        <li class="regular-text soft">Savings of up to 33%</li>
                        <li class="regular-text soft">15% discount on all additional days (discount applicable on a 1-day regular ticket office ticket)</li>
                        <li class="regular-text soft">The Latitude Card is exclusive to Canadian residents.</li>
                      </ul>
                      <p class="regular-text soft">Take advantage of the Latitude Card! Ski tickets for Tremblant will be on sale only through our platforms.</p>
    `,
    launch_latitude_meta_title: 'Ski 3 to 4 days for $86 per day',
    launch_latitude_meta_desc:
      'The best discounts to ski Tremblant with no compromise! Opt for the Latitude Card and get 3 to 4 days at the lowest price available.',

    launch_latitude_product_1: `
                      <div class="row">
                        <p class="small-title">Multi-day tickets from</p>
                        <p class="medium-title">$89</p>
                        <p class="small-title">Per day</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Ski 2 to 7 days</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Consecutive use</p>
                      </div>
                      <div class="row">
                        <p class="small-title">On sale until<br>November 29, 2022</p>
                      </div>
    `,
    launch_latitude_product_2: `
                      <div class="row">
                        <p class="small-title">Latitude card</p>
                        <p class="medium-title">$86</p>
                        <p class="small-title">Per day</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Ski 3 to 4 days</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Spontaneous use</p>
                      </div>
                      <div class="row">
                        <p class="small-title">On sale until<br>November 22, 2022</p>
                      </div>
    `,
    launch_latitude_product_3: `
                      <div class="row">
                        <p class="small-title">Tonik Pass</p>
                        <p class="medium-title">$739</p>
                        <p class="small-title">2022 - 2023 season</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Up to 111 fixed days on the snow</p>
                      </div>
                      <div class="row">
                        <a href="https://medias.tremblant.ca/pdf/ski/tonik-pass-calendar-2022-23.pdf" target="_blank"><p class="small-title">2022-23 Calendar</p></a>
                      </div>
                      <div class="row">
                        <p class="small-title">Price goes up on<br>December 9, 2022</p>
                      </div>
    `,

    ski_magazine: '#1 ski resort for Après-ski by Ski Mag',
    ski_magazine_link: 'https://www.tremblant.ca/about-us/awards',

    flash_dec_hero: 'Transferable<br>Tickets from<br><span>$90</span><br>Purchase in pairs',
    flash_dec_pop: 'Ski tickets from $90<br>Purchase in pairs',
    flash_dec_cut_off: 'On sale until<br>December 9, 2022 1 P.M.',
    flash_dec_link: 'https://reservations.tremblant.ca/ecomm/shop/merchandise/2624850/en-US/?productsupercategoryid=23',
    flash_dec_promo_heb: 'SAVE UP TO<br>15% ON LODGING',
    flash_dec_data: `
                      <h2 class="small-title">Flash sale<br>Tickets from $90<br>Purchase in pairs</h2>
                      <p class="regular-text">On sale until December 9, 2022 1 P.M.</p>
                      <p class="regular-text soft">Get pairs of transferable lift tickets at an excellent price and take advantage of the mountain during three popular periods during the season! Keep them for yourself, invite friends or slip them under the Christmas tree! These tickets, sold in pairs, are valid during the following periods:</p>
                      <div class="table split_details">
                        <div class="row nbb">
                          <div class="cell-35">
                            <p class="regular-text"><span>Beginning of the season</span><br>Dec. 8 to 23, 2022</p>
                          </div>
                          <div class="cell-65">
                            <p class="regular-text"><span>$95 per ticket</span><br>Purchase in pairs<br>Excluding Saturdays</p>
                          </div>
                        </div>
                        <div class="row nbb">
                          <div class="cell-35">
                            <p class="regular-text"><span>Heart of the season</span><br>Jan. 9 to Feb. 10, 2023</p>
                          </div>
                          <div class="cell-65">
                            <p class="regular-text"><span>$90 per ticket</span><br>Purchase in pairs<br>Excluding Saturdays and Sundays</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="cell-35">
                            <p class="regular-text"><span>Spring skiing</span><br>Mar. 20 to Apr. 16, 2023</p>
                          </div>
                          <div class="cell-65">
                            <p class="regular-text"><span>$95 per ticket</span><br>Purchase in pairs<br>Excluding Saturdays</p>
                          </div>
                        </div>
                      </div>
                      <p class="regular-text soft">Royalty and taxes extra. Rates, dates, royalty and taxes are subject to change without notice.</p>
    `,
    flash_dec_meta_title: 'Flash sale - Tickets from $90 - Purchase in pairs',
    flash_dec_meta_desc:
      'Get pairs of transferable lift tickets at an excellent price and take advantage of the mountain during three popular periods during the season!',

    flash_bd_link:
      'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=41&ProductCategoryCode=17301&ClientId=801&CultureName=en-CA',
    flash_bd_meta_title: 'BOXING DAY - Latitude Card - Ski 3 to 4 days for $86 per day',
    flash_bd_meta_desc:
      'The best discounts to ski Tremblant with no compromise! Limited time offer! From December 27, 1 p.m. and December 29, 1 p.m., opt for the Latitude Card and get 3 to 4 days at the lowest price available',

    flash_jan_meta_title: 'Save up to 20% on lodging and 25% on lift tickets',
    flash_jan_meta_desc: 'This winter, live the Tremblant joie de vivre and take advantage of the best offer on a two-night stay or more!',

    brindo_deal: 'Save 20% on daily access to brind\'O',
    brindo_data: `
                      <h2 class="small-title">Save 20% on daily access to brind'O</h2>
                      <ul>
                        <li class="regular-text soft">Upon presentation of proof of lodging at the Resort (room card, visitor card, Montagnard card) or ski access (day lift ticket, 2022-2023 ski pass (Ikon Pass, Tonik pass, Latitude card)</li>
                        <li class="regular-text soft">Valid until April 30, 2023.</li>
                        <li class="regular-text soft">Cannot be combined with any other offer. Certain conditions apply.</li>
                      </ul>
    `,

    printemps_lodging_hero: 'Save up to<br><span>15%</span><br>on lodging',
    printemps_lodging_title: 'Save up to 15%<br>on lodging',
    printemps_lodging_pop: 'Save up to 15%<br>on lodging',
    printemps_lodging_pop_cta: 'Show details',
    printemps_lodging_cut: 'On sale until April 29',
    printemps_lodging_link: 'https://www.tremblant.ca/plan/hotels-condos?sc_lang=en',
    printemps_lodging_data: `
                      <h2 class="small-title">“SPRING’S IN THE AIR!” OFFER. Save up to 15% on your spring stay.</h2>
                      <p class="regular-text bold">When you book 2 nights and more!</p>
                      <p class="regular-text soft">Take advantage of this offer all winter and spring long, even for Easter! Until April 29, 2023, book a two-night stay or more and save :</p>
                      <div class="price-grid">
                        <div class="price half">
                          <p>15%<br><span>3-night stays and +</span></p>
                        </div>
                        <div class="price half">
                          <p>10%<br><span>2-night stays</span></p>
                        </div>
                      </div>
    `,
    printemps_lodging_meta_title: 'Save up to 15% on lodging',
    printemps_lodging_meta_desc: 'Take advantage of this offer all winter and spring long, even for Easter! Until April 29, 2023, book a two-night stay or more and save.',

    printemps_entrepot_hero: '<span class="smaller">Ticket Outlet</span><br>Up to<br><span>20%</span><br>off lift tickets',
    printemps_entrepot_hero_small: 'Save up to<br><span>20%</span><br>off lift tickets',
    printemps_entrepot_title: 'Save up to 20% off lift tickets',
    printemps_entrepot_pop: 'Save up to 20% off lift tickets',
    printemps_entrepot_pop_cta: 'Show details',
    printemps_entrepot_link: 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/en-US/?productcategoryid=117',
    printemps_entrepot_data: `
                      <h2 class="small-title">Save up to 20% off lift tickets</h2>
                      <p class="regular-text soft">Save up to 20% on 1-day to 5-day lift tickets! Prices may vary based on dates. Daily quantities are limited. Afternoon (half-day) tickets also available to ski from noon.</p>
    `,
    printemps_entrepot_meta_title: 'Save up to 20% off lift tickets',
    printemps_entrepot_meta_desc: 'Save up to 20% on 1-day to 5-day lift tickets! Prices may vary based on dates. Daily quantities are limited. Afternoon (half-day) tickets also available to ski from noon.',

    flash_mars_hero: 'Lift tickets starting from<br><span>$95</span>',
    flash_mars_hero_small: 'Lift tickets starting from<br><span>$95</span>',
    flash_mars_cut_off: 'On sale until March 9, 2023 1 P.M.',
    flash_mars_title: 'Lift tickets starting from $95',
    flash_mars_pop: 'Lift tickets<br>starting from $95',
    flash_mars_pop_cta: 'Show details',
    flash_mars_link: 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/en-US/?productcategoryid=117&agecategory=8&days=1',
    flash_mars_data: `
                      <h2 class="small-title">Lift tickets starting from $95</h2>
                      <p class="regular-text soft">Get lift tickets and take advantage of the mountain as of March 20, 2023! From March 7, 2023, 1 p.m., to March 9, 2023, 1 p.m., visit the Tremblant Ticket Outlet and get lift tickets starting from $95.</p>
    `,
    flash_mars_meta_title: 'Lift tickets starting from $95',
    flash_mars_meta_desc: 'Get lift tickets and take advantage of the mountain as of March 20, 2023! From March 7, 2023, 1 p.m., to March 9, 2023, 1 p.m., visit the Tremblant Ticket Outlet and get lift tickets starting from $95.',


    renou_dink_link: 'https://www.ikonpass.com/en/local-passes/tremblant/tonik-pass?currency=CAD&co-branded=tremblant',
    renou_dink_hero_title: 'Tonik Pass 2023-2024<br><span>Adult (18-69)</span>',
    renou_dink_renewal_deal: 'Renewal*<br><span>$599</span>',
    renou_dink_new_deal: 'New<br><span>$649</span>',
    renou_dink_cut_off: 'Prices go up on April 21, 2023',
    renou_dink_pop: 'Tonik Pass 2023-2024<br>Adult (18-69)',
    renou_dink_data: `
                              <h2 class="small-title">Tonik Pass 2023-2024<br> Renewal $599<br>New $649</h2>
                              <p class="regular-text">Prices go up on April 21, 2023</p>
                              <p class="regular-text soft">Get your Tonik pass 2022-2023 now and benefit from the best prices of the year. Tone up your winter with a calendar that allows you to enjoy the slopes nearly all season. The Tonik Pass is:</p>
                              <ul>
                                <li class="regular-text soft">112 fixed days on the snow and 3 floating days to be used anytime during the season, even during the Holidays! (except on December 27-30, 2023, and February 17-18, 2024). <a href="https://medias.tremblant.ca/pdf/ski/tonik-pass-calendar-2023-24.pdf" target="_blank">See the calendar</a></li>
                                <li class="regular-text soft">Up to 26 weekend days</li>
                                <li class="regular-text soft">Includes days during school breaks</li>
                                <li class="regular-text soft">15% discount on all non-included days (discount applicable on a 1-day regular ticket office ticket)</li>
                                <li class="regular-text soft">Privileges Bundle for passholders starting from $125 giving access to First Tracks and other feature products!</li>
                                <li class="regular-text soft">Exclusive renewal rate offered to 2022-23 Tonik passholders.</li>
                                <li class="regular-text soft">Alpine touring pass included.</li>
                                <li class="regular-text soft">The Tonik Pass is exclusive to Canadian residents.</li>
                              </ul>
                              <p class="regular-text">Renewal</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>$599<br><span>Adult (18-69)</span></p>
                                </div>
                                <div class="price">
                                  <p>$399<br><span>Student (18+)</span></p>
                                </div>
                                <div class="price">
                                  <p>$479<br><span>Senior (70+)</span></p>
                                </div>
                              </div>
                              <br>
                              <p class="regular-text italic">You need the promo codes we sent you by mail to unlock your exclusive renewal rates.</p>
                              <br>
                              <p class="regular-text">New</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>$649<br><span>Adult (18-69)</span></p>
                                </div>
                                <div class="price">
                                  <p>$399<br><span>Student (18+)</span></p>
                                </div>
                                <div class="price">
                                  <p>$519<br><span>Senior (70+)</span></p>
                                </div>
                              </div>
                              <br>
                              <p class="regular-text italic">To unlock the student discount, simply follow the instructions to apply the college discount.</p>
    `,
    renou_dink_meta_title: 'Tonik Pass 2023-2024 - Renewal at $599',
    renou_dink_meta_desc: 'Get your Tonik pass 2022-2023 now and benefit from the best prices of the year.',

    renou_famille_link: 'https://www.ikonpass.com/en/local-passes/tremblant/tonik-pass?currency=CAD&co-branded=tremblant',
    renou_famille_hero_title: 'Tonik Pass 2023-2024<br><span>Child (5-12)</span>',
    renou_famille_renewal_deal: 'Renewal*<br><span>$109</span>',
    renou_famille_new_deal: 'New<br><span>$119</span>',
    renou_famille_cut_off: 'Prices go up on April 21, 2023',
    renou_famille_pop: 'Tonik Pass 2023-2024<br>Child (5-12)',
    renou_famille_data: `
                              <h2 class="small-title">Tonik Pass 2023-2024<br> Renewal $109<br>New $119</h2>
                              <p class="regular-text">Prices go up on April 21, 2023</p>
                              <p class="regular-text soft">Get your Tonik pass 2022-2023 now and benefit from the best prices of the year. Tone up your winter with a calendar that allows you to enjoy the slopes nearly all season. The Tonik Pass is:</p>
                              <ul>
                                <li class="regular-text soft">112 fixed days on the snow and 3 floating days to be used anytime during the season, even during the Holidays! (except on December 27-30, 2023, and February 17-18, 2024). <a href="https://medias.tremblant.ca/pdf/ski/tonik-pass-calendar-2023-24.pdf" target="_blank">See the calendar</a></li>
                                <li class="regular-text soft">Up to 26 weekend days</li>
                                <li class="regular-text soft">Includes days during school breaks</li>
                                <li class="regular-text soft">15% discount on all non-included days (discount applicable on a 1-day regular ticket office ticket)</li>
                                <li class="regular-text soft">Privileges Bundle for passholders starting from $125 giving access to First Tracks and other feature products!</li>
                                <li class="regular-text soft">Exclusive renewal rate offered to 2022-23 Tonik passholders.</li>
                                <li class="regular-text soft">Alpine touring pass included.</li>
                                <li class="regular-text soft">The Tonik Pass is exclusive to Canadian residents.</li>
                              </ul>
                              <p class="regular-text">Renewal</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>$109<br><span>Child (5-12)</span></p>
                                </div>
                                <div class="price">
                                  <p>$369<br><span>Youth (13-17)</span></p>
                                </div>
                                <div class="price">
                                  <p>$49<br><span>Peewee (0-4)</span></p>
                                </div>
                              </div>
                              <br>
                              <p class="regular-text italic">You need the promo codes we sent you by mail to unlock your exclusive renewal rates.</p>
                              <br>
                              <p class="regular-text">New</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>$119<br><span>Child (5-12)</span></p>
                                </div>
                                <div class="price">
                                  <p>$399<br><span>Youth (13-17)</span></p>
                                </div>
                                <div class="price">
                                  <p>$49<br><span>Peewee (0-4)</span></p>
                                </div>
                              </div>
                              <br>
    `,
    renou_famille_meta_title: 'Tonik Pass 2023-2024 - Renewal at $109',
    renou_famille_meta_desc: 'Get your Tonik pass 2022-2023 now and benefit from the best prices of the year.',

    renou_popup_new_title: 'New platform',
    renou_popup_new_desc: 'Tremblant will be integrating RFID technology for the 2023/24 season. Therefore, the purchase of your 23/24 Tonik Pass will be made via a new platform. Access to all of lifts will now be gained by passing through a gate which reads the content of your pass via Radio Frequency IDentification (RFID).',

  },
  dink_hiver_2023_experiences: [
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/events-dink.jpg',
      number: '56',
      titre: 'days of events<br>and entertainment',
      data: `
            <h3 class="small-title">Entertainment throughout the season</h3>
            <p class="regular-text">Electrify your stay with diverse and exciting events this winter! From musical concerts to sporting and charity, there's something for everyone to enjoy. Vibrate to the sound of the mountain!</p>
            <ul>
              <!--<li class="regular-text">Ski Season Opening</li>
              <li class="regular-text">Tremblant's 24H</li>-->
              <!--<li class="regular-text">Holiday Atmosphere</li>
              <li class="regular-text">New Year's Eve Party</li>-->
              <li class="regular-text">Spring Break Atmosphere</li>
              <li class="regular-text">Après-ski at Place Saint-Bernard</li>
              <li class="regular-text">Caribou Cup</li>
              <li class="regular-text">Easter Celebrations</li>
              <li class="regular-text">Festival Rando Alpine</li>
              <li class="regular-text">Jamigos</li>
            </ul>
            <a href="https://www.tremblant.ca/things-to-do/events?sc_lang=en" target="_blank" class="cta red">Learn more</a>
      `,
      cta: 'Have fun at Tremblant',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/restaurants-dink.jpg',
      number: '36',
      titre: 'Restaurants,<br />bars and cafes',
      data: `
            <h3 class="small-title">36 restaurants, bars and cafes</h3>
            <p class="regular-text">While staying at Tremblant, enjoy a wide variety of restaurants throughout the ski resort. Whether it's lunchtime or after a day of playing outdoors in the snow, there's always a delicious blend of flavours just waiting for your taste buds.</p>
      `,
      cta: 'Discover our restaurants',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/activites-dink.jpg',
      number: '33',
      titre: 'ways<br>to have fun',
      data: `
            <h3 class="small-title">Tremblant: Where there's plenty of fun</h3>
            <p class="regular-text">Make the most of your vacation at Tremblant and check out our array of winter activities that are all about fun. At Tremblant, there's always a reason to have a blast in the heart of a buzzing village!</p>
      `,
      cta: 'Explore our activities',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/hebergement-dink.jpg',
      number: '1900',
      titre: 'lodging<br>units',
      data: `
            <h3 class="small-title">Lodging on the resort</h3>
            <p class="regular-text">On the mountainside, in the heart of the pedestrian village or near the golf courses, Tremblant has a wide variety of residences to suit all your needs. Customized comfort that will rejuvenate you this winter!</p>
      `,
      cta: 'Discover our lodgings',
    },
  ],
  famille_hiver_2023_experiences: [
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/events-famille-2.jpg',
      number: '56',
      titre: 'days of events<br>and entertainment',
      data: `
            <h3 class="small-title">Entertainment throughout the season</h3>
            <p class="regular-text">Electrify your stay with diverse and exciting events this winter! From musical concerts to sporting and charity, there's something for everyone to enjoy. Vibrate to the sound of the mountain!</p>
            <ul>
              <!--<li class="regular-text">Ski Season Opening</li>
              <li class="regular-text">Tremblant's 24H</li>-->
              <!--<li class="regular-text">Holiday Atmosphere</li>
              <li class="regular-text">New Year's Eve Party</li>-->
              <li class="regular-text">Spring Break Atmosphere</li>
              <li class="regular-text">Après-ski at Place Saint-Bernard</li>
              <li class="regular-text">Caribou Cup</li>
              <li class="regular-text">Easter Celebrations</li>
              <li class="regular-text">Festival Rando Alpine</li>
              <li class="regular-text">Jamigos</li>
            </ul>
            <a href="https://www.tremblant.ca/things-to-do/events?sc_lang=en" target="_blank" class="cta red">Learn more</a>
      `,
      cta: 'Have fun at Tremblant',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/restaurants-famille-2.jpg',
      number: '36',
      titre: 'Restaurants,<br />bars and cafes',
      data: `
            <h3 class="small-title">36 restaurants, bars and cafes</h3>
            <p class="regular-text">While staying at Tremblant, enjoy a wide variety of restaurants throughout the ski resort. Whether it's lunchtime or after a day of playing outdoors in the snow, there's always a delicious blend of flavours just waiting for your taste buds.</p>
      `,
      cta: 'Discover our restaurants',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/activites-famille-brindo.jpg',
      number: '33',
      titre: 'ways<br>to have fun',
      data: `
            <h3 class="small-title">Tremblant: Where there's plenty of fun</h3>
            <p class="regular-text">Make the most of your vacation at Tremblant and check out our array of winter activities that are all about fun. At Tremblant, there's always a reason to have a blast in the heart of a buzzing village!</p>
      `,
      cta: 'Explore our activities',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/hebergement-dink.jpg',
      number: '1900',
      titre: 'lodging<br>units',
      data: `
            <h3 class="small-title">Lodging on the resort</h3>
            <p class="regular-text">On the mountainside, in the heart of the pedestrian village or near the golf courses, Tremblant has a wide variety of residences to suit all your needs. Customized comfort that will rejuvenate you this winter!</p>
      `,
      cta: 'Discover our lodgings',
    },
  ],
  general_hiver_2023_experiences: [
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/events-dink-2.jpg',
      number: '56',
      titre: 'days of events<br>and entertainment',
      data: `
            <h3 class="small-title">Entertainment throughout the season</h3>
            <p class="regular-text">Electrify your stay with diverse and exciting events this winter! From musical concerts to sporting and charity, there's something for everyone to enjoy. Vibrate to the sound of the mountain!</p>
            <ul>
              <li class="regular-text">School Break Atmosphere</li>
              <li class="regular-text">Après-ski at Place Saint-Bernard</li>
              <li class="regular-text">Caribou Cup</li>
              <li class="regular-text">Easter Celebrations</li>
              <li class="regular-text">Festival Rando Alpine</li>
              <li class="regular-text">Jamigos</li>
            </ul>
            <a href="https://www.tremblant.ca/things-to-do/events?sc_lang=en" target="_blank" class="cta red">Learn more</a>
      `,
      cta: 'Have fun at Tremblant',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/restaurants-famille-2.jpg',
      number: '36',
      titre: 'Restaurants,<br />bars and cafes',
      data: `
            <h3 class="small-title">36 restaurants, bars and cafes</h3>
            <p class="regular-text">While staying at Tremblant, enjoy a wide variety of restaurants throughout the ski resort. Whether it's lunchtime or after a day of playing outdoors in the snow, there's always a delicious blend of flavours just waiting for your taste buds.</p>
      `,
      cta: 'Discover our restaurants',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/activites-dink.jpg',
      number: '33',
      titre: 'ways<br>to have fun',
      data: `
            <h3 class="small-title">Tremblant: Where there's plenty of fun</h3>
            <p class="regular-text">Make the most of your vacation at Tremblant and check out our array of winter activities that are all about fun. At Tremblant, there's always a reason to have a blast in the heart of a buzzing village!</p>
      `,
      cta: 'Explore our activities',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/hebergement-dink.jpg',
      number: '1900',
      titre: 'lodging<br>units',
      data: `
            <h3 class="small-title">Lodging on the resort</h3>
            <p class="regular-text">On the mountainside, in the heart of the pedestrian village or near the golf courses, Tremblant has a wide variety of residences to suit all your needs. Customized comfort that will rejuvenate you this winter!</p>
      `,
      cta: 'Discover our lodgings',
    },
  ],
  printemps_hiver_2023_experiences: [
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/events-famille-3.jpg',
      position: "bottom",
      number: '56',
      titre: 'days of events<br>and entertainment',
      data: `
            <h3 class="small-title">Entertainment throughout the season</h3>
            <p class="regular-text">Electrify your stay with diverse and exciting events this winter! From musical concerts to sporting and charity, there's something for everyone to enjoy. Vibrate to the sound of the mountain!</p>
            <ul>
              <li class="regular-text">Festival Rando Alpine</li>
              <li class="regular-text">Jamigos</li>
              <li class="regular-text">Après ski Place Saint-Bernard</li>
              <li class="regular-text">Caribou Cup</li>
              <li class="regular-text">Easter Celebrations</li>
            </ul>
            <a href="https://www.tremblant.ca/things-to-do/events?sc_lang=en" target="_blank" class="cta red">Learn more</a>
      `,
      cta: 'Have fun at Tremblant',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/restaurants-dink-2.jpg',
      position: "bottom",
      number: '36',
      titre: 'Restaurants,<br />bars and cafes',
      data: `
            <h3 class="small-title">36 restaurants, bars and cafes</h3>
            <p class="regular-text">While staying at Tremblant, enjoy a wide variety of restaurants throughout the ski resort. Whether it's lunchtime or after a day of playing outdoors in the snow, there's always a delicious blend of flavours just waiting for your taste buds.</p>
      `,
      cta: 'Discover our restaurants',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/activites-dink-2.jpg',
      position: "bottom",
      number: '33',
      titre: 'ways<br>to have fun',
      data: `
            <h3 class="small-title">Tremblant: Where there's plenty of fun</h3>
            <p class="regular-text">Make the most of your vacation at Tremblant and check out our array of winter activities that are all about fun. At Tremblant, there's always a reason to have a blast in the heart of a buzzing village!</p>
      `,
      cta: 'Explore our activities',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/hebergement-dink-2.jpg',
      position: "bottom",
      number: '1900',
      titre: 'lodging<br>units',
      data: `
            <h3 class="small-title">Lodging on the resort</h3>
            <p class="regular-text">On the mountainside, in the heart of the pedestrian village or near the golf courses, Tremblant has a wide variety of residences to suit all your needs. Customized comfort that will rejuvenate you this winter!</p>
      `,
      cta: 'Discover our lodgings',
    },
  ],
  dink_hiver_2023_activity: [
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/patin-dink.jpg',
      titre: `
            <p class="big">Lodging</p>
            <p class="small-title">advantages</p>
      `,
      data: `<h3 class="small-title">Enjoy resort benefits close to your lodging when you stay at Tremblant</h3>
              <p class="regular-text">Book your accommodation at the resort and discover the magic of Tremblant day or night. You can borrow skates, boot hockey equipment and snow sliding equipment, benefits exclusively available to those staying at the resort, and enjoy the illuminated skating rink and other thrilling activities by the Saint-Bernard Chapel. A truly magical world easily accessible, all within walking distance of your lodging!</p>
      `,
      cta: 'Discover our benefits',
    },
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/village-dink-2.jpg',
      titre: `
            <p class="big">Pedestrian</p>
            <p class="small-title">Village</p>
      `,
      data: `<h3 class="small-title">A pedestrian village buzzing with energy!</h3>
              <p class="regular-text">Live the Tremblant winter experience and discover the excitement of our pedestrian village at the foot of Mont Tremblant.  No matter what you want to do, you'll find the right activity.</p>
              <ul>
                <li class="regular-text">Indoor activities</li>
                <!-- <li class="regular-text">Jardins de glace</li> -->
                <li class="regular-text">Brind'O</li>
                <li class="regular-text">Shopping</li>
                <li class="regular-text">Mont Tremblant Casino</li>
                <li class="regular-text">Skating</li>
                <li class="regular-text">Spas and massages</li>
                <li class="regular-text">Creative studio</li>
              </ul>
      `,
      cta: 'Discover the village',
    },
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/feerique-dink-2.jpg',
      titre: `
            <p class="big">Magical</p>
            <p class="small-title">atmosphere</p>
      `,
      data: `<h3 class="small-title">Fairytale atmosphere at the rendezvous</h3>
              <p class="regular-text">Overlooked by a majestic summit, the Tremblant environment is a source of many memories. Enjoy a festive, colourful, enchanting site and experience the joys of winter!</p>
      `,
      cta: 'Putting on a show',
    },
  ],
  famille_hiver_2023_activity: [
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/patin-famille.jpg',
      titre: `
            <p class="big">Lodging</p>
            <p class="small-title">advantages</p>
      `,
      data: `<h3 class="small-title">Enjoy resort benefits close to your lodging when you stay at Tremblant</h3>
              <p class="regular-text">Book your accommodation at the resort and discover the magic of Tremblant day or night. You can borrow skates, boot hockey equipment and snow sliding equipment, benefits exclusively available to those staying at the resort, and enjoy the illuminated skating rink and other thrilling activities by the Saint-Bernard Chapel. A truly magical world easily accessible, all within walking distance of your lodging!</p>
      `,
      cta: 'Discover our benefits',
    },
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/village-famille.jpg',
      titre: `
            <p class="big">Pedestrian</p>
            <p class="small-title">village</p>
      `,
      data: `<h3 class="small-title">A pedestrian village buzzing with energy!</h3>
              <p class="regular-text">Live the Tremblant winter experience and discover the excitement of our pedestrian village at the foot of Mont Tremblant.  No matter what you want to do, you'll find the right activity.</p>
              <ul>
                <li class="regular-text">Indoor activities</li>
                <!-- <li class="regular-text">Jardins de glace</li> -->
                <li class="regular-text">Brind'O</li>
                <li class="regular-text">Shopping</li>
                <li class="regular-text">Mont Tremblant Casino</li>
                <li class="regular-text">Skating</li>
                <li class="regular-text">Spas and massages</li>
                <li class="regular-text">Creative studio</li>
              </ul>
      `,
      cta: 'Discover the village',
    },
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/feerique-dink-2.jpg',
      titre: `
            <p class="big">Magical</p>
            <p class="small-title">atmosphere</p>
      `,
      data: `<h3 class="small-title">Fairytale atmosphere at the rendezvous</h3>
              <p class="regular-text">Overlooked by a majestic summit, the Tremblant environment is a source of many memories. Enjoy a festive, colourful, enchanting site and experience the joys of winter!</p>
      `,
      cta: 'Putting on a show',
    },
  ],
  general_hiver_2023_activity: [
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/patin-dink.jpg',
      titre: `
            <p class="big">Lodging</p>
            <p class="small-title">advantages</p>
      `,
      data: `<h3 class="small-title">Enjoy resort benefits close to your lodging when you stay at Tremblant</h3>
              <p class="regular-text">Book your accommodation at the resort and discover the magic of Tremblant day or night. You can borrow skates, boot hockey equipment and snow sliding equipment, benefits exclusively available to those staying at the resort, and enjoy the illuminated skating rink and other thrilling activities by the Saint-Bernard Chapel. A truly magical world easily accessible, all within walking distance of your lodging!</p>
      `,
      cta: 'Discover our benefits',
    },
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/village-dink-2.jpg',
      titre: `
            <p class="big">Pedestrian</p>
            <p class="small-title">Village</p>
      `,
      data: `<h3 class="small-title">A pedestrian village buzzing with energy!</h3>
              <p class="regular-text">Live the Tremblant winter experience and discover the excitement of our pedestrian village at the foot of Mont Tremblant.  No matter what you want to do, you'll find the right activity.</p>
              <ul>
                <li class="regular-text">Indoor activities</li>
                <!-- <li class="regular-text">Jardins de glace</li> -->
                <li class="regular-text">Brind'O</li>
                <li class="regular-text">Shopping</li>
                <li class="regular-text">Mont Tremblant Casino</li>
                <li class="regular-text">Skating</li>
                <li class="regular-text">Spas and massages</li>
                <li class="regular-text">Creative studio</li>
              </ul>
      `,
      cta: 'Discover the village',
    },
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/feerique-dink-2.jpg',
      titre: `
            <p class="big">Magical</p>
            <p class="small-title">atmosphere</p>
      `,
      data: `<h3 class="small-title">Fairytale atmosphere at the rendezvous</h3>
              <p class="regular-text">Overlooked by a majestic summit, the Tremblant environment is a source of many memories. Enjoy a festive, colourful, enchanting site and experience the joys of winter!</p>
      `,
      cta: 'Putting on a show',
    },
  ],
  privileges: [
    {
      title: 'First Tracks',
      image: '/vue-app-static-img/privilege-premiere-trace-new.jpg',
      details: `<h3 class="small-title">First Tracks</h3>
                    <p class="regular-text">Ski before everybody on mornings included in your pass starting at 7:45 a.m.</p>
                    <p class="regular-text">Valid from December 16, 2023, to March 17, 2024, on days when the pass is valid.</p>
                   `,
    },
    {
      title: '20 coffees/hot chocolates',
      image: '/vue-app-static-img/privilege-boissons-chaudes-2.jpg',
      details: `<h3 class="small-title">20 coffees/hot chocolates</h3>
                    <p class="regular-text">20 coffees or hot chocolates added to your season pass. Valid at the following Tremblant cafeterias: Le Grand Manitou, Chalet des Voyageurs, La Fourchette du Diable and Café Johannsen.</p>
                    <p class="regular-text">Valid from November 23, 2023, to April 14, 2024.</p>
                    <p class="regular-text">No limit per visit.</p>
                   `,
    },
    {
      title: '15% off in Tremblant cafeteria',
      image: '/vue-app-static-img/privilege-rabais-cafeteria-new.jpg',
      details: `<h3 class="small-title">15% off in Tremblant cafeteria</h3>
                    <p class="regular-text">On regular-priced at Le Grand Manitou, Chalet des voyageurs, La Fourchette du Diable and Café Johannsen.</p>
                    <p class="regular-text">Valid from November 23, 2023, to April 14, 2024.</p>
                    <p class="regular-text">Not transferable.</p>
                   `,
    },
    {
      title: '15% off in Tremblant Boutiques',
      image: '/vue-app-static-img/privilege-rabais-boutiques-new.jpg',
      details: `<h3 class="small-title">15% off in Tremblant Boutiques</h3>
                    <p class="regular-text">Applicable on regular-priced merchandise at participating Tremblant boutiques (except Ugg, Canada Goose, Arc’téryx, Blundstone articles and Tremblant's 24h hats): NOR Tremblant, Alpine, Le Skieur, Boutique Sommet des Neiges, Burton Tremblant, Chamonix, Columbia Tremblant, Explore!, Magasin de la Place, Le Manitou, Salomon Tremblant, Tremblant & CO.</p>
                    <p class="regular-text">Valid from September 1, 2023, until April 14, 2024.</p>
                    <p class="regular-text">Not transferable.</p>
                   `,
    },
    {
      title: '$200 in Tremblant Boutique',
      image: '/vue-app-static-img/privilege-carte-cadeau-new.jpg',
      details: `<h3 class="small-title">$200 in Tremblant Boutique</h3>
                    <p class="regular-text">4 gift certificates of $50 each. One gift certificate for every $300 spent after discounts and before taxes, calculated after the 15% member discount. Certificates are valid on all in-store products, even items on sale, except Club de Ski Mont-Tremblant and Club Élite Tremblant uniforms and products. Certificates must be used by January 31, 2021, at participating boutiques: NOR Tremblant, Alpine, Le Skieur, Boutique Sommet des Neiges, Burton Tremblant, Chamonix, Columbia Tremblant, Explore!, Magasin de la Place, Le Manitou, Salomon Tremblant, Tremblant & CO.</p>
                    <p class="regular-text">Valid from September 1, 2023, to January 31, 2024.</p>
                    <p class="regular-text">Non-transferable.</p>
                   `,
    },
    {
      title: '1 equipment tune-up at Le Ski Shop',
      image: '/vue-app-static-img/privilege-mise-au-point.jpg',
      details: `<h3 class="small-title">1 equipment tune-up at Le Ski Shop</h3>
                    <p class="regular-text">1 overnight equipment tune-up at Le Ski Shop located in Chalet des Voyageurs.</p>
                    <p class="regular-text">Valid as of the opening of Le Ski Shop to April 14, 2024.</p>
                    <p class="regular-text">Non-transferable.</p>
                   `,
    },
  ],
  dink_hiver_2023_domaine: [
    {
      video: '',
      img: '/vue-app-static-img/domaine/pistes-2.jpg',
      titre: `
            <p class="big number">102</p>
            <p class="small-title">ski trails</p>
      `,
      data: `<h3 class="small-title">Have fun on the slopes all season long</h3>
              <p class="regular-text">Ride the trails of the highest mountain in the Laurentides. Whether you like long trails, glades or steep hills, Tremblant has something to offer you!</p>
      `,
      cta: 'Discover the trails',
    },
    {
      video: '',
      img: '/vue-app-static-img/domaine/remontees-2.jpg',
      titre: `
            <p class="big number">14</p>
            <p class="small-title">ski lifts</p>
      `,
      data: `<h3 class="small-title">Enjoy the view from the top</h3>
              <p class="regular-text">Equipped with 14 lifts, Mont Tremblant offers the best ski trails in North America. From the village to the slopes, explore the mountain your way!</p>
      `,
      cta: 'Learn more',
    },
    {
      video: '',
      img: '/vue-app-static-img/domaine/parcs-neige.jpg',
      titre: `
            <p class="big number">3</p>
            <p class="small-title">snowparks</p>
      `,
      data: `<h3 class="small-title">Thrills guaranteed!</h3>
              <p class="regular-text">Tremblant offers 3 snow parks with 60 different modules to accompany you throughout your progression. Modules, ramps, jumps, contoured trails... Something to thrill you all season long!</p>
      `,
      cta: 'Discover the parks',
    },
  ],
  boxing_day_2023: {
    flash_boxing_day_title: 'BOXING DAY<br/>Latitude Card', // BOXING DAY - <br/> Latitude Card
    flash_boxing_day_promo: 'Ski 3 to 4 days for <br/> <span>86$</span> <br/> per day', // Ski 3 to 4 days for <br/> <span>86$</span> <br/> per day
    flash_boxing_day_cutoff: 'On sale until <br/> December 29, 2022 1 P.M.', // On sale until <br/> December 29, 2022
    flash_boxing_day_smallpopup: 'Ski 3 to 4 days for $86 <br/> per day', // Ski 3 to 4 days for $86 <br/> per day
    flash_boxing_day_details: `
      <h2 class="small-title">
              <small>Latitude Card </small>
              <br/>
              Ski 3 to 4 days for $86 per day
            </h2>
            <p class="regular-text">On sale until December 29, 2022 1 P.M.</p>
            <p class="regular-text soft">The best discounts to ski Tremblant with no compromise! Limited time offer! From December 27, 1 p.m. and December 29, 1 p.m., opt for the Latitude Card and get 3 to 4 days at the lowest price available:</p>
            <ul class="regular-text soft">
              <li>3 to 4 days of skiing or snowboarding to be used consecutively or not, for $86 per day, per adult</li>
              <li>The days can be used as you wish during the 2022/23 season with the exception of: December 27, 2022, to January 5, 2023, inclusively and The following 8 Saturdays: January 14, 21 and 28, February 11 and 18, March 4, 18 and 25, 2023.</li>
              <li>Savings of up to 33%</li>
              <li>15% discount on all additional days (discount applicable on a 1-day regular ticket office ticket)</li>
              <li>The Latitude Card is exclusive to Canadian residents.</li>
            </ul>

            <div class="price-grid wrap">
              <div class="price half">
                <p>$86 <span>/day</span><br><span>Adult (18-69)</span></p>
              </div>
              <div class="price half">
                <p>$74 <span>/day</span><br><span>Senior (70+)</span></p>
              </div>
              <div class="price half">
                <p>$65 <span>/day</span><br><span>Youth (13-17)</span></p>
              </div>
              <div class="price half">
                <p>$49 <span>/day</span><br><span>Child (5-12)</span></p>
              </div>
            </div>

            <br/>

            <p class="regular-text soft">Take advantage of the Latitude Card! Ski tickets for Tremblant will be on sale only through our platforms.</p>
          `,
  },
  flash_janvier_2023: {
    flash_janvier_deal1: 'Save up to <br/> <span>20%</span> <br/> on lodging', //
    flash_janvier_deal2: 'Save up to <br/> <span>25%</span> <br/> on your lift tickets', //
    flash_janvier_cutoff: 'On sale until <br/> January 12, 2022 1 P.M.', //
    flash_janvier_smallpopup: 'Save up to 20% <br/> on lodging', //
    flash_janvier_details: `<h2 class="small-title">TIME FOR A WINTER VACATION IN TREMBLANT FLASH SALE</h2>

            <p class="regular-text soft">
              Enjoy great savings on a winter getaway at the mountain!
              <br/><br/>
              This winter, live the Tremblant joie de vivre and take advantage of the best offer on a two-night stay or more! 
              <br/><br/>
              Are you looking for the best place where to spend a winter wonderland getaway between January 11 and April 30, 2023? Book your lodging during our flash sale from January 10 to 12, 2023, and you’ll get up to 20% off! 
              <br/><br/>
              Enjoy additional discounts on lift tickets with our Ticket Outlet
            </p>`,
    flash_janvier_details2: `<h2 class="small-title">Tremblant Ticket Outlet</h2>
            <h2 class="small-title">Save up to 25% on lift tickets. </h2>
            <p class="regular-text soft">
              Get lift tickets and take advantage of the mountain this winter! From January 10, 2023, 1 p.m., to January 12, 2023, 1 p.m., visit the Tremblant Ticket Outlet and save up to 25% on lift tickets. 
            </p>`,
  },
}
export const hiver2023Fr = {
  site_hiver_2023: {
    buy_card: 'Achetez votre carte',
    buy_tickets: 'Achetez vos billets',
    buy_short: 'Achetez',

    bh_lodging_hero: "Économisez<br>jusqu'à<br><span>25 %</span><br>Sur l’hébergement",
    bh_lodging_title: "Économisez jusqu'à 25 %<br>Sur l’hébergement",
    bh_lodging_pop: 'meilleures disponibilités<br>et rabais de l’année',
    bh_lodging_pop_cta: 'Afficher les détails',
    bh_lodging_cut: "Offres valables jusqu'au 18 octobre 2022",
    bh_lodging_link: 'https://www.tremblant.ca/planifiez/hotels-condos?sc_lang=fr-CA',
    bh_lodging_data: `
                      <h2 class="small-title">Économisez jusqu'à<br>25% sur l’hébergement</h2>
                      <p class="regular-text">Offre valable jusqu'au 18 octobre 2022</p>
                      <p class="regular-text soft">Vous avez jusqu’au 18 octobre 2022 pour accéder aux meilleures unités tout en bénéficiant des plus importants rabais de la saison! Planifiez votre séjour dès maintenant, même en prévision de Noël ou de Pâques, et obtenez:</p>
                      <div class="price-grid">
                        <div class="price">
                          <p>25%<br><span>4 nuits ou +</span></p>
                        </div>
                        <div class="price">
                          <p>20%<br><span>3 nuits</span></p>
                        </div>
                        <div class="price">
                          <p>15%<br><span>2 nuits</span></p>
                        </div>
                      </div>
    `,
    bh_lodging_meta_title: "Économisez jusqu'à 25 % sur l’hébergement",
    bh_lodging_meta_desc:
      'Vous avez jusqu’au 18 octobre 2022 pour accéder aux meilleures unités tout en bénéficiant des plus importants rabais de la saison!',

    bh_ski_hero_dink: 'Skiez 2 à 7 jours<br>à partir de<br><span>85$</span><br>/jour',
    bh_ski_hero_famille: 'Skiez 2 à 7 jours<br>à partir de<br><span>49$</span><br>/jour enfant (5-12)',
    bh_ski_link: 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/fr-CA/?productcategoryid=117&startMonth=11',
    bh_ski_name: 'Billets multi-jours',
    bh_ski_data_dink: `
                      <h2 class="small-title">Skiez 2 à 7 jours<br>à partir de 85$/jour</h2>
                      <p class="regular-text">Offre valable jusqu'au 18 octobre 2022</p>
                      <p class="regular-text soft">Procurez-vous vos billets de ski avant le 18 octobre 2022 et obtenez le meilleur tarif pour la saison 2022-23.  N’hésitez pas à en profiter car, les billets de ski pour Tremblant sont en vente seulement via nos plateformes. Lundi au vendredi :</p>
                      <div class="price-grid wrap">
                        <div class="price half">
                          <p>85$ <span>/jour</span><br><span>Adulte (18-69)</span></p>
                        </div>
                        <div class="price half">
                          <p>73$ <span>/jour</span><br><span>Aîné (70+)</span></p>
                        </div>
                        <div class="price half">
                          <p>64$ <span>/jour</span><br><span>Jeune (13-17)</span></p>
                        </div>
                        <div class="price half">
                          <p>49$ <span>/jour</span><br><span>Enfant (5-12)</span></p>
                        </div>
                      </div>
    `,
    bh_ski_data_famille: `
                      <h2 class="small-title">Skiez 2 à 7 jours<br>à partir de 49$/jour enfant(5-12)</h2>
                      <p class="regular-text">Offre valable jusqu'au 18 octobre 2022</p>
                      <p class="regular-text soft">Procurez-vous vos billets de ski avant le 18 octobre 2022 et obtenez le meilleur tarif pour la saison 2022-23.  N’hésitez pas à en profiter car, les billets de ski pour Tremblant sont en vente seulement via nos plateformes. Lundi au vendredi :</p>
                      <div class="price-grid wrap">
                        <div class="price half">
                          <p>49$ <span>/jour</span><br><span>Enfant (5-12)</span></p>
                        </div>
                        <div class="price half">
                          <p>64$ <span>/jour</span><br><span>Jeune (13-17)</span></p>
                        </div>
                        <div class="price half">
                          <p>85$ <span>/jour</span><br><span>Adulte (18-69)</span></p>
                        </div>
                        <div class="price half">
                          <p>73$ <span>/jour</span><br><span>Aîné (70+)</span></p>
                        </div>
                      </div>
    `,

    sejour_title: 'Profitez de votre séjour',
    sejour_desc:
      "Sur un terrain de jeu grandeur nature, Tremblant offre une panoplie d'activités et d’événements pour tous les goûts. Durant tout l'hiver, venez profiter de ce que le village piétonnier et la montagne ont à offrir, que ce soit seul, en couple ou entre amis!",

    tonik_dink_hero: 'Passe Tonik<br><span>699 $</span><br>Adulte (18-69)',
    tonik_dink_pop: 'Passe Tonik 699 $<br>Adulte (18-69)',
    tonik_famille_hero: 'Passe Tonik<br><span>145 $</span><br>Enfant (5-12)',
    tonik_famille_pop: 'Passe Tonik 145 $<br>Enfant (5-12)',
    tonik_cut_off: 'Les prix augmentent le 14 octobre 2022',
    tonik_link:
      'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=42&ProductCategoryCode=276&ClientId=802&CultureName=fr-CA&_ga=2.226458716.711625545.1659354497-858024303.1585064247&_gl=1*lj06wu*_ga*ODU4MDI0MzAzLjE1ODUwNjQyNDc.*_ga_B1YVPN56M6*MTY1OTU0NzE5OC42OC4wLjE2NTk1NDcxOTguNjA.',
    tonik_argument_1: "Jusqu'à 111 jours de glisse fixes",
    tonik_argument_2: "Jusqu'à 25 journées de fin de semaine",
    tonik_argument_3: '3 jours flottants pour utilisation en tout temps',
    tonik_dink_data: `
                      <h2 class="small-title">Passe Tonik 739 $<br>Adulte (18-69)</h2>
                      <p class="regular-text soft">Une saison à votre mesure! Procurez-vous la passe Tonik 2022-2023 dès maintenant au meilleur tarif de l’année et donnez du tonus à votre hiver en profitant des pentes presque toute la saison. La passe Tonik, c’est :</p>
                      <ul>
                        <li class="regular-text soft">Jusqu'à 111 jours de glisse et 3 flottants pour utilisation en tout temps, même durant la période des fêtes! (à l’exception des 29 et 30 décembre 2022, 18 et 19 février 2023). <a href="https://medias.tremblant.ca/pdf/ski/calendrier-passe-tonik-2022-23.pdf?_gl=1*jvzstm*_ga*ODEzODI4NTg3LjE2NTE3NzUwNzI.*_ga_B1YVPN56M6*MTY2MDc0MzkxNy4xMTAuMS4xNjYwNzQzOTU0LjIzLjAuMA..&_ga=2.100092573.2082659159.1660663990-813828587.1651775072&_gac=1.194134623.1660316223.CjwKCAjw9NeXBhAMEiwAbaY4lmXiToIk2iekz3zN-dJpgiOy0JUsQLmmttZ98NApHzpLnlQjSR10AhoCTywQAvD_BwE" target="_blank">Calendrier 2022/2023</a></li>
                        <li class="regular-text soft">Jusqu’à 25 journées de fin de semaine.</li>
                        <li class="regular-text soft">Des journées durant les relâches scolaires</li>
                        <li class="regular-text soft">15 % de rabais sur toutes les journées non incluses</li>
                        <li class="regular-text soft">Forfait de privilèges pour détenteurs pour seulement 159 $ donnant accès aux Premières traces et à d’autres offres exclusives aux détenteurs!</li>
                        <li class="regular-text soft">Tarif de renouvellement offert exclusivement aux détenteurs de passe Tonik 2022-23.</li>
                        <li class="regular-text soft">Passe de randonnée alpine incluse.</li>
                        <li class="regular-text soft">La passe Tonik est offerte en exclusivité aux résidents canadiens</li>
                      </ul>
                      <div class="price-grid wrap">
                        <div class="price half">
                          <p>469 $<br><span>Étudiant (18-25)</span></p>
                        </div>
                        <div class="price half">
                          <p>739 $<br><span>Adulte (18-69)</span></p>
                        </div>
                        <div class="price half">
                          <p>595 $<br><span>Aîné (70+)</span></p>
                        </div>
                        <div class="price half">
                          <p>259 $<br><span>Maître (80+)</span></p>
                        </div>
                      </div> 
    `,
    tonik_famille_data: `
                      <h2 class="small-title">Passe Tonik 165 $<br>Enfant (5-12)</h2>
                      <p class="regular-text soft">Une saison à votre mesure! Procurez-vous la passe Tonik 2022-2023 dès maintenant au meilleur tarif de l’année et donnez du tonus à votre hiver en profitant des pentes presque toute la saison. La passe Tonik, c’est :</p>
                      <ul>
                        <li class="regular-text soft">Jusqu'à 111 jours de glisse et 3 flottants pour utilisation en tout temps, même durant la période des fêtes! (à l’exception des 29 et 30 décembre 2022, 18 et 19 février 2023). <a href="https://medias.tremblant.ca/pdf/ski/calendrier-passe-tonik-2022-23.pdf?_gl=1*jvzstm*_ga*ODEzODI4NTg3LjE2NTE3NzUwNzI.*_ga_B1YVPN56M6*MTY2MDc0MzkxNy4xMTAuMS4xNjYwNzQzOTU0LjIzLjAuMA..&_ga=2.100092573.2082659159.1660663990-813828587.1651775072&_gac=1.194134623.1660316223.CjwKCAjw9NeXBhAMEiwAbaY4lmXiToIk2iekz3zN-dJpgiOy0JUsQLmmttZ98NApHzpLnlQjSR10AhoCTywQAvD_BwE" target="_blank">Calendrier 2022/2023</a></li>
                        <li class="regular-text soft">Jusqu’à 25 journées de fin de semaine.</li>
                        <li class="regular-text soft">Des journées durant les relâches scolaires</li>
                        <li class="regular-text soft">15 % de rabais sur toutes les journées non incluses</li>
                        <li class="regular-text soft">Forfait de privilèges pour détenteurs pour seulement 119 $ donnant accès aux Premières traces et à d’autres offres exclusives aux détenteurs!</li>
                        <li class="regular-text soft">Tarif de renouvellement offert exclusivement aux détenteurs de passe Tonik 2022-23.</li>
                        <li class="regular-text soft">Passe de randonnée alpine incluse.</li>
                        <li class="regular-text soft">La passe Tonik est offerte en exclusivité aux résidents canadiens</li>
                      </ul>
                      <div class="price-grid wrap">
                        <div class="price half">
                          <p>45 $<br><span>Peewee (0-4)</span></p>
                        </div>
                        <div class="price half">
                          <p>165 $<br><span>Enfant (5-12)</span></p>
                        </div>
                        <div class="price half">
                          <p>469 $<br><span>Jeune (13-17)</span></p>
                        </div>
                        <div class="price half">
                          <p>469 $<br><span>Étudiant (18-25)</span></p>
                        </div>
                      </div> 
    `,
    tonik_dink_meta_title: 'Passe Tonik 699 $ Adulte (18-69)',
    tonik_dink_meta_desc: 'Procurez-vous la passe Tonik 2022-2023 dès maintenant au meilleur tarif de l’année.',

    tonik_famille_meta_title: 'Passe Tonik 145 $ Enfant (5-12)',
    tonik_famille_meta_desc: 'Procurez-vous la passe Tonik 2022-2023 dès maintenant au meilleur tarif de l’année.',

    latitude_hero: 'Skiez 3 à 4 jours pour<br><span>82 $</span><br>par jour',
    latitude_pop: 'Skiez 3 à 4 jours pour<br>82 $ par jour',
    latitude_cut_off: "Offre valable jusqu'au 6 octobre 2022",
    latitude_link:
      'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=3&ProductCategoryCode=64&ClientId=802&CultureName=fr-CA',
    latitude_argument_1: '3 à 4 journées de glisse',
    latitude_argument_2: 'Jusqu’à 36 % d’économies',
    latitude_argument_3: '15 % de rabais sur toutes journées additionnelles',
    latitude_name: 'Carte latitude',
    latitude_data: `
                      <h2 class="small-title">Skiez 3 à 4 jours pour<br>82 $ par jour</h2>
                      <p class="regular-text">Offre valable jusqu'au 6 octobre 2022</p>
                      <p class="regular-text soft">Skiez Tremblant à votre façon. Optez pour la carte Latitude pour vos sorties de ski à Tremblant cet hiver. Choisissez de 3 à 4 jours de glisse au plus bas tarif disponible:</p>
                      <ul>
                        <li class="regular-text soft">3 à 4 journées de ski ou de planche, consécutives ou non, à partir de 82 $ par jour, par adulte</li>
                        <li class="regular-text soft">Les journées peuvent être utilisées à votre guise durant la saison de ski 2022-2023, à l’exception du 27 décembre 2022 au 5 janvier 2023 inclusivement et les 8 samedis suivants : 14, 21 et 28 janvier 2023, 11 et 18 février 2023, 4, 18 et 25 mars 2023.</li>
                        <li class="regular-text soft">Jusqu’à 36 % d’économies</li>
                        <li class="regular-text soft">15 % de rabais sur les journées additionnelles (applicable sur un billet régulier 1-jour, tarification billetterie)</li>
                        <li class="regular-text soft">La Carte Latitude est offerte en exclusivité aux résidents canadiens.</li>
                      </ul>
                      <p class="regular-text soft">N’hésitez pas à en profiter, car les billets de ski pour Tremblant seront en vente seulement via nos plateformes.</p>
    `,
    latitude_meta_title: 'Skiez 3 à 4 jours pour 82 $ par jour',
    latitude_meta_desc: 'Skiez Tremblant à votre façon. Optez pour la carte Latitude pour vos sorties de ski à Tremblant cet hiver.',

    latitude_product_1: `
                      <div class="row">
                        <p class="small-title">Billets multi-jours à partir de</p>
                        <p class="medium-title">85 $</p>
                        <p class="small-title">Par jour</p>
                      </div>
                      <div class="row">
                        <p class="small-title">2 à 7 journées de glisse</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Utilisation consécutive</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Offre valable jusqu’au<br>18 octobre 2022</p>
                      </div>
    `,
    latitude_product_2: `
                      <div class="row">
                        <p class="small-title">Carte latitude</p>
                        <p class="medium-title">82 $</p>
                        <p class="small-title">Par jour</p>
                      </div>
                      <div class="row">
                        <p class="small-title">3 à 4 journées de glisse</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Utilisation spontanée</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Offre valable jusqu'au<br>6 octobre 2022</p>
                      </div>
    `,
    latitude_product_3: `
                      <div class="row">
                        <p class="small-title">Passe tonik</p>
                        <p class="medium-title">699 $</p>
                        <p class="small-title">Saison 2022 - 2023</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Jusqu'à 111 journées de glisse</p>
                      </div>
                      <div class="row">
                        <p class="small-title">utilisation en tout temps</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Les prix augmentent<br>le 14 octobre 2022</p>
                      </div>
    `,

    privilege_card_title: 'Bonifiez votre expérience<br>avec le forfait de privilèges',
    privilege_price_1: '<p>175 $<br><span>Adultes (18 ans et +)</span></p>',
    privilege_price_2: '<p>125 $<br><span>Jeune (0-17)</span></p>',
    privilege_cta_title: 'ACHETEZ VOTRE FORFAIT PRIVILÈGES',
    privilege_cta_link:
      'https://www.ikonpass.com/fr/local-passes/tremblant/privilege-bundle?currency=CAD&co-branded=tremblant',

    domaine_title: 'Plus de liberté. Plus de ski.<br>Plus de plaisir.',
    domaine_title_latitude: 'Éclatez-vous sur les pentes',
    domaine_desc:
      "Le sommet de Tremblant se dresse à 875 mètres (2 871 pieds) et offre une vue imprenable sur les Laurentides. Le domaine skiable de la station, d'une superficie totale de 305 hectares (755 acres), comprend 102 pistes réparties sur 4 versants.",

    rando_title: 'Passe de randonnée alpine<br>incluse avec votre passe tonik',
    rando_data:
      '<p class="regular-text">Profitez de nos zones d’ascension désignées<br><br>Venez faire l’ascension de la plus haute montagne des Laurentides, skis aux pieds, dans l’une de nos zones désignées ou pistes boisées et redescendez dans l’une de nos 102 pistes de ski balisées!</p>',

    apres_ski_title: 'Profitez de votre après-ski',

    price_grid_title: 'Faites le choix qui convient<br>le mieux à vos besoins',

    al_lodging_hero: "Économisez<br>jusqu'à<br><span>15 %</span><br>Sur l’hébergement",
    al_lodging_title: "Économisez jusqu'à 15 %<br>Sur l’hébergement",
    al_lodging_pop: "Économisez jusqu'à 15 %<br>Sur l’hébergement",
    al_lodging_pop_cta: 'Afficher les détails',
    al_lodging_cut: 'En vente jusqu’au 29 avril 2023',
    al_lodging_link: 'https://www.tremblant.ca/planifiez/hotels-condos?sc_lang=fr-CA',
    al_lodging_data: `
                      <h2 class="small-title">Économisez jusqu'à<br>15% sur l’hébergement</h2>
                      <p class="regular-text soft">Profitez de cette offre tout au long de la saison! Du 30 novembre 2022 au 29 avril 2023, réservez un séjour de deux nuits et plus pour des séjours du 1er décembre 2022 au 30 avril 2023 et obtenez:</p>
                      <div class="price-grid">
                        <div class="price half">
                          <p>15%<br><span>3 nuits ou +</span></p>
                        </div>
                        <div class="price half">
                          <p>10%<br><span>2 nuits</span></p>
                        </div>
                      </div>
    `,
    al_lodging_meta_title: "Économisez jusqu'à 15 % sur l’hébergement",
    al_lodging_meta_desc: 'Planifiez aujourd’hui et économisez pour la saison hivernale à Tremblant. Réservez d’ici au 29 avril 2023 et économisez jusqu’à 15 %.',

    al_ski_hero_dink: 'Skiez 2 à 7 jours<br>à partir de<br><span>89$</span><br>/jour',
    al_ski_link: 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/fr-CA/?productcategoryid=117&startMonth=11',
    al_ski_name: 'Billets multi-jours',
    al_ski_data_dink: `
                      <h2 class="small-title">Skiez 2 à 7 jours<br>à partir de 89$/jour</h2>
                      <p class="regular-text">Offre valide jusq'au 29 novembre 2022</p>
                      <p class="regular-text soft">Réservez à l'avance et sécurisez le meilleur prix. Procurez-vous vos billets de ski avant le 18 octobre 2022 et obtenez le meilleur tarif pour la saison 2022-23. N’hésitez pas à en profiter car, les billets de ski pour Tremblant sont en vente seulement via nos plateformes. Lundi au vendredi :</p>
                      <div class="price-grid wrap">
                        <div class="price half">
                          <p>89$ <span>/jour</span><br><span>Adulte (18-69)</span></p>
                        </div>
                        <div class="price half">
                          <p>89$ <span>/jour</span><br><span>Aîné (70+)</span></p>
                        </div>
                        <div class="price half">
                          <p>67$ <span>/jour</span><br><span>Jeune (13-17)</span></p>
                        </div>
                        <div class="price half">
                          <p>51$ <span>/jour</span><br><span>Enfant (5-12)</span></p>
                        </div>
                      </div>
    `,

    al_entrepot_hero: '<span class="smaller">Entrepôt de billets<br>Jusqu’à<br><span>20%</span><br>de rabais sur les billets de ski</span>',
    al_entrepot_hero_small: 'Économisez<br>Jusqu’à<br><span>20%</span><br>sur les billets de ski',
    al_entrepot_title: 'Économisez jusqu’à 20% de rabais sur les billets de ski',
    al_entrepot_pop: 'Jusqu’à 20% de rabais<br>sur les billets de ski',
    al_entrepot_pop_cta: 'Afficher les détails',
    al_entrepot_link: 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/fr-CA/?productcategoryid=117',
    al_entrepot_data: `
                      <h2 class="small-title">Économisez jusqu’à 20% de rabais sur les billets de ski</h2>
                      <p class="regular-text soft">Économiser jusqu’à 20 % à l’achat de 1 à 5 journées de glisse! Vous y trouverez des tarifs variables pour skier Tremblant en fonction de la date et du nombre de jours que vous choisirez. Quantités journalières limitées. Billets d’après-midi (½ jour) également disponibles pour skier à partir de midi.</p>
    `,
    al_entrepot_meta_title: 'Économisez jusqu’à 20% de rabais sur les billets de ski',
    al_entrepot_meta_desc: 'Bienvenue dans l’entrepôt de billets Tremblant! Vous y trouverez des tarifs variables pour skier Tremblant en fonction de la date et du nombre de jours que vous choisirez. Cette façon conviviale d’acheter vos billets de ski vous fera économiser jusqu’à 20%!',

    launch_lodging_hero: "Économisez<br>jusqu'à<br><span>20 %</span><br>Sur l’hébergement",
    launch_lodging_title: "Économisez jusqu'à 20 %<br>Sur l’hébergement",
    launch_lodging_pop: "Économisez jusqu'à 20 %<br>Sur l’hébergement",
    launch_lodging_pop_cta: 'Afficher les détails',
    launch_lodging_cut: 'En vente jusqu’au 29 novembre 2022',
    launch_lodging_link: 'https://www.tremblant.ca/planifiez/hotels-condos?sc_lang=fr-CA',
    launch_lodging_data: `
                      <h2 class="small-title">Économisez jusqu'à<br>20% sur l’hébergement</h2>
                      <p class="regular-text">En vente jusqu’au 29 novembre 2022</p>
                      <p class="regular-text soft">Offre séjour de rêve cet hiver. Réservez vos vacances à Tremblant dès maintenant pour économiser ! Planifiez aujourd’hui et économisez pour la saison hivernale à Tremblant. Réservez d’ici au 29 novembre 2022 et économisez jusqu’à 20 %, même pour des séjours à Noël et à Pâques :</p>
                      <div class="price-grid">
                        <div class="price half">
                          <p>20%<br><span>3 nuits ou +</span></p>
                        </div>
                        <div class="price half">
                          <p>10%<br><span>2 nuits</span></p>
                        </div>
                      </div>
                      <br>
                      <p class="regular-text soft">Les avantages de réserver tôt :</p>
                      <ul>
                        <li class="regular-text">Meilleure offre pour des séjours d’hiver</li>
                        <li class="regular-text">Meilleure sélection d’hébergement</li>
                        <li class="regular-text">Plus de temps pour planifier tous les détails de votre séjour</li>
                      </ul>
    `,
    launch_lodging_meta_title: "Économisez jusqu'à 20 % sur l’hébergement",
    launch_lodging_meta_desc:
      'Offre séjour de rêve cet hiver. Réservez vos vacances à Tremblant dès maintenant pour économiser ! Planifiez aujourd’hui et économisez pour la saison hivernale à Tremblant.',

    launch_ski_hero_dink: 'Skiez 2 à 7 jours<br>à partir de<br><span>89$</span><br>/jour',
    launch_ski_hero_famille: 'Skiez 2 à 7 jours<br>à partir de<br><span>51$</span><br>/jour enfant (5-12)',
    launch_ski_link: 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/fr-CA/?productcategoryid=117&startMonth=11',
    launch_ski_name: 'Billets multi-jours',
    launch_ski_data_dink: `
                      <h2 class="small-title">Skiez 2 à 7 jours<br>à partir de 89$/jour</h2>
                      <p class="regular-text">En vente jusqu'au 29 novembre 2022</p>
                      <p class="regular-text soft">Réservez à l'avance et sécurisez le meilleur prix. Procurez-vous vos billets de ski avant le 29 novembre 2022 et obtenez le meilleur tarif pour la saison 2022-23. N’hésitez pas à en profiter car, les billets de ski pour Tremblant sont en vente seulement via nos plateformes. Lundi au vendredi :</p>
                      <div class="price-grid wrap">
                        <div class="price half">
                          <p>89$ <span>/jour</span><br><span>Adulte (18-69)</span></p>
                        </div>
                        <div class="price half">
                          <p>77$ <span>/jour</span><br><span>Aîné (70+)</span></p>
                        </div>
                        <div class="price half">
                          <p>67$ <span>/jour</span><br><span>Jeune (13-17)</span></p>
                        </div>
                        <div class="price half">
                          <p>51$ <span>/jour</span><br><span>Enfant (5-12)</span></p>
                        </div>
                      </div>
    `,
    launch_ski_data_famille: `
                      <h2 class="small-title">Skiez 2 à 7 jours<br>à partir de 51$/jour enfant(5-12)</h2>
                      <p class="regular-text">Offre valide jusqu'au 29 novembre 2022</p>
                      <p class="regular-text soft">Réservez à l'avance et sécurisez le meilleur prix. Procurez-vous vos billets de ski avant le 29 novembre 2022 et obtenez le meilleur tarif pour la saison 2022-23. N’hésitez pas à en profiter car, les billets de ski pour Tremblant sont en vente seulement via nos plateformes. Lundi au vendredi :</p>
                      <div class="price-grid wrap">
                        <div class="price half">
                          <p>51$ <span>/jour</span><br><span>Enfant (5-12)</span></p>
                        </div>
                        <div class="price half">
                          <p>67$ <span>/jour</span><br><span>Jeune (13-17)</span></p>
                        </div>
                        <div class="price half">
                          <p>89$ <span>/jour</span><br><span>Adulte (18-69)</span></p>
                        </div>
                        <div class="price half">
                          <p>77$ <span>/jour</span><br><span>Aîné (70+)</span></p>
                        </div>
                      </div>
    `,

    launch_latitude_hero: 'Skiez 3 à 4 jours pour<br><span>86 $</span><br>par jour',
    launch_latitude_pop: 'Skiez 3 à 4 jours pour<br>86 $ par jour',
    launch_latitude_cut_off: "En vente jusqu'au 22 novembre",
    launch_latitude_link:
      'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=3&ProductCategoryCode=64&ClientId=802&CultureName=fr-CA',
    launch_latitude_argument_1: '3 à 4 journées de glisse',
    launch_latitude_argument_2: 'Jusqu’à 33 % d’économies',
    launch_latitude_argument_3: '15 % de rabais sur toutes journées additionnelles',
    launch_latitude_name: 'Carte latitude',
    launch_latitude_data: `
                      <h2 class="small-title">Skiez 3 à 4 jours pour<br>86 $ par jour</h2>
                      <p class="regular-text">En vente jusqu'au 22 novembre</p>
                      <p class="regular-text soft">Skiez Tremblant à votre façon. Optez pour la carte Latitude pour vos sorties de ski à Tremblant cet hiver. Choisissez de 3 à 4 jours de glisse au plus bas tarif disponible:</p>
                      <ul>
                        <li class="regular-text soft">3 à 4 journées de ski ou de planche, consécutives ou non, pour 86 $ par jour, par adulte.</li>
                        <li class="regular-text soft">Les journées peuvent être utilisées à votre guise durant la saison de ski 2022-2023, à l’exception du 27 décembre 2022 au 5 janvier 2023 inclusivement et les 8 samedis suivants : 14, 21 et 28 janvier 2023, 11 et 18 février 2023, 4, 18 et 25 mars 2023.</li>
                        <li class="regular-text soft">Jusqu’à 33 % d’économies</li>
                        <li class="regular-text soft">15 % de rabais sur les journées additionnelles (applicable sur un billet régulier 1-jour, tarification billetterie)</li>
                        <li class="regular-text soft">La Carte Latitude est offerte en exclusivité aux résidents canadiens.</li>
                      </ul>
                      <p class="regular-text soft">N’hésitez pas à en profiter car, les billets de ski pour Tremblant seront en vente seulement via nos plateformes.</p>
    `,
    launch_latitude_meta_title: 'Skiez 3 à 4 jours pour 86 $ par jour',
    launch_latitude_meta_desc: 'Skiez Tremblant à votre façon. Optez pour la carte Latitude pour vos sorties de ski à Tremblant cet hiver.',

    launch_latitude_product_1: `
                      <div class="row">
                        <p class="small-title">Billets multi-jours à partir de</p>
                        <p class="medium-title">89 $</p>
                        <p class="small-title">Par jour</p>
                      </div>
                      <div class="row">
                        <p class="small-title">2 à 7 journées de glisse</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Utilisation consécutive</p>
                      </div>
                      <div class="row">
                        <p class="small-title">En vente jusqu’au<br>29 novembre 2022</p>
                      </div>
    `,
    launch_latitude_product_2: `
                      <div class="row">
                        <p class="small-title">Carte latitude</p>
                        <p class="medium-title">86 $</p>
                        <p class="small-title">Par jour</p>
                      </div>
                      <div class="row">
                        <p class="small-title">3 à 4 journées de glisse</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Utilisation spontanée</p>
                      </div>
                      <div class="row">
                        <p class="small-title">En vente jusqu'au<br>22 novembre 2022</p>
                      </div>
    `,
    launch_latitude_product_3: `
                      <div class="row">
                        <p class="small-title">Passe tonik</p>
                        <p class="medium-title">739 $</p>
                        <p class="small-title">Saison 2022 - 2023</p>
                      </div>
                      <div class="row">
                        <p class="small-title">Jusqu'à 111 journées de glisse</p>
                      </div>
                      <div class="row">
                        <a href="https://medias.tremblant.ca/pdf/ski/calendrier-passe-tonik-2022-23.pdf" target="_blank"><p class="small-title">Calendrier 2022-23</p></a>
                      </div>
                      <div class="row">
                        <p class="small-title">Les prix augmentent<br>le 9 décembre 2022</p>
                      </div>
    `,

    ski_magazine: "Nommé #1 pour l'après-ski par Ski Magazine",
    ski_magazine_link: 'https://www.tremblant.ca/a-propos/prix-excellence',

    flash_dec_hero: 'Billets transférables<br>à partir de<br><span>90$</span><br>Achat en paire',
    flash_dec_pop: 'Billets à partir de 90$<br>Achat en paire',
    flash_dec_cut_off: "En vente jusqu'au<br>9 décembre 2022 13h.",
    flash_dec_link: 'https://reservations.tremblant.ca/ecomm/shop/merchandise/2624850/fr-CA/?productsupercategoryid=23',
    flash_dec_promo_heb: "ÉCONOMISEZ JUSQU'À<br>15% SUR L’HÉBERGEMENT",
    flash_dec_data: `
                      <h2 class="small-title">Vente éclair<br>Billets à partir de 90 $<br>Achat en paire</h2>
                      <p class="regular-text">En vente jusqu'au 9 décembre 2022 13h.</p>
                      <p class="regular-text soft">Obtenez des paires de billets de ski transférables à un excellent tarif pour profiter des pistes à trois moments forts de la saison! Gardez-les pour vous, invitez des amis ou glissez-les sous l’arbre de Noël! Les billets, vendus en paires, sont valables pour les périodes suivantes :</p>
                      <div class="table split_details">
                        <div class="row nbb">
                          <div class="cell-35">
                            <p class="regular-text"><span>Début de saison</span><br>8 au 23 déc. 2022</p>
                          </div>
                          <div class="cell-65">
                            <p class="regular-text"><span>95 $ par billet</span><br>Achat en paire<br>Non valable les samedis</p>
                          </div>
                        </div>
                        <div class="row nbb">
                          <div class="cell-35">
                            <p class="regular-text"><span>Cœur de l’hiver</span><br>9 jan. au 10 fév. 2023</p>
                          </div>
                          <div class="cell-65">
                            <p class="regular-text"><span>90 $ par billet</span><br>Achat en paire<br>Non valable les samedis et dimanches</p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="cell-35">
                            <p class="regular-text"><span>Ski de printemps</span><br>20 mar. au 16 avr. 2023</p>
                          </div>
                          <div class="cell-65">
                            <p class="regular-text"><span>95 $ par billet</span><br>Achat en paire<br>Non valable les samedis</p>
                          </div>
                        </div>
                      </div>
                      <p class="regular-text soft">Redevance et taxes en sus. Ces tarifs, dates, redevance et taxes sont modifiables sans préavis.</p>
    `,
    flash_dec_meta_title: 'Vente éclair - Billets à partir de 90 $ - Achat en paire',
    flash_dec_meta_desc:
      'Obtenez des paires de billets de ski transférables à un excellent tarif pour profiter des pistes à trois moments forts de la saison!',

    flash_bd_link:
      'https://ecom.tremblant.ca/eStore/Content/Commerce/Products/DisplayProducts.aspx?ProductGroupCode=41&ProductCategoryCode=17301&ClientId=802&CultureName=fr-CA',
    flash_bd_meta_title: 'OFFRE D’APRÈS-NOËL - Carte Latitude - Skiez 3 à 4 jours pour 86$ par jour',
    flash_bd_meta_desc:
      'Skiez Tremblant à votre façon. De retour pour une période limitée! Du 27 décembre 13h au 29 décembre 13h, optez pour la carte Latitude pour vos sorties de ski à Tremblant cet hiver.',

    flash_jan_meta_title: "Économisez jusqu'à 20 % sur l’hébergement et 25 % sur les billets de ski.",
    flash_jan_meta_desc:
      'Cet hiver, faites l’expérience de la joie de vivre de Tremblant et profitez de la meilleure offre sur un séjour de 2 nuits ou plus !',

    brindo_deal: 'Économisez 20% sur l’accès journalier à brind’O',
    brindo_data: `
                      <h2 class="small-title">Économisez 20% sur l’accès journalier à brind’O</h2>
                      <ul>
                        <li class="regular-text soft">Sur présentation d'une preuve d'hébergement au Centre de villégiature (carte de chambre, carte visiteur, carte Montagnard) ou d’un accès ski (billet de remontée de la journée, passe de ski 2022-2023 (Ikon Pass, passe Tonik, carte Latitude)</li>
                        <li class="regular-text soft">Valable jusqu’au 30 avril 2023.</li>
                        <li class="regular-text soft">Ne peut être combiné à aucune autre offre. Certaines conditions s’appliquent.</li>
                      </ul>
    `,

    printemps_lodging_hero: "Économisez<br>jusqu'à<br><span>15 %</span><br>Sur l’hébergement",
    printemps_lodging_title: "Économisez jusqu'à 15 %<br>Sur l’hébergement",
    printemps_lodging_pop: "Économisez jusqu'à 15 %<br>Sur l’hébergement",
    printemps_lodging_pop_cta: 'Afficher les détails',
    printemps_lodging_cut: 'En vente jusqu\'au 29 avril',
    printemps_lodging_link: 'https://www.tremblant.ca/planifiez/hotels-condos?sc_lang=fr-CA',
    printemps_lodging_data: `
                      <h2 class="small-title">OFFRE « VIVE LE PRINTEMPS! » Économisez jusqu'à 15%.</h2>
                      <p class="regular-text bold">Lorsque vous réservez un séjour de 2 nuits ou plus !</p>
                      <p class="regular-text soft">Profitez de cette offre tout au long de l’hiver et du printemps, et même à Pâques! Jusqu’au 29 avril 2023, réservez un séjour de deux nuits et plus et obtenez :</p>
                      <div class="price-grid">
                        <div class="price half">
                          <p>15%<br><span>3 nuits ou +</span></p>
                        </div>
                        <div class="price half">
                          <p>10%<br><span>2 nuits</span></p>
                        </div>
                      </div>
    `,
    printemps_lodging_meta_title: "Économisez jusqu'à 15 % sur l’hébergement",
    printemps_lodging_meta_desc: 'Profitez de cette offre tout au long de l’hiver et du printemps, et même à Pâques! Jusqu’au 29 avril 2023, réservez un séjour de deux nuits et plus.',

    printemps_entrepot_hero: '<span class="smaller">Économisez<br>Jusqu’à<br><span>20%</span><br>de rabais sur les billets de ski',
    printemps_entrepot_hero_small: 'Économisez<br>Jusqu’à<br><span>20%</span><br>sur les billets de ski',
    printemps_entrepot_title: 'Économisez jusqu’à 20% de rabais sur les billets de ski',
    printemps_entrepot_pop: 'Jusqu’à 20% de rabais<br>sur les billets de ski',
    printemps_entrepot_pop_cta: 'Afficher les détails',
    printemps_entrepot_link: 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/fr-CA/?productcategoryid=117',
    printemps_entrepot_data: `
                      <h2 class="small-title">Économisez jusqu’à 20% de rabais sur les billets de ski</h2>
                      <p class="regular-text soft">Économiser jusqu’à 20 % à l’achat de 1 à 5 journées de glisse! Vous y trouverez des tarifs variables pour skier Tremblant en fonction de la date et du nombre de jours que vous choisirez. Quantités journalières limitées. Billets d’après-midi (½ jour) également disponibles pour skier à partir de midi.</p>
    `,
    printemps_entrepot_meta_title: 'Économisez jusqu’à 20% de rabais sur les billets de ski',
    printemps_entrepot_meta_desc: 'Économiser jusqu’à 20 % à l’achat de 1 à 5 journées de glisse! Vous y trouverez des tarifs variables pour skier Tremblant en fonction de la date et du nombre de jours que vous choisirez. Quantités journalières limitées. Billets d’après-midi (½ jour) également disponibles pour skier à partir de midi.',

    flash_mars_hero: 'Billets de ski à partir de<br><span>95$</span>',
    flash_mars_hero_small: 'Billets de ski à partir de<br><span>$95</span>',
    flash_mars_cut_off: 'En vente jusqu\'au 9 mars 2023, 13h.',
    flash_mars_title: 'Billets de ski à partir de 95$',
    flash_mars_pop: 'Billets de ski<br>à partir de 95$',
    flash_mars_pop_cta: 'Voir les détails',
    flash_mars_link: 'https://reservations.tremblant.ca/ecomm/shop/calendar/2624850/fr-CA/?productcategoryid=117&agecategory=8&days=1',
    flash_mars_data: `
                      <h2 class="small-title">Billets de ski à partir de 95$</h2>
                      <p class="regular-text soft">Obtenez des billets de ski pour profiter des pistes dès le 20 mars ! Du 7 mars, 13h, au 9 mars, 13h, visitez l’entrepôt de billets Tremblant et obtenez des billets de ski à partir de 95$.</p>
    `,
    flash_mars_meta_title: 'Billets de ski à partir de 95$',
    flash_mars_meta_desc: 'Obtenez des billets de ski pour profiter des pistes dès le 20 mars ! Du 7 mars, 13h, au 9 mars, 13h, visitez l’entrepôt de billets Tremblant et obtenez des billets de ski à partir de 95$.',

    renou_dink_link: 'https://www.ikonpass.com/fr/local-passes/tremblant/tonik-pass?currency=CAD&co-branded=tremblant',
    renou_dink_hero_title: 'Passe TONIK 2023-2024<br><span>Adulte (18-69)</span>',
    renou_dink_renewal_deal: 'Renouvellement*<br><span>599$</span>',
    renou_dink_new_deal: 'Nouveau détenteur<br><span>649$</span>',
    renou_dink_cut_off: 'Les tarifs augmentent le 21 avril 2023',
    renou_dink_pop: 'Passe TONIK 2023-2024<br>Adulte (18-69)',
    renou_dink_data: `
                              <h2 class="small-title">Passe TONIK 2023-2024<br>Renouvellement 599$<br>Nouveau détenteur 649$</h2>
                              <p class="regular-text">Les tarifs augmentent le 21 avril 2023</p>
                              <p class="regular-text soft">Procurez-vous la passe Tonik 2023-2024 dès maintenant au meilleur tarif de l’année et donnez du tonus à votre hiver en profitant des pentes presque toute la saison. La passe Tonik, c’est :</p>
                              <ul>
                                <li class="regular-text soft">Jusqu'à 112 jours de glisse et 3 flottants pour utilisation en tout temps, même durant la période des fêtes! (à l’exception des 27 au 30 décembre 2023 17 et 18 février 2024). <a href="https://medias.tremblant.ca/pdf/ski/calendrier-passe-tonik-2023-24.pdf" target="_blank">Voir le calendrier</a></li>
                                <li class="regular-text soft">Jusqu’à 26 journées de fin de semaine.</li>
                                <li class="regular-text soft">Des journées durant les relâches scolaires</li>
                                <li class="regular-text soft">15 % de rabais sur toutes les journées non incluses</li>
                                <li class="regular-text soft">Forfait de privilèges pour détenteurs à partir de 125 $ donnant accès aux Premières traces et à d’autres offres exclusives aux détenteurs !</li>
                                <li class="regular-text soft">Tarif de renouvellement offert exclusivement aux détenteurs de passe Tonik 2022-23.</li>
                                <li class="regular-text soft">Passe de randonnée alpine incluse.</li>
                                <li class="regular-text soft">La passe Tonik est offerte en exclusivité aux résidents canadiens</li>
                              </ul>
                              <p class="regular-text">Renouvellement</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>599$<br><span>Adulte (18-69)</span></p>
                                </div>
                                <div class="price">
                                  <p>399$<br><span>Étudiant (18+)</span></p>
                                </div>
                                <div class="price">
                                  <p>479$<br><span>Aîné (70+)</span></p>
                                </div>
                              </div>
                              <br>
                              <p class="regular-text italic">Vous aurez besoin des codes promos envoyés par la poste pour accéder à vos tarifs exclusifs de renouvellement.</p>
                              <br>
                              <p class="regular-text">Nouveau détenteur</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>649$<br><span>Adulte (18-69)</span></p>
                                </div>
                                <div class="price">
                                  <p>399$<br><span>Étudiant (18+)</span></p>
                                </div>
                                <div class="price">
                                  <p>519$<br><span>Aîné (70+)</span></p>
                                </div>
                              </div>
                              <br>
                              <p class="regular-text italic">Pour accéder au tarif étudiant, vous n'avez qu'à suivre les indications lors de l'achat en ligne.</p>
    `,
    renou_dink_meta_title: 'Passe TONIK 2023-2024 - Renouvellement à 599$',
    renou_dink_meta_desc: 'Procurez-vous la passe Tonik 2023-2024 dès maintenant au meilleur tarif de l’année et donnez du tonus à votre hiver en profitant des pentes presque toute la saison.',

    renou_famille_link: 'https://www.ikonpass.com/fr/local-passes/tremblant/tonik-pass?currency=CAD&co-branded=tremblant',
    renou_famille_hero_title: 'Passe TONIK 2023-2024<br><span>Enfant (5-12)</span>',
    renou_famille_renewal_deal: 'Renouvellement*<br><span>109$</span>',
    renou_famille_new_deal: 'Nouveau détenteur <br><span>119$</span>',
    renou_famille_cut_off: 'Les tarifs augmentent le 21 avril 2023',
    renou_famille_pop: 'Passe TONIK 2023-2024<br>Enfant (5-12)',
    renou_famille_data: `
                              <h2 class="small-title">Passe TONIK 2023-2024<br>Renouvellement 109$<br>Nouveau détenteur 119$</h2>
                              <p class="regular-text">Les tarifs augmentent le 21 avril 2023</p>
                              <p class="regular-text soft">Procurez-vous la passe Tonik 2023-2024 dès maintenant au meilleur tarif de l’année et donnez du tonus à votre hiver en profitant des pentes presque toute la saison. La passe Tonik, c’est :</p>
                              <ul>
                                <li class="regular-text soft">Jusqu'à 112 jours de glisse et 3 flottants pour utilisation en tout temps, même durant la période des fêtes! (à l’exception des 27 au 30 décembre 2023 17 et 18 février 2024). <a href="https://medias.tremblant.ca/pdf/ski/calendrier-passe-tonik-2023-24.pdf" target="_blank">Voir le calendrier</a></li>
                                <li class="regular-text soft">Jusqu’à 26 journées de fin de semaine.</li>
                                <li class="regular-text soft">Des journées durant les relâches scolaires</li>
                                <li class="regular-text soft">15 % de rabais sur toutes les journées non incluses</li>
                                <li class="regular-text soft">Forfait de privilèges pour détenteurs à partir de 125 $ donnant accès aux Premières traces et à d’autres offres exclusives aux détenteurs !</li>
                                <li class="regular-text soft">Tarif de renouvellement offert exclusivement aux détenteurs de passe Tonik 2022-23.</li>
                                <li class="regular-text soft">Passe de randonnée alpine incluse.</li>
                                <li class="regular-text soft">La passe Tonik est offerte en exclusivité aux résidents canadiens</li>
                              </ul>
                              <p class="regular-text">Renouvellement</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>109$<br><span>Enfant (5-12)</span></p>
                                </div>
                                <div class="price">
                                  <p>369$<br><span>Jeune (13-17)</span></p>
                                </div>
                                <div class="price">
                                  <p>49$<br><span>Peewee (0-4)</span></p>
                                </div>
                              </div>
                              <br>
                              <p class="regular-text italic">Vous aurez besoin des codes promos envoyés par la poste pour accéder à vos tarifs exclusifs de renouvellement.</p>
                              <br>
                              <p class="regular-text">Nouveau détenteur</p>
                              <div class="price-grid double">
                                <div class="price">
                                  <p>119$<br><span>Enfant (5-12)</span></p>
                                </div>
                                <div class="price">
                                  <p>399$<br><span>Jeune (13-17)</span></p>
                                </div>
                                <div class="price">
                                  <p>49$<br><span>Peewee (0-4)</span></p>
                                </div>
                              </div>
                              <br>
    `,
    renou_famille_meta_title: 'Passe TONIK 2023-2024 - Renouvellement à 109$',
    renou_famille_meta_desc: 'Procurez-vous la passe Tonik 2023-2024 dès maintenant au meilleur tarif de l’année et donnez du tonus à votre hiver en profitant des pentes presque toute la saison.',

    renou_popup_new_title: 'Nouvelle plateforme',
    renou_popup_new_desc: 'Tremblant intégrera la technologie RFID à ses installations pour la saison 2023-24. Par le fait même, l’achat de votre passe Tonik 23-24 se fera sur une nouvelle plateforme. L’accès à l’ensemble des remontées se fera désormais en traversant une barrière qui lira le contenu de votre passe grâce à l’identification par radiofréquence (RFID).',

  },
  dink_hiver_2023_experiences: [
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/events-dink.jpg',
      number: '56',
      titre: "JOURS D'ÉVÉNEMENTS<br>ET DE DIVERTISSEMENT",
      data: `
            <h3 class="small-title">Du divertissement tout au long de la saison</h3>
            <p class="regular-text">Électrisez votre séjour avec des événements diversifiés et effervescents cet hiver! Des concerts musicaux en passant par les événements sportifs et caritatifs, il y a de quoi emballer tous et chacun. Vibrez au son de la montagne!</p>
            <ul>
              <!--<li class="regular-text">Ouverture de la saison de ski</li>
              <li class="regular-text">24h Tremblant</li>-->
              <!--<li class="regular-text">Célébrations du temps des fêtes</li>
              <li class="regular-text">Jour de l’An</li>-->
              <li class="regular-text">Animation des relâches scolaires</li>
              <li class="regular-text">L'Après-ski Place Saint-Bernard</li>
              <li class="regular-text">Coupe Caribou</li>
              <li class="regular-text">Pâques</li>
              <li class="regular-text">Festival Rando Alpine</li>
              <li class="regular-text">Jamigos</li>
            </ul>
            <a href="https://www.tremblant.ca/quoi-faire/evenements?sc_lang=fr-CA" target="_blank" class="cta red">En savoir plus</a>
      `,
      cta: 'Éclatez-vous à Tremblant',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/restaurants-dink.jpg',
      number: '36',
      titre: 'Restaurants,<br />bars et cafés',
      data: `
            <h3 class="small-title">36 restaurants, bars et cafés</h3>
            <p class="regular-text">En séjournant à Tremblant, profitez d'une foule de restaurants traversant le centre de villégiature. Que ce soit sur l'heure du lunch ou après une journée de glisse sur les pentes enneigées, les saveurs n'attendent que vos papilles.</p>
      `,
      cta: 'Découvrez nos restaurants',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/activites-dink.jpg',
      number: '33',
      titre: "FAÇONS<br>DE S'AMUSER",
      data: `
            <h3 class="small-title">Tremblant: Là où on s'amuse abondamment</h3>
            <p class="regular-text">Bonifiez vos vacances à Tremblant et consultez notre éventail d'activités hivervales plus éclatées les unes que les autres. À Tremblant, il y a toujours une raison pour s'amuser au coeur d'un village bourdonnant!</p>
      `,
      cta: 'Explorez nos activités',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/hebergement-dink.jpg',
      number: '1900',
      titre: 'unités<br />d’hébergement',
      data: `
            <h3 class="small-title">Hébergement à proximité des pistes de ski et du village piétonnier</h3>
            <p class="regular-text">À flanc de montagne, au coeur du centre de villégiature ou aux abords des golfs, Tremblant regorge d'une grande variété de résidences adaptées à tous vos besoins. Un confort sur mesure qui saura vous ressourcer cet hiver!</p>
      `,
      cta: 'Découvrez nos hébergements',
    },
  ],
  famille_hiver_2023_experiences: [
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/events-famille-2.jpg',
      number: '56',
      titre: "JOURS D'ÉVÉNEMENTS<br>ET DE DIVERTISSEMENT",
      data: `
            <h3 class="small-title">Du divertissement tout au long de la saison</h3>
            <p class="regular-text">Électrisez votre séjour avec des événements diversifiés et effervescents cet hiver! Des concerts musicaux en passant par les événements sportifs et caritatifs, il y a de quoi emballer tous et chacun. Vibrez au son de la montagne!</p>
            <ul>
              <!--<li class="regular-text">Ouverture de la saison de ski</li>
              <li class="regular-text">24h Tremblant</li>-->
              <!--<li class="regular-text">Célébrations du temps des fêtes</li>
              <li class="regular-text">Jour de l’An</li>-->
              <li class="regular-text">Animation des relâches scolaires</li>
              <li class="regular-text">L'Après-ski Place Saint-Bernard</li>
              <li class="regular-text">Coupe Caribou</li>
              <li class="regular-text">Pâques</li>
              <li class="regular-text">Festival Rando Alpine</li>
              <li class="regular-text">Jamigos</li>
            </ul>
            <a href="https://www.tremblant.ca/quoi-faire/evenements?sc_lang=fr-CA" target="_blank" class="cta red">En savoir plus</a>
      `,
      cta: 'Éclatez-vous à Tremblant',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/restaurants-famille-2.jpg',
      number: '36',
      titre: 'Restaurants,<br />bars et cafés',
      data: `
            <h3 class="small-title">36 restaurants, bars et cafés</h3>
            <p class="regular-text">En séjournant à Tremblant, profitez d'une foule de restaurants traversant le centre de villégiature. Que ce soit sur l'heure du lunch ou après une journée de glisse sur les pentes enneigées, les saveurs n'attendent que vos papilles.</p>
      `,
      cta: 'Découvrez nos restaurants',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/activites-famille-brindo.jpg',
      number: '33',
      titre: "FAÇONS<br>DE S'AMUSER",
      data: `
            <h3 class="small-title">Tremblant: Là où on s'amuse abondamment</h3>
            <p class="regular-text">Bonifiez vos vacances à Tremblant et consultez notre éventail d'activités hivervales plus éclatées les unes que les autres. À Tremblant, il y a toujours une raison pour s'amuser au coeur d'un village bourdonnant!</p>
      `,
      cta: 'Explorez nos activités',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/hebergement-dink.jpg',
      number: '1900',
      titre: 'unités<br />d’hébergement',
      data: `
            <h3 class="small-title">Hébergement à proximité des pistes de ski et du village piétonnier</h3>
            <p class="regular-text">À flanc de montagne, au coeur du centre de villégiature ou aux abords des golfs, Tremblant regorge d'une grande variété de résidences adaptées à tous vos besoins. Un confort sur mesure qui saura vous ressourcer cet hiver!</p>
      `,
      cta: 'Découvrez nos hébergements',
    },
  ],
  general_hiver_2023_experiences: [
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/events-dink-2.jpg',
      number: '56',
      titre: "JOURS D'ÉVÉNEMENTS<br>ET DE DIVERTISSEMENT",
      data: `
            <h3 class="small-title">Du divertissement tout au long de la saison</h3>
            <p class="regular-text">Électrisez votre séjour avec des événements diversifiés et effervescents cet hiver! Des concerts musicaux en passant par les événements sportifs et caritatifs, il y a de quoi emballer tous et chacun. Vibrez au son de la montagne!</p>
            <ul>
              <li class="regular-text">Animation des relâches scolaires</li>
              <li class="regular-text">L'Après-ski Place Saint-Bernard</li>
              <li class="regular-text">Coupe Caribou</li>
              <li class="regular-text">Pâques</li>
              <li class="regular-text">Festival Rando Alpine</li>
              <li class="regular-text">Jamigos</li>
            </ul>
            <a href="https://www.tremblant.ca/quoi-faire/evenements?sc_lang=fr-CA" target="_blank" class="cta red">En savoir plus</a>
      `,
      cta: 'Éclatez-vous à Tremblant',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/restaurants-famille-2.jpg',
      number: '36',
      titre: 'Restaurants,<br />bars et cafés',
      data: `
            <h3 class="small-title">36 restaurants, bars et cafés</h3>
            <p class="regular-text">En séjournant à Tremblant, profitez d'une foule de restaurants traversant le centre de villégiature. Que ce soit sur l'heure du lunch ou après une journée de glisse sur les pentes enneigées, les saveurs n'attendent que vos papilles.</p>
      `,
      cta: 'Découvrez nos restaurants',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/activites-dink.jpg',
      number: '33',
      titre: "FAÇONS<br>DE S'AMUSER",
      data: `
            <h3 class="small-title">Tremblant: Là où on s'amuse abondamment</h3>
            <p class="regular-text">Bonifiez vos vacances à Tremblant et consultez notre éventail d'activités hivervales plus éclatées les unes que les autres. À Tremblant, il y a toujours une raison pour s'amuser au coeur d'un village bourdonnant!</p>
      `,
      cta: 'Explorez nos activités',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/hebergement-dink.jpg',
      number: '1900',
      titre: 'unités<br />d’hébergement',
      data: `
            <h3 class="small-title">Hébergement à proximité des pistes de ski et du village piétonnier</h3>
            <p class="regular-text">À flanc de montagne, au coeur du centre de villégiature ou aux abords des golfs, Tremblant regorge d'une grande variété de résidences adaptées à tous vos besoins. Un confort sur mesure qui saura vous ressourcer cet hiver!</p>
      `,
      cta: 'Découvrez nos hébergements',
    },
  ],
  printemps_hiver_2023_experiences: [
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/events-famille-3.jpg',
      position: "bottom",
      number: '56',
      titre: "JOURS D'ÉVÉNEMENTS<br>ET DE DIVERTISSEMENT",
      data: `
            <h3 class="small-title">Du divertissement tout au long de la saison</h3>
            <p class="regular-text">Électrisez votre séjour avec des événements diversifiés et effervescents cet hiver! Des concerts musicaux en passant par les événements sportifs et caritatifs, il y a de quoi emballer tous et chacun. Vibrez au son de la montagne!</p>
            <ul>
              <li class="regular-text">Festival Rando Alpine</li>
              <li class="regular-text">Jamigos</li>
              <li class="regular-text">L'Après ski Place Saint-Bernard</li>
              <li class="regular-text">Coupe Caribou</li>
              <li class="regular-text">Pâques</li>
            </ul>
            <a href="https://www.tremblant.ca/quoi-faire/evenements?sc_lang=fr-CA" target="_blank" class="cta red">En savoir plus</a>
      `,
      cta: 'Éclatez-vous à Tremblant',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/restaurants-dink-2.jpg',
      position: "bottom",
      number: '36',
      titre: 'Restaurants,<br />bars et cafés',
      data: `
            <h3 class="small-title">36 restaurants, bars et cafés</h3>
            <p class="regular-text">En séjournant à Tremblant, profitez d'une foule de restaurants traversant le centre de villégiature. Que ce soit sur l'heure du lunch ou après une journée de glisse sur les pentes enneigées, les saveurs n'attendent que vos papilles.</p>
      `,
      cta: 'Découvrez nos restaurants',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/activites-dink-2.jpg',
      position: "bottom",
      number: '33',
      titre: "FAÇONS<br>DE S'AMUSER",
      data: `
            <h3 class="small-title">Tremblant: Là où on s'amuse abondamment</h3>
            <p class="regular-text">Bonifiez vos vacances à Tremblant et consultez notre éventail d'activités hivervales plus éclatées les unes que les autres. À Tremblant, il y a toujours une raison pour s'amuser au coeur d'un village bourdonnant!</p>
      `,
      cta: 'Explorez nos activités',
    },
    {
      img: '/vue-app-static-img/quoi-faire-hiver-2023/hebergement-dink-2.jpg',
      position: "bottom",
      number: '1900',
      titre: 'unités<br />d’hébergement',
      data: `
            <h3 class="small-title">Hébergement à proximité des pistes de ski et du village piétonnier</h3>
            <p class="regular-text">À flanc de montagne, au coeur du centre de villégiature ou aux abords des golfs, Tremblant regorge d'une grande variété de résidences adaptées à tous vos besoins. Un confort sur mesure qui saura vous ressourcer cet hiver!</p>
      `,
      cta: 'Découvrez nos hébergements',
    },
  ],
  dink_hiver_2023_activity: [
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/patin-dink.jpg',
      titre: `
            <p class="big">Avantages</p>
            <p class="small-title">Hébergement</p>
      `,
      data: `<h3 class="small-title">Profitez d’avantages à proximité de votre hébergement lorsque vous séjournez à Tremblant</h3>
              <p class="regular-text">Réservez votre hébergement au centre de villégiature et découvrez la magie de Tremblant de jour comme de soir. Empruntez gratuitement des patins, de l’équipement de hockey-bottines et de l’équipement de glissade, des avantages offerts exclusivement aux invités en hébergement à Tremblant, et profitez de la patinoire illuminée ainsi que des autres activités au terrain de la chapelle Saint-Bernard. Un univers féérique facilement accessible, à seulement quelques pas de votre unité!</p>
      `,
      cta: 'Profitez des avantages',
    },
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/village-dink-2.jpg',
      titre: `
            <p class="big">Village</p>
            <p class="small-title">piétonnier</p>
      `,
      data: `<h3 class="small-title">Un village piétionnier à l'énergie bourdonnante!</h3>
              <p class="regular-text">Vivez l'expérience hivernale Tremblant et découvrez l'effervescence de notre village piétonnier au pied du mont Tremblant. Peu importe votre envie, vous trouverez l'activité qui convient.</p>
              <ul>
                <li class="regular-text">Activités intérieures</li>
                <!-- <li class="regular-text">Jardins de glace</li> -->
                <li class="regular-text">Brind'O</li>
                <li class="regular-text">Magasinage</li>
                <li class="regular-text">Casino de Mont-Tremblant</li>
                <li class="regular-text">Patinage</li>
                <li class="regular-text">Spas et massages</li>
                <li class="regular-text">Studio Créatif</li>
              </ul>
      `,
      cta: 'Découvrir le village',
    },
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/feerique-dink-2.jpg',
      titre: `
            <p class="big">Ambiance</p>
            <p class="small-title">Féérique</p>
      `,
      data: `<h3 class="small-title">Ambiance féérique au rendez-vous</h3>
              <p class="regular-text">Dominé d'un sommet majestueux, l'environnement Tremblant est source de souvenirs multiples. Divertissez-vous sur un site festif, coloré, enchanteur et vivez les joies de l'hiver!</p>
      `,
      cta: "S'en mettre plein la vue",
    },
  ],
  famille_hiver_2023_activity: [
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/patin-famille.jpg',
      titre: `
            <p class="big">Avantages</p>
            <p class="small-title">Hébergement</p>
      `,
      data: `<h3 class="small-title">Profitez d’avantages à proximité de votre hébergement lorsque vous séjournez à Tremblant</h3>
              <p class="regular-text">Réservez votre hébergement au centre de villégiature et découvrez la magie de Tremblant de jour comme de soir. Empruntez gratuitement des patins, de l’équipement de hockey-bottines et de l’équipement de glissade, des avantages offerts exclusivement aux invités en hébergement à Tremblant, et profitez de la patinoire illuminée ainsi que des autres activités au terrain de la chapelle Saint-Bernard. Un univers féérique facilement accessible, à seulement quelques pas de votre unité!</p>
      `,
      cta: 'Profitez des avantages',
    },
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/village-famille.jpg',
      titre: `
            <p class="big">Village</p>
            <p class="small-title">Piétonnier</p>
      `,
      data: `<h3 class="small-title">Un village piétionnier à l'énergie bourdonnante!</h3>
              <p class="regular-text">Vivez l'expérience hivernale Tremblant et découvrez l'effervescence de notre village piétonnier au pied du mont Tremblant. Peu importe votre envie, vous trouverez l'activité qui convient.</p>
              <ul>
                <li class="regular-text">Activités intérieures</li>
                <!-- <li class="regular-text">Jardins de glace</li> -->
                <li class="regular-text">Brind'O</li>
                <li class="regular-text">Magasinage</li>
                <li class="regular-text">Casino de Mont-Tremblant</li>
                <li class="regular-text">Patinage</li>
                <li class="regular-text">Spas et massages</li>
                <li class="regular-text">Studio Créatif</li>
              </ul>
      `,
      cta: 'Découvrir le village',
    },
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/feerique-dink-2.jpg',
      titre: `
            <p class="big">Ambiance</p>
            <p class="small-title">Féérique</p>
      `,
      data: `<h3 class="small-title">Ambiance féérique au rendez-vous</h3>
              <p class="regular-text">Dominé d'un sommet majestueux, l'environnement Tremblant est source de souvenirs multiples. Divertissez-vous sur un site festif, coloré, enchanteur et vivez les joies de l'hiver!</p>
      `,
      cta: "S'en mettre plein la vue",
    },
  ],
  general_hiver_2023_activity: [
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/patin-dink.jpg',
      titre: `
            <p class="big">Avantages</p>
            <p class="small-title">Hébergement</p>
      `,
      data: `<h3 class="small-title">Profitez d’avantages à proximité de votre hébergement lorsque vous séjournez à Tremblant</h3>
              <p class="regular-text">Réservez votre hébergement au centre de villégiature et découvrez la magie de Tremblant de jour comme de soir. Empruntez gratuitement des patins, de l’équipement de hockey-bottines et de l’équipement de glissade, des avantages offerts exclusivement aux invités en hébergement à Tremblant, et profitez de la patinoire illuminée ainsi que des autres activités au terrain de la chapelle Saint-Bernard. Un univers féérique facilement accessible, à seulement quelques pas de votre unité!</p>
      `,
      cta: 'Profitez des avantages',
    },
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/village-dink-2.jpg',
      titre: `
            <p class="big">Village</p>
            <p class="small-title">Piétonnier</p>
      `,
      data: `<h3 class="small-title">Un village piétionnier à l'énergie bourdonnante!</h3>
              <p class="regular-text">Vivez l'expérience hivernale Tremblant et découvrez l'effervescence de notre village piétonnier au pied du mont Tremblant. Peu importe votre envie, vous trouverez l'activité qui convient.</p>
              <ul>
                <li class="regular-text">Activités intérieures</li>
                <!-- <li class="regular-text">Jardins de glace</li> -->
                <li class="regular-text">Brind'O</li>
                <li class="regular-text">Magasinage</li>
                <li class="regular-text">Casino de Mont-Tremblant</li>
                <li class="regular-text">Patinage</li>
                <li class="regular-text">Spas et massages</li>
                <li class="regular-text">Studio Créatif</li>
              </ul>
      `,
      cta: 'Découvrir le village',
    },
    {
      video: '',
      img: '/vue-app-static-img/activity-hiver-2023/feerique-dink-2.jpg',
      titre: `
            <p class="big">Ambiance</p>
            <p class="small-title">Féérique</p>
      `,
      data: `<h3 class="small-title">Ambiance féérique au rendez-vous</h3>
              <p class="regular-text">Dominé d'un sommet majestueux, l'environnement Tremblant est source de souvenirs multiples. Divertissez-vous sur un site festif, coloré, enchanteur et vivez les joies de l'hiver!</p>
      `,
      cta: "S'en mettre plein la vue",
    },
  ],
  privileges: [
    {
      title: 'Premières traces',
      image: '/vue-app-static-img/privilege-premiere-trace-new.jpg',
      details: `<h3 class="small-title">Premières traces</h3>
                    <p class="regular-text">Skiez dès 7 h 45 du 16 décembre 2023 au 17 mars 2024, lors des journées de validité de votre passe.</p>
                   `,
    },
    {
      title: '20 cafés ou chocolats chauds',
      image: '/vue-app-static-img/privilege-boissons-chaudes-2.jpg',
      details: `<h3 class="small-title">20 cafés ou chocolats chauds</h3>
                    <p class="regular-text">Ajout de 20 cafés ou chocolats chauds sur votre passe de saison. Valable au Grand Manitou, Chalet des voyageurs, Fourchette du Diable, au Café Johannsen et au Nomade. Aucune limite d’achat par visite.</p>
                    <p class="regular-text">Valables du 23 novembre 2022 au 14 avril 2024.</p>
                   `,
    },
    {
      title: '15 % de rabais dans les cafétérias Tremblant',
      image: '/vue-app-static-img/privilege-rabais-cafeteria-new.jpg',
      details: `<h3 class="small-title">15 % de rabais dans les cafétérias Tremblant</h3>
                    <p class="regular-text">Applicable sur tous les produits à prix courant dans les cafétérias Tremblant suivantes : Le Grand Manitou, Chalet des voyageurs, La Fourchette du Diable, Café Johannsen et au Nomade. Non transférable.</p>
                    <p class="regular-text">Valable du 23 novembre 2022 au 14 avril 2024.</p>
                   `,
    },
    {
      title: '15 % de rabais dans les boutiques Tremblant',
      image: '/vue-app-static-img/privilege-rabais-boutiques-new.jpg',
      details: `<h3 class="small-title">15 % de rabais dans les boutiques Tremblant</h3>
                    <p class="regular-text">Applicable sur marchandise à prix courant dans les boutiques Tremblant participantes (à l’exception des produits Ugg, Canada Goose, Arc’téryx, Blundstone et tuques 24 h de Tremblant) : Nor Tremblant, Boutique Sommet des Neiges, Burton Tremblant, Chamonix, Columbia Tremblant, Explore!, Alpine, Le Skieur, Magasin de la Place, Le Manitou, Salomon Tremblant, Tremblant & CO. Non transférable.</p>
                    <p class="regular-text">Valable  du 1er septembre 2023  au 14 avril 2024.</p>
                   `,
    },
    {
      title: '200 $ dans les boutiques Tremblant',
      image: '/vue-app-static-img/privilege-carte-cadeau-new.jpg',
      details: `<h3 class="small-title">200 $ dans les boutiques Tremblant</h3>
                    <p class="regular-text">4 certificats de 50 $ chacun. Chaque certificat est applicable par tranche de 300 $, calculé après l’escompte de 15 % offert aux détenteurs et avant les taxes. Applicable sur tous les articles, même les articles en solde, sauf les uniformes et produits du Club de Ski Mont-Tremblant et du Club Élite Tremblant. Les certificats doivent être utilisés avant le 31 janvier 2023 dans les boutiques participantes : Nor Tremblant, Boutique Sommet des Neiges, Burton Tremblant, Chamonix, Columbia Tremblant, Explore!, Alpine, Le Skieur, Magasin de la Place, Le Manitou, Salomon Tremblant, Tremblant & CO. Non transférable.</p>
                    <p class="regular-text">Valable du 1er septembre 2023 au 31 janvier 2024.</p>
                   `,
    },
    {
      title: 'Une mise au point gratuite au ski shop',
      image: '/vue-app-static-img/privilege-mise-au-point.jpg',
      details: `<h3 class="small-title">Une mise au point gratuite au ski shop</h3>
                    <p class="regular-text">Ajout d’une mise au point pour le lendemain sur votre passe de saison à la boutique Le Ski Shop du Chalet des voyageurs. Non transférable.</p>
                    <p class="regular-text">Valable dès l'ouverture de la boutique Le Ski Shop au 14 avril 2024.</p>
                   `,
    },
  ],
  dink_hiver_2023_domaine: [
    {
      video: '',
      img: '/vue-app-static-img/domaine/pistes-2.jpg',
      titre: `
            <p class="big number">102</p>
            <p class="small-title">Pistes</p>
      `,
      data: `<h3 class="small-title">Éclatez-vous sur les pistes toute la saison</h3>
              <p class="regular-text">Dévalez les pistes de la plus haute montagne des Laurentides. Que vous aimiez les longues pistes, les sous-bois ou bien le dénivelé, Tremblant a de quoi vous procurer une expérience exaltante!</p>
      `,
      cta: 'Découvrez les pistes',
    },
    {
      video: '',
      img: '/vue-app-static-img/domaine/remontees-2.jpg',
      titre: `
            <p class="big number">14</p>
            <p class="small-title">Remontées</p>
      `,
      data: `<h3 class="small-title">Admirez la vue du sommet</h3>
              <p class="regular-text">Équipé de 14 remontées mécaniques, le mont Tremblant est LA montagne skiable d'exception en Amérique du Nord. Du village à la pente, explorez la montagne à votre façon!</p>
      `,
      cta: 'En savoir plus',
    },
    {
      video: '',
      img: '/vue-app-static-img/domaine/parcs-neige.jpg',
      titre: `
            <p class="big number">3</p>
            <p class="small-title">Parcs à neige</p>
      `,
      data: `<h3 class="small-title">Des sensations fortes garanties!</h3>
              <p class="regular-text">Tremblant propose 3 parcs à neige comprenant 60 modules variés pour vous accompagner tout au long de votre progression. Modules, rampes, sauts, pistes profilées... De quoi vous donner des sensations fortes toute la saison!</p>
      `,
      cta: 'Découvrez les parcs',
    },
  ],
  boxing_day_2023: {
    flash_boxing_day_title: "Offre<br/>d'après-Noël<br/>Carte Latitude",
    flash_boxing_day_promo: 'Skiez 3 à 4 jours pour <br/> <span>86$</span> <br/> par jour',
    flash_boxing_day_cutoff: "En vente jusqu'au <br/> 29 décembre 2022 13h.",
    flash_boxing_day_smallpopup: 'Skiez 3 à 4 jours pour 86$ par jour',
    flash_boxing_day_details: `
      <h2 class="small-title">
              <small>Carte Latitude</small>
              <br>
              Skiez 3 à 4 jours pour 86$
              <br>
              par jour
            </h2>
            <p class="regular-text">En vente jusqu'au 29 décembre 2022 13h.</p>
            <p class="regular-text soft">Skiez Tremblant à votre façon. De retour pour une période limitée! Du 27 décembre 13h au 29 décembre 13h, optez pour la carte Latitude pour vos sorties de ski à Tremblant cet hiver. Choisissez de 3 à 4 jours de glisse au plus bas tarif disponible:</p>
            <ul class="regular-text soft">
              <li>3 à 4 journées de ski ou de planche, consécutives ou non, pour 86 $ par jour, par adulte</li>
              <li>Les journées peuvent être utilisées à votre guise durant la saison de ski 2022-2023, à l’exception du 27 décembre 2022 au 5 janvier 2023 inclusivement et les 8 samedis suivants : 14, 21 et 28 janvier 2023, 11 et 18 février 2023, 4, 18 et 25 mars 2023.</li>
              <li>Jusqu’à 33&nbsp;% d’économies</li>
              <li>15 % de rabais sur les journées additionnelles (applicable sur un billet régulier 1-jour, tarification billetterie)</li>
              <li>La Carte Latitude est offerte en exclusivité aux résidents canadiens.</li>
            </ul>

            <div class="price-grid wrap">
              <div class="price half">
                <p>86$ <span>/jour</span><br><span>Adulte (18-69)</span></p>
              </div>
              <div class="price half">
                <p>74$ <span>/jour</span><br><span>Aîné (70+)</span></p>
              </div>
              <div class="price half">
                <p>65$ <span>/jour</span><br><span>Jeune (13-17)</span></p>
              </div>
              <div class="price half">
                <p>49$ <span>/jour</span><br><span>Enfant (5-12)</span></p>
              </div>
            </div>

            <br/>
            
            <p class="regular-text soft">N’hésitez pas à en profiter car, les billets de ski pour Tremblant seront en vente seulement via nos plateformes.</p>
          `,
  },
  flash_janvier_2023: {
    flash_janvier_deal1: 'Économisez <br/> jusqu’à <br/> <span>20&nbsp;%</span> <br/> sur l’hébergement',
    flash_janvier_deal2: 'Économisez <br/> jusqu’à <br/> <span>25&nbsp;%</span> <br/> sur les billets de ski',
    flash_janvier_cutoff: "En vente jusqu'au <br/> 12 janvier 2023 13h.",
    flash_janvier_smallpopup: "ÉCONOMISEZ JUSQU'À 20&nbsp;% <br/> SUR L’HÉBERGEMENT",
    flash_janvier_details: `<h2 class="small-title"><small>VENTE ÉCLAIR </small> <br/> VIVEMENT LES VACANCES D’HIVER À TREMBLANT</h2>

            <p class="regular-text soft">
              Réalisez de belles économies sur un séjour hivernal à lamontagne&nbsp;!
              <br/><br/>
              Cet hiver, faites l’expérience de la joie de vivre de Tremblant et profitez de la meilleure offre sur un séjour de 2 nuits ou plus&nbsp;! 
              <br/><br/>
              Vous cherchez le parfait endroit où passer une escapade d’hiver entre le 11 janvier et le 30 avril 2023? Réservez durant la vente éclair du 10 au 12 janvier 2023 et vous obtiendrez jusqu’à 20% de rabais! 
              <br/><br/>
              Bénéficiez de rabais additionnels sur vos billets de ski en visitant notre Entrepôt de billets&nbsp;!
            </p>`,
    flash_janvier_details2: `<h2 class="small-title">ENTREPÔT DE BILLETS <br/> TREMBLANT</h2>
            <h2 class="small-title">Jusqu’à 25% de rabais sur les billets de ski. </h2>
            <p class="regular-text soft">
              Obtenez des billets de ski pour profiter des pistes cet hiver! Du 10 janvier, 13h, au 12 janvier 13h, visitez l’entrepôt de billets Tremblant et économisez jusqu’à 25% de rabais sur les billets de ski. 
            </p>`,
  },
}
