import { CountUp } from 'countup.js'

export const initAnimationsLayer = () => {
  setTimeout(() => {
    // In View Observer trigger Anims
    const animSelector = '[data-inview]'
    const animElements = [].slice.call(document.querySelectorAll(animSelector))

    const animObserver = new IntersectionObserver(function (elems) {
      elems.forEach(function (elem) {
        if (elem.intersectionRatio > 0) {
          elem.target.classList.add('is-in-view')

          const player = elem.target.querySelector('lottie-player')
          if (player) {
            setTimeout(function () {
              player.load(player.getAttribute('data-src'))
              player.play()
            }, 1)
          }

          const isMobile = window.matchMedia('(max-width: 600px)').matches
          if (!isMobile) {
            const countElem = elem.target.querySelector('[data-count-up]')
            if (countElem) {
              const allCounts = [].slice.call(document.querySelectorAll('[data-count-up]'))
              allCounts.forEach(function (count) {
                const countUp = new CountUp(count, count.dataset.countUp, { useGrouping: false })
                countUp.start()
              })
            }
          }
        }
      })
    })

    setTimeout(function () {
      animElements.forEach(function (elem) {
        animObserver.observe(elem)
      })
    }, 1)
  }, 1)
}
