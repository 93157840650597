import { hiver2022En, hiver2022Fr } from './hiver-2022/index'
import { ete2022En, ete2022Fr } from './ete-2022/index'
import { hiver2023En, hiver2023Fr } from './hiver-2023/index'

const trans = {
  en: {
    ...hiver2022En,
    ...ete2022En,
    ...hiver2023En,
  },
  fr: {
    ...hiver2022Fr,
    ...ete2022Fr,
    ...hiver2023Fr,
  },
}

export default trans
